/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch, useParams, Redirect, RouteProps } from 'react-router-dom';
import { isLoggedIn } from 'kits/authKit';
import { setSessionSilent } from 'kits/sessionKit';

const ProtectedRoute: React.FC<RouteProps> = (props) => {
    setSessionSilent('@rememberedPath', window.location.pathname);

    return <>{isLoggedIn() ? <Route {...props} /> : <Redirect to="/login" />}</>;
};

export default ProtectedRoute;
