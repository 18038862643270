import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { useCurrentRoomId } from 'shared-state/location/hook';

export interface IIFrameViewProps {
    url: string;
    isHidden?: boolean;
    shareToken?: string;
    onMessage?: (message: any) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IFrame: React.FC<IIFrameViewProps> = ({ url, isHidden, shareToken, onMessage }) => {
    const [room] = useCurrentRoomId();
    const localProfile = useLocalProfile();

    const frameRef = useRef<any>();
    if (!url) return <></>;
    const frameURL = useMemo(
        () => url.replace('${roomId}', room ? room.split('_')[0] : '').replace('${userName}', encodeURIComponent(localProfile?.displayName || '')),
        [url]
    );

    const onMessageEvent = useCallback(
        (event: MessageEvent) => {
            if (!url.includes(event.origin)) return;
            onMessage && onMessage(event.data);
        },
        [url]
    );

    useEffect(() => {
        window.addEventListener('message', onMessageEvent);
        return () => {
            window.removeEventListener('message', onMessageEvent);
        };
    }, [onMessage, frameURL]);

    return (
        <>
            {frameURL && (
                <iframe
                    onLoad={(ev) => {
                        const el: any = ev.target;

                        if (shareToken != 'IKnowWhatIamDoing') return;
                        el.contentWindow?.postMessage({ eventName: 'tokens', eventData: { accessToken: localStorage.getItem('ivAccessToken') } }, '*');
                    }}
                    ref={frameRef.current}
                    src={frameURL}
                    frameBorder="0"
                    style={{ width: '100%', height: '100%', minHeight: '100%' }}
                />
            )}
        </>
    );
};
export default IFrame;
