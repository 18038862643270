/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { IProfile } from 'shared-state/identity/types';

export const getPresenceChannelNameForArea = (areaId?: string) => areaId && 'presence-' + areaId;

export const getVisitorChannelNameForArea = (areaId?: string) => areaId && 'presence-v-' + areaId;

export const isPresenceChannel = (channelName: string) => channelName.includes('presence-') && !channelName.includes('presence-v');
export const isVisitorChannel = (channelName: string) => channelName.includes('presence-v');

export const userProfileHasUpdated = (prevProfile: IProfile, newProfile: IProfile) => {
    return !(prevProfile.availability == newProfile.availability && prevProfile.room == newProfile.room);
};
