import * as React from 'react';

function SvgGalleryView(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 17h13v13H2V17zm2.167 2.167v8.666h8.666v-8.666H4.167zM2 2h13v13H2V2zm2.167 2.167v8.666h8.666V4.167H4.167zM17 2h13v13H17V2zm2.167 2.167v8.666h8.666V4.167h-8.666zM17 17h13v13H17V17zm2.167 2.167v8.666h8.666v-8.666h-8.666z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgGalleryView;
