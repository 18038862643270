import * as React from 'react';

function SvgBack(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M10.813 9.281L4.093 16l6.72 6.719 1.406-1.438L7.938 17H28v-2H7.937l4.282-4.281-1.406-1.438z" fill="#000" />
        </svg>
    );
}

export default SvgBack;
