/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Channel } from 'pusher-js';
import { pusherInstance } from './pusher/instance';

export const getChannel = (channelName: string): Channel | undefined => {
    const channel = pusherInstance?.channel(channelName);
    if (!channel) {
        console.warn('Channel not found (' + channelName + ')');
        console.log('These channels are available', pusherInstance?.allChannels());
        return;
    }
    return channel;
};

export const getChannelSubscribers = (channelName: string): string[] => {
    // Very hacky way of getting members from pusher js without causing type issues
    const channel = { ...{ members: { members: {}, get: (key: string) => ({ info: { sub: '' } }) } }, ...pusherInstance?.channel(channelName) };
    if (!channel) return [];
    const members = Object.keys(channel?.members.members).map((key) => channel?.members.get(key).info);
    console.log('[CHLM]', members);

    const memberIds = members.map((member) => member.sub.split(':')[2]);
    console.log('[CHLM]', memberIds);
    return memberIds;
};

export const send = (channelName: string, eventName: string, data: any) => {
    const channel = getChannel(channelName);

    console.log('SENDING ' + eventName);

    channel?.trigger(eventName, data);
};

export const bind = (channelName: string, eventName: string, handler: Function) => {
    const channel = getChannel(channelName);

    channel?.bind(eventName, handler);
};
export const unbind = (channelName: string, eventName: string, handler: Function) => {
    const channel = getChannel(channelName);

    channel?.unbind(eventName, handler);
};

export const subscribe = async (channelName: string) => {
    pusherInstance?.subscribe(channelName);
};
export const unsubscribe = (channelName: string) => {
    pusherInstance?.unsubscribe(channelName);
};

export const getSocketId = () => {
    return pusherInstance?.connection.socket_id;
};

export const getChannelNameForOptions = (options: any) => {
    const { channelType, campusId, areaId } = options;
    const channelName = (channelType == 'visitor' ? 'presence-v-' : 'presence-') + areaId + '__' + campusId;

    return channelName;
};
