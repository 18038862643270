import React from 'react';
import MainView from 'routes/Main/Main.view';
import { useMainState } from './Main.state';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Main: React.FC<{}> = ({ children }) => {
    const state = useMainState();

    return (
        <>
            {state.currentRoomId && state.currentAreaId && (
                <MainView
                    {...{
                        ...state
                    }}
                />
            )}
        </>
    );
};

export default Main;
