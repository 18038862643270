import React from 'react';
import { DefaultButton } from '@fluentui/react';

interface IResetWizardViewProps {
    onClickEvent: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ResetWizardView: React.FC<IResetWizardViewProps> = ({ children, onClickEvent }) => {
    return <DefaultButton onClick={onClickEvent}>Reset </DefaultButton>;
};

export default ResetWizardView;
