import { Stack } from '@fluentui/react';
import DraggableItem from 'components/DraggableItem/DraggableItem';
import UserFace from 'features/Rooms/RoomList/UserFace/UserFace';
import { UserFaceOverflowButton } from 'features/Rooms/RoomList/UserFaceOverflowButton/UserFaceOverflowButton';
import React from 'react';
import { IUserFacepileProps } from './UserFacepile';

const UserFacepileView: React.FC<IUserFacepileProps> = ({ children, users, draggable, actionStyles, panelTitle }) => {
    return (
        <Stack horizontal grow tokens={{ childrenGap: 8 }}>
            {children}
            {users &&
                users.slice(0, 4).map((user: any, index: any) =>
                    draggable ? (
                        <DraggableItem key={user.uid} draggableId={'user-' + user.uid} index={0}>
                            <UserFace index={index} user={user} showDetails={users.length <= 1} actionStyles={actionStyles} />
                        </DraggableItem>
                    ) : (
                        <UserFace key={user.uid} index={index} user={user} showDetails={users.length <= 1} actionStyles={actionStyles} />
                    )
                )}
            {users.length > 4 && <UserFaceOverflowButton users={users} overflowIndex={4} panelTitle={panelTitle} />}
        </Stack>
    );
};

export default UserFacepileView;
