import { DefaultButton, Modal, Text, PrimaryButton, Stack, TextField, Label } from '@fluentui/react';
import CopyButton from 'components/CopyButton/CopyButton';
import React from 'react';

interface ICreateNewLinkModalViewProps {
    isOpen?: boolean;
    onDismiss?: Function;
    linkToCopy?: string;
    description: string;
    setDescription: React.Dispatch<React.SetStateAction<string>>;
    _onDismiss: () => void;
    _onSubmit: any;
}

const CreateNewLinkModalView: React.FC<ICreateNewLinkModalViewProps> = ({
    isOpen,
    linkToCopy,
    onDismiss,
    description,
    setDescription,
    _onDismiss,
    _onSubmit
}) => {
    return (
        <Modal isOpen={isOpen} onDismiss={() => onDismiss && onDismiss()}>
            {linkToCopy ? (
                <Stack tokens={{ childrenGap: 8 }} style={{ padding: 16, minHeight: 176 }}>
                    <Stack>
                        <Text variant="large">New link successfully created!</Text>
                    </Stack>
                    <Stack grow verticalAlign="center">
                        <Label>{description} </Label>
                        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
                            <TextField style={{ minWidth: 180 }} readOnly value={linkToCopy} />
                            <CopyButton value={linkToCopy} />
                        </Stack>
                    </Stack>
                    <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 8 }}>
                        <PrimaryButton text="Finish" onClick={_onDismiss} />
                    </Stack>
                </Stack>
            ) : (
                <Stack tokens={{ childrenGap: 8 }} style={{ padding: 16 }}>
                    <Text variant="large">Create new link...</Text>
                    <TextField
                        label="Description"
                        value={description}
                        onChange={(ev, newValue) => {
                            newValue && setDescription(newValue);
                        }}
                    />
                    <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 8 }}>
                        <DefaultButton text="Cancel" onClick={_onDismiss} />
                        <PrimaryButton text="Create Link" onClick={_onSubmit} />
                    </Stack>
                </Stack>
            )}
        </Modal>
    );
};

export default CreateNewLinkModalView;
