import * as React from 'react';

function SvgShape(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 29 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M27.907 1.14a1.25 1.25 0 01-.047 1.767l-19 18a1.25 1.25 0 01-1.775-.055l-6.75-7.25a1.25 1.25 0 011.83-1.704l5.89 6.327L26.14 1.093a1.25 1.25 0 011.767.047z"
                fill="#00CF21"
            />
        </svg>
    );
}

export default SvgShape;
