import { PivotItem } from '@fluentui/react';
import { signOut } from 'kits/authKit';
import IvicosStrings from 'kits/stringKit';
import React from 'react';
import { useHistory } from 'react-router';
import { useIdentityClaims } from 'shared-state/identity/hooks';
import { IIdentityClaims } from 'shared-state/identity/types';

export interface IIdentityPanelAction {
    title: string;
    action?: (e: any) => void;
    key: string;
    disabled?: boolean;
    symbol: string;
}

export interface IIdentityPanelState {
    // onPanelDismiss: Function;
    actions: IIdentityPanelAction[];
    selectedKey: string;
    userDisplayName: string;
    identityClaims?: IIdentityClaims;
    handleLinkClick: (item?: PivotItem | undefined) => void;
}

export const useIdentityPanelState: () => IIdentityPanelState = () => {
    const [identity] = useIdentityClaims();
    const userDisplayName = identity?.displayName || '';

    const routeHistory = useHistory();
    const actions = [
        {
            key: 'switch-org',
            title: 'Switch Org',
            symbol: 'EMI',
            disabled: identity?.roles.includes('visitor'),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: (e: any) => {
                routeHistory.push('/switch');
            }
        },
        {
            key: 'sign-out',
            title: IvicosStrings.signOutActionTitle,
            symbol: 'PowerButton',
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: (e: any) => {
                signOut(false);
            }
        }
    ];

    const [selectedKey, setSelectedKey] = React.useState('campus');

    const handleLinkClick = (item?: PivotItem) => {
        item && setSelectedKey(item?.props.itemKey || '');
    };
    const [identityClaims] = useIdentityClaims();

    return { actions, userDisplayName, selectedKey, handleLinkClick, identityClaims };
};
