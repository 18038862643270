import { DefaultButton, Icon, Pivot, PivotItem, Stack, Text } from '@fluentui/react';
import { IIdentityPanelState } from 'features/IdentityPanel/IdentityPanel.state';
import React from 'react';
import AvailabilitySelector from './children/AvailabilitySelector/AvailabilitySelector';
import PersonalPreferences from './children/PersonalPreferences/PersonalPreferences';
import UserProfile from './children/UserProfile/UserProfile';

const IdentityPanelView: React.FC<IIdentityPanelState> = (props) => {
    const { actions, userDisplayName, handleLinkClick, identityClaims, selectedKey } = props;

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Stack style={{ maxHeight: 256, minHeight: 256, overflow: 'hidden' }}>
                <Pivot
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    onLinkClick={handleLinkClick}
                    selectedKey={selectedKey}
                >
                    {!identityClaims?.roles.includes('visitor') && (
                        <PivotItem headerText="Status" itemKey="status">
                            <AvailabilitySelector />
                        </PivotItem>
                    )}
                    <PivotItem headerText="Profile" itemKey="edit-profile">
                        <UserProfile />
                    </PivotItem>
                    {!identityClaims?.roles.includes('visitor') && (
                        <PivotItem headerText="Preferences" itemKey="preferences">
                            <PersonalPreferences />
                        </PivotItem>
                    )}
                </Pivot>
            </Stack>

            <Stack horizontal horizontalAlign="start" verticalAlign="center" tokens={{ childrenGap: 4 }}>
                <Icon iconName="Contact"></Icon>
                <Text>
                    Logged in as <b>{userDisplayName}</b>
                </Text>
            </Stack>
            <Stack horizontal horizontalAlign="space-around" wrap tokens={{ childrenGap: 8 }} style={{ marginBottom: '32px' }}>
                {actions &&
                    actions.map((action) => (
                        <DefaultButton
                            key={action.key}
                            disabled={action.disabled}
                            iconProps={{
                                iconName: action.symbol
                            }}
                            onClick={(e) => {
                                action.action && action.action(e);
                            }}
                        >
                            {action.title}
                        </DefaultButton>
                    ))}
            </Stack>
        </Stack>
    );
};

export default IdentityPanelView;
