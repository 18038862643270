/* eslint-disable react/display-name */
import { IC } from 'components/SVGIcon';
import { Text, getTheme, DefaultEffects, PersonaSize, Persona, Stack, mergeStyles, IPersonaCoinProps, IconButton, TooltipHost } from '@fluentui/react';
import HoverCardHost from 'components/HoverCardHost/HoverCardHost';
import { useOrganizationBranding } from 'features/Header/hooks';
import { availabilityToPersonaPresence, UserAvailability } from 'kits/availabilityKit';
import React from 'react';
import { IProfile } from 'shared-state/identity/types';
import { useCurrentOrgId } from 'shared-state/location/hook';
import Badge from 'components/Badge';
import { IUserAction } from '../helpers/userTypes';

export interface IUserFaceActionStyles {
    hidden?: boolean;
    expanded?: boolean;
}

export interface IUserFaceViewProps {
    user: IProfile;
    index: number;
    showDetails?: boolean;
    actionStyles?: IUserFaceActionStyles;
    profileImageURL?: string;
    actionsShouldBeDisplayOnHover: boolean;
    detailsAreHidden: boolean;
    defaultAvailibility: UserAvailability;
    actions: IUserAction[];
}

const UserFaceView: React.FC<IUserFaceViewProps> = ({
    user,
    index,
    showDetails,
    actionStyles,
    profileImageURL,
    actionsShouldBeDisplayOnHover,
    detailsAreHidden,
    defaultAvailibility,
    actions
}) => {
    const theme = getTheme();

    const [orgId] = useCurrentOrgId();
    const [branding] = useOrganizationBranding(orgId);

    const customCoinClass = mergeStyles({
        borderRadius: 25,
        border: '3px solid ' + branding?.orgColors
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const coinProps: IPersonaCoinProps = {
        className: customCoinClass
    };

    const renderActions = () =>
        actions?.map((action) => (
            <TooltipHost key={action.key} content={action.title}>
                <IconButton
                    onClick={() => action.action()}
                    onRenderIcon={() => (
                        <IC variant="dark" size={16}>
                            {action.onRenderIcon && action.onRenderIcon()}
                        </IC>
                    )}
                />
            </TooltipHost>
        ));

    // const colors = Object.values(PersonaInitialsColor);
    // const prop = colors[Math.floor(Math.random() * colors.length)]

    return (
        <Stack className="hoverable" horizontal style={{ position: 'relative', overflow: 'visible' }} verticalAlign="center" horizontalAlign="space-between">
            {(actionsShouldBeDisplayOnHover || detailsAreHidden) && (
                <Stack
                    horizontalAlign={index <= 2 ? 'start' : 'end'}
                    className="display--on-parent-hover"
                    style={{ position: 'absolute', zIndex: 300, bottom: 30, left: index > 2 ? undefined : 16, right: index <= 2 ? undefined : 16, width: 300 }}
                ></Stack>
            )}
            <HoverCardHost
                disabled={!actionsShouldBeDisplayOnHover && !detailsAreHidden}
                content={
                    <Stack
                        horizontal
                        className="padding--small "
                        verticalAlign="center"
                        tokens={{ childrenGap: 4 }}
                        style={{ minHeight: 32, borderRadius: 4, boxShadow: DefaultEffects.elevation16, background: theme.palette.white }}
                    >
                        {actionsShouldBeDisplayOnHover && (
                            <>
                                <Stack horizontal>{renderActions()}</Stack>
                                {detailsAreHidden && <div style={{ width: 1, height: 24, minHeight: 24, background: theme.palette.neutralLight }} />}
                            </>
                        )}
                        {detailsAreHidden && <Text className={'padding_horizontal--small'}>{user.displayName}</Text>}
                    </Stack>
                }
            >
                {user.type == 'visitor' ? (
                    <Stack>
                        <Stack horizontal verticalAlign="center">
                            <Stack horizontalAlign="center" verticalAlign="start" style={{ width: '40px', paddingRight: 10, marginLeft: 5 }}>
                                <Badge text={user.displayName} size="51" fontSize="medium" />
                            </Stack>
                            {showDetails && (
                                <Text variant="medium" style={{ color: theme.palette.black, marginLeft: 7 }}>
                                    {user.displayName.split(' ').length > 1
                                        ? `${user.displayName.split(' ')[0]} ${user.displayName.split(' ')[1]}`
                                        : `${user.displayName.split(' ')[0]}`}
                                </Text>
                            )}
                        </Stack>
                    </Stack>
                ) : (
                    <Persona
                        size={PersonaSize.size40}
                        text={user.displayName}
                        imageUrl={profileImageURL}
                        hidePersonaDetails={!showDetails}
                        presence={availabilityToPersonaPresence(user.availability || defaultAvailibility)}
                    />
                )}

                {showDetails && !actionStyles?.hidden && actionStyles?.expanded && <Stack horizontal>{renderActions()}</Stack>}
            </HoverCardHost>
        </Stack>
    );
};

export default UserFaceView;
