/* eslint-disable react/display-name */

import { Stack, Text } from '@fluentui/react';
import React from 'react';
import { IDynamicFormElementProps } from './DynamicForm';

const DynamicFormView: React.FC<{ formFields: IDynamicFormElementProps[]; ElemDictionary: any }> = (props) => {
    const { formFields, ElemDictionary } = props;

    return (
        <>
            <Stack tokens={{ childrenGap: 16 }}>
                {formFields.map((elem: IDynamicFormElementProps) => (
                    <Stack key={elem.key} tokens={{ childrenGap: 8 }}>
                        {ElemDictionary[elem.type] && ElemDictionary[elem.type](elem)}
                        {elem.description && <Text variant="small">{elem.description}</Text>}
                    </Stack>
                ))}
            </Stack>
        </>
    );
};

export default DynamicFormView;
