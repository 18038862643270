import {
    Callout,
    DefaultButton,
    DefaultEffects,
    DelayedRender,
    DetailsList,
    Dialog,
    DialogFooter,
    DialogType,
    DirectionalHint,
    IColumn,
    Link,
    mergeStyleSets,
    Modal,
    Panel,
    Persona,
    PersonaSize,
    PrimaryButton,
    ScrollablePane,
    Stack,
    Text,
    TextField,
    Toggle
} from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { useTheme } from '@fluentui/react-theme-provider';
import DynamicForm from 'components/DynamicForm/DynamicForm';
import { IC } from 'components/SVGIcon';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import { sendEvent } from 'kits/eventKit';
import { Close, InfoBubble, QuestionCircle, Shape } from 'kits/IconKit2';
import React from 'react';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import 'styles/introjs-custom.css';
import { getClassNames } from 'styles/responsiveness';
import { ICampusSettingsState } from './CampusSettings.state';

export interface IMember {
    userId: string;
    profile_pic: string;
    name: string;
    email: string;
    role: 'user' | 'manager' | 'owner' | 'invited';
}

const CampusSettingsView: React.FC<ICampusSettingsState> = (props) => {
    const {
        microsoftProps,
        currentUser,
        successModal,
        setSuccessModal,
        isFormModalOpen,
        showFormModal,
        hideFormModal,
        titleId,
        colorButtonId,
        campus,
        creator,
        ownerId,
        managers,
        filteredManagers,
        setFilteredManagers,
        selectedManager,
        setSelectedManager,
        hideTransferOwnershipDialog,
        toggleHideTransferOwnershipDialog,
        hideTransferOwnershipConfirmationDialog,
        toggleHideTransferOwnershipConfirmationDialog,
        hideTransferOwnershipErrorDialog,
        toggleHideTransferOwnershipErrorDialog: toggleHideTransferOwnershipErrorDialog,
        onCampusFormChange,
        onUpdateCampus,
        fetchManagers,
        filterManagers,
        selectManagerUsingName,
        selectManagerUsingEmail,
        transferOwnership,
        setChanges,
        changes,
        formFields,
        stepsEnabled,
        setStepsEnabled,
        isOpen,
        openPanel,
        dismissPanel,
        isDisable,
        disableButton,
        notDisableButton,
        theme,
        fetchCampus
    } = props;
    const iconProps = { iconName: 'Search' };

    const managerColumns: IColumn[] = [
        {
            key: 'column1',
            name: 'Details of managers',
            ariaLabel: 'Member names',
            fieldName: 'name',
            minWidth: 128,
            maxWidth: 512,
            isPadded: true,
            onRender: (user: IMember) => userImage(user)
        }
    ];

    const transferOwnershipDialogContentProps = {
        type: DialogType.normal,
        title: 'Transfer ownership?',
        closeButtonAriaLabel: 'Close',
        subText: `Clicking confirm changes ownership rights to "${selectedManager[0]?.name}" and you will become a manager.`
    };

    const classNames = mergeStyleSets({
        scrollList: {
            marginLeft: 20,
            overflowX: 'hidden',
            overflowY: 'scroll',
            maxHeight: 300
        },

        transferLink: {
            marginTop: 20
        }
    });

    function _onMsLinkToggle(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
        if (checked) {
            confirm('Are you sure?') === true ? microsoftProps.setMicrosoftTenantIsInvited(true) : !checked;
        }
        return microsoftProps.setMicrosoftTenantIsInvited(false);
    }

    const directionalHint = DirectionalHint.bottomCenter;

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);

    const msButtonId = useId('callout-button');

    const styles = mergeStyleSets({
        button: {
            width: 130
        },
        callout: {
            width: 320,
            padding: '20px 24px'
        }
    });

    const stepsSettings = {
        stepsEnabled: true,
        initialStep: 0,
        steps: [
            {
                element: '.profile-picture',
                intro: 'This section contains all the information regarding your account at ivCAMPUS and lets you choose and set a personal profile image.',
                tooltipClass: 'introjs-tooltip-dude'
            },
            {
                element: '.display-settings',
                intro: 'Here you can set your ivCAMPUS name, brand color and logo.',
                position: 'right'
            }
        ],
        hintsEnabled: false
    };

    const options = {
        doneLabel: 'Ok!',
        hideNext: false,
        showStepNumbers: true,
        showBullets: false,
        hidePrev: true
    };

    const { settingsOwnerCard, settingsGeneralCard } = getClassNames();
    const { smallDevice, mediumDevice, smallLaptop } = useWindowWidth();

    const userImage = (user: IMember) => <Persona size={PersonaSize.size40} imageUrl={user.profile_pic} text={user.name} secondaryText={user.email}></Persona>;

    return (
        <>
            {smallDevice && (
                <Stack style={{ padding: '26px 0px 13px 33px' }}>
                    <Text variant="large">Campus settings</Text>
                </Stack>
            )}
            <Stack grow style={{ position: 'relative', height: 'calc( 80vh - 140px )' }}>
                <ScrollablePane style={{ height: '100%', minHeight: '100%', margin: '0px 30px' }}>
                    <Stack horizontal={!smallDevice && !mediumDevice && !smallLaptop} tokens={{ childrenGap: 48 }} verticalAlign="start">
                        <Stack>
                            <Stack horizontalAlign="start" tokens={{ childrenGap: 16 }} className={settingsOwnerCard}>
                                <Text variant={smallDevice ? 'large' : 'xLarge'} style={{ color: theme.palette.black }}>
                                    Ownership
                                </Text>
                                <Stack style={{ width: '100%' }}>
                                    <Persona
                                        size={smallDevice ? PersonaSize.size32 : PersonaSize.size120}
                                        imageUrl={creator?.profile_pic}
                                        text={creator?.name}
                                        secondaryText={creator?.email}
                                    ></Persona>
                                </Stack>
                                {currentUser?.uid === ownerId && (
                                    <Link
                                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                        onClick={(e) => {
                                            openPanel();
                                            fetchManagers();
                                        }}
                                        className={classNames.transferLink}
                                    >
                                        <Text>Transfer ownership</Text>
                                    </Link>
                                )}
                            </Stack>
                        </Stack>

                        <Stack tokens={{ childrenGap: 16 }} className={`${settingsGeneralCard} display-settings`}>
                            <Text variant={smallDevice ? 'large' : 'xLarge'} style={{ color: theme.palette.black }}>
                                Brand identity
                            </Text>

                            <DynamicForm formFields={formFields} onChange={onCampusFormChange} />
                            <Stack horizontalAlign="end" horizontal>
                                <PrimaryButton
                                    text="Save"
                                    iconProps={{ iconName: 'Save' }}
                                    onClick={() => {
                                        setSuccessModal(false);
                                        showFormModal();
                                    }}
                                />
                            </Stack>
                        </Stack>
                        {microsoftProps.isMicrosoftUser && (
                            <Stack
                                style={{
                                    width: smallDevice ? '100%' : 380,
                                    background: theme.palette.white,
                                    padding: '16px 26px',
                                    borderRadius: 2,
                                    boxShadow: DefaultEffects.elevation4
                                }}
                            >
                                <Stack horizontal={false} tokens={{ childrenGap: 16 }}>
                                    <Text variant={smallDevice ? 'large' : 'xLarge'} style={{ color: theme.palette.black }}>
                                        Access method
                                    </Text>
                                    {microsoftProps.isMicrosoftUser && (
                                        <Stack>
                                            <Stack horizontal horizontalAlign="space-around">
                                                <Toggle onChange={_onMsLinkToggle} checked={microsoftProps.microsoftTenantIsInvited} />
                                                <Stack style={{ width: '100%', paddingLeft: '10px' }}>
                                                    {microsoftProps.microsoftTenantIsInvited ? (
                                                        <Text
                                                            variant="medium"
                                                            style={
                                                                smallDevice
                                                                    ? { width: '100%', color: theme.palette.black }
                                                                    : { width: '75%', color: theme.palette.black }
                                                            }
                                                        >
                                                            Everyone from your Microsoft organisation is asked to join this campus on sign-up.
                                                        </Text>
                                                    ) : (
                                                        <Text
                                                            variant="medium"
                                                            style={
                                                                smallDevice
                                                                    ? { width: '100%', color: theme.palette.black }
                                                                    : { width: '70%', color: theme.palette.black }
                                                            }
                                                        >
                                                            Switch on MS active directory sync for all ivCAMPUS members.
                                                        </Text>
                                                    )}
                                                </Stack>
                                                {!smallDevice && (
                                                    <Stack verticalAlign="center" style={{ width: '20px' }}>
                                                        <IC variant="custom" size={15} style={{ cursor: 'pointer' }}>
                                                            <QuestionCircle onClick={toggleIsCalloutVisible} id={msButtonId} />
                                                        </IC>
                                                    </Stack>
                                                )}
                                                {isCalloutVisible && (
                                                    <Callout
                                                        className={styles.callout}
                                                        onDismiss={toggleIsCalloutVisible}
                                                        role="status"
                                                        target={`#${msButtonId}`}
                                                        aria-live="assertive"
                                                        directionalHint={directionalHint}
                                                    >
                                                        <DelayedRender>
                                                            <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="start">
                                                                <IC size={24} variant="dark">
                                                                    <InfoBubble />
                                                                </IC>
                                                                <Stack>
                                                                    <Text variant="medium" style={{ marginBottom: 5 }}>
                                                                        You can link your ivCAMPUS to your Microsoft organisation.{' '}
                                                                    </Text>
                                                                    <Text variant="medium">
                                                                        This way everyone in your Microsoft organisation will be asked to join this ivCAMPUS on
                                                                        sign-up.
                                                                    </Text>
                                                                </Stack>
                                                            </Stack>
                                                        </DelayedRender>
                                                    </Callout>
                                                )}
                                            </Stack>
                                        </Stack>
                                    )}
                                </Stack>
                            </Stack>
                        )}
                    </Stack>
                </ScrollablePane>
            </Stack>

            <Panel isOpen={isOpen} onDismiss={dismissPanel} closeButtonAriaLabel="Close">
                <Stack>
                    <Text variant="xLarge">Transfer ownership</Text>
                </Stack>
                <Stack style={{ flexDirection: 'column', height: '100px', justifyContent: 'space-around', marginTop: 70 }}>
                    <Stack style={{ margin: 10, padding: 10, width: '100%' }}>
                        <Text variant="medium">
                            When transferring ownership you will no longer be the owner of your campus. Please be aware of this before transferring!
                        </Text>
                    </Stack>
                    <Stack style={{ margin: 10, paddingLeft: 10, width: '100%' }}>
                        <Text>List of current managers</Text>
                    </Stack>

                    <Stack style={{ margin: 10, padding: 10, width: '100%' }}>
                        <TextField
                            placeholder={'Search managers'}
                            iconProps={iconProps}
                            onChange={(e: any) => {
                                filterManagers(e.target.value);
                            }}
                        />
                    </Stack>
                </Stack>

                <br />
                <br />

                <Stack
                    onClick={(e: any) => {
                        const selectedOne = e.target.innerText;
                        const emailExp = /\S+@\S+\.\S+/;
                        if (emailExp.test(selectedOne)) {
                            selectManagerUsingEmail(selectedOne);
                            notDisableButton();
                        } else {
                            selectManagerUsingName(selectedOne);
                            notDisableButton();
                        }
                    }}
                >
                    <DetailsList
                        items={filteredManagers.length === 0 ? managers : filteredManagers}
                        columns={managerColumns}
                        checkboxVisibility={2}
                        className={classNames.scrollList}
                    />
                </Stack>

                <Stack
                    style={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '100px',
                        justifyContent: 'space-around',
                        marginLeft: 10
                    }}
                >
                    <Stack style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-around' }}>
                        <DefaultButton
                            text="Cancel"
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            onClick={(e) => {
                                dismissPanel();
                                disableButton();
                                setFilteredManagers([]);
                            }}
                        />
                        <PrimaryButton
                            text="Transfer ownership"
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            onClick={(e) => {
                                toggleHideTransferOwnershipDialog();
                            }}
                            disabled={isDisable}
                        />
                    </Stack>
                </Stack>
            </Panel>

            <Dialog
                hidden={hideTransferOwnershipDialog}
                onDismiss={() => {
                    toggleHideTransferOwnershipDialog();
                }}
                dialogContentProps={transferOwnershipDialogContentProps}
            >
                <DialogFooter>
                    <DefaultButton
                        onClick={() => {
                            setFilteredManagers([]);
                            setSelectedManager([]);
                            disableButton();
                            toggleHideTransferOwnershipDialog();
                        }}
                        text={'Cancel'}
                    />
                    <PrimaryButton
                        onClick={() => {
                            toggleHideTransferOwnershipDialog();
                            transferOwnership();
                        }}
                        text={'Confirm'}
                    />
                </DialogFooter>
            </Dialog>

            <Dialog
                hidden={hideTransferOwnershipConfirmationDialog}
                onDismiss={() => {
                    toggleHideTransferOwnershipConfirmationDialog();
                }}
            >
                <Stack>
                    <Stack style={{ flexDirection: 'row', justifyContent: 'flex-start', marginTop: '-30px' }}>
                        <IC size={20} variant="custom">
                            <Shape id={colorButtonId} />
                        </IC>

                        <Text variant="xLarge" style={{ marginLeft: 10 }}>
                            Ownership transferred
                        </Text>
                    </Stack>

                    <Text style={{ marginLeft: 30, marginTop: 10, marginRight: 0 }}>
                        Please sign out from the campus and then sign in again to implement the changes.
                    </Text>
                </Stack>

                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            toggleHideTransferOwnershipConfirmationDialog();
                            setSelectedManager([]);
                            setFilteredManagers([]);
                            disableButton();
                            window.location.reload();
                        }}
                        text={'Close'}
                    />
                </DialogFooter>
            </Dialog>

            {/* Modal to show error */}
            <Dialog
                hidden={hideTransferOwnershipErrorDialog}
                onDismiss={() => {
                    toggleHideTransferOwnershipErrorDialog();
                }}
            >
                <Stack>
                    <Stack style={{ flexDirection: 'row', justifyContent: 'flex-start', marginTop: '-30px' }}>
                        <IC size={20} variant="red">
                            <Close id={colorButtonId} />
                        </IC>

                        <Text variant="xLarge" style={{ marginLeft: 10 }}>
                            Error occurred
                        </Text>
                    </Stack>

                    <Text style={{ marginLeft: 30, marginTop: 10, marginRight: 0 }}>
                        Some error occurred during the process of transferring ownership. Please try to transfer ownership again and if the error is repeated
                        contact the responsible person.
                    </Text>
                </Stack>

                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            toggleHideTransferOwnershipErrorDialog();
                            setSelectedManager([]);
                            setFilteredManagers([]);
                            disableButton();
                            // fetchMembers();
                            window.location.reload();
                        }}
                        text={'Close'}
                    />
                </DialogFooter>
            </Dialog>

            <Steps
                enabled={stepsEnabled}
                steps={stepsSettings.steps}
                initialStep={stepsSettings.initialStep}
                options={options}
                onExit={() => setStepsEnabled(false)}
                onStart={() => {
                    localStorage.setItem('intro-campus-settings', 'false');
                }}
            />

            <Modal isOpen={isFormModalOpen} onDismiss={hideFormModal} isBlocking={false} titleAriaId={titleId}>
                {successModal ? (
                    <>
                        <Stack
                            tokens={{ childrenGap: 17 }}
                            style={{ justifyContent: 'center', alignContent: 'center', padding: '20px', width: '330px', height: '110px' }}
                        >
                            <Stack horizontal horizontalAlign="space-around" style={{ width: '60%' }}>
                                <IC size={20} variant="custom">
                                    <Shape id={colorButtonId} />
                                </IC>
                                <Text variant="xLarge">Changes saved</Text>
                            </Stack>
                        </Stack>
                        <Stack horizontal verticalAlign="center" horizontalAlign="end" tokens={{ childrenGap: 7 }} style={{ padding: '20px' }}>
                            <PrimaryButton
                                text="Close"
                                onClick={() => {
                                    setChanges({});
                                    hideFormModal();
                                    setSuccessModal(false);
                                    sendEvent('refetchBranding', {});
                                    fetchCampus();
                                }}
                            />
                        </Stack>
                    </>
                ) : (
                    <>
                        <Stack
                            tokens={{ childrenGap: 17 }}
                            style={{ justifyContent: 'center', alignContent: 'center', padding: '20px', width: '330px', height: '110px' }}
                        >
                            <Text variant="xLarge">Save changes?</Text>
                            <Text>Clicking confirm wil save any changes you just made on your campus settings.</Text>
                        </Stack>
                        <Stack horizontal verticalAlign="center" horizontalAlign="end" tokens={{ childrenGap: 7 }} style={{ padding: '20px' }}>
                            <DefaultButton text="Cancel" onClick={hideFormModal} />
                            <PrimaryButton
                                text="Confirm"
                                onClick={() => {
                                    {
                                        setSuccessModal(true);
                                        if (!campus) return;
                                        if (changes && Object.keys(changes).length > 0) {
                                            onUpdateCampus &&
                                                onUpdateCampus(campus.orgId, changes).then(() => {
                                                    sendEvent('refetchBranding', {});
                                                    setTimeout(() => {
                                                        hideFormModal();
                                                    }, 1000);
                                                });

                                            return;
                                        }
                                    }
                                }}
                            />
                        </Stack>
                    </>
                )}
            </Modal>
        </>
    );
};

export default CampusSettingsView;
