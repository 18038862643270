import React from 'react';
import ResetWizardView from './ResetWizard.view';

const onClickEvent = () => {
    localStorage.clear();
    sessionStorage.clear();
    return (window.location.href = '/');
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ResetWizard = () => {
    return <ResetWizardView onClickEvent={onClickEvent} />;
};

export default ResetWizard;
