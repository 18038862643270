import { ITheme, Panel, Pivot, PivotItem, Stack, Text } from '@fluentui/react';
import { CampusDto } from 'client-api-wrapper/lib/campus_api/responses.dto';
import { OrgBrandingDto } from 'client-api-wrapper/lib/identity_provider/responses.dto';
import { IC } from 'components/SVGIcon';
import AreaList from 'features/Areas/AreaList/AreaList';
import CampusSettings from 'features/CampusSettings/CampusSettings';
import SetupAssistant from 'features/CampusSettings/SetupAssistant/SetupAssistant';
import ChangeLog from 'features/ChangeLog/ChangeLog';
import ManageAccess from 'features/Members/ManageAccess';
import PowerUserImporter from 'features/Members/PowerUserImporter';
import OnboardingVideoPopup from 'features/OnboardingVideoPopup/OnboardingVideoPopup';
import 'intro.js/introjs.css';
import IvicosColors from 'kits/colorKit';
import { Menu } from 'kits/IconKit2';
import React from 'react';
import 'styles/pivot.css';

export interface IDashboardViewProps {
    selectedKey: string;
    theme: ITheme;
    campus?: CampusDto;
    isOpen: boolean;
    openPanel: () => void;
    dismissPanel: () => void;
    tabIsVisible: {
        areas: boolean;
        members: boolean;
        settings: boolean;
        log: boolean;
    };
    setTabIsVisible: React.Dispatch<
        React.SetStateAction<{
            areas: boolean;
            members: boolean;
            settings: boolean;
            log: boolean;
        }>
    >;
    handleLinkClick: (item?: PivotItem | undefined) => void;
    isAdmin?: boolean;
    branding?: OrgBrandingDto;
    pivotDisplaySmallDevice: string;
    burgerMenu: string;
    campusNameSmallDevice: string;
    stackTitleSmallDevice: string;
    smallDevice: boolean;
}

const DashboardView: React.FC<IDashboardViewProps> = (props) => {
    const {
        selectedKey,
        theme,
        campus,
        isOpen,
        openPanel,
        dismissPanel,
        tabIsVisible,
        setTabIsVisible,
        handleLinkClick,
        isAdmin,
        branding,
        pivotDisplaySmallDevice,
        burgerMenu,
        campusNameSmallDevice,
        stackTitleSmallDevice,
        smallDevice
    } = props;

    const isAreasVisible = selectedKey == 'areas';
    const isManageAccessVisibile = selectedKey == 'members';
    const isCampusSettingVisible = selectedKey == 'settings';
    const isChangeLogVisible = selectedKey == 'changelog';

    return (
        // Areas
        <>
            <Panel
                isOpen={isOpen}
                onDismiss={dismissPanel}
                // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                closeButtonAriaLabel="Close"
            >
                <img src={branding && branding.orgLogo} style={{ width: '50%' }} />

                <Stack style={{ marginTop: '15px' }}>
                    <Stack>
                        <Text
                            variant="xLarge"
                            onClick={() => {
                                dismissPanel();
                                setTabIsVisible({ areas: true, members: false, settings: false, log: false });
                            }}
                            style={
                                tabIsVisible.areas
                                    ? {
                                          //   borderBottom: `3px solid ${IvicosColors.Mittelblau}`,
                                          fontSize: '27px',
                                          color: IvicosColors.Mittelblau,
                                          marginBottom: '35px',
                                          fontWeight: 'bolder'
                                      }
                                    : { marginBottom: '30px' }
                            }
                        >
                            Areas
                        </Text>
                    </Stack>
                    {isAdmin && (
                        <Text
                            variant="xLarge"
                            onClick={() => {
                                dismissPanel();
                                setTabIsVisible({ areas: false, members: true, settings: false, log: false });
                            }}
                            style={
                                tabIsVisible.members
                                    ? // ? { borderBottom: `3px solid ${IvicosColors.Mittelblau}`, width: '43%', fontSize: '21px', marginBottom: '35px' }
                                      // : { marginBottom: '30px' }
                                      {
                                          //   borderBottom: `3px solid ${IvicosColors.Mittelblau}`,
                                          fontSize: '27px',
                                          color: IvicosColors.Mittelblau,
                                          marginBottom: '35px',
                                          fontWeight: 'bolder'
                                      }
                                    : { marginBottom: '30px' }
                            }
                        >
                            Members
                        </Text>
                    )}
                    {isAdmin && (
                        <Text
                            variant="xLarge"
                            onClick={() => {
                                dismissPanel();
                                setTabIsVisible({ areas: false, members: false, settings: true, log: false });
                            }}
                            style={
                                tabIsVisible.settings
                                    ? // : { marginBottom: '30px' }

                                      // ? { borderBottom: `3px solid ${IvicosColors.Mittelblau}`, width: '47%', fontSize: '21px', marginBottom: '35px' }
                                      {
                                          fontSize: '27px',
                                          color: IvicosColors.Mittelblau,
                                          marginBottom: '35px',
                                          fontWeight: 'bolder'
                                      }
                                    : { marginBottom: '30px' }
                            }
                        >
                            Campus settings
                        </Text>
                    )}
                    <Text
                        variant="xLarge"
                        onClick={() => {
                            dismissPanel();
                            setTabIsVisible({ areas: false, members: false, settings: false, log: true });
                        }}
                        style={
                            tabIsVisible.log
                                ? {
                                      fontSize: '27px',
                                      color: IvicosColors.Mittelblau,
                                      marginBottom: '35px',
                                      fontWeight: 'bolder'
                                  }
                                : { marginBottom: '30px' }
                        }
                    >
                        Changelog
                    </Text>
                </Stack>
            </Panel>

            <Stack grow verticalAlign="stretch" style={{ maxWidth: '100%', overflow: 'hidden', paddingTop: 16, backgroundColor: theme.palette.neutralLight }}>
                {!smallDevice && <SetupAssistant />}
                <PowerUserImporter />
                {!smallDevice && <OnboardingVideoPopup />}
                <Stack horizontal={smallDevice} className={`${stackTitleSmallDevice} start`}>
                    {/* <Stack style={{ marginLeft: '35px', marginTop: '25px' }} className="start"> */}
                    <IC size={32} variant="custom">
                        <Menu className={burgerMenu} onClick={openPanel} />
                    </IC>
                    <Text className={campusNameSmallDevice} style={{ color: theme.semanticColors.bodyText }} variant="xxLarge">
                        {campus?.name || 'Campus'}
                    </Text>
                </Stack>
                <Stack horizontal horizontalAlign="start" style={{ padding: 26 }} className={pivotDisplaySmallDevice}>
                    <Pivot selectedKey={selectedKey} onLinkClick={handleLinkClick} headersOnly={true} className={`${pivotDisplaySmallDevice} menu`}>
                        <PivotItem headerText="Areas" itemKey="areas" className="one-tab" style={{ width: '30px', height: '20px' }} />
                        {isAdmin && <PivotItem headerText="Members" itemKey="members" />}
                        {isAdmin && <PivotItem headerText="Campus settings" itemKey="settings" />}
                        <PivotItem headerText="Changelog" itemKey="changelog" />
                    </Pivot>
                </Stack>

                {!smallDevice && (
                    <Stack>
                        {isAreasVisible && (
                            <Stack grow>
                                <AreaList />
                            </Stack>
                        )}

                        {isManageAccessVisibile && <ManageAccess />}

                        {isCampusSettingVisible && <CampusSettings />}

                        {isChangeLogVisible && (
                            <Stack verticalAlign="stretch" style={{ width: 450, height: '100%' }}>
                                <ChangeLog />
                            </Stack>
                        )}
                    </Stack>
                )}

                {smallDevice && (
                    <Stack>
                        {tabIsVisible.areas && (
                            <Stack grow>
                                <AreaList />
                            </Stack>
                        )}

                        {tabIsVisible.members && <ManageAccess />}

                        {tabIsVisible.settings && <CampusSettings />}

                        {tabIsVisible.log && (
                            <Stack verticalAlign="stretch" style={{ width: 450, height: '100%' }}>
                                <ChangeLog />
                            </Stack>
                        )}
                    </Stack>
                )}
            </Stack>
        </>
    );
};

export default DashboardView;
