import { useUserMessagingActions } from 'adapters/realtime-adapter/actions/messaging';
import { IRoomResource } from 'kits/apiKit3/legacy';
import { useHistory } from 'react-router';
import { useStyledRoomList } from 'shared-state/directory/hooks';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { IProfile } from 'shared-state/identity/types';
import { useCurrentAreaId } from 'shared-state/location/hook';
import { useReceivedMessageInbox } from 'shared-state/notifications/hooks';
import { IReceivedMessage } from 'shared-state/notifications/types';

export interface IDragNDropContextState {
    routeHistory: any;
    areaId?: string;
    localProfile?: IProfile;
    rooms: IRoomResource[];
    addMessage: (msg: IReceivedMessage) => void;
    inviteUserToRoom: (userId: string, roomId: string) => void;
}

export const useDragNDropContextState: () => IDragNDropContextState = () => {
    const routeHistory = useHistory();

    const [areaId] = useCurrentAreaId();
    const localProfile = useLocalProfile();
    const rooms = useStyledRoomList();

    const [, addMessage] = useReceivedMessageInbox();
    const { inviteUserToRoom } = useUserMessagingActions();

    return { routeHistory, areaId, localProfile, rooms, addMessage, inviteUserToRoom };
};
