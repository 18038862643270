import { useUpdateEvents } from 'adapters/realtime-adapter/actions/messaging';
import { roomDTOtoResource } from 'kits/apiKit3/legacy';
import { resourceService } from 'services/api/resource.service';
import { useRoomList } from 'shared-state/directory/hooks';
import { useRoomSettingsRoomId } from 'shared-state/display-options/hooks';
import { useCurrentAreaId, useCurrentCampusId } from 'shared-state/location/hook';

export interface ICreateNewRoomButtonState {
    onClick: (e: any, setButtonState: (state: number) => void) => '' | Promise<any> | undefined;
}

export const useCreateNewRoomButtonState: () => ICreateNewRoomButtonState = () => {
    const [, setRoomSettingsRoomId] = useRoomSettingsRoomId();
    const [areaId] = useCurrentAreaId();
    const [campusId] = useCurrentCampusId();
    const [, setRoomList] = useRoomList();
    const sendUpdateEvent = useUpdateEvents();

    const _createRoom = async () => {
        const defaultRoom = {
            name: 'Untitled Room',
            iconKey: 'Room',
            isPrivate: false,
            isAudioOnly: false,
            isOpenForVisitors: false
        };
        if (!campusId || !areaId) return {};
        const createdRoom = await resourceService.createRoom(campusId, areaId, defaultRoom);
        const roomRes = roomDTOtoResource(createdRoom);
        roomRes.attributes.whitelist = [];
        return { data: roomRes };
    };

    const onClick = (e: any, setButtonState: (state: number) => void) => {
        // Set button state to loading
        setButtonState(1);
        return (
            areaId &&
            _createRoom().then((r: any) => {
                // Set button state to success
                setButtonState(2);

                // Open room settings for new room
                if (r.data) {
                    sendUpdateEvent({ type: 'creation', room: r.data });
                    setRoomList((prevRoomList: any) => [...prevRoomList, ...[r.data]]);
                    setRoomSettingsRoomId(r.data.id);
                }

                setTimeout(() => {
                    // Reset button state after timeout
                    setButtonState(0);
                }, 1200);
                return r;
            })
        );
    };

    return { onClick };
};
