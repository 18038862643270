import { Modal, Stack, IconButton, Text, TextField, PrimaryButton } from '@fluentui/react';
import React from 'react';

interface IAttachmentUpdateModalViewProps {
    isOpen?: boolean;
    onDismiss?: Function;
    updateAttachment: (event: any) => Promise<void>;
    titleHandler: (e: any) => void;
    attachmentTitle?: string;
    count: number;
    descriptionHandler: (e: any) => void;
    attachmentDescription?: string;
    count2: number;
    setCount: React.Dispatch<React.SetStateAction<number>>;
    setCount2: React.Dispatch<React.SetStateAction<number>>;
}

const AttachmentUpdateModalView: React.FC<IAttachmentUpdateModalViewProps> = (props) => {
    const {
        isOpen,
        onDismiss,
        updateAttachment,
        titleHandler,
        attachmentTitle,
        count,
        descriptionHandler,
        attachmentDescription,
        count2,
        setCount,
        setCount2
    } = props;
    return (
        <Modal isOpen={isOpen}>
            <Stack horizontal style={{ padding: 16 }} verticalAlign="center" horizontalAlign="space-between">
                <Text variant="large">Edit attachment</Text>
                <IconButton
                    iconProps={{ iconName: 'clear' }}
                    onClick={() => {
                        onDismiss && onDismiss();
                    }}
                />
            </Stack>
            <form
                onSubmit={(event) => {
                    updateAttachment(event);
                }}
            >
                <Stack style={{ padding: 16, minHeight: 176 }}>
                    <TextField
                        onChange={(e) => {
                            titleHandler(e);
                        }}
                        placeholder={'Give your attachment a title'}
                        defaultValue={attachmentTitle}
                        maxLength={40}
                        required
                    />
                    <Text variant="small" style={{ marginLeft: 'auto', marginBottom: 10 }}>
                        {count}/40
                    </Text>
                    <TextField
                        onChange={(e) => {
                            descriptionHandler(e);
                        }}
                        placeholder={'Add a description to your attachment'}
                        defaultValue={attachmentDescription}
                        multiline
                        rows={9}
                        resizable={false}
                        maxLength={280}
                        // required
                    />
                    <Text variant="small" style={{ marginLeft: 'auto' }}>
                        {count2}/280
                    </Text>
                </Stack>
                <Stack style={{ flexDirection: 'row' }}>
                    <PrimaryButton
                        style={{ marginLeft: 100, marginRight: 10, marginBottom: 20, backgroundColor: 'white', color: 'black', borderColor: 'black' }}
                        onClick={() => {
                            onDismiss && onDismiss();
                            setCount(0);
                            setCount2(0);
                        }}
                    >
                        Cancel
                    </PrimaryButton>
                    <PrimaryButton type="submit">Save</PrimaryButton>
                </Stack>
            </form>
        </Modal>
    );
};

export default AttachmentUpdateModalView;
