import * as React from 'react';

function SvgSpeakerView(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M3 6v20h26V6H3zm2 2h22v16H5V8zm10 2v8h10v-8H15zm2 2h6v4h-6v-4z" fill="#000" />
        </svg>
    );
}

export default SvgSpeakerView;
