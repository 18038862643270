/* eslint-disable quotes */
import {
    Callout,
    CommandBarButton,
    DefaultButton,
    DelayedRender,
    DirectionalHint,
    getTheme,
    IconButton,
    Label,
    mergeStyles,
    mergeStyleSets,
    Modal,
    PrimaryButton,
    Stack,
    Text,
    TooltipHost
} from '@fluentui/react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { IC } from 'components/SVGIcon';
import { deleteResource } from 'kits/fetchKit';
import { QuestionCircle, Trash } from 'kits/IconKit2';
import imageBg from 'media/img/imageBg.png';
import React, { useState } from 'react';
import Files from 'react-files';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { getClassNames } from 'styles/responsiveness';
import { IImagePickerImage } from '../ImagePicker.state';

interface ISettingsImagePickerViewProps {
    onDismiss: () => void;
    modalIsOpen: boolean;
    selectedImage: IImagePickerImage;
    imageOptions: IImagePickerImage[];
    onImageSelect: (i: IImagePickerImage) => void;
    onFile: (f: any) => void;
    onModalOpen: () => void;
    onDiscardImage: (f: string) => void;
    label?: string;
}

const SettingsImagePickerView: React.FC<ISettingsImagePickerViewProps> = ({
    onModalOpen,
    onDismiss,
    imageOptions,
    modalIsOpen,
    selectedImage,
    onImageSelect,
    onFile,
    label,
    onDiscardImage
}) => {
    const theme = getTheme();

    const onCloseToUploadImage = () => onImageSelect({ id: '', url: '' });

    const [isHoverVisible, setIsHoverVisible] = useState('');
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const directionalHint = DirectionalHint.rightCenter;
    const buttonId = useId('callout-button');

    const styles = mergeStyleSets({
        button: {
            width: 130
        },
        callout: {
            width: 320,
            padding: '20px 24px'
        }
    });

    const dropZoneStyles = mergeStyles({
        background: theme.palette.neutralLight,
        width: '30vh',
        height: '22vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        cursor: 'pointer',
        alignItems: 'center',
        paddingTop: '0.5vh',
        ':hover': {
            background: theme.palette.themeLight
        },
        ':hover > .note': {
            opacity: 1
        }
    });

    const onImageHoverHandler = (id: string) => {
        imageOptions.map((img) => (img.id === id ? setIsHoverVisible(id) : null));
    };

    const { imagePickerBg, imagePickerImage, uploadedImage, imagePool } = getClassNames();
    const { smallDevice } = useWindowWidth();
    const [imageID, setImageID] = useState<string>();

    const onDiscardFrontImage = async (f: string) => {
        const isSure = confirm('Are you sure you want to delete this image?');
        if (!isSure) return;
        const token = localStorage.getItem('ivAccessToken');
        await deleteResource(token, 'https://api-de-eu.ivicos-campus.app/beta/media/default/images/' + f);
        onCloseToUploadImage();
    };

    const imageIDCollector = async (id: string) => {
        await setImageID(id);
    };

    return (
        <>
            {label && <Label style={{}}>{label}</Label>}
            <Stack>
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 32 }}>
                    <Stack horizontal style={smallDevice ? { width: '100%' } : { width: '430px' }}>
                        <Stack
                            grow
                            style={{
                                backgroundImage: "url('" + imageBg + "')"
                            }}
                            className={imagePickerImage}
                            onClick={onModalOpen}
                        >
                            <Stack
                                grow
                                style={{
                                    backgroundImage: "url('" + selectedImage.url + "')"
                                }}
                                className={imagePickerBg}
                            >
                                {selectedImage.url == '' && <Text>Logo</Text>}
                            </Stack>
                        </Stack>

                        {!smallDevice && (
                            <IC variant="custom" size={15} style={{ cursor: 'pointer' }}>
                                <QuestionCircle onClick={toggleIsCalloutVisible} id={buttonId} />
                            </IC>
                        )}
                    </Stack>

                    {isCalloutVisible && (
                        <Callout
                            className={styles.callout}
                            onDismiss={toggleIsCalloutVisible}
                            role="status"
                            target={`#${buttonId}`}
                            aria-live="assertive"
                            directionalHint={directionalHint}
                        >
                            <DelayedRender>
                                <Text variant="small">
                                    The logo will appear on your campus top bar, in your waiting area, and when inviting colleagues to your campus. The maximum
                                    size shoud be 250 x 100 and 2 MB.
                                </Text>
                            </DelayedRender>
                        </Callout>
                    )}

                    {!smallDevice && (
                        <Stack style={{ flexDirection: 'column', marginLeft: 'auto', width: '23%' }}>
                            <DefaultButton iconProps={{ iconName: 'edit' }} onClick={onModalOpen}>
                                Change logo
                            </DefaultButton>
                        </Stack>
                    )}
                </Stack>

                <Modal isOpen={modalIsOpen} onDismiss={onDismiss}>
                    <Stack style={{ maxWidth: 580, flexDirection: 'column', alignItems: 'center' }}>
                        <Stack horizontal horizontalAlign="space-between" style={{ width: '100%', padding: '16px 16px' }}>
                            <Text variant={smallDevice ? 'medium' : 'large'}>Upload a new image</Text>
                            <IconButton iconProps={{ iconName: 'Cancel' }} onClick={onDismiss} />
                        </Stack>
                        <Stack grow horizontalAlign="center" tokens={{ childrenGap: 16 }}>
                            {selectedImage.url != '' && selectedImage.url != 'none' ? (
                                <Stack horizontalAlign="center" verticalAlign="center" style={{ width: '100%', padding: 16 }} tokens={{ childrenGap: 16 }}>
                                    <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 16 }}>
                                        <CommandBarButton
                                            onClick={onCloseToUploadImage}
                                            text="Clear"
                                            iconProps={{ iconName: 'Clear' }}
                                            style={smallDevice ? { height: 42, fontSize: '15px' } : { height: 42 }}
                                        />
                                        <Files onChange={onFile} accepts={['image/jpg', 'image/jpeg', 'image/png', 'image/svg']} minFileSize={0} clickable>
                                            <CommandBarButton
                                                text={smallDevice ? 'Upload' : 'Upload new Image'}
                                                iconProps={{ iconName: 'Upload' }}
                                                style={smallDevice ? { height: 42, fontSize: '15px' } : { height: 42 }}
                                            />
                                        </Files>
                                        {imageID && (
                                            <CommandBarButton
                                                text="Delete"
                                                iconProps={{ iconName: 'Delete' }}
                                                style={smallDevice ? { height: 42, fontSize: '15px' } : { height: 42 }}
                                                onClick={() => {
                                                    onDiscardFrontImage(imageID);
                                                }}
                                            />
                                        )}
                                    </Stack>
                                    {/* <Stack> */}
                                    <Stack style={{ height: '100px' }}>
                                        <img
                                            src={selectedImage.url}
                                            alt="selected image"
                                            // style={{ width: '30vh', justifyContent: 'center', marginBottom: 8, alignContent: 'center' }}
                                            className={uploadedImage}
                                        />
                                    </Stack>
                                </Stack>
                            ) : (
                                <Stack>
                                    <Files
                                        className={dropZoneStyles}
                                        onChange={onFile}
                                        accepts={['image/jpg', 'image/jpeg', 'image/png', 'image/svg']}
                                        maxFileSize={10000000}
                                        minFileSize={0}
                                        clickable
                                    >
                                        <Text className={mergeStyles(['note', { opacity: 1 }])}> Click to upload image </Text>
                                    </Files>
                                </Stack>
                            )}
                            <Stack
                                style={
                                    !smallDevice
                                        ? {
                                              width: '100%',
                                              padding: '0px 16px',
                                              backgroundColor: theme.palette.neutralLighter,
                                              borderBottom: '1px ' + theme.palette.neutralQuaternary + ' solid'
                                          }
                                        : {
                                              //   width: '80%',
                                              padding: '0px 16px',
                                              backgroundColor: theme.palette.neutralLighter,
                                              borderBottom: '1px ' + theme.palette.neutralQuaternary + ' solid'
                                          }
                                }
                                className={imagePool}
                            >
                                <Stack style={{ width: '100%', margin: '2vh 0vh' }}>
                                    <Label>Recently uploaded Images</Label>
                                </Stack>
                                <Stack
                                    horizontal
                                    horizontalAlign="space-between"
                                    wrap
                                    styles={
                                        !smallDevice
                                            ? { inner: { overflowY: 'scroll', overflowX: 'hidden', width: '100%', height: '250px', marginBottom: '20px' } }
                                            : // : { inner: { overflowY: 'scroll', height: '150px' } }
                                              { inner: { overflowY: 'scroll', overflowX: 'hidden', width: '100%', height: '150px', marginBottom: '20px' } }
                                    }
                                    // styles={{ inner: { overflowY: 'scroll', overflowX: 'hidden', width: '100%', height: '250px', marginBottom: '20px' } }}
                                    className="iphone-scroll"
                                >
                                    {imageOptions.map((img: IImagePickerImage) => (
                                        <Stack
                                            key={img.id}
                                            styles={
                                                smallDevice
                                                    ? {
                                                          root: {
                                                              padding: '0.5vh',
                                                              cursor: 'pointer',
                                                              width: '100px',
                                                              height: '100px',
                                                              justifyContent: 'center',
                                                              alignItems: 'center'
                                                          }
                                                      }
                                                    : {
                                                          root: {
                                                              padding: '0.5vh',
                                                              cursor: 'pointer',
                                                              width: '160px',
                                                              height: '160px',
                                                              justifyContent: 'center',
                                                              alignItems: 'center',
                                                              border: '2px transparent solid',
                                                              ':hover': {
                                                                  border: '2px ' + theme.palette.themePrimary + ' solid'
                                                              }
                                                          }
                                                      }
                                            }
                                            onMouseEnter={() => {
                                                onImageHoverHandler(img.id);
                                            }}
                                            onMouseLeave={() => setIsHoverVisible('')}

                                            // style={{ padding: '10px', cursor: 'pointer', width: '160px', height: '160px', border: '2px transparent solid' }}
                                        >
                                            <Stack style={smallDevice ? { width: '80px', height: 'fit' } : { width: '140px', position: 'relative' }}>
                                                <img
                                                    src={img.url}
                                                    alt="img"
                                                    key={img.id}
                                                    onClick={() => {
                                                        onImageSelect(img);
                                                        imageIDCollector(img.id);
                                                    }}
                                                    style={smallDevice ? { width: '100%' } : {}}
                                                />
                                                {isHoverVisible === img.id && !smallDevice && (
                                                    <Stack
                                                        horizontal
                                                        styles={{
                                                            root: {
                                                                position: 'absolute',
                                                                right: 0,
                                                                padding: '8px',
                                                                top: 0,
                                                                color: theme.palette.red,
                                                                backgroundColor: theme.palette.white,
                                                                '&:hover': {
                                                                    backgroundColor: theme.palette.neutralLighter
                                                                },
                                                                '&:active': {
                                                                    backgroundColor: theme.palette.neutralLight
                                                                }
                                                            }
                                                        }}
                                                        onClick={() => {
                                                            onDiscardImage(img.id);
                                                        }}
                                                    >
                                                        <TooltipHost content="Delete">
                                                            <IC variant="custom" iconColor={theme.palette.red} size={15}>
                                                                <Trash />
                                                            </IC>
                                                        </TooltipHost>
                                                    </Stack>
                                                )}
                                                {/* {isHoverVisible === img.id ? (
                                                    <Stack
                                                        horizontal
                                                        styles={{
                                                            root: {
                                                                position: 'absolute',
                                                                right: 0,
                                                                padding: '8px',
                                                                top: 0,
                                                                color: theme.palette.red,
                                                                backgroundColor: theme.palette.white,
                                                                '&:hover': {
                                                                    backgroundColor: theme.palette.neutralLighter
                                                                },
                                                                '&:active': {
                                                                    backgroundColor: theme.palette.neutralLight
                                                                }
                                                            }
                                                        }}
                                                        onClick={() => {
                                                            onDiscardImage(img.id);
                                                        }}
                                                    >
                                                        <TooltipHost content="Delete">
                                                            <IC variant="custom" iconColor={theme.palette.red} size={15}>
                                                                <Trash />
                                                            </IC>
                                                        </TooltipHost>
                                                    </Stack>
                                                ) : null} */}
                                            </Stack>
                                        </Stack>
                                    ))}
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack horizontal horizontalAlign="end" style={{ width: '100%', padding: '16px' }}>
                            <PrimaryButton onClick={onDismiss} text="Close" />
                        </Stack>
                    </Stack>
                </Modal>
            </Stack>
        </>
    );
};

export default SettingsImagePickerView;
