import { useBoolean, useId } from '@uifabric/react-hooks';
import { useEffect, useState } from 'react';
import { idpService } from 'services/api/identity-provider.service';

export interface IPersonalPreferencesState {
    currentDeviceSettings: {
        cameraDisabled: boolean;
        microphoneDisabled: boolean;
    };
    toggleDeviceSettings: (toggle: string) => Promise<void>;
    hideErrorDialog: boolean;
    toggleHideErrorDialog: () => void;
    colorButtonId: string;
    deviceSettings: any;
}

export const deviceSettings: any = {};

export const usePersonalPreferencesState: () => IPersonalPreferencesState = () => {
    const [hideErrorDialog, { toggle: toggleHideErrorDialog }] = useBoolean(true);

    const colorButtonId = useId('callout-button');
    const [currentDeviceSettings, setCurrentDeviceSettings] = useState<{ cameraDisabled: boolean; microphoneDisabled: boolean }>({
        cameraDisabled: false,
        microphoneDisabled: false
    });
    useEffect(() => {
        const getUserData = async () => {
            const response = await idpService.getUser();
            deviceSettings.cameraDisabled = response.device_settings.camera_disabled;
            deviceSettings.microphoneDisabled = response.device_settings.mic_disabled;
            setCurrentDeviceSettings({ cameraDisabled: response.device_settings.camera_disabled, microphoneDisabled: response.device_settings.mic_disabled });
        };
        getUserData();
    }, []);

    const toggleDeviceSettings = async (toggle: string) => {
        const currentCameraSetup = toggle == 'camera' ? !currentDeviceSettings.cameraDisabled : currentDeviceSettings.cameraDisabled;
        const currentMicSetup = toggle == 'mic' ? !currentDeviceSettings.microphoneDisabled : currentDeviceSettings.microphoneDisabled;
        setCurrentDeviceSettings({ cameraDisabled: currentCameraSetup, microphoneDisabled: currentMicSetup });

        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const updateResponse = await idpService.updateUser(undefined, undefined, undefined, currentCameraSetup, currentMicSetup);
            deviceSettings.cameraDisabled = updateResponse.device_settings.camera_disabled;
            deviceSettings.microphoneDisabled = updateResponse.device_settings.mic_disabled;
        } catch {
            toggleHideErrorDialog();
        }
    };

    return {
        currentDeviceSettings,
        toggleDeviceSettings,
        hideErrorDialog,
        toggleHideErrorDialog,
        colorButtonId,
        deviceSettings
    };
};
