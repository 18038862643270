import { useCallback, useState } from 'react';

export interface ICreateNewLinkModalState {
    description: string;
    setDescription: React.Dispatch<React.SetStateAction<string>>;
    _onDismiss: () => void;
    _onSubmit: any;
}

export const useCreateNewLinkModalState: (onDismiss?: Function, onSubmit?: (desc: string) => void) => ICreateNewLinkModalState = (onDismiss, onSubmit) => {
    const [description, setDescription] = useState<string>('');

    const _onDismiss = () => {
        setDescription('');
        onDismiss && onDismiss();
    };

    const _onSubmit = useCallback(() => {
        onSubmit && onSubmit(description);
    }, [description]);

    return {
        description,
        setDescription,
        _onDismiss,
        _onSubmit
    };
};
