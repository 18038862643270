/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { useRecoilState } from 'recoil';
import { aIvAccessToken, aIvIdentityToken, aJitsiDomain, aJitsiRoom, aJitsiToken, aMsAccessToken } from 'shared-state/tokens/atoms';

export const useIvicosAccesToken = (tennantId: string) => {
    return useRecoilState(aIvAccessToken(tennantId));
};
export const useIvicosIdentityToken = (tennantId: string) => {
    return useRecoilState(aIvIdentityToken(tennantId));
};
export const useMicrosoftAccessToken = (tennantId: string) => {
    return useRecoilState(aMsAccessToken(tennantId));
};

export const useLocalJitsiToken = (roomId: string) => {
    return useRecoilState(aJitsiToken(roomId));
};

export const useLocalJitsiRoom = (roomId: string) => {
    return useRecoilState(aJitsiRoom(roomId));
};

export const useLocalJitsiDomain = (roomId: string) => {
    return useRecoilState(aJitsiDomain(roomId));
};
