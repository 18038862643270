import { DefaultButton, DefaultEffects, Stack, Text } from '@fluentui/react';
import { SharedColors } from '@uifabric/fluent-theme';
import { IC } from 'components/SVGIcon';
import MarkdownHost from 'features/MarkdownHost/MarkdownHost';
import { Close } from 'kits/IconKit2';
import React from 'react';
import { DarkTheme } from 'themes/dark';
import { INotificationsBannerState } from './NotificationsBanner.state';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NotificationBannerView: React.FC<INotificationsBannerState> = ({ getNotificationIcon, timestamp, title, description, onDismiss, actions, children }) => {
    return (
        <Stack
            style={{
                backdropFilter: 'blur(5px)',
                padding: '16px',
                boxShadow: DefaultEffects.elevation64,
                background: 'rgba(0,0,0,0.7)',
                zIndex: 3000,
                color: '#fff'
            }}
        >
            <Stack horizontal tokens={{ childrenGap: 16 }}>
                <Stack
                    verticalAlign="center"
                    horizontalAlign="center"
                    style={{ height: 48, width: 48, minHeight: 48, minWidth: 48, background: SharedColors.cyanBlue10 }}
                >
                    <IC size={40} variant="light">
                        {getNotificationIcon()}
                    </IC>
                </Stack>
                <Stack grow style={{ maxWidth: 230 }}>
                    <Text variant="tiny">{timestamp}</Text>
                    <Text variant="large">{title}</Text>
                    <Text variant="medium" style={{ wordWrap: 'break-word' }}>
                        <MarkdownHost>{description}</MarkdownHost>
                    </Text>
                </Stack>
                <Stack>
                    <div className="pressable" onClick={() => onDismiss()}>
                        <IC size={24} variant="custom" iconColor="#fff">
                            <Close />
                        </IC>
                    </div>
                </Stack>
            </Stack>
            <Stack horizontal horizontalAlign="end" style={{ marginTop: 16 }} tokens={{ childrenGap: 8 }}>
                <NotificationBannerActions actions={actions} />
            </Stack>
        </Stack>
    );
};

export default NotificationBannerView;

export interface INotificationBannerAction {
    title: string;
    key: string;
    action: Function;
}

export interface INotificationBannerActionsProps {
    actions: INotificationBannerAction[];
}

const NotificationBannerActions: React.FC<INotificationBannerActionsProps> = ({ actions }) => {
    return (
        <>
            {actions.map((action: INotificationBannerAction) => (
                <DefaultButton theme={DarkTheme} key={action.key} style={{ width: '50%' }} onClick={() => action.action()}>
                    {action.title}
                </DefaultButton>
            ))}
        </>
    );
};
