import * as React from 'react';

function SvgGroup(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M8 8c-2.75 0-5 2.25-5 5 0 1.477.66 2.8 1.688 3.719C1.93 17.984 0 20.777 0 24h2c0-3.324 2.676-6 6-6s6 2.676 6 6h2a7.95 7.95 0 00-.969-3.813h.063A3.969 3.969 0 0119 17c2.219 0 4 1.781 4 4h2a5.899 5.899 0 00-.813-3c.415-1.16 1.5-2 2.813-2 1.668 0 3 1.332 3 3h2c0-1.836-1.016-3.441-2.5-4.313.324-.48.5-1.07.5-1.687 0-1.645-1.355-3-3-3s-3 1.355-3 3c0 .617.176 1.207.5 1.688a5.007 5.007 0 00-1.75 1.656 6.043 6.043 0 00-.875-.594A3.926 3.926 0 0023 13c0-2.2-1.8-4-4-4a4 4 0 00-3.906 3.188c-.055.257-.094.539-.094.812 0 1.074.457 2.027 1.156 2.75a6.025 6.025 0 00-2.5 2.594 8.077 8.077 0 00-2.344-1.625C12.34 15.8 13 14.477 13 13c0-2.75-2.25-5-5-5zm0 2c1.668 0 3 1.332 3 3s-1.332 3-3 3-3-1.332-3-3 1.332-3 3-3zm11 1c1.117 0 2 .883 2 2s-.883 2-2 2-2-.883-2-2c0-.14.004-.273.031-.406A1.991 1.991 0 0119 11zm8 1c.563 0 1 .438 1 1 0 .563-.438 1-1 1-.563 0-1-.438-1-1 0-.563.438-1 1-1z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgGroup;
