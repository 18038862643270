import { getTheme, ITheme, PivotItem } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { CampusDto } from 'client-api-wrapper/lib/campus_api/responses.dto';
import { OrgBrandingDto } from 'client-api-wrapper/lib/identity_provider/responses.dto';
import { useOrganizationBranding } from 'features/Header/hooks';
import 'intro.js/introjs.css';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useCampus } from 'shared-state/directory/hooks';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { useIdentityClaims } from 'shared-state/identity/hooks';
import { useCurrentOrgId } from 'shared-state/location/hook';
import { useUserList } from 'shared-state/presence/hooks';
import 'styles/pivot.css';
import { getClassNames } from 'styles/responsiveness';

export interface IDashboardState {
    theme: ITheme;
    tabKey?: string;
    campus?: CampusDto;
    isOpen: boolean;
    openPanel: () => void;
    dismissPanel: () => void;
    tabIsVisible: {
        areas: boolean;
        members: boolean;
        settings: boolean;
        log: boolean;
    };
    setTabIsVisible: React.Dispatch<
        React.SetStateAction<{
            areas: boolean;
            members: boolean;
            settings: boolean;
            log: boolean;
        }>
    >;
    handleLinkClick: (item?: PivotItem | undefined) => void;
    isAdmin?: boolean;
    branding?: OrgBrandingDto;
    pivotDisplaySmallDevice: string;
    burgerMenu: string;
    campusNameSmallDevice: string;
    stackTitleSmallDevice: string;
    smallDevice: boolean;
}

export const useDashboardState: () => IDashboardState = () => {
    const theme = getTheme();
    const { tabKey } = useParams<{ tabKey?: string }>();
    const [, setUserList] = useUserList();
    const [identityClaims] = useIdentityClaims();
    const routeHistory = useHistory();
    const [campus] = useCampus();
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [tabIsVisible, setTabIsVisible] = useState({
        areas: true,
        members: false,
        settings: false,
        log: false
    });

    useEffect(() => {
        setUserList([]);
    }, []);

    const handleLinkClick = (item?: PivotItem) => {
        if (item) {
            item.props.itemKey && routeHistory.push('/dashboard/' + item.props.itemKey);
            dismissPanel();
        }
    };

    const isAdmin = identityClaims?.roles.includes('owner') || identityClaims?.roles.includes('manager');

    const [orgId] = useCurrentOrgId();
    const [branding] = useOrganizationBranding(orgId);
    const { pivotDisplaySmallDevice, burgerMenu, campusNameSmallDevice, stackTitleSmallDevice } = getClassNames();
    const { smallDevice } = useWindowWidth();
    return {
        theme,
        tabKey,
        campus,
        isOpen,
        openPanel,
        dismissPanel,
        tabIsVisible,
        setTabIsVisible,
        handleLinkClick,
        isAdmin,
        branding,
        pivotDisplaySmallDevice,
        burgerMenu,
        campusNameSmallDevice,
        stackTitleSmallDevice,
        smallDevice
    };
};
