/* eslint-disable @typescript-eslint/no-empty-function */
import { IResource } from 'kits/apiKit3/legacy';
import React from 'react';
import { ICallActionKey } from '../CallActions';
import useRoomCallController from './RoomCallController.state';
import RoomCallControllerView from './RoomCallController.view';

export interface IRoomCallControllerProps {
    isHidden?: boolean;
    displayName: string;
    actions: Array<Array<ICallActionKey>>;
    profileImageURL?: string;
    callEventListeners?: Array<IRoomCallEventListener>;
    muteOnJoin?: 'audio' | 'both' | 'video';
    jwt: string;
    domain: string;
    room?: IResource;
    onAPI?: (api: any) => void;
    roomName: string;
    onAddOnDataRecieved?: (addonId: string, data: any) => void;
}

export interface IRoomCallEventListener {
    eventName: string;
    handler: (e: any) => void;
}

const RoomCallController: React.FC<IRoomCallControllerProps> = (props) => {
    const state = useRoomCallController(
        props.actions,
        props.profileImageURL,
        props.callEventListeners,
        props.muteOnJoin,
        props.room,
        props.onAPI,
        props.roomName,
        props.onAddOnDataRecieved
    );

    return (
        <RoomCallControllerView
            {...{
                ...state,
                ...{
                    isHidden: props.isHidden,
                    displayName: props.displayName,
                    actions: props.actions,
                    jwt: props.jwt,
                    domain: props.domain,
                    roomName: props.roomName,
                    room: props.room
                }
            }}
        ></RoomCallControllerView>
    );
};

export default RoomCallController;
