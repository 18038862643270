/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { useWaitingAreaState } from './WaitingArea.state';
import WaitingAreaView from './WaitingArea.view';

const WaitingArea = () => {
    const state = useWaitingAreaState();

    return (
        <WaitingAreaView
            {...{
                ...state,
                ...{
                    areaId: state.currentAreaId
                }
            }}
        ></WaitingAreaView>
    );
};

export default WaitingArea;
