/* eslint-disable @typescript-eslint/no-unused-vars */
import jwtDecode from 'jwt-decode';
import { useEvent } from 'kits/eventKit';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { idpService } from 'services/api/identity-provider.service';
import { getTokens } from 'services/api/token-storage.service';
import { useIdentityClaims } from 'shared-state/identity/hooks';
import { IIdentityClaims } from 'shared-state/identity/types';
import { useCurrentOrgId } from 'shared-state/location/hook';

export const IdentityAdapter: React.FC<{}> = ({ children }) => {
    const [identityClaims, setIdentityClaims] = useIdentityClaims();
    const [currentOrgId, setCurrentOrgId] = useCurrentOrgId();

    const routeHistory = useHistory();
    const parseToken = () => {
        const tokens = getTokens();

        if (!tokens.accessToken) {
            routeHistory.push('/login');
            return;
        }
        const accessTokenClaims = jwtDecode<any>(tokens.accessToken);
        if (!accessTokenClaims || !accessTokenClaims.sub) return;

        const userSub: string = accessTokenClaims.sub;

        // Backwards comp
        let type = accessTokenClaims.role;
        const uid = userSub.split(':')[2];
        const upn = accessTokenClaims.email;
        const areas = ['*'];
        const displayName = accessTokenClaims.name;
        const idp = userSub.split(':')[0];
        let roles = [accessTokenClaims.role];
        const tid = userSub.split(':')[1];
        const profileImageURL = accessTokenClaims.profile_pic;
        const msTenantId = accessTokenClaims.msTenantId;
        setCurrentOrgId(tid);
        console.log('tokens', tokens);
        console.log(msTenantId);
        if (tokens.confirmationToken) {
            const confirmationTokenClaims = jwtDecode<any>(tokens.confirmationToken);
            type = confirmationTokenClaims.role;
            roles = [confirmationTokenClaims.role];
        }

        const userIdentity: IIdentityClaims = {
            type,
            uid,
            upn,
            areas,
            displayName,
            idp,
            roles,
            tid,
            profileImageURL,
            msTenantId
        };

        console.log(userIdentity);

        setIdentityClaims(userIdentity);
    };

    const getProfileFromIdp = async () => {
        const userProfile = await idpService.getUser();

        setIdentityClaims((prevClaims) => {
            if (!prevClaims) return prevClaims;

            return {
                ...prevClaims,
                ...{
                    profileImageURL: userProfile.profile_pic || undefined,
                    displayName: userProfile.name
                }
            };
        });
    };

    const refreshIdentity = () => {
        parseToken();
        getProfileFromIdp();
    };

    useEffect(() => {
        refreshIdentity();
    }, []);

    useEvent('refetchUserDetails', (data: any) => {
        refreshIdentity();
    });

    return <></>;
};
