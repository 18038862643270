import { useEvent } from 'kits/eventKit';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useArea } from 'shared-state/directory/hooks';
import { useIsSideBarMaximized } from 'shared-state/display-options/hooks';
import { useCurrentAreaId } from 'shared-state/location/hook';
import { useMessageComposerUserIds } from 'shared-state/messaging/hooks';
import { useUserList } from 'shared-state/presence/hooks';
import { v1 as uuidv1 } from 'uuid';

export interface ISideBarState {
    isMaximized: boolean;
    toggleSidebar: Function;
    isConnected: boolean;
    openAnnouncementDialog: Function;
    goBackToDashboard: Function;
    sidebarTitle: string;
}

export const useSideBarState: () => ISideBarState = () => {
    const routeHistory = useHistory();

    const [isMaximized, setIsMaximized] = useIsSideBarMaximized();
    const [areaId] = useCurrentAreaId();
    const [userList] = useUserList();
    const [, setMemoRecipients] = useMessageComposerUserIds();

    const [isConnected, setIsConnected] = useState(false);
    const [connectionTimeout, setConnectionTimeout] = useState(false);
    const [area] = useArea(areaId);

    const sidebarTitle = isConnected ? area?.name || 'Area' : 'Connecting...';

    const goBackToDashboard = () => {
        if (confirm('Are you sure you want to leave this area?')) routeHistory.push('/areas');
        const newJitsiSessionId = uuidv1();
        localStorage.setItem('jitsiSessionId', newJitsiSessionId);
    };

    const openAnnouncementDialog = () => {
        if (userList.length <= 0) {
            alert('There is no one here to anounce something to :(');
            return;
        }
        setMemoRecipients(userList.map((u) => u.uid));
    };

    const toggleSidebar = () => {
        setIsMaximized((prev) => !prev);
    };

    useEffect(() => {
        setTimeout(() => {
            setConnectionTimeout(true);
        }, 6000000000);
    }, []);

    useEffect(() => {
        if (!connectionTimeout) return;

        if (!isConnected) {
            alert('Your connection timed out :(');
            window.location.reload();
        }
    }, [connectionTimeout]);

    useEvent('presenceChannelConnectionChanged', (event: any) => {
        setIsConnected(event.connected);
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    useEvent('maximizeSideBar', (event: any) => {
        setIsMaximized(true);
    });

    return {
        isMaximized,
        toggleSidebar,
        isConnected,
        openAnnouncementDialog,
        goBackToDashboard,
        sidebarTitle
    };
};
