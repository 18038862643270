import * as React from 'react';

function SvgMenu(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M4 7v2h24V7H4zm0 8v2h24v-2H4zm0 8v2h24v-2H4z" fill="#000" />
        </svg>
    );
}

export default SvgMenu;
