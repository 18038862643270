import * as React from 'react';

function SvgErrorSymbol(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M16 3.219l-.875 1.5-12 20.781-.844 1.5H29.72l-.844-1.5-12-20.781-.875-1.5zm0 4L26.25 25H5.75L16 7.219zM15 14v6h2v-6h-2zm0 7v2h2v-2h-2z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgErrorSymbol;
