import {
    Callout,
    ColorPicker,
    DefaultButton,
    DelayedRender,
    IColor,
    IconButton,
    Label,
    mergeStyleSets,
    Modal,
    PrimaryButton,
    Stack,
    Text
} from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import { QuestionCircle } from 'kits/IconKit2';
import React from 'react';

interface IColorSelectorViewProps {
    label?: string;
    value: string;
    showModal: () => void;
    isModalOpen: boolean;
    smallDevice: boolean;
    toggleIsCalloutVisible: () => void;
    isCalloutVisible: boolean;
    colorSelectorWidth: string;
    buttonId: string;
    directionalHint: 12;
    callout?: string;
    width: string;
    height: string;
    onDismiss: () => void;
    colorChange: (ev: any, colorObj: IColor) => any;
}

const ColorSelectorView: React.FC<IColorSelectorViewProps> = (props) => {
    const {
        label,
        value,
        showModal,
        isModalOpen,
        smallDevice,
        toggleIsCalloutVisible,
        isCalloutVisible,
        colorSelectorWidth,
        buttonId,
        directionalHint,
        callout,
        width,
        height,
        onDismiss,
        colorChange
    } = props;

    const styles = mergeStyleSets({
        button: {
            width: 130
        },
        callout: {
            width: 320,
            padding: '20px 24px'
        }
    });

    return (
        <Stack>
            {label && <Label>{label}</Label>}
            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 32 }}>
                <Stack style={{ width: '100%' }}>
                    <Stack horizontal={!smallDevice}>
                        <Stack
                            style={{
                                backgroundColor: value,
                                color: value,
                                width: width,
                                height: height
                            }}
                            className={colorSelectorWidth}
                            onClick={showModal}
                        ></Stack>
                        {!smallDevice && (
                            <IC variant="custom" size={15} style={{ cursor: 'pointer' }}>
                                <QuestionCircle onClick={toggleIsCalloutVisible} id={buttonId} />
                            </IC>
                        )}
                    </Stack>
                    {isCalloutVisible && !smallDevice && (
                        <Callout
                            className={styles.callout}
                            onDismiss={toggleIsCalloutVisible}
                            role="status"
                            target={`#${buttonId}`}
                            aria-live="assertive"
                            directionalHint={directionalHint}
                        >
                            <DelayedRender>
                                <Text variant="small">{callout}</Text>
                            </DelayedRender>
                        </Callout>
                    )}
                    <Stack horizontalAlign="center" style={smallDevice ? { width: '100%' } : { width: 400 }}>
                        <Text>{`${value?.toUpperCase()}`}</Text>
                    </Stack>
                </Stack>
                {!smallDevice && (
                    <Stack style={{ width: 209 }}>
                        <DefaultButton onClick={showModal} iconProps={{ iconName: 'edit' }} text={'Change color'}></DefaultButton>
                    </Stack>
                )}
            </Stack>

            <Modal isOpen={isModalOpen} onDismiss={onDismiss} isBlocking={false}>
                <Stack horizontal horizontalAlign="end">
                    <IconButton iconProps={{ iconName: 'cancel' }} ariaLabel="Close popup modal" onClick={onDismiss} />
                </Stack>
                <ColorPicker color={value} onChange={colorChange} showPreview={true} />
                <Stack style={{ alignItems: 'center', paddingBottom: 10 }}>
                    <PrimaryButton text={'Confirm'} onClick={onDismiss} />
                </Stack>
            </Modal>
        </Stack>
    );
};

export default ColorSelectorView;
