import { DialogType, getTheme, ITheme } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { ICallAction } from 'features/Rooms/RoomView/CallActions';
import 'intro.js/introjs.css';
import { IRoomResource } from 'kits/apiKit3/legacy';
import { Settings } from 'kits/IconKit2';
import IvicosStrings from 'kits/stringKit';
import React, { useEffect } from 'react';
import { useRoom } from 'shared-state/directory/hooks';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { useCurrentRoomId } from 'shared-state/location/hook';
import { ICallActionGroup } from './CallActionBar';

export interface ICallActionBarState {
    theme: ITheme;
    currentRoomId?: string;
    currentRoom?: IRoomResource;
    hideDialog: boolean;
    toggleHideDialog: () => void;
    dialogContentProps: {
        type: DialogType;
        title: string;
        closeButtonAriaLabel: string;
    };
}

export const useCallActionBarState: (actions: Array<ICallActionGroup>) => ICallActionBarState = (actions) => {
    const theme = getTheme();
    const [currentRoomId] = useCurrentRoomId();
    const [currentRoom] = useRoom(currentRoomId || '');
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(false);

    const { smallDevice } = useWindowWidth();

    useEffect(() => {
        if (smallDevice) {
            const callAction: ICallAction = {
                key: 'device-settings',
                title: IvicosStrings.deviceSettingsActionTitle,
                command: 'toggleDeviceSettings',
                icon: <Settings />
            };
            const groupZero = actions.filter((actionGroup: ICallActionGroup) => actionGroup.key === 'action-group-0')[0];
            groupZero.actions = [];

            const groupOne = actions.filter((actionGroup: ICallActionGroup) => actionGroup.key === 'action-group-1')[0];
            groupOne.actions.push(callAction);
        }
    }, []);

    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Settings',
        closeButtonAriaLabel: 'Close'
    };

    return {
        theme,
        currentRoomId,
        currentRoom,
        hideDialog,
        toggleHideDialog,
        dialogContentProps
    };
};
