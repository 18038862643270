import { Stack } from '@fluentui/react';
import StatefulButton from 'components/StatefulButton/StatefulButton';
import { Plus } from 'kits/IconKit2';
import IvicosStrings from 'kits/stringKit';
import React from 'react';
import { ICreateNewRoomButtonState } from './CreateNewRoomButton.state';

const CreateNewRoomButtonView: React.FC<ICreateNewRoomButtonState> = ({ onClick }) => {
    return (
        <Stack horizontalAlign="center">
            <StatefulButton
                text={IvicosStrings.createNewRoomActionTitle}
                loadingText={IvicosStrings.createNewRoomActionLoadingTitle}
                successText="Saved!"
                onRenderIcon={() => <Plus />}
                onClick={onClick}
            />
        </Stack>
    );
};

export default CreateNewRoomButtonView;
