import {
    Label,
    ListPeoplePicker,
    ValidationState,
    PeoplePickerItemSuggestion,
    IBasePickerSuggestionsProps,
    IPersonaStyles,
    IPersonaProps,
    Stack,
    PersonaPresence
} from '@fluentui/react';
import React, { CSSProperties } from 'react';
import { mergeStyles } from '@uifabric/merge-styles';

const suggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: 'Suggested People',
    mostRecentlyUsedHeaderText: 'Suggested Contacts',
    noResultsFoundText: 'No results found',
    loadingText: 'Loading',
    showRemoveButtons: true,
    suggestionsAvailableAlertText: 'People Picker Suggestions available',
    suggestionsContainerAriaLabel: 'Suggested contacts'
};

const personaStyles: Partial<IPersonaStyles> = {
    root: {
        height: 'auto',
        // eslint-disable-next-line prettier/prettier
        // eslint-disable-next-line no-useless-escape
        ':before': { content: '\\a', 'white-space': 'pre' }
    },
    secondaryText: {
        height: 'auto',
        whiteSpace: 'normal'
    },
    primaryText: {
        height: 'auto',
        whiteSpace: 'normal'
    }
};

export const PeoplePicker: React.FunctionComponent<{
    label: string | undefined;
    onChangeSelections: (items?: IPersonaProps[]) => void;
    membersList: IPersonaProps[];
    selectedItems: IPersonaProps[];
    isVisible: boolean;
}> = ({ label, onChangeSelections, membersList, selectedItems, isVisible }) => {
    const [delayResults, setDelayResults] = React.useState(true);
    const [isPickerDisabled, setIsPickerDisabled] = React.useState(false);
    const [mostRecentlyUsed, setMostRecentlyUsed] = React.useState<IPersonaProps[]>(membersList);
    const [peopleList, setPeopleList] = React.useState<IPersonaProps[]>(membersList);

    const picker = React.useRef(null);
    const onFilterChanged = (
        filterText: string,
        currentPersonas: IPersonaProps[] | undefined,
        limitResults?: number
    ): IPersonaProps[] | Promise<IPersonaProps[]> => {
        const filterNames = (item: IPersonaProps, filterText: string) => {
            const nameItems = item.primaryText?.toLowerCase().split(' ');
            return nameItems && nameItems.filter((nameItem) => nameItem.startsWith(filterText.toLowerCase())).length > 0;
        };
        if (filterText) {
            let filteredPersonas: IPersonaProps[] = peopleList.filter((item) => filterNames(item, filterText));

            if (currentPersonas) filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
            filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;
            return filterPromise(filteredPersonas);
        } else {
            return [];
        }
    };

    const filterPromise = (personasToReturn: IPersonaProps[]): IPersonaProps[] | Promise<IPersonaProps[]> => {
        if (delayResults) {
            return convertResultsToPromise(personasToReturn);
        } else {
            return personasToReturn;
        }
    };

    const returnMostRecentlyUsed = (currentPersonas: IPersonaProps[] | undefined): IPersonaProps[] | Promise<IPersonaProps[]> => {
        if (currentPersonas) return filterPromise(removeDuplicates(mostRecentlyUsed, currentPersonas));
        else return mostRecentlyUsed;
    };

    const onRemoveSuggestion = (item: IPersonaProps): void => {
        const indexPeopleList: number = peopleList.indexOf(item);
        const indexMostRecentlyUsed: number = mostRecentlyUsed.indexOf(item);

        if (indexPeopleList >= 0) {
            const newPeople: IPersonaProps[] = peopleList.slice(0, indexPeopleList).concat(peopleList.slice(indexPeopleList + 1));
            setPeopleList(newPeople);
        }

        if (indexMostRecentlyUsed >= 0) {
            const newSuggestedPeople: IPersonaProps[] = mostRecentlyUsed
                .slice(0, indexMostRecentlyUsed)
                .concat(mostRecentlyUsed.slice(indexMostRecentlyUsed + 1));
            setMostRecentlyUsed(newSuggestedPeople);
        }
    };

    const onDisabledButtonClick = (): void => {
        setIsPickerDisabled(!isPickerDisabled);
    };

    const onToggleDelayResultsChange = (): void => {
        setDelayResults(!delayResults);
    };

    const onRenderSuggestionItem = (personaProps: IPersonaProps, suggestionsProps: IBasePickerSuggestionsProps) => {
        return (
            <PeoplePickerItemSuggestion
                personaProps={{ ...personaProps, presence: PersonaPresence.none, styles: personaStyles }}
                suggestionsProps={suggestionsProps}
            />
        );
    };

    const containerStackStyle: () => CSSProperties = React.useCallback(() => {
        return isVisible || isVisible === undefined ? { visibility: 'visible' } : { visibility: 'hidden' };
    }, [isVisible]);

    const selectedItemSelectorClassName = mergeStyles({
        '> div > div.ms-SelectionZone > div > div.ms-PickerPersona-container': {
            marginTop: '13px'
        }
    });

    return (
        <Stack style={containerStackStyle()} className={selectedItemSelectorClassName}>
            <Label>{label}</Label>
            <ListPeoplePicker
                defaultSelectedItems={selectedItems}
                onResolveSuggestions={onFilterChanged}
                onEmptyInputFocus={returnMostRecentlyUsed}
                getTextFromItem={getTextFromItem}
                className={'ms-PeoplePicker'}
                pickerSuggestionsProps={suggestionProps}
                key={'list'}
                removeButtonAriaLabel={'Remove'}
                onRemoveSuggestion={onRemoveSuggestion}
                onRenderSuggestionsItem={onRenderSuggestionItem}
                onValidateInput={validateInput}
                inputProps={{
                    placeholder: 'Start typing names to add users to the whitelist...',
                    'aria-label': 'People Picker'
                }}
                componentRef={picker}
                resolveDelay={300}
                disabled={isPickerDisabled}
                onChange={onChangeSelections}
            />
        </Stack>
    );
};

function removeDuplicates(personas: IPersonaProps[], possibleDupes: IPersonaProps[]) {
    return personas.filter((persona) => !listContainsPersona(persona, possibleDupes));
}

function listContainsPersona(persona: IPersonaProps, personas: IPersonaProps[]) {
    if (!personas || !personas.length || personas.length === 0) {
        return false;
    }
    return personas.filter((item) => item.primaryText === persona.primaryText).length > 0;
}

function convertResultsToPromise(results: IPersonaProps[]): Promise<IPersonaProps[]> {
    return new Promise<IPersonaProps[]>((resolve, reject) => setTimeout(() => resolve(results), 2000));
}

function getTextFromItem(persona: IPersonaProps): string {
    return persona.primaryText ? persona.primaryText : 'persona';
}

function validateInput(input: string): ValidationState {
    if (input.indexOf('@') !== -1) {
        return ValidationState.valid;
    } else if (input.length > 1) {
        return ValidationState.warning;
    } else {
        return ValidationState.invalid;
    }
}
