/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getTokens = () => {
    const rawToken = localStorage.getItem('ivAccessToken');
    if (!rawToken) {
        console.warn('No Token found');
        return { accessToken: undefined, confirmationToken: undefined };
    }

    const tokens = rawToken.split(':');

    return {
        accessToken: tokens[0],
        confirmationToken: tokens[1]
    };
};
