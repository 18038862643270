/* eslint-disable no-control-regex */
import React from 'react';
import { useRegistrationFormControllerState } from './RegistrationFormController.state';
import RegistrationFormControllerView from './RegistrationFormController.view';

export interface IRegistrationFormControllerProps {
    onRegistrationSuccess(token: string, payload: any): void;
    onError(err: any): void;
    invitationId: string;
}
export const RegistrationFormController: React.FC<IRegistrationFormControllerProps> = (props) => {
    const { onRegistrationSuccess, onError, invitationId } = props;

    const state = useRegistrationFormControllerState(onRegistrationSuccess, onError, invitationId);

    return (
        <RegistrationFormControllerView
            {...{
                ...state
            }}
        />
    );
};

export default RegistrationFormController;
