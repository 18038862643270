import { DefaultButton, IComboBox, PrimaryButton, Stack } from '@fluentui/react';
import React from 'react';
import ImportedPerson from '../ImportedPerson/ImportedPerson';

export type ICSVData = { body: string[][]; headline: string[]; setBody: (value: React.SetStateAction<string[][]>) => void };

export interface IReviewDataStepViewProps {
    onChangeImportedPersonData: (
        index: number,
        datatype: number
    ) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement | IComboBox>, newValue?: string) => void;
    validateEmail: (value: string) => boolean;
    validateRole: (value: string) => boolean;
    data?: ICSVData;
    declineData: () => void;
    acceptData: () => void;
}

const ReviewDataStepView: React.FC<IReviewDataStepViewProps> = (props) => {
    const { data, declineData, acceptData, onChangeImportedPersonData, validateEmail, validateRole } = props;

    return (
        <Stack tokens={{ childrenGap: 16 }}>
            {data?.body &&
                data?.body.map((line, index) => (
                    <ImportedPerson
                        key={index}
                        indexInCSVDATA={index}
                        email={line[0]}
                        role={line[1]}
                        onChangeImportedPersonData={onChangeImportedPersonData}
                        validateEmail={validateEmail}
                        validateRole={validateRole}
                    />
                ))}
            <Stack horizontalAlign="end" horizontal tokens={{ childrenGap: 16 }}>
                <DefaultButton onClick={() => declineData()} text="Change file" />
                <PrimaryButton onClick={() => confirm('Are you sure you want to invite these people?') && acceptData()} text="Invite these people" />
            </Stack>
        </Stack>
    );
};

export default ReviewDataStepView;
