import * as React from 'react';

function SvgVideoBackground(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M3 5v22h26V5H3zm2 2h22v18H5V7zm11 3c-2.75 0-5 2.25-5 5 0 1.477.66 2.8 1.688 3.719-2.16.996-3.829 2.933-4.438 5.281h2.094A6.01 6.01 0 0116 20a6.01 6.01 0 015.656 4h2.094c-.61-2.348-2.277-4.285-4.438-5.281C20.34 17.8 21 16.477 21 15c0-2.75-2.25-5-5-5zm0 2c1.668 0 3 1.332 3 3s-1.332 3-3 3-3-1.332-3-3 1.332-3 3-3z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgVideoBackground;
