import React from 'react';
import { useDynamicFormState } from './DynamicForm.state';
import DynamicFormView from './DynamicForm.view';

export type IDynamicFormType =
    | 'text'
    | 'dropdown'
    | 'image'
    | 'color'
    | 'multiline-text'
    | 'icon'
    | 'toggle'
    | 'checkbox'
    | 'section-heading'
    | 'personaSelector';

export interface IDynamicFormElementProps {
    key: string;
    type: IDynamicFormType;
    label?: string;
    value?: any;
    description?: string;
    callout?: string;
    width?: string;
    height?: string;
    isVisible?: boolean;
    disabled?: boolean;
}

const DynamicForm: React.FC<{ formFields: IDynamicFormElementProps[]; onChange: (key: string, value: any) => void }> = (props) => {
    const state = useDynamicFormState(props.onChange);

    return (
        <DynamicFormView
            {...{
                ...state,
                ...{
                    formFields: props.formFields
                }
            }}
        />
    );
};

export default DynamicForm;
