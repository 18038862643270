import { Modal, Stack, IconButton, List, Text } from '@fluentui/react';
import React from 'react';

interface IAttachmentCreationModalViewProps {
    isOpen?: boolean;
    onDismiss?: Function;
    createAttachment: (addOnName: string, optionalRef?: string | undefined) => Promise<void>;
    addOns: {
        id: string;
        type: string;
        attributes: {
            urlTemplate: string;
            iconUrl: string;
            displayName: string;
            description: string;
        };
        relationships: {};
    }[];
}

const AttachmentCreationModalView: React.FC<IAttachmentCreationModalViewProps> = (props) => {
    const { isOpen, onDismiss, createAttachment, addOns } = props;
    return (
        <Modal isOpen={isOpen}>
            <Stack horizontal style={{ padding: 16 }} verticalAlign="center" horizontalAlign="space-between">
                <Text variant="large">Choose attachment type...</Text>
                <IconButton
                    iconProps={{ iconName: 'clear' }}
                    onClick={() => {
                        onDismiss && onDismiss();
                    }}
                />
            </Stack>
            <Stack style={{ padding: 16, minHeight: 176 }}>
                <List
                    items={addOns.map((addOn) => ({
                        displayName: addOn.attributes.displayName,
                        description: addOn.attributes.description,
                        id: addOn.id,
                        icon: addOn.attributes.iconUrl
                    }))}
                    onRenderCell={(item) => {
                        if (!item) return <></>;
                        return (
                            <Stack
                                className="pressable"
                                horizontal
                                style={{ padding: 8 }}
                                verticalAlign="center"
                                tokens={{ childrenGap: 8 }}
                                onClick={() => {
                                    if (item.id == 'linkmanager') {
                                        const url = prompt('Please enter an url');
                                        if (!url) return;
                                        const encodedUrl = Buffer.from(url, 'binary').toString('base64');
                                        createAttachment(item.id, encodedUrl);
                                        return;
                                    }
                                    createAttachment(item.id);
                                }}
                            >
                                <img src={item.icon || 'https://static-cdn.vercel.app/addon.svg'} height={48} />
                                <Stack>
                                    <Text variant="large">{item.displayName}</Text>
                                    <Text variant="small">{item.description}</Text>
                                </Stack>
                            </Stack>
                        );
                    }}
                />
            </Stack>
        </Modal>
    );
};

export default AttachmentCreationModalView;
