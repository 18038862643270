import ImagePickerView from 'components/ImagePicker/views/ImagePickerView';
import SettingsImagePickerView from 'components/ImagePicker/views/SettingsImagePickerView';
import React from 'react';
import { useImagePickerState } from './ImagePicker.state';

export interface IImagePickerProps {
    selectedImageURL?: string;
    onImageSelect: Function;
    label?: string;
    view: string;
}

const ImagePicker: React.FC<IImagePickerProps> = (props) => {
    const state = useImagePickerState(props.onImageSelect, props.selectedImageURL);

    if (props.view == 'settings') {
        return (
            <SettingsImagePickerView
                {...{
                    ...state,
                    ...{
                        imageOptions: state.fetchedImages,
                        onImageSelect: (i) => state._onImageSelect(i),
                        onFile: state.onImageUploadToLocalStorage,
                        label: props.label
                    }
                }}
            />
        );
    } else {
        return (
            <ImagePickerView
                {...{
                    ...state,
                    ...{
                        imageOptions: state.fetchedImages,
                        onImageSelect: (i) => state._onImageSelect(i),
                        onFile: state.onImageUploadToLocalStorage,
                        label: props.label
                    }
                }}
            />
        );
    }
};

export default ImagePicker;
