import { DialogType, FontWeights, getTheme, IFontWeight, ITheme, memoizeFunction } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { IDynamicFormElementProps } from 'components/DynamicForm/DynamicForm';
import { useEffect, useMemo, useState } from 'react';
import { idpService } from 'services/api/identity-provider.service';
import { useIdentityClaims } from 'shared-state/identity/hooks';
import 'intro.js/introjs.css';
import { useHistory } from 'react-router';

export interface IMember {
    userId: string;
    profile_pic: string;
    name: string;
    email: string;
    role: 'user' | 'manager' | 'owner' | 'invited';
}
export interface IInvitee {
    email: string;
    role: 'user' | 'manager' | 'owner';
}

export interface ISteps {
    stepsEnabled: boolean;
    initialStep: number;
    steps: [
        {
            element: string;
            intro: string;
        },
        {
            element: string;
            intro: string;
            position: string;
        },
        {
            element: string;
            intro: string;
            tooltipClass: string;
        }
    ];
}
const inviteeToMember = (invitee: IInvitee) => ({ ...invitee, ...{ name: 'Invite pending...', profilePic: '', userId: 'invitee', role: 'invited' } });

export interface IManageAccessState {
    isEditUserOpen: boolean;
    dismissEditUserPanel: () => void;
    editUserForm: IDynamicFormElementProps[];
    onEditUserFormChange: (key: string, newValue: any) => void;
    user: IMember | undefined;
    changes: any;
    onUpdateUser: (userId: string, changes: any) => Promise<any | undefined>;
    setChanges: any;
    filterUsers: (text: string) => void;
    filteredMembers: IMember[];
    iconProps: {
        iconName: string;
    };
    setEmailToInvite: React.Dispatch<React.SetStateAction<string>>;
    inviteUser: () => void;
    _members: (
        | IMember
        | {
              name: string;
              profilePic: string;
              userId: string;
              role: string;
              email: string;
          }
    )[];
    togglehideInviteDialog: () => void;
    inviteDialogContentProps: {
        type: DialogType;
        title: string;
        closeButtonAriaLabel: string;
        subText: string;
    };
    hideInviteDialog: boolean;
    emailToInvite: string;
    deleteDialogContentProps: {
        type: DialogType;
        title: string;
        closeButtonAriaLabel: string;
        subText: string;
    };
    hideDeleteDialog: boolean;
    togglehideDeleteDialog: () => void;
    deleteUser: () => Promise<void>;
    togglehideReinviteDialog: () => void;
    hideReinviteDialog: boolean;
    reinviteDialogContentProps: {
        type: DialogType;
        title: string;
        closeButtonAriaLabel: string;
        subText: string;
    };
    togglehideUserExistsDialog: () => void;
    hideUserExistsDialog: boolean;
    userAlreadyExistsContentProps: {
        type: DialogType;
        title: string;
        closeButtonAriaLabel: string;
        subText: string;
    };
    onDismiss: () => void;
    isModalOpen: boolean;
    showModal: () => void;
    hideModal: () => void;
    userForMobileModal?: IMember;
    setUserForMobileModal: React.Dispatch<React.SetStateAction<IMember | undefined>>;
    setUserToDelete: React.Dispatch<React.SetStateAction<IMember | undefined>>;
    setUser: React.Dispatch<React.SetStateAction<IMember | undefined>>;
    openEditUserPanel: () => void;
    isInvitePanelOpen: boolean;
    openInvitePanel: () => void;
    dismissInvitePanel: () => void;
    isText: boolean;
    getDescriptionStyles: (
        theme: ITheme
    ) => {
        root: {
            color: string;
            fontWeight: IFontWeight;
            display: string;
        };
    };
    theme: ITheme;
    stepsEnabled?: boolean;
    setStepsEnabled: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    steps: ISteps;
    options: {
        doneLabel: string;
        hideNext: boolean;
        showStepNumbers: boolean;
        showBullets: boolean;
        hidePrev: boolean;
    };
    routeHistory: any;
    microsoftProps: { microsoftTenantIsInvited: boolean; isMicrosoftUser: boolean; setMicrosoftTenantIsInvited: (isInvited: boolean) => void };
}

export const useManageAccessState: () => IManageAccessState = () => {
    const theme = getTheme();
    const iconProps = { iconName: 'Search' };
    const [user, setUser] = useState<IMember>();
    const [emailToInvite, setEmailToInvite] = useState('');
    const [userToDelete, setUserToDelete] = useState<IMember>();
    const [userForMobileModal, setUserForMobileModal] = useState<IMember>();
    const [idClaims] = useIdentityClaims();
    const [isEditUserOpen, { setTrue: openEditUserPanel, setFalse: dismissEditUserPanel }] = useBoolean(false);
    const [members, setMembers] = useState<IMember[]>([]);
    const [filteredMembers, setfilteredMembers] = useState<IMember[]>([]);
    const [invitees, setInvitees] = useState<IInvitee[]>([]);
    const [displayEmailError, setDisplayEmailError] = useState('none');
    const [hideInviteDialog, { toggle: togglehideInviteDialog }] = useBoolean(true);
    const [hideReinviteDialog, { toggle: togglehideReinviteDialog }] = useBoolean(true);
    const [hideUserExistsDialog, { toggle: togglehideUserExistsDialog }] = useBoolean(true);
    const [hideDeleteDialog, { toggle: togglehideDeleteDialog }] = useBoolean(true);
    const [isText, setIsText] = useState(false);
    const [isInvitePanelOpen, { setTrue: openInvitePanel, setFalse: dismissInvitePanel }] = useBoolean(false);
    const [stepsEnabled, setStepsEnabled] = useState<boolean>();
    const [changes, setChanges] = useState<any>({});
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

    const _members = useMemo(
        () => [...invitees.filter((invitee) => !invitee.email.includes('microsoft.tenant')).map((invitee) => inviteeToMember(invitee)), ...members],
        [members, invitees]
    );
    const tenantIdEmail = idClaims?.msTenantId + '@microsoft.tenant';

    const isMicrosoftUser = idClaims?.msTenantId ? true : false;
    const microsoftTenantIsInvited = invitees.find((invitee) => invitee.email.includes(tenantIdEmail)) ? true : false;

    const setMicrosoftTenantIsInvited = async (isInvited: boolean) => {
        try {
            if (isInvited) {
                await idpService.newInvited(tenantIdEmail, 'user');
            } else {
                await idpService.deleteInvited(tenantIdEmail);
            }
        } catch (err) {
            console.log(err);
        }
        fetchUsers();
    };

    const inviteDialogContentProps = {
        type: DialogType.normal,
        title: 'Invitation Sent',
        closeButtonAriaLabel: 'Close',
        subText: 'Your invitation to ' + emailToInvite + ' has been sent!'
    };

    const reinviteDialogContentProps = {
        type: DialogType.normal,
        title: 'Resend Invitation?',
        closeButtonAriaLabel: 'Close',
        subText: 'Are you sure you want to resend the invitation to ' + emailToInvite + '?'
    };

    const userAlreadyExistsContentProps = {
        type: DialogType.normal,
        title: 'User already exists',
        closeButtonAriaLabel: 'Close',
        subText: emailToInvite + ' is already a member'
    };

    const deleteDialogContentProps = {
        type: DialogType.normal,
        title: 'Delete Invitation',
        closeButtonAriaLabel: 'Close',
        subText: 'Are you sure you want to delete this user?'
    };

    const getDescriptionStyles = memoizeFunction((theme: ITheme) => ({
        root: { color: theme.palette.red, fontWeight: FontWeights.bold, display: displayEmailError }
    }));

    const fetchUsers = async () => {
        const fetchedMembers = await idpService.showMembers();
        const fetchedInvitees = await idpService.showOrgInvited();
        setMembers(fetchedMembers);
        setInvitees(fetchedInvitees);
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    // FILTER DETAILSLIST WITH THE SEARCH BAR

    const filterUsers = (text: string) => {
        if (text.includes('+')) return;
        if (text.includes('[')) return;
        if (text.includes('(')) return;
        if (text.includes(')')) return;
        if (text.includes('?')) return;
        const regex = new RegExp(text.toUpperCase());
        text.length > 0 ? setIsText(true) : setIsText(false);
        const result: any = _members.filter((member) => regex.test(member.email.toUpperCase()));
        setfilteredMembers(result);
    };

    // DYNAMIC FORM STUFF

    const userFormForUser: (user: IMember | undefined) => IDynamicFormElementProps[] = (user) => {
        return [
            { key: 'name', label: user?.name, value: user?.name, type: 'section-heading' },
            { key: 'email', label: user?.email, value: user?.email, type: 'section-heading' },
            {
                key: 'role',
                label: 'Role',
                value: {
                    selectedKey: user?.role,
                    options: [
                        { key: 'user', text: 'User' },
                        { key: 'manager', text: 'Manager' }
                    ]
                },
                type: 'dropdown'
            }
        ];
    };
    const [editUserForm, setEditUserForm] = useState<IDynamicFormElementProps[]>(userFormForUser(user));

    useEffect(() => {
        setEditUserForm(userFormForUser(user));
    }, [user]);

    const onChange = (change: { [index: string]: string | boolean }) => {
        setChanges((prevState: any) => {
            const newState = { ...prevState, ...change };
            return newState;
        });
    };

    const onEditUserFormChange = (key: string, newValue: any) => {
        if (key == 'role') {
            onChange({ [key]: newValue.selectedKey });
        } else {
            onChange({ [key]: newValue });
        }
        setEditUserForm((prevArray) => {
            return prevArray.map((el: any) => {
                if (el.key != key) return el;
                return { ...el, ...{ value: newValue } };
            });
        });
    };

    const onUpdateUser = (userId: string, changes: any): Promise<any | undefined> => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return new Promise((resolve, reject) => {
            if (!user) return resolve(undefined);
            idpService.changeMemberRole(changes.role, user.userId).then(() => fetchUsers());
        });
    };

    // INVITE USERS

    const inviteUser = () => {
        const memberEmails = members.map((a) => a.email);
        if (validateEmail(emailToInvite)) {
            setDisplayEmailError('none');
            if (!memberEmails.includes(emailToInvite)) {
                idpService.newInvited(emailToInvite, 'user');
                togglehideInviteDialog();
            } else {
                togglehideUserExistsDialog();
            }
        } else {
            setDisplayEmailError('');
        }
        fetchUsers();
    };

    const validateEmail = (email: string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    // DELETE USERS (MEMBERS OR INVITEES)

    const deleteUser = async () => {
        if (userToDelete) {
            if (userToDelete.userId != 'invitee') {
                await idpService.removeMember(userToDelete.userId);
            } else {
                idpService.deleteInvited(userToDelete.email);
            }
        }
        await fetchUsers();
    };

    const onDismiss = () => {
        hideModal();
    };

    useEffect(() => {
        if (localStorage.getItem('intro-manage-access') === 'false') {
            setStepsEnabled(false);
        } else if (localStorage.getItem('intro-manage-access') === 'true') {
            setStepsEnabled(true);
        }
    }, []);

    const steps: ISteps = {
        stepsEnabled: true,
        initialStep: 0,
        steps: [
            {
                element: '.invite',
                intro:
                    'Invite members to your ivCAMPUS by typing in their email and clicking the invite button. You can cancel or resend invitations in the table below, as well as remove members who have already joined.'
            },
            {
                element: '.table',
                intro:
                    'This table will show all users you have invited to your ivCAMPUS, whether they have accepted the invitation or not. Their name and profile picture will be displayed once they accept the invitation.',
                position: 'top'
            },
            {
                element: '.filter',
                intro: 'Filter the table by email.',
                tooltipClass: 'introjs-tooltip-thumb'
            }
        ]
    };

    const options = {
        doneLabel: 'Ok!',
        hideNext: false,
        showStepNumbers: true,
        showBullets: false,
        hidePrev: true
    };
    const routeHistory = useHistory();

    return {
        isEditUserOpen,
        dismissEditUserPanel,
        editUserForm,
        onEditUserFormChange,
        user,
        changes,
        onUpdateUser,
        setChanges,
        filterUsers,
        filteredMembers,
        iconProps,
        setEmailToInvite,
        inviteUser,
        _members,
        togglehideInviteDialog,
        inviteDialogContentProps,
        hideInviteDialog,
        emailToInvite,
        deleteDialogContentProps,
        hideDeleteDialog,
        togglehideDeleteDialog,
        deleteUser,
        togglehideReinviteDialog,
        hideReinviteDialog,
        reinviteDialogContentProps,
        togglehideUserExistsDialog,
        hideUserExistsDialog,
        userAlreadyExistsContentProps,
        onDismiss,
        isModalOpen,
        showModal,
        hideModal,
        userForMobileModal,
        setUserToDelete,
        setUser,
        openEditUserPanel,
        isInvitePanelOpen,
        openInvitePanel,
        dismissInvitePanel,
        isText,
        getDescriptionStyles,
        theme,
        setUserForMobileModal,
        stepsEnabled,
        setStepsEnabled,
        steps,
        options,
        routeHistory,
        microsoftProps: {
            isMicrosoftUser,
            microsoftTenantIsInvited,
            setMicrosoftTenantIsInvited
        }
    };
};
