import React from 'react';
import { useDragNDropContextState } from './DragNDropContext.state';
import { DragNDropContextView } from './DragNDropContext.view';

export const DragNDropContext: React.FC = ({ children }) => {
    const state = useDragNDropContextState();

    return (
        <DragNDropContextView
            {...{
                ...state
            }}
        >
            {children}
        </DragNDropContextView>
    );
};
