import { AtomEffect } from 'recoil';
import { getTokenFromSession, setTokenInSession } from 'shared-state/tokens/helpers';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useTokenAtomEffects = (tokenType: string) => {
    const effects: (tennantId: string) => AtomEffect<string | undefined>[] = (tennantId: string) => [
        ({ onSet, setSelf }) => {
            const currentSessionState = getTokenFromSession(tokenType, tennantId);
            currentSessionState && setSelf(currentSessionState);
            onSet((newValue) => {
                typeof newValue == 'string' && setTokenInSession(tokenType, tennantId, newValue);
            });
        }
    ];
    return effects;
};
