import React from 'react';
import { Stack, TooltipHost } from '@fluentui/react';
import { IC } from 'components/SVGIcon';

export interface ISideBarIconButtonProps {
    onClick?: Function;
    title?: string;
    variant?: 'dark' | 'primary' | 'red';
}

export const SideBarIconButton: React.FC<ISideBarIconButtonProps> = ({ onClick, title, variant = 'dark', children }) => {
    return (
        <TooltipHost content={title}>
            <Stack
                onClick={() => onClick && onClick()}
                className="pressable"
                style={{ borderRadius: 4, padding: 4 }}
                verticalAlign="center"
                horizontalAlign="center"
            >
                <IC size={24} variant={variant}>
                    {children}
                </IC>
            </Stack>
        </TooltipHost>
    );
};
