// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { getFlag } from 'routes/Flags/Flags';
import { thirdPartyAuthService } from 'services/api/third-party-auth.service';
import { useCurrentAreaId, useCurrentCampusId, useCurrentRoomId } from 'shared-state/location/hook';
import { useLocalJitsiDomain, useLocalJitsiRoom, useLocalJitsiToken } from 'shared-state/tokens/hooks';

export const useJitsiToken = (): [string | undefined, string | undefined, string | undefined, Function] => {
    const routeHistory = useHistory();
    const [currentRoomId] = useCurrentRoomId();
    const [currentAreaId] = useCurrentAreaId();
    const [currentCampusId] = useCurrentCampusId();
    const [jwt, setJwt] = useLocalJitsiToken(currentRoomId || 'default');
    const [room, setRoom] = useLocalJitsiRoom(currentRoomId || 'default');
    const [domain, setDomain] = useLocalJitsiDomain(currentRoomId || 'default');

    const jitsiSessionId = localStorage.getItem('jitsiSessionId');

    const clearToken = () => setJwt(undefined);

    const fetchToken = async () => {
        console.log('[JITSIHOOK] Clearing JWT...');
        clearToken();
        const jitsiEnvFlavor = getFlag('useJitsiStagingEnv') == 'YES' ? 'staging' : 'production';
        console.log('[JITSIHOOK]: env flavor: ', jitsiEnvFlavor, getFlag('useJitsiStagingEnv'));
        // @TODO remove debug thingy
        try {

            const tokenReq = await thirdPartyAuthService.getJitsiKey(currentCampusId, currentAreaId, currentRoomId, jitsiEnvFlavor);

            setDomain(tokenReq.domain);
            setRoom(tokenReq.room);
            setJwt(tokenReq.key);
        } catch (err) {
            routeHistory.push('/error/403');
        }
        console.log('[JITSIHOOK] Setting JWT...');
    };

    useEffect(() => {
        if (!currentRoomId || !currentCampusId || !currentAreaId) {
            clearToken();
            return;
        }

        fetchToken();
    }, [currentRoomId, currentCampusId, currentAreaId, jitsiSessionId]);

    return [jwt, room, domain, clearToken];
};
