import * as React from 'react';

function SvgRoom(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M15.75 4l-.156.031L4.719 7.406 4 7.625v16.063l.656.218 10.875 4.032.188.062h.562l.188-.063 10.875-4.03.656-.22V7.625l-.719-.219-10.875-3.375L16.25 4h-.5zM15 6.344V18.5l-9 3.094V9.125l9-2.781zm2 0l9 2.781v12.469l-3-1.032v-9l-4-1.28v8.905l-2-.687V6.344zm-4 4.062l-4 1.25v5.5l4-1.562v-5.188zm3 9.844L24 23l-8 2.969L8 23l8-2.75z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgRoom;
