export const hardcodedAddOns = [
    {
        id: 'linkmanager',
        type: 'iv_campus_addon',
        attributes: {
            urlTemplate: 'https://static-cdn.vercel.app/api/go?to=${ref}',
            iconUrl: 'https://static-cdn.vercel.app/link.svg',
            displayName: 'Link',
            description: 'Post links from any site'
        },
        relationships: {}
    },
    {
        id: 'etherpad',
        type: 'iv_campus_addon',
        attributes: {
            urlTemplate:
                'https://etherpad.ivicos-campus.app/p/${ref}?showControls=true&showChat=true&showLineNumbers=true&useMonospaceFont=false&userName=${userName}',
            displayName: 'Etherpad',
            iconUrl: 'https://static-cdn.vercel.app/etherpad.svg',
            description: 'Collaborative document tool'
        },
        relationships: {}
    },
    {
        id: 'excalidraw',
        type: 'iv_campus_addon',
        attributes: {
            urlTemplate: 'https://excalidraw.com/#room=ivicos-${ref},pfLqgEoY1c2ioq8LmGwsFA',
            displayName: 'Excalidraw',
            iconUrl: 'https://static-cdn.vercel.app/excalidraw.svg',
            description: 'Collaborative whiteboard tool'
        },
        relationships: {}
    },
    {
        id: 'chess',
        type: 'iv_campus_addon',
        attributes: {
            urlTemplate: 'https://campus-arcade.vercel.app/chess/${roomId}',
            displayName: 'Chess',
            iconUrl: 'https://static-cdn.vercel.app/chess.svg',
            description: 'Put your boss in checkmate :)'
        },
        relationships: {}
    },
    {
        id: 'tictactoe',
        type: 'iv_campus_addon',
        attributes: {
            urlTemplate: 'https://campus-arcade.vercel.app/tictactoe/${roomId}',
            iconUrl: 'https://static-cdn.vercel.app/tictactoe.svg',
            displayName: 'TicTacToe',
            description: 'X’s and O’s are still a ton of fun'
        },
        relationships: {}
    }
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const parseUrlTemplate = (addOn: any, roomId: string, attachmentRef: string, displayName: string) => {
    const template = addOn.attributes.urlTemplate;

    return template.replace('${roomId}', roomId).replace('${ref}', attachmentRef).replace('${userName}', encodeURIComponent(displayName));
};
