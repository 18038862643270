import React from 'react';
import { useManageAccessState } from './ManageAccess.State';
import ManageAccessView from './ManageAccess.view';

const ManageAccess: React.FC = () => {
    const state = useManageAccessState();

    return (
        <ManageAccessView
            {...{
                ...state,
                ...{
                    members: state._members,
                    microsoftProps: {
                        microsoftTenantIsInvited: state.microsoftProps.microsoftTenantIsInvited,
                        isMicrosoftUser: state.microsoftProps.isMicrosoftUser,
                        setMicrosoftTenantIsInvited: state.microsoftProps.setMicrosoftTenantIsInvited
                    }
                }
            }}
        />
    );
};

export default ManageAccess;
