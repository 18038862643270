import React from 'react';
import { useTabOption } from 'shared-state/tabs/hooks';

export interface ISideBarTabProps {
    tabKey: string;
}
export const SideBarTab: React.FC<ISideBarTabProps> = ({ tabKey, children }) => {
    const [tabOption] = useTabOption('sidebar', tabKey);

    return <div style={{ display: tabOption?.isSelected ? 'contents' : 'none' }}>{tabOption && children}</div>;
};
