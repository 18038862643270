import { deleteResource, getResource, postFileResource } from 'kits/fetchKit';
import { useEffect, useState } from 'react';

export interface IImagePickerImage {
    id: string;
    url: string;
}

export interface IImagePickerState {
    fetchedImages: IImagePickerImage[];
    modalIsOpen: boolean;
    onModalOpen: () => void;
    selectedImage: IImagePickerImage;
    _onImageSelect: (img: any) => void;
    onDismiss: () => void;
    onImageUploadToLocalStorage: (f: any) => Promise<void>;
    onDiscardImage: (f: string) => void;
}

export const useImagePickerState: (onImageSelect: Function, selectedImageURL?: string) => IImagePickerState = (onImageSelect, selectedImageURL) => {
    const [selectedImage, setSelectedImage] = useState<{ id: string; url: string }>({
        id: '',
        url: selectedImageURL || ''
    });
    const [fetchedImages, refetchImages] = useMediaImages();
    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        refetchImages();
    }, [selectedImage]);

    useEffect(() => {
        setSelectedImage({ id: '', url: selectedImageURL || '' });
    }, [selectedImageURL]);

    const onDismiss = () => {
        setModalIsOpen(false);
    };

    const uploadMediaFile = async (file: File) => {
        const mediaEndpoint = 'https://api-de-eu.ivicos-campus.app/beta/media/default/images';
        const token = localStorage.getItem('ivAccessToken');
        if (!token) return false;
        const uploadResult = await postFileResource(token, mediaEndpoint, [['file', file]]);
        return uploadResult;
    };

    useEffect(() => {
        // die richtige url
        if (selectedImageURL === undefined) return;

        if (selectedImage.url != selectedImageURL) onImageSelect(selectedImage.url);
    }, [selectedImage]);

    const _onImageSelect = (img: any) => {
        setSelectedImage(img);
        onImageSelect(img.url);
    };

    const onImageUploadToLocalStorage = async (f: any) => {
        const file = f[0];
        if (typeof file == 'undefined') return;
        const r = await uploadMediaFile(file);
        if (!r) return;
        const nextImg = { id: r.key, url: r.image_url };
        _onImageSelect(nextImg);
    };

    const onDiscardImage = async (f: string) => {
        const isSure = confirm('Are you sure you want to delete this image?');
        if (!isSure) return;
        const token = localStorage.getItem('ivAccessToken');

        await deleteResource(token, 'https://api-de-eu.ivicos-campus.app/beta/media/default/images/' + f);
        refetchImages();
    };

    const onModalOpen = () => {
        setModalIsOpen(true);
    };

    return { fetchedImages, modalIsOpen, onModalOpen, selectedImage, _onImageSelect, onDismiss, onImageUploadToLocalStorage, onDiscardImage };
};

export const useMediaImages = (): [IImagePickerImage[], Function] => {
    const [images, setImages] = useState<IImagePickerImage[]>([]);

    const mediaEndpoint = 'https://api-de-eu.ivicos-campus.app/beta/media/default/images';

    const refetch = async () => {
        const token = localStorage.getItem('ivAccessToken');

        const result = await getResource(token, mediaEndpoint, (err: any) => {
            console.log(err);
        });

        if (!result || !result.images) return;

        const fetchedImages: IImagePickerImage[] = result.images.map((img: any) => ({ id: img.key, url: img.image_url }));
        setImages(fetchedImages);
    };

    useEffect(() => {
        refetch();
    }, []);

    return [images, refetch];
};
