import { selector, selectorFamily } from 'recoil';
import { sProfile } from 'shared-state/identity/selectors';
import { IProfile } from 'shared-state/identity/types';
import { aUserList } from './atoms';

export const sUsers = selector<IProfile[]>({
    key: 'sUsers',
    get: ({ get }) => {
        const allUsers = get(aUserList);
        const myProfile = get(sProfile);

        const localUsers = myProfile ? [myProfile] : [];

        return [...allUsers, ...localUsers];
    }
});

export const sUsersInRoom = selectorFamily<IProfile[], string>({
    key: 'sUsersInRoom',
    get: (roomId: string) => ({ get }) => {
        const allUsersIncludingLocalUsers = get(sUsers);
        return allUsersIncludingLocalUsers.filter((u) => u.room == roomId);
    }
});

export const sUsersWithIDs = selectorFamily<IProfile[], string[]>({
    key: 'sUsersInRoom',
    get: (userIds: string[]) => ({ get }) => {
        const allUsersIncludingLocalUsers = get(sUsers);
        return allUsersIncludingLocalUsers.filter((u) => userIds.includes(u.uid));
    }
});

export const sUser = selectorFamily<IProfile | undefined, string>({
    key: 'sUser',
    get: (userId: string) => ({ get }) => {
        const allUsersIncludingLocalUsers = get(sUsers);
        return allUsersIncludingLocalUsers.find((u) => u.uid == userId);
    }
});

export const sUserFromSpeakerStats = selectorFamily<IProfile | undefined, string>({
    key: 'sUserFromSpeakerStats',
    get: (userName: string) => ({ get }) => {
        const allUsersIncludingLocalUsers = get(sUsers);
        return allUsersIncludingLocalUsers.find((u) => u.displayName == userName);
    }
});
