import { AreaDto, CampusDto } from 'client-api-wrapper/lib/campus_api/responses.dto';
import { OrgBrandingDto } from 'client-api-wrapper/lib/identity_provider/responses.dto';
import { IRoomResource } from 'kits/apiKit3/legacy';
import { atom } from 'recoil';

export const aRoomList = atom<IRoomResource[]>({ key: 'aRoomList', default: [] });

export const aRoomListStyles = atom<any>({ key: 'aRoomListStyles', default: {} });

export const aSelectedVisitor = atom<string | undefined>({ key: 'aSelectedVisitor', default: undefined });

export const aCampus = atom<CampusDto | undefined>({ key: 'aCampus', default: undefined });
export const aAreaList = atom<AreaDto[]>({ key: 'aAreaList', default: [] });

export const aOrgBranding = atom<OrgBrandingDto | undefined>({ key: 'aOrgBranding', default: undefined });
