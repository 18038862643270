import React from 'react';
import { Stack, TooltipHost, Text } from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import { Antenna, EncryptedRoom, EuropeSecure } from 'kits/IconKit2';
import IvicosStrings from 'kits/stringKit';

export interface IStatusIconBarProps {
    connected?: boolean;
    encryptionEnabled?: boolean;
    cameraMuted?: boolean;
    micMuted?: boolean;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StatusIconBar: React.FC<IStatusIconBarProps> = ({ connected, encryptionEnabled, cameraMuted, micMuted }) => {
    return (
        <Stack style={{ position: 'absolute', bottom: 16, left: 16 }} horizontal tokens={{ childrenGap: 8 }}>
            {connected && (
                <TooltipHost
                    content={
                        <Stack className="reveal--on-hover" verticalAlign="center" tokens={{ childrenGap: 8 }}>
                            <Text variant="small">{IvicosStrings.connectedToLabel}</Text>
                            <Stack horizontal tokens={{ childrenGap: 2 }} verticalAlign="start">
                                <IC variant="dark" size={16}>
                                    <Antenna />
                                </IC>
                                <Text variant="small">
                                    <b>ivicos Video Platform ({localStorage.getItem('jitsiDomain')?.split('.')[0].toUpperCase()})</b>
                                    <br />
                                    <i>Powered by Open Telekom Cloud</i>
                                </Text>
                            </Stack>
                        </Stack>
                    }
                >
                    <IC size={32} variant="magenta" style={{ opacity: 0.6 }}>
                        <EuropeSecure />
                    </IC>
                </TooltipHost>
            )}
            {encryptionEnabled && (
                <TooltipHost content={<b>{IvicosStrings.encryptionIsEnabledLabel}</b>}>
                    <IC variant="primary" size={32}>
                        <EncryptedRoom />
                    </IC>
                </TooltipHost>
            )}
        </Stack>
    );
};

export default StatusIconBar;
