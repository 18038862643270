/* eslint-disable @typescript-eslint/no-unused-vars */
import BeatingHeart from 'adapters/realtime-adapter/BeatingHeart';
import PresenceChannel from 'adapters/realtime-adapter/channels/PresenceChannel';
import VisitorsChannel from 'adapters/realtime-adapter/channels/VisitorsChannel';
import React, { useCallback, useEffect, useState } from 'react';
import { pusherInstance } from 'services/realtime-transport/ivicos-pusher/pusher/instance';
import { useIdentityClaims } from 'shared-state/identity/hooks';
import { useCurrentAreaId, useCurrentRoomId } from 'shared-state/location/hook';

const RealtimeAdapter: React.FC<{ isOutside?: boolean }> = ({ isOutside }) => {
    const [roomId] = useCurrentRoomId();
    const [areaId] = useCurrentAreaId();
    const [identityClaims] = useIdentityClaims();
    const [presenceChannelShouldBeActive, setPresenceChannelShouldBeActive] = useState(false);
    const [visitorChannelShoudBeActive, setVisitorChannelShoudBeActive] = useState(false);
    const checkActivity = useCallback(() => {
        const nextPresenceChannelShouldBeActive = typeof identityClaims != 'undefined' && !identityClaims?.roles.includes('unconfirmed_visitor');
        const nextVisitorChannelShoudBeActive = !identityClaims?.roles.includes('visitor');
        setPresenceChannelShouldBeActive(nextPresenceChannelShouldBeActive);
        setVisitorChannelShoudBeActive(nextVisitorChannelShoudBeActive);
    }, [roomId, areaId, identityClaims]);

    useEffect(() => {
        checkActivity();
        console.log('yeet', identityClaims, visitorChannelShoudBeActive, presenceChannelShouldBeActive);
    }, [roomId, areaId, identityClaims]);

    useEffect(() => {
        pusherInstance?.connection.bind('error', (error: any) => console.log('[RTA] ', error, pusherInstance));
    }, []);

    return (
        <>
            {areaId && (
                <>
                    {presenceChannelShouldBeActive && <PresenceChannel areaId={areaId} />}
                    {visitorChannelShoudBeActive && <VisitorsChannel areaId={areaId} />}
                </>
            )}
            <BeatingHeart />
        </>
    );
};
export default RealtimeAdapter;
