import React from 'react';
import { useCreateNewLinkModalState } from './CreateNewLinkModal.state';
import CreateNewLinkModalView from './CreateNewLinkModal.view';

export interface ICreateNewLinkModalProps {
    isOpen?: boolean;
    onDismiss?: Function;
    linkToCopy?: string;
    onSubmit?: (desc: string) => void;
}

const CreateNewLinkModal: React.FC<ICreateNewLinkModalProps> = (props) => {
    const state = useCreateNewLinkModalState(props.onDismiss, props.onSubmit);

    return (
        <CreateNewLinkModalView
            {...{
                ...state,
                ...{
                    isOpen: props.isOpen,
                    linkToCopy: props.linkToCopy
                }
            }}
        ></CreateNewLinkModalView>
    );
};
export default CreateNewLinkModal;
