/* eslint-disable @typescript-eslint/no-unused-vars */
import { sendHeartbeatEvent, useHeartbeatEvent } from 'adapters/realtime-adapter/actions/messaging';
import { getPresenceChannelNameForArea } from 'adapters/realtime-adapter/helpers';
import React, { useCallback, useEffect } from 'react';
import { useInterval } from 'react-use';
import { useRealtimeService } from 'services/realtime-transport';
import { getChannelNameForOptions } from 'services/realtime-transport/ivicos-pusher/actions';
import { useSharedAvailability, useLocalProfile } from 'shared-state/identity/hooks';
import { IProfile } from 'shared-state/identity/types';
import { useCurrentAreaId, useCurrentCampusId, useCurrentRoomId } from 'shared-state/location/hook';
import { useUserList } from 'shared-state/presence/hooks';

const BeatingHeart: React.FC<{}> = () => {
    const [campusId] = useCurrentCampusId();
    const [areaId] = useCurrentAreaId();
    const [roomId] = useCurrentRoomId();
    const [sharedAvailability] = useSharedAvailability();
    const timeBetweenBeats = 30000;

    const [userList, setUserList] = useUserList();
    const localProfile = useLocalProfile();
    const RT = useRealtimeService();

    const sendHeartbeat = useCallback(() => localProfile && areaId && campusId && sendHeartbeatEvent(RT, campusId, areaId, localProfile), [
        localProfile,
        campusId,
        areaId,
        RT
    ]);

    useEffect(() => {
        sendHeartbeat();
    }, [roomId, sharedAvailability]);

    useInterval(() => {
        //CleanUp task
        const channelMembers = RT.getChannelSubscribers(getChannelNameForOptions({ campusId, areaId, channelType: 'presence' }) || '');
        setUserList((prevList: IProfile[]) => {
            const nextList = prevList.filter((u) => channelMembers.includes(u.uid));
            return nextList;
        });

        //Heartbeat
        sendHeartbeat();
    }, timeBetweenBeats);

    return <></>;
};

export default BeatingHeart;
