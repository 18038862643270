/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { IUpdateEvent, sendHeartbeatEvent, useUserMessagingActions } from 'adapters/realtime-adapter/actions/messaging';
import { userProfileHasUpdated } from 'adapters/realtime-adapter/helpers';
import { IRoomResource } from 'kits/apiKit3/legacy';
import { sendEvent } from 'kits/eventKit';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useRealtimeService } from 'services/realtime-transport';
import { useRoom, useRoomList } from 'shared-state/directory/hooks';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { IProfile } from 'shared-state/identity/types';
import { useCurrentAreaId, useCurrentCampusId, useCurrentRoomId } from 'shared-state/location/hook';
import { makeMessageId, useReceivedMessageInbox } from 'shared-state/notifications/hooks';
import { IReceivedMessage } from 'shared-state/notifications/types';
import { useUserList, useUsersInRoom } from 'shared-state/presence/hooks';
import { v1 as uuidv1 } from 'uuid';

const useHearbeatSender = () => {
    const REALTIME = useRealtimeService();
    const [areaId] = useCurrentAreaId();
    const [campusId] = useCurrentCampusId();
    const localProfile = useLocalProfile();

    const sendHeartbeat = useCallback(() => localProfile && areaId && campusId && sendHeartbeatEvent(REALTIME, campusId, areaId, localProfile), [
        localProfile,
        campusId,
        areaId,
        REALTIME
    ]);
    return sendHeartbeat;
};

const useHeartbeatHandler = () => {
    const [areaId] = useCurrentAreaId();
    const [userList, setUserList] = useUserList();
    const getUserById = useCallback((id: string) => userList.find((u) => u.uid == id), [userList]);
    const handleHeartbeat = (receivedProfile: IProfile) => {
        const newProfile = receivedProfile;

        //Check if user already existed when event arrived
        const prevProfile = getUserById(newProfile.uid);

        //If Profile has not updated return
        if (prevProfile && !userProfileHasUpdated(prevProfile, newProfile)) return;

        setUserList((prevList: IProfile[]) => {
            //Get newest available version of previous profile if existing
            const _prevProfile = prevList.find((u) => u.uid == newProfile.uid);
            console.log('[HBS]2 Old profile:', _prevProfile);
            if (_prevProfile) {
                console.log('[HBS]5 Updating profile', newProfile);

                return prevList.map((u) => {
                    if (u.uid != newProfile.uid) return u;

                    return newProfile;
                });
            }
            console.log('[HBS]4 Adding new Profile', newProfile);

            return [...prevList, ...[newProfile]];
        });
    };

    return handleHeartbeat;
};

const useConnectionHandler = () => {
    const sendHeartbeat = useHearbeatSender();
    const [, setUserList] = useUserList();

    const handleConnection = (data: any) => {
        console.log('CONNECTED', data);
        setUserList([]);
        sendHeartbeat();
    };

    return handleConnection;
};

const useResourceUpdateHandler = () => {
    const [, setRoomList] = useRoomList();

    const handleResourceUpdate = (event: IUpdateEvent) => {
        console.log('[RSA] Client Update!', event);
        if (event.type == 'update' && event.room) {
            console.log('[RSA] Updating room...');
            setRoomList((prevList: IRoomResource[]) => {
                return prevList.map((r) => {
                    if (r.id != event.room?.id) return r;
                    console.log('[RSA] Found room, replacing...');
                    return event.room;
                });
            });
        }
        if (event.type == 'creation' && event.room) {
            setRoomList((prevList: IRoomResource[]) => {
                if (!event.room) return prevList;
                return [...prevList, ...[event.room]];
            });
        }
        setRoomList((prevList: IRoomResource[]) => {
            return prevList.filter((r) => r.id != event.roomId);
        });
    };
    return handleResourceUpdate;
};

const useMemberRemovedHandler = () => {
    const [areaId] = useCurrentAreaId();

    const [, setUserList] = useUserList();

    const handleMemberRemoved = (user: any) => {
        console.log('USER LEFT', user);
        setUserList((prevList: IProfile[]) => {
            const nextList = prevList.filter((u) => !user.info.sub.includes(u.uid));
            return nextList;
        });
    };
    return handleMemberRemoved;
};

const useMemberAddedHandler = () => {
    const routeHistory = useHistory();
    const localProfile = useLocalProfile();

    const sendHeartbeat = useHearbeatSender();

    const handleMemberAdded = (user: any) => {
        console.log('USER JOINED', user);

        if (user.info.sub.includes(localProfile?.uid)) routeHistory.push('/error/409');

        sendHeartbeat();
    };

    return handleMemberAdded;
};

const useMessageHandler = () => {
    const [, addMessage] = useReceivedMessageInbox();
    const [currentRoomId] = useCurrentRoomId();
    const localProfile = useLocalProfile();
    const { handleSessionIdRequest, handleSessionIdResponse } = useJitsiSessionIdHandler();

    const handleMessage = (data: any, metaData: any) => {
        // Do something with the message
        const senderId = metaData.user_id.split(':')[3];

        if (data.meta?.forRoom != currentRoomId && data.meta?.forUser != localProfile?.uid) return;

        const newRecievedMessage: IReceivedMessage = {
            id: makeMessageId(),
            sender: senderId,
            type: data.type,
            timestamp: new Date(),
            meta: {
                forRoom: data.meta?.forRoom,
                forUser: data.meta?.forUser,
                attachedRoom: data.meta?.attachedRoom
            },
            body: data.body,
            announce: data.announce,
            timeout: data.timeout
        };

        if (data.type != 'request-jitsisessionid' && data.type != 'response-jitsisessionid') {
            addMessage(newRecievedMessage);
        } else if (data.type == 'request-jitsisessionid') {
            handleSessionIdRequest(senderId, data.body);
        } else if (data.type == 'response-jitsisessionid') {
            handleSessionIdResponse(data.body);
        }
    };
    return handleMessage;
};

export const useJitsiSessionIdHandler = () => {
    const [roomId] = useCurrentRoomId();
    const [room] = useRoom(roomId || '');
    const { sendAccessResponse } = useUserMessagingActions();

    const isRoomEnteranceAllowedForUser: (userId: string) => boolean | undefined = (userId) => {
        if (!userId) return false;
        if (!room) return false;
        else if (room.attributes.whitelist && room.attributes.whitelist.length > 0)
            return room.attributes.whitelist.includes(userId) || userId === room?.attributes.creatorId;
        else return !room.attributes.private;
    };
    const handleSessionIdRequest = (userId: string, requestedRoomId: string) => {
        if (roomId == requestedRoomId) {
            sendAccessResponse(userId, localStorage.getItem('jitsiSessionId') || 'undefined');
        }
    };
    const handleSessionIdResponse = (jitsiSessionId: string) => {
        console.log('Received and setting newJitsiSessionId: ', jitsiSessionId);
        localStorage.setItem('jitsiSessionId', jitsiSessionId);
    };
    return { handleSessionIdRequest, handleSessionIdResponse };
};

export const useJitsiSessionRouter = (roomId: string) => {
    const usersInRoom = useUsersInRoom(roomId);
    const { sendAccessRequest } = useUserMessagingActions();
    const [currentRoomId] = useCurrentRoomId();

    const conditionRouting = () => {
        if (usersInRoom.length > 0) {
            if (roomId == currentRoomId) return;
            sendAccessRequest(usersInRoom[0].uid, roomId);
        } else if (usersInRoom.length == 0) {
            const newJitsiSessionId = uuidv1();
            localStorage.setItem('jitsiSessionId', newJitsiSessionId);
        }
    };

    return { conditionRouting };
};

export const usePresenceChannelHandlers = () => {
    const REALTIME = useRealtimeService();

    const handleConnection = useConnectionHandler();
    const handleHeartbeat = useHeartbeatHandler();
    const handleResourceUpdate = useResourceUpdateHandler();
    const handleMemberRemoved = useMemberRemovedHandler();
    const handleMemberAdded = useMemberAddedHandler();
    const handleMessage = useMessageHandler();

    const handleUnsubscribe = () => {
        sendEvent('presenceChannelConnectionChanged', { connected: false });
    };

    const handleSubscribe = () => {
        sendEvent('presenceChannelConnectionChanged', { connected: true });
    };
    return [
        {
            eventName: REALTIME.defaultEventTypes.connected,
            callback: handleConnection
        },
        {
            eventName: REALTIME.defaultEventTypes.connected,
            callback: handleSubscribe
        },
        {
            eventName: 'custom:unsubscribed',
            callback: handleUnsubscribe
        },
        {
            eventName: REALTIME.defaultEventTypes.error,
            callback: (error: any) => {
                console.log('[RTA] Err', error);
            }
        },
        {
            eventName: 'client-heartbeat',
            callback: handleHeartbeat
        },
        {
            eventName: 'client-update',
            callback: handleResourceUpdate
        },

        {
            eventName: REALTIME.defaultEventTypes.memberRemoved,
            callback: handleMemberRemoved
        },
        {
            eventName: REALTIME.defaultEventTypes.memberAdded,
            callback: handleMemberAdded
        },
        {
            eventName: 'client-message',
            callback: handleMessage
        }
    ];
};
