import { ICallActionKey } from 'features/Rooms/RoomView/CallActions';
import { useJitsiToken } from 'kits/apiKit3/jitsi';
import { IRoomResource } from 'kits/apiKit3/legacy';
import { sendEvent } from 'kits/eventKit';
import { useEffect, useState } from 'react';
import { useArea, useRoom } from 'shared-state/directory/hooks';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { useIdentityClaims, useLocalProfile } from 'shared-state/identity/hooks';
import { IIdentityClaims } from 'shared-state/identity/types';
import { useCurrentAreaId, useCurrentRoomId } from 'shared-state/location/hook';
import 'styles/helpers.css';
import { getClassNames } from 'styles/responsiveness';

export interface IRoomViewState {
    jwt: string | undefined;
    room: string | undefined;
    domain: string | undefined;
    smallDevice: boolean;
    innerBurgerMenu: string;
    campusNameSmallDevice: string;
    currentRoom: IRoomResource | undefined;
    title: string;
    callActions: ICallActionKey[][];
    pimg: string | undefined;
    showSideBySide: boolean;
    selectedTool: string;
    identity: IIdentityClaims | undefined;
    noVideoRoom: string | null;
}

export const useRoomViewState: () => IRoomViewState = () => {
    const [roomId] = useCurrentRoomId();
    const [identity] = useIdentityClaims();
    const [currentRoom] = useRoom(roomId || '');
    const [jwt, room, domain] = useJitsiToken();
    const localProfile = useLocalProfile();
    const pimg = localProfile?.profileImage;

    const [selectedTool, setSelectedTool] = useState('jitsi');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showSideBySide, setShowSideBySide] = useState(false);

    useEffect(() => {
        setSelectedTool('jitsi');
    }, [roomId]);

    const callActions: ICallActionKey[][] = [
        // ['device-settings', 'encrypt-room'],
        ['device-settings', 'toggle-background-dialog', 'toggle-view'],
        currentRoom?.attributes?.audioOnly ? ['toggle-audio', 'share-screen'] : ['toggle-video', 'toggle-audio', 'share-screen'],
        roomId?.includes('personal') ? [] : ['toggle-attachments', 'toggle-stats'],
        window.location.pathname.includes('rooms') ? ['leave-room'] : []
    ];
    const { smallDevice } = useWindowWidth();
    const [areaId] = useCurrentAreaId();
    const [area] = useArea(areaId);
    const title = area?.name || 'Area';
    const idValue = window.location.pathname.split('rooms/')[1];
    const noVideoRoom: string | null = localStorage.getItem(`noVideo-${idValue}`);
    const { innerBurgerMenu, campusNameSmallDevice } = getClassNames();

    // storing the roomId in localStorage for older no-video rooms
    if (currentRoom?.attributes.audioOnly && !noVideoRoom) {
        localStorage.setItem(`noVideo-${idValue}`, idValue);
        sendEvent('disable-camera', {});
    }

    // removing roomIds from localStorage
    if (noVideoRoom && !currentRoom?.attributes.audioOnly) {
        localStorage.removeItem(`noVideo-${idValue}`);
    }

    // removing undefined roomIds from localStorage
    if (localStorage.getItem('noVideo-undefined')) {
        localStorage.removeItem('noVideo-undefined');
    }

    return {
        jwt,
        room,
        domain,
        smallDevice,
        innerBurgerMenu,
        campusNameSmallDevice,
        currentRoom,
        title,
        callActions,
        pimg,
        showSideBySide,
        selectedTool,
        identity,
        noVideoRoom
    };
};
