import React from 'react';
import { CommandButton, Stack, Text, Toggle } from '@fluentui/react';
import TopBar from 'features/Header/children/TopBar/TopBar';
import IvicosColors from 'kits/colorKit';
import { useHistory } from 'react-router';

export type IFeatureFlag = 'useJitsiStagingEnv' | 'useDarkMode';

interface IFlagsViewProps {
    setFlag: (flag: IFeatureFlag, value: string) => any;
    getFlag: (flag: IFeatureFlag) => any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FlagsView: React.FC<IFlagsViewProps> = ({ children, setFlag, getFlag }) => {
    const routeHistory = useHistory();

    return (
        <Stack style={{ height: '100vh', width: '100vw', background: IvicosColors.Grau25 }}>
            <TopBar title="Experimental features" />
            <Stack grow horizontalAlign="center" style={{ paddingTop: 32 }}>
                <Stack horizontalAlign="start" style={{ maxWidth: 560, width: '100%' }}>
                    <CommandButton iconProps={{ iconName: 'back' }} onClick={() => routeHistory.push('/')}>
                        Go back
                    </CommandButton>
                </Stack>
                <Stack style={{ marginBottom: 32, maxWidth: 560, width: '100%' }} tokens={{ childrenGap: 16 }}>
                    <Stack horizontal style={{ background: '#fff', padding: 26 }} verticalAlign="center">
                        <Stack grow style={{ paddingRight: 26 }}>
                            <Text variant="large">Use dark mode</Text>
                            <Text variant="small" style={{ maxWidth: '80%' }}>
                                If you enable this flag, you will be able to use iv:CAMPUS in dark mode
                            </Text>
                        </Stack>
                        <Toggle
                            defaultChecked={getFlag('useDarkMode') == 'YES'}
                            onChange={(e: any, checked?: boolean) => {
                                setFlag('useDarkMode', checked ? 'YES' : 'NO');
                            }}
                        />
                    </Stack>
                    <Stack horizontal style={{ background: '#fff', padding: 26 }} verticalAlign="center">
                        <Stack grow style={{ paddingRight: 26 }}>
                            <Text variant="large">Use the Jitsi staging environment</Text>
                            <Text variant="small" style={{ maxWidth: '80%' }}>
                                If you enable this flag, you will not be able to talk to users who have not enabled this flag.
                            </Text>
                        </Stack>
                        <Toggle
                            defaultChecked={getFlag('useJitsiStagingEnv') == 'YES'}
                            onChange={(e: any, checked?: boolean) => {
                                setFlag('useJitsiStagingEnv', checked ? 'YES' : 'NO');
                            }}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default FlagsView;
