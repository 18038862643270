import React from 'react';
import { useCreateNewRoomButtonState } from './CreateNewRoomButton.state';
import CreateNewRoomButtonView from './CreateNewRoomButton.view';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CreateNewRoomButton = () => {
    const state = useCreateNewRoomButtonState();

    return (
        <CreateNewRoomButtonView
            {...{
                ...state
            }}
        />
    );
};

export default CreateNewRoomButton;
