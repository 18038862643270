import { Stack } from '@fluentui/react';
import AreasPanel from 'features/Areas/AreaPanel/AreaPanel';
import MemoComposer from 'features/MemoComposer/MemoComposer';
import NotificationsLayer from 'features/NotificationsLayer/NotificationsLayer';
import RoomList from 'features/Rooms/RoomList/RoomList/RoomList';
import RoomView from 'features/Rooms/RoomView/RoomView';
import { SideBarTab } from 'features/SideBar/children/SideBarTab';
import { SideBarTabIcon } from 'features/SideBar/children/SideBarTabIcon/SideBarTabIcon';
import SideBar from 'features/SideBar/SideBar';
import VisitorsTabController from 'features/Visitors/UserTab/VisitorsTabController/VisitorsTabController';
import 'intro.js/introjs.css';
import { Room, Visitors } from 'kits/IconKit2';
import React, { useCallback } from 'react';
import { IIdentityClaims } from 'shared-state/identity/types';
export interface IMainViewProps {
    identityClaims: IIdentityClaims | undefined;
    onTabSelect: () => void;
    smallDevice: boolean;
    mobileMenuOpen: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MainView: React.FC<IMainViewProps> = ({ identityClaims, onTabSelect, smallDevice, mobileMenuOpen, children }) => {
    const onRenderTabIcons = useCallback(() => {
        return (
            <>
                <SideBarTabIcon tabKey="rooms" title="Rooms" selectedByDefault onSelect={onTabSelect}>
                    <Room />
                </SideBarTabIcon>
                {!identityClaims?.roles.includes('visitor') && (
                    <SideBarTabIcon tabKey="visitors" title="Visitors" onSelect={onTabSelect}>
                        <Stack className={'visitors'}>
                            <Visitors />
                        </Stack>
                    </SideBarTabIcon>
                )}
            </>
        );
    }, [identityClaims]);

    return (
        <>
            <Stack
                grow={!smallDevice ? true : false}
                horizontal={!smallDevice ? true : false}
                verticalAlign="stretch"
                style={{ maxWidth: '100%', overflow: 'hidden', height: '100%' }}
            >
                <MemoComposer />
                <AreasPanel />
                <NotificationsLayer />
                <SideBar onRenderTabIcons={onRenderTabIcons} mobileMenuOpen={!smallDevice ? true : mobileMenuOpen}>
                    <SideBarTab tabKey="rooms">
                        <RoomList />
                    </SideBarTab>
                    <SideBarTab tabKey="visitors">
                        <VisitorsTabController />
                    </SideBarTab>
                </SideBar>
                <RoomView mobileMenuOpen={!smallDevice ? false : mobileMenuOpen} />
            </Stack>
        </>
    );
};

export default MainView;
