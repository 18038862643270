/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-empty-function */
import { ITheme, PersonaPresence } from '@fluentui/react';

export type UserAvailability = 'Available' | 'AvailableIdle' | 'Away' | 'BeRightBack' | 'DoNotDisturb' | 'BusyIdle' | 'Busy' | 'Offline' | 'PresenceUnknown';

export interface ILocalAvailability {
    key: UserAvailability;
    description: string;
    iconName: string;
    iconColor: string;
}
export const localAvailabilities: (theme: ITheme) => ILocalAvailability[] = (theme: ITheme) => [
    {
        key: 'Available',
        description: 'Available',
        iconName: 'SkypeCircleCheck',
        iconColor: theme.palette.green
    },
    {
        key: 'Away',
        description: 'Away',
        iconName: 'SkypeCircleClock',
        iconColor: theme.palette.orangeLighter
    },
    {
        key: 'BeRightBack',
        description: 'Be right back!',
        iconName: 'SkypeCircleClock',
        iconColor: theme.palette.orangeLighter
    },
    {
        key: 'DoNotDisturb',
        description: 'Do Not Disturb',
        iconName: 'SkypeCircleMinus',
        iconColor: theme.palette.red
    },
    {
        key: 'Busy',
        description: 'Busy',
        iconName: 'StatusCircleOuter',
        iconColor: theme.palette.red
    }
];

export function availabilityToPersonaPresence(availability: UserAvailability) {
    const lookupTable = {
        Available: PersonaPresence.online,
        AvailableIdle: PersonaPresence.online,
        Away: PersonaPresence.away,
        BeRightBack: PersonaPresence.away,
        DoNotDisturb: PersonaPresence.dnd,
        BusyIdle: PersonaPresence.busy,
        Busy: PersonaPresence.busy,
        Offline: PersonaPresence.online,
        PresenceUnknown: PersonaPresence.online
    };
    return lookupTable[availability] || PersonaPresence.online;
}

export interface IMSUserAvailability {
    id: string;
    availability: UserAvailability;
    activity: string;
}
