/* eslint-disable @typescript-eslint/no-unused-vars */
import { RoomDto } from 'client-api-wrapper/lib/campus_api/responses.dto';
import { roomDTOtoResource } from 'kits/apiKit3/legacy';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { resourceService } from 'services/api/resource.service';
import { useAreaList, useCampus, useRoomList, useSelectedVisitor } from 'shared-state/directory/hooks';
import { useIdentityClaims } from 'shared-state/identity/hooks';
import { useCurrentAreaId, useCurrentCampusId, useCurrentOrgId, useCurrentRoomId } from 'shared-state/location/hook';

export const ResourceAdapter: React.FC<{}> = ({ children }) => {
    const params = useParams<{ areaId?: string; roomId?: string }>();

    const [identityClaims] = useIdentityClaims();
    const [currentOrgId] = useCurrentOrgId();
    const [currentCampusId, setCurrentCampusId] = useCurrentCampusId();
    const [currentAreaId, setCurrentAreaId] = useCurrentAreaId();
    const [currentRoomId, setCurrentRoomId] = useCurrentRoomId();

    const routeHistory = useHistory();
    const [, setSelectedVisitor] = useSelectedVisitor();

    const [campus, setCampus] = useCampus();
    const [areaList, setAreaList] = useAreaList();
    //Currently legacy format
    const [roomList, setRoomList] = useRoomList();
    const fetchCampus = async () => {
        if (!currentOrgId || currentOrgId != identityClaims?.tid) return;
        const campusDTO = await resourceService.getCampusById(currentOrgId);

        if (!campusDTO) return; // Error @TODO handle

        console.log('[RA] Campus fetched ', campusDTO);

        setCampus(campusDTO);
        setCurrentCampusId(campusDTO.id);
    };

    const fetchAreas = async () => {
        if (!currentCampusId) {
            setAreaList([]);
            return;
        }
        const areaDTOs = await resourceService.getAllForCampus(currentCampusId);

        if (!areaDTOs) return; // Error @TODO handle

        console.log('[RA] Areas fetched ', areaDTOs);

        setAreaList(areaDTOs);
    };

    const fetchRooms = async () => {
        if (!currentAreaId || !currentCampusId) {
            setRoomList([]);
            return;
        }
        const roomDTOs: any = await resourceService.getAllForArea(currentCampusId, currentAreaId);

        if (!roomDTOs) return;

        console.log('[RA] Rooms fetched ', roomDTOs);

        /* Backwards comp */
        const roomResources = roomDTOs.map((room: RoomDto) => roomDTOtoResource(room));
        setRoomList(roomResources);
    };

    const handleError = (err: any, subject?: string) => {
        let loginRequired = false;
        console.error('[RESOURCE ADAPTER]', err.message);

        // if (subject == 'campus' && err.message.includes('404')) loginRequired = true;
        if (err.message.includes('401')) loginRequired = true;
        if (err.message.includes('404')) loginRequired = true;

        if (loginRequired) routeHistory.push('/login');
    };

    useEffect(() => {
        fetchCampus().catch((err: any) => {
            handleError(err, 'campus');

            // Retry later
            setTimeout(() => {
                fetchCampus().catch((err: any) => handleError(err, 'campus'));
            }, 1000);
        });
    }, [currentOrgId]);

    useEffect(() => {
        fetchAreas()
            .then(() => {
                currentAreaId && fetchRooms();
            })
            .catch((err: any) => {
                handleError(err, 'areas');
                //routeHistory.push('/error/403');
            });
    }, [currentCampusId]);

    useEffect(() => {
        fetchRooms().catch((err: any) => {
            handleError(err, 'rooms');
            // routeHistory.push('/error/403');
        });
    }, [currentAreaId]);

    useEffect(() => {
        setCurrentAreaId(params.areaId);
    }, [params.areaId]);

    useEffect(() => {
        const personalRoomName = 'personal-' + identityClaims?.uid + '_' + params.areaId;
        const nextRoomId = params.roomId ? params.roomId : personalRoomName || undefined;
        if (params.roomId?.includes('visitor-')) setSelectedVisitor(params.roomId.replace('visitor-', ''));
        else setSelectedVisitor(undefined);
        console.log('Next Room ID', params.roomId, nextRoomId);
        setCurrentRoomId(nextRoomId);
    }, [params.roomId, currentAreaId]);

    return <></>;
};
