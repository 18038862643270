import React from 'react';
import { useLinkManagerState } from './LinkManager.state';
import LinkManagerView from './LinkManager.view';

export interface ILinkManagerProps {
    isOpen?: boolean;
}

const LinkManager: React.FC<ILinkManagerProps> = () => {
    const state = useLinkManagerState();

    return (
        <LinkManagerView
            {...{
                ...state,
                ...{
                    createLinkModalIsOpen: state.modalIsOpen,
                    modalLink: state.newestLink,
                    onModalOpened: () => state.setModalIsOpen(true)
                }
            }}
        ></LinkManagerView>
    );
};
export default LinkManager;
