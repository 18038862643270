import * as React from 'react';

function SvgChevronLeft(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M19.031 4.281L20.47 5.72 10.187 16 20.47 26.281 19.03 27.72l-11-11L7.344 16l.687-.719 11-11z" fill="#000" />
        </svg>
    );
}

export default SvgChevronLeft;
