/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { IIdentity } from 'kits/authKit';
import { sendEvent, useEvent } from 'kits/eventKit';
import { useState } from 'react';

export const useSession = (key: string, defaultValue?: string): [any, (val: any) => void] => {
    const initialValue = sessionStorage.getItem('@sessionKit:' + key);
    if (!initialValue && defaultValue) sessionStorage.setItem('@sessionKit:' + key, defaultValue);

    const [value, setValue] = useState(sessionStorage.getItem('@sessionKit:' + key));

    const dispatchSessionEvent = useEvent('sessionKit_' + key, (e: any) => {
        if (e.key != key) return;
        if (e.value != value) {
            console.log('[SESSION UPDATE] ', key + ' = ' + e.value);
            setValue(e.value);
        }
    });

    return [value, (val: any) => setSession(key, val)];
};

export const useSessionIdentity = (key: string, defaultValue?: IIdentity): [any, (val: any) => void] => {
    const retrieveFromStorage = () => {
        try {
            const unparsedVal = sessionStorage.getItem('@sessionKit:' + key);
            if (!unparsedVal) return undefined;
            const val = JSON.parse(unparsedVal);
            return val;
        } catch (err) {
            return undefined;
        }
    };
    const initialValue = retrieveFromStorage();
    if (!initialValue && defaultValue) sessionStorage.setItem('@sessionKit:' + key, JSON.stringify(defaultValue));

    const [value, setValue] = useState(retrieveFromStorage());

    const dispatchSessionEvent = useEvent('sessionKit_' + key, (e: any) => {
        if (e.key != key) return;
        if (e.value != value) {
            console.log('[SESSION UPDATE] ', key + ' = ' + e.value);
            setValue(e.value);
        }
    });

    return [value, (val: any) => setSessionIdentity(key, val)];
};

export const getSession = (key: string): [any, (val: any) => void] => {
    return [sessionStorage.getItem('@sessionKit:' + key), (val: any) => setSession(key, val)];
};

export const setSession = (key: string, value: any) => {
    if (sessionStorage.getItem('@sessionKit:' + key) == value) return;

    sessionStorage.setItem('@sessionKit:' + key, value);
    sendEvent('sessionKit_' + key, {
        key: key,
        value: value
    });
};

export const setSessionIdentity = (key: string, value: IIdentity) => {
    sessionStorage.setItem('@sessionKit:' + key, JSON.stringify(value));
    sendEvent('sessionKit_' + key, {
        key: key,
        value: value
    });
};

export const setSessionSilent = (key: string, value: any) => {
    if (sessionStorage.getItem('@sessionKit:' + key) == value) return;

    sessionStorage.setItem('@sessionKit:' + key, value);
};

export const updateSession = (key: string) => {
    const value = sessionStorage.getItem('@sessionKit:' + key);
    sendEvent('sessionKit_' + key, {
        key: key,
        value: value
    });
};
