/* eslint-disable react/display-name */
import { IUserAction } from 'features/Rooms/RoomList/helpers/userTypes';
import { HandWave, Memo } from 'kits/IconKit2';
import IvicosStrings from 'kits/stringKit';
import React from 'react';
import { useUserMessagingActions } from 'adapters/realtime-adapter/actions/messaging';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { IProfile } from 'shared-state/identity/types';
import { useCurrentRoomId } from 'shared-state/location/hook';
import { useMessageComposerUserIds } from 'shared-state/messaging/hooks';
import { makeMessageId, useReceivedMessageInbox } from 'shared-state/notifications/hooks';

export const useUserActions = (user: IProfile): IUserAction[] => {
    const [currentRoomId] = useCurrentRoomId();

    const localProfile = useLocalProfile();

    const [, setMessageComposerUserIds] = useMessageComposerUserIds();
    const [, addMessage] = useReceivedMessageInbox();

    const { inviteUserToRoom } = useUserMessagingActions();

    const sendMemoAction: IUserAction = {
        key: 'send-memo',
        title: IvicosStrings.sendUserMemoActionTitle,
        action: () => {
            setMessageComposerUserIds([user.uid]);
        },
        onRenderIcon: () => <Memo />
    };

    const inviteToRoomAction: IUserAction = {
        key: 'invite-user',
        title: IvicosStrings.inviteUserToRoomActionTitle,
        action: () => {
            currentRoomId && inviteUserToRoom(user.uid, currentRoomId);
            currentRoomId &&
                addMessage({
                    id: makeMessageId(),
                    type: 'confirmation',
                    body: 'Invitation sent!',
                    timeout: 2000,
                    sender: '',
                    meta: {},
                    timestamp: new Date()
                });
        },
        onRenderIcon: () => <HandWave />
    };

    const actions = currentRoomId == user.room ? [sendMemoAction] : [sendMemoAction, inviteToRoomAction];

    return localProfile?.uid == user.uid ? [] : actions;
};
