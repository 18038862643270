import { PrimaryButton, Stack } from '@fluentui/react';
import Badge from '../../../../../components/Badge';
import React from 'react';
import { IInviteStepProps } from './InviteStep';

export type ICSVData = { body: string[][]; headline: string[]; setBody: (value: React.SetStateAction<string[][]>) => void };

const InviteStepView: React.FC<IInviteStepProps> = (props) => {
    const { data, invitationStatus, onFinish } = props;
    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <table>
                <tbody>
                    {data?.body.map((user, index) => {
                        return (
                            <tr key={user[0]} style={{ marginBottom: 16 }}>
                                <td>
                                    {/* <Persona key={index} text={user[0]} secondaryText={user[1]} /> */}
                                    <Badge text={user[0]} size="41" fontSize="medium" />
                                </td>
                                <td>{['Inviting...', 'Successfully invited!', 'Error occured :('][invitationStatus[index] || 0]}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <Stack horizontalAlign="end" horizontal tokens={{ childrenGap: 16 }}>
                <PrimaryButton text="Finish" onClick={() => onFinish()} />
            </Stack>
        </Stack>
    );
};

export default InviteStepView;
