import { getTheme, ITheme } from '@fluentui/react';
import { UserAvailability } from 'kits/availabilityKit';
import { SetterOrUpdater } from 'recoil';
import { useSharedAvailability } from 'shared-state/identity/hooks';

export interface IAvailabilitySelectorState {
    theme: ITheme;
    sharedAvailability?: UserAvailability;
    setSharedAvailability: SetterOrUpdater<UserAvailability | undefined>;
}

export const useAvailabilitySelectorState: () => IAvailabilitySelectorState = () => {
    const theme = getTheme();
    const [sharedAvailability, setSharedAvailability] = useSharedAvailability();

    return {
        theme,
        sharedAvailability,
        setSharedAvailability
    };
};
