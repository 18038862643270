/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-empty */
export type ITokenOption = Array<string | null> | string | null;

// BASIC OPERATIONS
const headerData = (token: ITokenOption, method?: string) => {
    let idToken;
    let accessToken;

    if (Array.isArray(token)) {
        idToken = token[0];
        accessToken = token[1];
    } else {
        accessToken = token;
    }

    return {
        ...(method == 'GET'
            ? {}
            : {
                  'Content-Type': 'application/json'
              }),
        ...(idToken != null && {
            Identification: 'Bearer ' + idToken
        }),
        ...(accessToken != null && {
            Authorization: 'Bearer ' + accessToken
        })
    };
};

export function getResource(token: ITokenOption, url: string, onError = (err: any) => console.log(err)) {
    return fetch(url, {
        method: 'GET',
        headers: new Headers(headerData(token, 'GET'))
    })
        .then((res) => {
            if (!res.ok) {
            }
            return res.json();
        })
        .then((data) => {
            console.log('[Resource fetched]', data);
            return data;
        })
        .catch((err) => {
            console.log(new Error(`Error calling endpoint: ${err}`));
        });
}

export function postResource(token: ITokenOption, url: string, body: any, onError = (err: any) => console.log(err)) {
    return fetch(url, {
        method: 'POST',
        headers: new Headers(headerData(token)),
        body: JSON.stringify(body)
    })
        .then((res) => {
            if (!res.ok) {
                //throw new Error(`Received ${res.status} from `);
            }
            return res.json();
        })
        .then((data) => {
            console.log('[Resource posted]', data);
            return data;
        })
        .catch((err) => {
            console.log(new Error(`Error calling endpoint: ${err}`));
        });
}

export function patchResource(token: ITokenOption, url: string, body: any) {
    return fetch(url, {
        method: 'PATCH',
        headers: new Headers(headerData(token)),
        body: JSON.stringify(body)
    })
        .then((res) => {
            if (!res.ok) {
                throw new Error(`Received ${res.status} from `);
            }
            return res.json();
        })
        .then((data) => {
            console.log('[Resource patched]', data);
            return data;
        })
        .catch((err) => {
            console.log(new Error(`Error calling endpoint: ${err}`));
        });
}

export async function postFileResource(token: string, url: string, files: Array<[string, File]>) {
    // add file to FormData object
    const _formData = new FormData();
    for (const file of files) {
        const fileKey = file[0];
        const fileBlob = file[1];
        _formData.append(fileKey, fileBlob);
    }

    // send `POST` request
    return fetch(url, {
        method: 'POST',
        headers: new Headers({
            authorization: 'Bearer ' + token
        }),
        body: _formData
    })
        .then((res) => {
            if (!res.ok) {
                throw new Error(`Received ${res.status} from `);
            }
            return res.json();
        })
        .then((data) => {
            console.log('[Resource deleted]', data);
            return data;
        })
        .catch((err) => {
            console.log(new Error(`Error calling endpoint: ${err}`));
        });
}

export function deleteResource(token: ITokenOption, url: string) {
    console.log('Deleting resource....');
    return fetch(url, {
        method: 'DELETE',
        headers: new Headers(headerData(token)),
        body: JSON.stringify({})
    })
        .then((res) => {
            if (!res.ok) {
                throw new Error(`Received ${res.status} from `);
            }
            return res.json();
        })
        .then((data) => {
            console.log('[Resource deleted]', data);
            return data;
        })
        .catch((err) => {
            console.log(new Error(`Error calling endpoint: ${err}`));
        });
}
