import { Checkbox, Dialog, DialogFooter, PrimaryButton, Stack, Text } from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import { Close } from 'kits/IconKit2';
import React from 'react';
import { IPersonalPreferencesState } from './PersonalPreferences.State';

const PersonalPreferencesView: React.FC<IPersonalPreferencesState> = (props) => {
    const { currentDeviceSettings, toggleDeviceSettings, hideErrorDialog, toggleHideErrorDialog, colorButtonId } = props;
    return (
        <>
            <Stack style={{ padding: 16 }}>
                <Stack
                    horizontal
                    style={{
                        padding: '5px',
                        marginBottom: '1vh'
                    }}
                >
                    <Checkbox
                        checked={currentDeviceSettings.cameraDisabled}
                        onChange={() => {
                            toggleDeviceSettings('camera');
                        }}
                    />
                    <Text variant="medium" style={{ padding: '0vh 1vh' }}>
                        Disable camera when entering a room?
                    </Text>
                </Stack>
                <Stack
                    horizontal
                    style={{
                        padding: '5px',
                        marginBottom: '1vh'
                    }}
                >
                    <Checkbox
                        checked={currentDeviceSettings.microphoneDisabled}
                        onChange={() => {
                            toggleDeviceSettings('mic');
                        }}
                    />
                    <Text variant="medium" style={{ padding: '0vh 1vh' }}>
                        Disable microphone when entering a room?
                    </Text>
                </Stack>
            </Stack>

            <Dialog
                hidden={hideErrorDialog}
                onDismiss={() => {
                    toggleHideErrorDialog();
                }}
            >
                <Stack>
                    <Stack style={{ flexDirection: 'row', justifyContent: 'flex-start', marginTop: '-30px' }}>
                        <IC size={20} variant="red">
                            <Close id={colorButtonId} />
                        </IC>

                        <Text variant="xLarge" style={{ marginLeft: 10 }}>
                            Error occurred
                        </Text>
                    </Stack>

                    <Text style={{ marginLeft: 30, marginTop: 10, marginRight: 0, textAlign: 'justify' }}>
                        An error occurred during the process of setting your device preferences. Please give it some time and try again. If the error repeats,
                        contact help or the campus manager.
                    </Text>
                </Stack>

                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            toggleHideErrorDialog();
                            window.location.reload();
                        }}
                        text={'Close'}
                    />
                </DialogFooter>
            </Dialog>
        </>
    );
};

export default PersonalPreferencesView;
