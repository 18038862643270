import React from 'react';
import { useRoomSettingsState } from './RoomSettings.state';
import RoomSettingsView from './RoomSettings.view';

const RoomSettings: React.FC<{}> = () => {
    const state = useRoomSettingsState();

    return (
        <RoomSettingsView
            {...{
                ...state,
                ...{
                    formFields: state.roomForm,
                    onFormChange: state.onRoomFormChange
                }
            }}
        />
    );
};

export default RoomSettings;
