/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect } from 'react';
import { atomFamily, useRecoilState } from 'recoil';
import { useStatePersist } from 'use-state-persist';
import { sendEvent, useEvent } from './eventKit';
import { getSession, useSession } from './sessionKit';

function lessThanDaysAgo(days: number, date: number) {
    const HOUR = 1000 * 60 * 60 * 24 * days;
    const anHourAgo = Date.now() - HOUR;

    return date > anHourAgo;
}

export const aMsProfileImage = atomFamily<{ URL: undefined | string; lastUpdate: undefined | number }, string>({
    key: 'aMsProfileImage',
    default: { URL: undefined, lastUpdate: undefined }
});
export const aMsTeamImage = atomFamily<{ URL: undefined | string; lastUpdate: undefined | number }, string>({
    key: 'aMsTeamImage',
    default: { URL: undefined, lastUpdate: undefined }
});

// URI format <image-provider>:<identifier>:<version>

// microsoft : <user-id>
// campusMedia : <user-id>

export const imageProviders: { [index: string]: (userId: string) => Promise<string | undefined> } = {
    microsoft: async (userId: string) => {
        return await downloadProfileImageFromMicrosoft(userId);
    },
    capusMedia: async (userId: string) => {
        return '';
    }
};

export function useProfileImage(profileImageURI?: string) {
    const [imageURL, setImageURL] = useStatePersist<string | undefined>('@image:' + profileImageURI);
    const [lastUpdated, setLastUpdate] = useStatePersist<number | undefined>('@image:lu:' + profileImageURI);

    const fetchData = async () => {
        const splitURI = profileImageURI?.split(':');
        if (!splitURI || splitURI.length < 2) return;
        const [imageProvider, userId] = splitURI;

        const getImageURL = imageProviders[imageProvider];
        const nextImageURL = getImageURL && (await getImageURL(userId));
        setImageURL(nextImageURL);
        setLastUpdate(Date.now());
    };

    useEffect(() => {
        (!imageURL || !lastUpdated || !lessThanDaysAgo(2, lastUpdated)) && fetchData();
    }, [profileImageURI]);

    return imageURL;
}

export const downloadProfileImageFromMicrosoft = async (userId: string): Promise<string | undefined> => {
    const [msAccessToken] = getSession('@msAccessToken');

    console.log('[PIM] MS Provider selected', msAccessToken);
    // User has no connected MS account return;
    if (!msAccessToken) return;
    console.log('[PIM] Trying to download');

    // Try downloading image from ms
    try {
        const response = await fetch(`https://graph.microsoft.com/v1.0/users/${userId}/photo/$value`, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + msAccessToken
            })
        });

        if (!response) return;

        if (!response.ok) {
            handlerError(response);
            return;
        }

        const images = await response.blob();
        if (!images) return;

        const str = await readBlob(images);
        console.log('[PIM]', str);
        return str;
    } catch (error) {
        handlerError(error);
        return;
    }
};

export const readBlob = (blob: Blob): Promise<string | undefined> => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
        temporaryFileReader.onerror = () => {
            temporaryFileReader.abort();
            reject(new DOMException('Problem parsing input file.'));
        };

        temporaryFileReader.onload = () => {
            const result = temporaryFileReader.result;
            resolve(result?.toString() || undefined);
        };
        temporaryFileReader.readAsDataURL(blob);
    });
};

export const handlerError = (error: any) => {
    if (error.status == 401) {
        return sendEvent('ms-unauthorized', {});
    }

    console.error(error);
};

export interface ITeamImageProps {
    teamId: string;
    imageProps: any;
}

export const TeamImage: React.FC<ITeamImageProps> = (props: ITeamImageProps) => {
    const { teamId, imageProps } = props;

    const teamImageURL = useTeamImage(teamId);

    return <img src={teamImageURL} {...imageProps} />;
};

export function useTeamImage(teamId: string) {
    const [msAccessToken, setMsAccessToken] = useSession('@msAccessToken');

    const [profileImage, setProfileImage] = useRecoilState(aMsTeamImage(teamId));

    const dispatchUnauthorizedEvent = useEvent('ms-unauthorized', (e: any) => {});

    const fetchData = (isCanceled: boolean) => {
        if (!msAccessToken || !teamId) return;
        fetch(`https://graph.microsoft.com/v1.0/teams/${teamId}/photo/$value`, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + msAccessToken
            })
        })
            .then((response: any) => {
                if (!response.ok) {
                    response.status == 401 && dispatchUnauthorizedEvent({ response });
                    return false;
                }

                return response.blob();
            })
            .then((images) => {
                if (!images) return;
                // Then create a local URL for that image and print it
                const a = new FileReader();
                a.onload = (e: any) => {
                    const dataUrl = e.target.result;
                    if (!isCanceled) setProfileImage({ URL: dataUrl, lastUpdate: Date.now() });
                };
                a.readAsDataURL(images);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        let isCanceled = false;
        if (!profileImage.URL || !profileImage.lastUpdate || lessThanDaysAgo(7, profileImage.lastUpdate)) {
            fetchData(isCanceled);
        }

        return () => {
            isCanceled = true;
        };
    }, [teamId]);

    return profileImage.URL;
}
