/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { aUserList, aVisitorList } from 'shared-state/presence/atoms';
import { sUser, sUserFromSpeakerStats, sUsers, sUsersInRoom, sUsersWithIDs } from 'shared-state/presence/selectors';

export const useUserList = () => {
    return useRecoilState(aUserList);
};
export const useVisitorList = (areaId: string) => {
    return useRecoilState(aVisitorList(areaId));
};

export const useUsers = () => {
    return useRecoilValue(sUsers);
};

export const useUser = (userId: string) => {
    return useRecoilValue(sUser(userId));
};

export const useUserFromSpeakerStats = (userName: string) => {
    return useRecoilValue(sUserFromSpeakerStats(userName));
};

export const useUsersInRoom = (roomId: string) => {
    return useRecoilValue(sUsersInRoom(roomId));
};

export const useUsersWithIDs = (userIds: string[]) => {
    return useRecoilValue(sUsersWithIDs(userIds));
};
