import { Pivot, PivotItem, Stack } from '@fluentui/react';
import theme from '@giphy/react-components/dist/components/search-bar/theme';
import React, { CSSProperties } from 'react';
import { getClassNames } from 'styles/responsiveness';

export interface TabAttributes {
    headerText: string;
    itemKey: string;
    className?: string;
    style?: CSSProperties;
}
export interface ITabToStackHorizontallyProps {
    activeTab: string;
    setActiveTab: any;
    tabs: TabAttributes[];
}

const TabToStackHorizontally: React.FC<ITabToStackHorizontallyProps> = ({ activeTab, setActiveTab, tabs }) => {
    const { pivotDisplaySmallDevice } = getClassNames();

    const handleLinkClick = (item?: PivotItem) => {
        if (item) {
            setActiveTab(item.props.itemKey);
        }
    };

    return (
        <Stack horizontal horizontalAlign="start" style={{ padding: 0 }} className={pivotDisplaySmallDevice}>
            <Pivot
                selectedKey={activeTab}
                onLinkClick={handleLinkClick}
                headersOnly={true}
                className={`${pivotDisplaySmallDevice} menu`}
                style={{ marginBottom: '26px' }}
            >
                {tabs.map((attribute: TabAttributes) => (
                    <PivotItem
                        key={attribute.itemKey}
                        itemKey={attribute.itemKey}
                        headerText={attribute.headerText}
                        className={attribute.className}
                        style={attribute.style}
                    />
                ))}
            </Pivot>
        </Stack>
    );
};

export default TabToStackHorizontally;
