import * as React from 'react';

function SvgSvgLockYes(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit={2}
            width="1em"
            height="1em"
            {...props}
        >
            <path
                d="M16 3c-3.844 0-7 3.156-7 7v3H6v16h20V13h-3v-3c0-3.844-3.156-7-7-7zM8 15h16v12H8V15zm1.575 5.584l4.476 4.477c.391.39 1.024.39 1.415 0l6.959-6.96-1.414-1.415-6.253 6.253-3.769-3.769-1.414 1.414zM16 5c2.754 0 5 2.246 5 5v3H11v-3c0-2.754 2.246-5 5-5z"
                fill="#212121"
                fillRule="nonzero"
            />
        </svg>
    );
}

export default SvgSvgLockYes;
