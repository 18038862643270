import React from 'react';
import DroppableAreaView from './DroppableArea.view';

export interface IDroppableAreaProps {
    droppableId: string;
    horizontal?: true;
    classNames?: {
        default: string;
        draggingOver?: string;
    };
    disabled?: boolean;
}

const DroppableArea: React.FC<IDroppableAreaProps> = (props) => {
    return (
        <DroppableAreaView
            {...{
                droppableId: props.droppableId,
                horizontal: props.horizontal,
                classNames: props.classNames,
                disabled: props.disabled
            }}
        >
            {props.children}
        </DroppableAreaView>
    );
};

export default DroppableArea;
