import { DefaultButton, IPersonaCoinProps, ITheme, mergeStyles, Stack, Text } from '@fluentui/react';
import { OrgBrandingDto } from 'client-api-wrapper/lib/identity_provider/responses.dto';
import Badge from 'components/Badge';
import { IRoomAction } from 'features/Rooms/RoomList/helpers/roomTypes';
import VisitorRoomSelector from 'features/Visitors/VisitorRoomSelector';
import React from 'react';

export interface IVisitorProps {
    displayName: string;
    selected: boolean;
    action: IRoomAction;
    theme: ITheme;
    branding?: OrgBrandingDto;
}
const VisitorView: React.FC<IVisitorProps> = (props) => {
    const { displayName, selected, action, theme, branding } = props;
    const customCoinClass = mergeStyles({
        borderRadius: 20,
        border: '3px solid ' + branding?.orgColors
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const coinProps: IPersonaCoinProps = {
        className: customCoinClass
    };

    return (
        <Stack>
            <VisitorRoomSelector />
            <Stack
                horizontal
                horizontalAlign="space-between"
                verticalAlign="center"
                style={{ marginBottom: 8, padding: '8px 16px', borderRadius: 4, background: selected ? theme.palette.themeLight : '' }}
            >
                <Stack horizontal verticalAlign="center">
                    <Stack horizontalAlign="center" verticalAlign="start" style={{ width: '40px', paddingRight: 10 }}>
                        <Badge text={displayName} size="30" fontSize="small" />
                    </Stack>
                    <Text variant="medium" style={{ color: theme.palette.black }}>
                        {displayName.split(' ').length > 1 ? `${displayName.split(' ')[0]} ${displayName.split(' ')[1]}` : `${displayName.split(' ')[0]}`}
                    </Text>
                </Stack>
                <Stack>
                    <DefaultButton onClick={(e) => action.action && action.action(e)}>{action.title}</DefaultButton>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default VisitorView;
