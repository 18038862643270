import { mergeStyles, Panel, PanelType, Stack, Toggle } from '@fluentui/react';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import DynamicForm, { IDynamicFormElementProps } from 'components/DynamicForm/DynamicForm';
import TabToStackHorizontally, { TabAttributes } from 'features/RoomList/RoomSettings/TabToStackHorizontally';
import IvicosStrings from 'kits/stringKit';
import React, { CSSProperties } from 'react';

export interface IRoomSettingsViewProps {
    isOpen: any;
    confirmationTarget: any;
    setConfirmationTarget: any;

    getConfirmationTitle: any;
    getConfirmationDesc: any;
    onConfirm: any;
    onPanelDismiss: any;
    onChange: any;

    onFormChange: (key: string, value: any) => void;
    formFields: IDynamicFormElementProps[];

    onRenderPanelFooterContent: any;

    onOpenForVisitorsChange: (event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined) => void;
    whitelistSelectorIsVisible: boolean;
    activeTab: string;
    setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

const RoomSettingsView: React.FC<IRoomSettingsViewProps> = ({
    isOpen,
    confirmationTarget,
    setConfirmationTarget,

    getConfirmationTitle,
    getConfirmationDesc,
    onConfirm,
    onPanelDismiss,

    onFormChange,
    formFields,
    onRenderPanelFooterContent,

    onOpenForVisitorsChange,
    whitelistSelectorIsVisible,
    activeTab,
    setActiveTab
}) => {
    const tabStyle: CSSProperties = { width: '30px', height: '20px' };
    const tabs: TabAttributes[] = [
        { headerText: 'General', itemKey: 'General', className: 'one-tab', style: tabStyle },
        { headerText: 'Access', itemKey: 'Access', className: 'one-tab', style: tabStyle }
    ];

    const formFieldContainersClassName = mergeStyles({
        '> div': {
            marginBottom: '26px'
        },
        '> div:last-child': {
            marginBottom: '0px'
        }
    });

    return (
        <Panel
            isOpen={isOpen}
            type={PanelType.custom}
            customWidth="360px"
            isLightDismiss
            onRenderFooterContent={() => onRenderPanelFooterContent()}
            onDismiss={onPanelDismiss}
            headerText={IvicosStrings.roomSettingsPanelTitle}
        >
            <ConfirmationDialog
                target={confirmationTarget}
                title={getConfirmationTitle()}
                desc={getConfirmationDesc()}
                onDismiss={() => setConfirmationTarget('none')}
                onConfirm={onConfirm}
                isOpen={confirmationTarget != 'none'}
            />
            <TabToStackHorizontally activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />
            <Stack grow>
                {activeTab === 'General' ? (
                    <Stack grow>
                        <DynamicForm
                            onChange={onFormChange}
                            formFields={formFields.filter(
                                (elem) => elem.key !== 'isOpenForVisitors' && elem.key !== 'whitelist' && elem.key !== 'toggleWhitelist'
                            )}
                        />
                    </Stack>
                ) : (
                    <Stack grow className={formFieldContainersClassName}>
                        <DynamicForm onChange={onFormChange} formFields={formFields.filter((elem) => elem.key === 'isOpenForVisitors')} />
                        <Toggle
                            defaultChecked={whitelistSelectorIsVisible}
                            checked={whitelistSelectorIsVisible}
                            role="checkbox"
                            onChange={onOpenForVisitorsChange}
                            label={IvicosStrings.toggleWhitelistLabel}
                        />
                        <DynamicForm
                            onChange={onFormChange}
                            formFields={formFields
                                .filter((elem) => elem.key === 'whitelist')
                                .map((elem) => {
                                    elem.isVisible = whitelistSelectorIsVisible;
                                    return elem;
                                })}
                        />
                    </Stack>
                )}
            </Stack>
        </Panel>
    );
};

export default RoomSettingsView;
