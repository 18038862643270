import * as React from 'react';

function SvgBell(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M16 3a2 2 0 00-2 2c0 .088.014.171.025.256C10.571 6.14 8 9.276 8 13v9c0 .565-.435 1-1 1H6v2h7.416c-.137.342-.416.612-.416 1 0 1.645 1.355 3 3 3s3-1.355 3-3c0-.388-.279-.658-.416-1H26v-2h-1c-.565 0-1-.435-1-1v-8.707c0-3.755-2.51-7.105-6.027-8.033.01-.086.027-.17.027-.26a2 2 0 00-2-2zm.186 4.002C19.452 7.1 22 9.974 22 13.293V22c0 .388.279.658.416 1H9.584c.137-.342.416-.612.416-1v-9c0-3.388 2.772-6.1 6.186-5.998zM4.189 8.584a13.029 13.029 0 00.004 10.832l1.819-.832a11.04 11.04 0 01-.004-9.168l-1.819-.832zm23.622 0l-1.819.832a11.04 11.04 0 01-.004 9.168l1.819.832a13.029 13.029 0 00.004-10.832zM16 25c.565 0 1 .435 1 1s-.435 1-1 1-1-.435-1-1 .435-1 1-1z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgBell;
