import { DefaultEffects, getTheme } from '@fluentui/react';
import { InvitationDto } from 'client-api-wrapper/lib/identity_provider/responses.dto';
import OutsideWrapper from 'features/Visitors/Entrance/OutsideWrapper/OutsideWrapper';
import RegistrationFormController from 'features/Visitors/Entrance/RegistrationFormatController/RegistrationFormController';
import React from 'react';
import LoadingPage from 'components/LoadingPage';

interface IVisitorsEntryRouteParams {
    invitationid: string;
    iFrameURL: string;
    onSuccess: Function;
    orgId?: string;
    invitation?: InvitationDto;
}

const VisitorsEntryView: React.FC<IVisitorsEntryRouteParams> = ({ invitationid, iFrameURL, onSuccess, orgId, invitation }) => {
    const theme = getTheme();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const backgroundStyle: React.CSSProperties = {
        backgroundColor: theme.palette.white,
        borderRadius: '4px 4px 0px 0px',
        boxShadow: DefaultEffects.elevation8,
        overflow: 'hidden',
        marginTop: iFrameURL ? 0 : 64
    };

    if (!orgId || !invitation) return <LoadingPage label="Checking invitation..." description="Plase wait..." />;

    return (
        <OutsideWrapper>
            <RegistrationFormController
                invitationId={invitationid}
                onRegistrationSuccess={() => {
                    onSuccess();
                }}
                onError={(err) => {
                    console.log(err);
                }}
            />
        </OutsideWrapper>
    );
};

export default VisitorsEntryView;
