import { DefaultButton, getTheme, Icon, Stack, Text } from '@fluentui/react';
import React from 'react';
import Files from 'react-files';
import { IImportCSVStepProps } from './ImportCSVStep';

const ImportCSVStepView: React.FC<IImportCSVStepProps> = (props) => {
    const { onFileUpload } = props;
    const theme = getTheme();
    return (
        <Stack tokens={{ childrenGap: 16 }}>
            <Files onChange={(files: File[]) => onFileUpload(files[0])} accepts={['text/csv']} minFileSize={0} clickable>
                <Stack
                    horizontalAlign="center"
                    verticalAlign="center"
                    tokens={{ childrenGap: 16 }}
                    styles={{
                        root: {
                            background: theme.palette.neutralLighter,
                            minHeight: 320,
                            cursor: 'pointer',
                            borderRadius: 2,
                            '&:hover': { background: theme.palette.neutralLight }
                        }
                    }}
                >
                    <Icon iconName="OpenFile" style={{ fontSize: 64 }} />
                    <Text variant="medium">Drag and drop a .csv file here, or click to select a file.</Text>
                </Stack>
            </Files>
            <a href={'data:text/csv;charset=utf-8,' + encodeURIComponent('EMAIL;ROLE\nmax.mustermann@example.org;user')} download="template.csv">
                <DefaultButton iconProps={{ iconName: 'Download' }} text="Download CSV template file" />
            </a>
        </Stack>
    );
};

export default ImportCSVStepView;
