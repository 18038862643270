import {
    DefaultButton,
    DetailsList,
    Dialog,
    DialogFooter,
    getTheme,
    IconButton,
    Modal,
    Panel,
    PrimaryButton,
    Separator,
    Stack,
    Text,
    TextField,
    ScrollablePane,
    mergeStyleSets,
    IColumn,
    Persona,
    PersonaSize
} from '@fluentui/react';
import { IC, ICButton } from 'components/SVGIcon';
import DynamicForm from 'components/DynamicForm/DynamicForm';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import { Plus, Resendmail, Settings, Trash, ThreeDots } from 'kits/IconKit2';
import React from 'react';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { IManageAccessState, IMember } from './ManageAccess.State';

const ManageAccessView: React.FC<IManageAccessState> = ({
    isEditUserOpen,
    dismissEditUserPanel,
    editUserForm,
    onEditUserFormChange,
    user,
    changes,
    onUpdateUser,
    setChanges,
    filterUsers,
    filteredMembers,
    iconProps,
    setEmailToInvite,
    inviteUser,
    _members,
    togglehideInviteDialog,
    inviteDialogContentProps,
    hideInviteDialog,
    emailToInvite,
    deleteDialogContentProps,
    hideDeleteDialog,
    togglehideDeleteDialog,
    deleteUser,
    togglehideReinviteDialog,
    hideReinviteDialog,
    reinviteDialogContentProps,
    togglehideUserExistsDialog,
    hideUserExistsDialog,
    userAlreadyExistsContentProps,
    onDismiss,
    isModalOpen,
    showModal,
    hideModal,
    userForMobileModal,
    setUserToDelete,
    setUser,
    openEditUserPanel,
    isInvitePanelOpen,
    openInvitePanel,
    dismissInvitePanel,
    isText,
    getDescriptionStyles,
    theme,
    setUserForMobileModal,
    stepsEnabled,
    setStepsEnabled,
    steps,
    options,
    routeHistory,
    microsoftProps
}) => {
    const { smallDevice, mediumDevice, smallLaptop, largeLaptop } = useWindowWidth();

    const onRenderDescription = (props: any): any => {
        const theme = getTheme();
        return (
            <>
                <Text variant="small" styles={getDescriptionStyles(theme)}>
                    {props.description}
                </Text>
            </>
        );
    };

    // COLUMNS FOR DETAILSLIST

    const settingsOrResendButton = (member: IMember) => {
        if (member.userId != 'invitee' && member.role != 'owner') {
            return (
                <ICButton
                    variant={'rect'}
                    onClick={() => {
                        setUser(member);
                        openEditUserPanel();
                    }}
                >
                    <Settings />
                </ICButton>
            );
        } else if (member.userId == 'invitee') {
            return (
                <ICButton
                    variant={'rect'}
                    onClick={() => {
                        setEmailToInvite(member.email);
                        togglehideReinviteDialog();
                    }}
                >
                    <Resendmail />
                </ICButton>
            );
        }
    };

    const deleteButton = (user: IMember) =>
        user.role != 'owner' && (
            <ICButton
                variant={'rect'}
                onClick={() => {
                    togglehideDeleteDialog();
                    setUserToDelete(user);
                }}
            >
                <Trash />
            </ICButton>
        );

    const threeDotsButton = (user: IMember) =>
        user.role != 'owner' && (
            <IC variant={'custom'} size={15} style={{ cursor: 'pointer', transform: 'rotate(90deg)' }}>
                <ThreeDots
                    onClick={() => {
                        showModal();
                        setUserForMobileModal(user);
                    }}
                />
            </IC>
        );

    const userImage = (user: IMember) => <Persona size={PersonaSize.size32} imageUrl={user.profile_pic}></Persona>;

    const mobileNameView = (user: IMember) => (
        <Stack>
            <Text variant="mediumPlus" style={{ marginBottom: 5 }}>
                {user.name}
            </Text>
            <Text variant="small">{user.email}</Text>
        </Stack>
    );

    const mobileRoleView = (user: IMember) => (
        <>
            <Text variant="mediumPlus" style={{ marginBottom: 5 }}>
                {user.role}
            </Text>
        </>
    );

    const classNames = mergeStyleSets({
        fields: {
            fontWeight: 600,
            fontSize: 17,
            marginBottom: 18,
            marginTop: 18,
            color: theme.palette.black
        },
        mobileFields: {
            marginBottom: 18,
            marginTop: 18,
            color: theme.palette.black
        }
    });

    const memberColumns: IColumn[] = [
        {
            key: 'column1',
            name: '',
            ariaLabel: 'Profile Images',
            fieldName: 'img',
            minWidth: 24,
            maxWidth: largeLaptop || smallLaptop ? 128 : 32,
            isPadded: true,
            onRender: (user: IMember) => userImage(user),
            className: classNames.fields
        },
        {
            key: 'column2',
            name: 'Name',
            ariaLabel: 'Member names',
            fieldName: 'name',
            minWidth: 128,
            maxWidth: largeLaptop ? 512 : 256,
            isPadded: true,
            className: classNames.fields
        },
        {
            key: 'columnX',
            name: 'Email',
            ariaLabel: 'Member emails',
            fieldName: 'email',
            minWidth: 190,
            maxWidth: largeLaptop ? 512 : 256,
            isPadded: true,
            className: classNames.fields
        },
        {
            key: 'column3',
            name: 'Role',
            ariaLabel: 'Member roles',
            fieldName: 'role',
            minWidth: largeLaptop ? 128 : 64,
            maxWidth: largeLaptop || smallLaptop ? 512 : 64,
            isPadded: true,
            className: classNames.fields
        },
        {
            key: 'column4',
            name: '',
            ariaLabel: 'Edit User',
            minWidth: largeLaptop || smallLaptop ? 50 : 30,
            maxWidth: largeLaptop || smallLaptop ? 50 : 30,
            isPadded: true,
            className: classNames.fields,
            onRender: (user: IMember) => settingsOrResendButton(user)
        },
        {
            key: 'column5',
            name: '',
            ariaLabel: 'Delete User',
            minWidth: largeLaptop || smallLaptop ? 50 : 30,
            maxWidth: largeLaptop || smallLaptop ? 50 : 30,
            isPadded: true,
            className: classNames.fields,
            onRender: (user: IMember) => deleteButton(user)
        }
    ];

    const mobileMemberColumns: IColumn[] = [
        {
            key: 'column1',
            name: '',
            ariaLabel: 'Profile Images',
            fieldName: 'img',
            minWidth: 8,
            maxWidth: 12,
            isPadded: true,
            onRender: (user: IMember) => userImage(user),
            className: classNames.mobileFields
        },
        {
            key: 'column2',
            name: 'Name',
            ariaLabel: 'Member names',
            fieldName: 'name',
            minWidth: 100,
            maxWidth: 128,
            isPadded: true,
            onRender: (user: IMember) => mobileNameView(user),
            className: classNames.mobileFields
        },
        {
            key: 'column3',
            name: 'Role',
            ariaLabel: 'Member roles',
            fieldName: 'role',
            minWidth: 32,
            maxWidth: 256,
            isPadded: true,
            onRender: (user: IMember) => mobileRoleView(user),
            className: classNames.mobileFields
        },
        {
            key: 'column4',
            name: '',
            ariaLabel: 'Edit User',
            minWidth: 1,
            maxWidth: 15,
            isPadded: true,
            className: classNames.mobileFields,
            onRender: (user: IMember) => threeDotsButton(user)
        }
    ];

    return (
        <>
            <Panel headerText="Member invites" isOpen={isInvitePanelOpen} onDismiss={dismissInvitePanel} closeButtonAriaLabel="Close">
                <Stack horizontal={false} tokens={{ childrenGap: 16 }} verticalAlign="start">
                    <Stack horizontal={false} horizontalAlign="start" tokens={{ childrenGap: 16 }} style={{ width: '100%', marginTop: 70 }}>
                        <Stack style={{ width: '100%' }}>
                            <Text variant="mediumPlus" style={{ marginBottom: 13 }}>
                                Invite member
                            </Text>
                            <TextField
                                onChange={(e: any) => setEmailToInvite(e.target.value)}
                                style={largeLaptop ? { minWidth: 300 } : { minWidth: 200 }}
                                placeholder={'Email address'}
                                value={emailToInvite}
                                description="Invalid email address"
                                className={'invite'}
                                onRenderDescription={onRenderDescription}
                            />
                        </Stack>
                        <Text variant="medium">
                            This is a single member invite. After sending the invite your colleague will be alerted via email and will appear on the members
                            list.
                        </Text>
                        <PrimaryButton text={'Invite member'} onClick={inviteUser} />
                    </Stack>
                    <Stack horizontal={false} horizontalAlign="start" tokens={{ childrenGap: 16 }} style={{ width: '100%', marginTop: 70 }}>
                        <Text variant="medium">
                            Use a .xcl or .csv file to upload multiple members at one time. The new invites will automatically appear on the members list.
                        </Text>
                        <PrimaryButton text={'Bulk invite'} onClick={() => routeHistory.push('/dashboard/members/powerImporter')} />
                    </Stack>
                </Stack>
            </Panel>
            {smallDevice && (
                <Stack style={{ padding: '26px 0px 13px 17px' }}>
                    <Text variant="large">Manage access</Text>
                </Stack>
            )}
            <Stack grow style={{ position: 'relative', height: 'calc( 100vh - 140px )' }}>
                <Stack className={largeLaptop ? 'padding--large' : 'padding--small'}>
                    <Stack
                        horizontal={!smallDevice && !mediumDevice ? true : false}
                        verticalAlign="center"
                        horizontalAlign="start"
                        style={{ marginBottom: 48, maxWidth: 1500 }}
                        tokens={{ childrenGap: 16 }}
                    >
                        <TextField
                            onChange={(e: any) => filterUsers(e.target.value)}
                            style={
                                filteredMembers.length === 0
                                    ? { color: theme.palette.red, minWidth: smallDevice ? 200 : 400 }
                                    : { color: theme.palette.green, minWidth: smallDevice ? 200 : 400 }
                            }
                            placeholder={'Filter members'}
                            iconProps={iconProps}
                            className={'filter'}
                        />
                        <PrimaryButton
                            text={'Invite member'}
                            onRenderIcon={() => (
                                <IC variant="light" size={16}>
                                    <Plus />
                                </IC>
                            )}
                            onClick={openInvitePanel}
                        />
                        {microsoftProps.microsoftTenantIsInvited && (
                            <Stack horizontal={smallDevice ? false : true} verticalAlign="center" horizontalAlign="space-around">
                                <Separator vertical={smallDevice ? false : true} />
                                <Text style={smallDevice ? { color: theme.palette.black } : { marginLeft: 15, color: theme.palette.black }}>
                                    MS ACTIVE DIRECTORY: enabled
                                </Text>
                            </Stack>
                        )}
                    </Stack>

                    <Stack style={{ marginBottom: '30px' }}>
                        <ScrollablePane style={{ width: '95%', height: '80%', marginTop: !largeLaptop && !smallLaptop ? '30%' : '5%', marginLeft: '2.5%' }}>
                            <DetailsList
                                items={!isText ? _members : filteredMembers}
                                columns={!smallDevice ? memberColumns : mobileMemberColumns}
                                checkboxVisibility={2}
                                compact={true}
                                className={'table'}
                            />
                        </ScrollablePane>
                    </Stack>
                </Stack>
            </Stack>

            <Panel isOpen={isEditUserOpen} onDismiss={dismissEditUserPanel}>
                <Stack style={{ flexDirection: 'column', alignItems: 'center', height: '500px', justifyContent: 'space-around' }}>
                    <DynamicForm formFields={editUserForm} onChange={onEditUserFormChange}></DynamicForm>
                    <Stack style={{ flexDirection: 'row', padding: '10px', width: '100%', justifyContent: 'space-around' }}>
                        <DefaultButton text="Cancel" onClick={dismissEditUserPanel} />
                        <PrimaryButton
                            text="Confirm and Save"
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            onClick={(e) => {
                                dismissEditUserPanel();
                                if (!user) return;
                                if (changes && Object.keys(changes).length > 0) {
                                    onUpdateUser &&
                                        onUpdateUser(user.userId, changes).then(() => {
                                            setChanges({});
                                            dismissEditUserPanel();
                                        });
                                    return;
                                }
                            }}
                        />
                    </Stack>
                </Stack>
            </Panel>
            <Modal isOpen={isModalOpen} onDismiss={onDismiss} isBlocking={false}>
                <Stack horizontal horizontalAlign="end">
                    <IconButton iconProps={{ iconName: 'cancel' }} ariaLabel="Close popup modal" onClick={onDismiss} />
                </Stack>
                <Stack horizontalAlign={'center'} style={{ paddingBottom: '30px' }}>
                    <Text variant="xLarge">{userForMobileModal?.name}</Text>
                    <Text variant="medium">{userForMobileModal?.email}</Text>
                </Stack>
                <Stack horizontalAlign={'center'} horizontal tokens={{ childrenGap: 32 }}>
                    <ICButton
                        variant={'rect'}
                        onClick={() => {
                            togglehideDeleteDialog();
                            setUserToDelete(userForMobileModal);
                            hideModal();
                        }}
                    >
                        <Trash />
                    </ICButton>

                    {userForMobileModal?.userId != 'invitee' && userForMobileModal?.role != 'owner' ? (
                        <ICButton
                            variant={'rect'}
                            onClick={() => {
                                setUser(userForMobileModal);
                                openEditUserPanel();
                                hideModal();
                            }}
                        >
                            <Settings />
                        </ICButton>
                    ) : (
                        <ICButton
                            variant={'rect'}
                            onClick={() => {
                                setEmailToInvite(userForMobileModal.email);
                                togglehideReinviteDialog();
                                hideModal();
                            }}
                        >
                            <Resendmail />
                        </ICButton>
                    )}
                </Stack>
            </Modal>

            <Steps
                enabled={stepsEnabled}
                steps={steps.steps}
                initialStep={steps.initialStep}
                options={options}
                onExit={() => setStepsEnabled(false)}
                onStart={() => {
                    localStorage.setItem('intro-manage-access', 'false');
                }}
            />

            <Dialog
                hidden={hideInviteDialog}
                onDismiss={() => {
                    togglehideInviteDialog();
                    setEmailToInvite('');
                }}
                dialogContentProps={inviteDialogContentProps}
            >
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            togglehideInviteDialog();
                            setEmailToInvite('');
                        }}
                        text={'Close'}
                    />
                </DialogFooter>
            </Dialog>

            <Dialog
                hidden={hideReinviteDialog}
                onDismiss={() => {
                    togglehideReinviteDialog();
                    setEmailToInvite('');
                }}
                dialogContentProps={reinviteDialogContentProps}
            >
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            inviteUser();
                            togglehideReinviteDialog();
                        }}
                        text={'Confirm'}
                    />
                    <DefaultButton
                        onClick={() => {
                            togglehideReinviteDialog();
                            setEmailToInvite('');
                        }}
                        text={'Cancel'}
                    />
                </DialogFooter>
            </Dialog>

            <Dialog
                hidden={hideUserExistsDialog}
                onDismiss={() => {
                    togglehideUserExistsDialog();
                    setEmailToInvite('');
                }}
                dialogContentProps={userAlreadyExistsContentProps}
            >
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            togglehideUserExistsDialog();
                            setEmailToInvite('');
                        }}
                        text={'Close'}
                    />
                </DialogFooter>
            </Dialog>

            <Dialog
                hidden={hideDeleteDialog}
                onDismiss={() => {
                    togglehideDeleteDialog();
                    setEmailToInvite('');
                }}
                dialogContentProps={deleteDialogContentProps}
            >
                <DialogFooter>
                    <DefaultButton onClick={() => togglehideDeleteDialog()} text={'Cancel'} />
                    <PrimaryButton
                        onClick={() => {
                            togglehideDeleteDialog();
                            deleteUser();
                        }}
                        text={'Confirm'}
                    />
                </DialogFooter>
            </Dialog>
        </>
    );
};

export default ManageAccessView;
