import React, { useEffect, useState } from 'react';
import { getTheme } from 'office-ui-fabric-react';
import { idpService } from 'services/api/identity-provider.service';
import { useStatePersist } from 'use-state-persist';
import { useEvent } from 'react-use';
import { ITheme } from '@fluentui/react';

export interface ISetupAssistantInfoLabels {
    open: {
        primary: string;
        secondary: string;
    };
    completed: {
        primary: string;
        secondary: string;
    };
}

export interface ISetupAssistantState {
    theme: ITheme;
    shouldBeVisible: boolean;
    setWasDismissed: React.Dispatch<unknown>;
    setVisibilityOverride: React.Dispatch<React.SetStateAction<boolean>>;
    isCompleted: boolean;
    infoLabels: ISetupAssistantInfoLabels;
    thingsToDo: ISetupAssistantToDo[];
    completedTodos: ISetupAssistantToDo[];
}

export interface ISetupAssistantToDo {
    key: string;
    done: boolean;
    label: string;
    path?: string;
}

export const useSetupAssistantState: () => ISetupAssistantState = () => {
    const theme = getTheme();

    const [wasDismissed, setWasDismissed] = useStatePersist('@setupAssistantWasDismissed');
    const [thingsToDo, setThingsToDo] = useState<ISetupAssistantToDo[]>([]);
    const [prevThingsToDo, setPrevThingsToDo] = useState<ISetupAssistantToDo[]>([]);

    const [visibilityOverride, setVisibilityOverride] = useState<boolean>(false);

    useEffect(() => {
        if (thingsToDo.filter((t) => !t.done).length == 0 && prevThingsToDo.filter((t) => !t.done).length != 0) setVisibilityOverride(true);
        setPrevThingsToDo(thingsToDo);
    }, [thingsToDo]);

    const toDoLabels: any = {
        profile_pic: 'Set profile image',
        orgLogo: 'Set logo of your organisation',
        orgColors: 'Set color of your organisation'
    };

    const toDoPaths: any = {
        profile_pic: '/#edit-profile',
        orgLogo: '/dashboard/settings',
        orgColors: '/dashboard/settings'
    };

    const checkProgress = async () => {
        const progress: any = await idpService.checkSetupCompleted();
        const keys = Object.keys(progress);

        const nextThingsToDo = keys.map((key) => ({ key, done: progress[key], label: toDoLabels[key], path: toDoPaths[key] }));
        setThingsToDo(nextThingsToDo);
    };

    useEffect(() => {
        checkProgress();
    }, []);

    useEvent('refetchBranding', () => {
        checkProgress();
    });
    useEvent('refetchUserDetails', () => {
        checkProgress();
    });

    const completedTodos = thingsToDo.filter((t) => t.done);
    const isCompleted = thingsToDo.filter((t) => !t.done).length <= 0;
    const shouldBeVisible = visibilityOverride || (!wasDismissed && !isCompleted);

    const infoLabels: ISetupAssistantInfoLabels = {
        open: {
            primary: 'Complete your company profile!',
            secondary: 'There are still a few things that need to be done as a manager before your ivCAMPUS is compete!'
        },
        completed: {
            primary: 'Setup completed!',
            secondary: 'You have completed your company profile set up!'
        }
    };

    return {
        theme,
        shouldBeVisible,
        setWasDismissed,
        setVisibilityOverride,
        isCompleted,
        infoLabels,
        thingsToDo,
        completedTodos
    };
};
