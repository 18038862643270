import React from 'react';
import { getTheme, Label, Stack } from '@fluentui/react';
import IconKit from 'kits/IconKit';
import { IIconPickerProps } from './IconPicker';

const IconPickerView: React.FC<IIconPickerProps> = (props) => {
    const { onChange, variant, value, label, style } = props;
    const theme = getTheme();
    if (!variant) return <></>;

    return (
        <Stack style={style}>
            {label && <Label>{label}</Label>}
            <Stack horizontal wrap>
                {Object.keys(IconKit.getIcons(variant)).map((k) => (
                    <Stack
                        key={variant + '-' + k}
                        styles={{
                            root: {
                                borderRadius: 2,
                                border: value == k ? '2px ' + theme.palette.themePrimary + ' solid' : '0',
                                width: 48,
                                height: 48,
                                ':hover': {
                                    background: theme.palette.neutralLighter
                                },
                                ':active': {
                                    background: theme.palette.neutralLight
                                }
                            }
                        }}
                        horizontalAlign="center"
                        verticalAlign="center"
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        onClick={(e) => {
                            onChange && onChange(k);
                        }}
                    >
                        <IconKit.Icon icon={IconKit.getIcon(variant, k)} size={32} />
                    </Stack>
                ))}
            </Stack>
        </Stack>
    );
};

export default IconPickerView;
