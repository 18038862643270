import { getTheme, ITheme } from '@fluentui/react';
import { OrgBrandingDto } from 'client-api-wrapper/lib/identity_provider/responses.dto';
import { useOrganizationBranding } from 'features/Header/hooks';
import { useCurrentOrgId } from 'shared-state/location/hook';

export interface IVisitorState {
    theme: ITheme;
    branding?: OrgBrandingDto;
}

export const useVisitorState: () => IVisitorState = () => {
    const theme = getTheme();
    const [orgId] = useCurrentOrgId();
    const [branding] = useOrganizationBranding(orgId);

    return {
        theme,
        branding
    };
};
