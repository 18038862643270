import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IResource } from 'kits/apiKit3/legacy';
import { useStaredAreas } from 'shared-state/display-options/hooks';
import { useCurrentOrgId } from 'shared-state/location/hook';
import { useAreaList } from 'shared-state/directory/hooks';
import { areaDTOtoResource } from 'kits/apiKit3/legacy';
import { useCurrentCampusId, useCurrentRoomId } from 'shared-state/location/hook';
import { useBoolean } from '@uifabric/react-hooks';
import { resourceService } from 'services/api/resource.service';
import { useIdentityClaims } from 'shared-state/identity/hooks';
import { IIdentityClaims } from 'shared-state/identity/types';

export interface IAreaListState {
    panelHeader: string;
    modalIsOpen: boolean;
    onAreaSelected: (areaId: string) => void;
    staredAreas: string[];
    onAreaStared: (a: IResource, s: boolean) => void;
    _areas: {
        isStared: boolean;
        id: string;
        type: string;
        attributes: any;
        relationships: any;
    }[];
    campusId?: string;
    roomId?: string;
    isPanelOpen: boolean;
    openPanel: () => void;
    dismissPanel: () => void;
    setPanelHeader: React.Dispatch<React.SetStateAction<string>>;
    areaName: string;
    setAreaName: React.Dispatch<React.SetStateAction<string>>;
    setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    saveDataForAreaDeletion: (
        areaName: string,
        areaId: string
    ) => {
        name: string;
        id: string;
    };
    areaToDelete: {
        name: string;
        id: string;
    };
    searchArea: (e: any, newValue: any) => void;
    searchInput: string;
    identityClaims?: IIdentityClaims;
    deleteAreaHandler: (areaId: string) => Promise<void>;
    setAreaToEdit: React.Dispatch<React.SetStateAction<string>>;
    onCreateAreaHandler: () => Promise<void>;
    onAreaChangeHandler: () => Promise<void>;
    onDismiss: () => void;
}

export const useAreaListState: (vertical?: boolean, onSelection?: Function) => IAreaListState = (vertical, onSelection) => {
    // good stuff
    const [campusId] = useCurrentCampusId();
    const [roomId] = useCurrentRoomId();

    // panel state

    const [isPanelOpen, { setTrue: openPanel, setFalse: closePanel }] = useBoolean(false);

    const dismissPanel = () => {
        setAreaName('');
        closePanel();
    };

    const [panelHeader, setPanelHeader] = useState('Create new area');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [areaName, setAreaName] = useState('');

    // Area Manipolation

    const routeHistory = useHistory();
    const [currentOrgId] = useCurrentOrgId();
    const [staredAreas, setStaredAreas] = useStaredAreas(currentOrgId || 'default');
    const [areas] = useAreaList();

    // Backwards comp
    const navigateToArea = (aid: string) => {
        routeHistory.push('/areas/' + aid);
    };
    const onAreaSelected = (areaId: string) => {
        navigateToArea(areaId);
        onSelection && onSelection(areaId);
    };
    const _areas = areas.map((area) => areaDTOtoResource(area)).map((area) => ({ ...area, ...{ isStared: staredAreas && staredAreas.includes(area.id) } }));

    const onAreaStared = (a: IResource, s: boolean) => {
        setStaredAreas((prevList: any) => {
            if (!s) return prevList.filter((areaId: any) => areaId != a.id);

            if (prevList && prevList.includes(a.id)) return prevList;

            if (!prevList) return [a.id];
            return [...prevList, ...[a.id]];
        });
    };

    const [areaToDelete, setAreaToDelete] = useState({
        name: '',
        id: ''
    });

    const saveDataForAreaDeletion = (areaName: string, areaId: string) => {
        setAreaToDelete({ name: areaName, id: areaId });
        return areaToDelete;
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [areaList, setAreaList] = useAreaList();

    const [searchInput, setSearchInput] = useState('');

    const [areaToEdit, setAreaToEdit] = useState('');

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [identityClaims, setIdentityClaims] = useIdentityClaims();

    const deleteAreaHandler = async (areaId: string) => {
        if (!campusId) return;
        const idOfCampus = campusId;
        await resourceService.deleteArea(idOfCampus, areaId);
        setAreaList((prevList: any) => prevList.filter((a: any) => a.id != areaId));
    };

    const searchArea = (e: any, newValue: any) => {
        typeof newValue == 'string' && setSearchInput(newValue);
    };

    const onDismiss = () => {
        setModalIsOpen(!modalIsOpen);
    };

    const onCreateAreaHandler = async () => {
        if (!areaName || !campusId) return;
        const newArea = await resourceService.createArea(campusId, areaName);

        setAreaList((prevList: any) => [...prevList, ...[newArea]]);
    };

    const onAreaChangeHandler = async () => {
        if (!areaName || !campusId) return;
        await resourceService.updateArea(campusId, areaToEdit, { name: areaName });
        setAreaList((prevList: any) =>
            prevList.map((area: any) => {
                if (area.id != areaToEdit) return area;

                return { ...area, ...{ name: areaName } };
            })
        );
    };

    return {
        onAreaSelected,
        staredAreas,
        onAreaStared,
        _areas,
        campusId,
        roomId,
        isPanelOpen,
        openPanel,
        dismissPanel,
        panelHeader,
        setPanelHeader,
        areaName,
        setAreaName,
        modalIsOpen,
        setModalIsOpen,
        saveDataForAreaDeletion,
        areaToDelete,
        searchArea,
        searchInput,
        identityClaims,
        deleteAreaHandler,
        setAreaToEdit,
        onCreateAreaHandler,
        onAreaChangeHandler,
        onDismiss
    };
};
