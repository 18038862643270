import React from 'react';
import { DefaultEffects, Stack, Text } from '@fluentui/react';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { prettyDate } from './helpers';
import { IChangeLogFeedEntry, IChangeLogState } from './ChangeLog.state';

const ChangeLogView: React.FC<IChangeLogState> = ({ changeLogFeed, theme, changeLogCard }) => {
    const { smallDevice } = useWindowWidth();
    const renderFeedEntry = (entry: IChangeLogFeedEntry) => (
        <Stack
            className={`${changeLogCard} surface padding--large`}
            tokens={{ childrenGap: 8 }}
            style={{ background: theme.palette.white, color: theme.palette.black, boxShadow: DefaultEffects.elevation8 }}
        >
            <Text variant="large">{entry.title}</Text>
            <Text variant="small">{prettyDate(new Date(entry.timestamp)) || 'At some point'}</Text>
            <Text>{entry.body}</Text>
        </Stack>
    );

    return (
        <>
            {smallDevice && (
                <Stack style={{ padding: '26px 0px 13px 33px' }}>
                    <Text variant="large">Change log</Text>
                </Stack>
            )}
            <Stack style={{ height: '100%' }} className="padding--large">
                <Stack tokens={{ childrenGap: 8 }}>
                    {changeLogFeed &&
                        changeLogFeed.map((entry: any, entryIndex: any) => <React.Fragment key={'e-' + entryIndex}>{renderFeedEntry(entry)}</React.Fragment>)}
                </Stack>
            </Stack>
        </>
    );
};

export default ChangeLogView;
