import { getTheme, ITheme } from '@fluentui/react';
import { useTheme } from '@fluentui/react-theme-provider';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { CampusDto } from 'client-api-wrapper/lib/campus_api/responses.dto';
import { OrganizationDto } from 'client-api-wrapper/lib/identity_provider/responses.dto';
import { IDynamicFormElementProps } from 'components/DynamicForm/DynamicForm';
import React, { useEffect, useState } from 'react';
import { idpService } from 'services/api/identity-provider.service';
import { resourceService } from 'services/api/resource.service';
import { useCampus } from 'shared-state/directory/hooks';
import { useIdentityClaims, useLocalProfile } from 'shared-state/identity/hooks';
import { IProfile } from 'shared-state/identity/types';
import { useCurrentCampusId, useCurrentOrgId } from 'shared-state/location/hook';

export interface ICampusSettingsState {
    currentUser?: IProfile;
    successModal: boolean;
    setSuccessModal: React.Dispatch<React.SetStateAction<boolean>>;
    isFormModalOpen: boolean;
    showFormModal: () => void;
    hideFormModal: () => void;
    titleId: string;
    colorButtonId: string;
    campus?: CampusDto;
    creator?: ICreator;
    ownerId: string;
    managers: IMember[];
    filteredManagers: IMember[];
    setFilteredManagers: React.Dispatch<React.SetStateAction<IMember[]>>;
    selectedManager: IMember[];
    setSelectedManager: React.Dispatch<React.SetStateAction<IMember[]>>;
    hideTransferOwnershipDialog: boolean;
    toggleHideTransferOwnershipDialog: () => void;
    hideTransferOwnershipConfirmationDialog: boolean;
    toggleHideTransferOwnershipConfirmationDialog: () => void;
    hideTransferOwnershipErrorDialog: boolean;
    toggleHideTransferOwnershipErrorDialog: () => void;
    onCampusFormChange: (key: string, newValue: any) => void;
    onUpdateCampus?: (campusId: string, changes: IChanges) => Promise<any | undefined>;
    fetchManagers: () => void;
    filterManagers: (text: string) => void;
    selectManagerUsingName: (text: string) => void;
    selectManagerUsingEmail: (text: string) => void;
    transferOwnership: () => void;
    //ask friedrich
    setChanges: any;
    changes: any;
    formFields: IDynamicFormElementProps[];
    stepsEnabled?: boolean;
    setStepsEnabled: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    isOpen: boolean;
    openPanel: () => void;
    dismissPanel: () => void;
    isDisable: boolean;
    disableButton: () => void;
    notDisableButton: () => void;
    theme: ITheme;
    fetchCampus: Function;
    microsoftProps: { microsoftTenantIsInvited: boolean; isMicrosoftUser: boolean; setMicrosoftTenantIsInvited: (isInvited: boolean) => void };
}

export interface ICreator {
    name: string;
    email: string;
    profile_pic: string;
    role: 'user' | 'manager' | 'owner';
}

export interface IChanges {
    name: string;
    color: string;
    logo: string;
}

export interface IMember {
    userId: string;
    profile_pic: string;
    name: string;
    email: string;
    role: 'user' | 'manager' | 'owner' | 'invited';
}

export interface IInvitee {
    email: string;
    role: 'user' | 'manager' | 'owner';
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCampusSettingsState = () => {
    const currentUser = useLocalProfile();
    const [successModal, setSuccessModal] = useState(false);
    const [isFormModalOpen, { setTrue: showFormModal, setFalse: hideFormModal }] = useBoolean(false);
    const titleId = useId('title');
    const colorButtonId = useId('callout-button');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [currentOrgId, setCurrentOrgId] = useCurrentOrgId();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [currentCampusId, setCurrentCampusId] = useCurrentCampusId();
    const [campus, setCampus] = useCampus();
    const [creator, setCreator] = useState<ICreator>();
    const [org, setOrg] = useState<OrganizationDto | undefined>();
    const [ownerId, setOwnerId] = useState<string>('');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [members, setMembers] = useState<IMember[]>([]);
    const [managers, setManagers] = useState<IMember[]>([]);
    const [filteredManagers, setFilteredManagers] = useState<IMember[]>([]);
    const [selectedManager, setSelectedManager] = useState<IMember[]>([]);
    const [hideTransferOwnershipDialog, { toggle: toggleHideTransferOwnershipDialog }] = useBoolean(true);
    const [hideTransferOwnershipConfirmationDialog, { toggle: toggleHideTransferOwnershipConfirmationDialog }] = useBoolean(true);
    const [hideTransferOwnershipErrorDialog, { toggle: toggleHideTransferOwnershipErrorDialog }] = useBoolean(true);
    const [idClaims] = useIdentityClaims();
    const [invitees, setInvitees] = useState<IInvitee[]>([]);
    const tenantIdEmail = idClaims?.msTenantId + '@microsoft.tenant';
    const isMicrosoftUser = idClaims?.msTenantId ? true : false;
    const microsoftTenantIsInvited = invitees.find((invitee) => invitee.email.includes(tenantIdEmail)) ? true : false;
    const [changes, setChanges] = useState<any>({});

    const [stepsEnabled, setStepsEnabled] = useState<boolean>();
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [isDisable, { setTrue: disableButton, setFalse: notDisableButton }] = useBoolean(true);
    const theme = useTheme();

    const setMicrosoftTenantIsInvited = async (isInvited: boolean) => {
        try {
            if (isInvited) {
                await idpService.newInvited(tenantIdEmail, 'user');
            } else {
                await idpService.deleteInvited(tenantIdEmail);
            }
        } catch (err) {
            console.log(err);
        }
        fetchUsers();
    };

    const fetchUsers = async () => {
        const fetchedMembers = await idpService.showMembers();
        const fetchedInvitees = await idpService.showOrgInvited();
        setMembers(fetchedMembers);
        setInvitees(fetchedInvitees);
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchCampus = async () => {
        if (!currentOrgId) return;
        const campusDTO = await resourceService.getCampusById(currentOrgId);
        if (!campusDTO) return;
        setCampus(campusDTO);
        setCurrentCampusId(campusDTO.id);
        const fetchedOrg = await idpService.showOrg();
        setOwnerId(fetchedOrg.creatorId);
        setOrg(fetchedOrg);
        const orgCreator = await idpService.showMemberProfile(fetchedOrg.creatorId);
        setCreator(orgCreator);
    };

    useEffect(() => {
        fetchCampus().catch((err: any) => {
            console.error(err);
        });
    }, [currentOrgId]);

    const onChange = (change: { [index: string]: string | boolean }) => {
        setChanges((prevState: any) => {
            const newState = { ...prevState, ...change };
            return newState;
        });
    };

    const onCampusFormChange = (key: string, newValue: any) => {
        onChange({ [key]: newValue });
        setFormFields((prevArray) => {
            return prevArray.map((el: any) => {
                if (el.key != key) return el;
                return { ...el, ...{ value: newValue } };
            });
        });
    };

    const onUpdateCampus = (campusId: string, changes: IChanges): Promise<any | undefined> => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return new Promise((resolve, reject) => {
            if (changes.name == '') return resolve(undefined);

            resourceService.updateCampus(campusId, { name: changes.name }).then((updatedCampus) => {
                setCampus(updatedCampus);
                idpService.updateOrg(changes.name, changes.logo, changes.color ? [changes.color] : undefined).then((updatedOrg) => {
                    setOrg(updatedOrg);

                    resolve(true);
                });
            });
        });
    };

    const fetchMembers = async () => {
        const fetchedMembers = await idpService.showMembers();
        setMembers(fetchedMembers);
    };

    const fetchManagers = async () => {
        const fetchedMembers = await idpService.showMembers();
        const allManagers = await fetchedMembers.filter((manager) => manager.role == 'manager');
        setManagers(allManagers);
    };

    const filterManagers = (text: string) => {
        const regex = new RegExp(text.toUpperCase());
        const result: any = managers.filter((manager) => regex.test(manager.name.toUpperCase()));
        setFilteredManagers(result);
    };

    const selectManagerUsingName = (text: string) => {
        const regex = new RegExp(text.toUpperCase());
        const result: any = managers.filter((manager) => regex.test(manager.name.toUpperCase()));
        setSelectedManager(result);
    };
    const selectManagerUsingEmail = (text: string) => {
        const regex = new RegExp(text.toUpperCase());
        const result: any = managers.filter((manager) => regex.test(manager.email.toUpperCase()));
        setSelectedManager(result);
    };

    const transferOwnership = async () => {
        if (selectedManager.length > 0) {
            idpService
                .transferOwnership(selectedManager[0].userId)
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .then((response) => {
                    if (currentOrgId) {
                        idpService.authUserToOrg(currentOrgId).then(() => fetchMembers());
                    }
                    fetchMembers();

                    toggleHideTransferOwnershipConfirmationDialog();
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch((error) => toggleHideTransferOwnershipErrorDialog());
        }
    };

    const formFieldsForCampus: (campus: any, org?: OrganizationDto) => IDynamicFormElementProps[] = (campus, org) => {
        return [
            { key: 'name', label: 'Campus name', value: campus?.name || '', type: 'text' },
            { key: 'color', label: 'Campus color', value: org?.orgColors[0] || '', type: 'color' },
            { key: 'logo', label: 'Campus logo', value: org?.orgLogo || '', type: 'image', view: 'settings' }
        ];
    };

    const [formFields, setFormFields] = useState<IDynamicFormElementProps[]>(formFieldsForCampus(campus, org));

    useEffect(() => {
        setFormFields(formFieldsForCampus(campus, org));
    }, [campus, org]);

    useEffect(() => {
        if (localStorage.getItem('intro-campus-settings') === 'false') {
            setStepsEnabled(false);
        } else if (localStorage.getItem('intro-campus-settings') === 'true') {
            setStepsEnabled(true);
        }
    }, []);

    return {
        currentUser,
        successModal,
        setSuccessModal,
        isFormModalOpen,
        showFormModal,
        hideFormModal,
        titleId,
        colorButtonId,
        campus,
        creator,
        ownerId,
        managers,
        filteredManagers,
        setFilteredManagers,
        selectedManager,
        setSelectedManager,
        hideTransferOwnershipDialog,
        toggleHideTransferOwnershipDialog,
        hideTransferOwnershipConfirmationDialog,
        toggleHideTransferOwnershipConfirmationDialog,
        hideTransferOwnershipErrorDialog,
        toggleHideTransferOwnershipErrorDialog,
        isMicrosoftUser,
        microsoftTenantIsInvited,
        setMicrosoftTenantIsInvited,
        onCampusFormChange,
        onUpdateCampus,
        fetchManagers,
        filterManagers,
        selectManagerUsingName,
        selectManagerUsingEmail,
        transferOwnership,
        setChanges,
        changes,
        formFields,
        stepsEnabled,
        setStepsEnabled,
        isOpen,
        openPanel,
        dismissPanel,
        isDisable,
        disableButton,
        notDisableButton,
        theme,
        fetchCampus
    };
};
