import * as React from 'react';

function SvgWindowClose(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M5 5v22h22V5H5zm2 2h18v18H7V7zm4.688 3.313l-1.407 1.406L14.562 16l-4.343 4.344 1.406 1.406 4.344-4.344 4.312 4.313 1.407-1.407L17.375 16l4.25-4.25-1.406-1.406-4.25 4.25-4.281-4.281z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgWindowClose;
