import { ISpeakerStatsSpeaker } from 'features/Rooms/SpeakerStats/SpeakerStats.state';
import { IResource } from 'kits/apiKit3/legacy';
import { useEffect, useState } from 'react';
import { useShowAttachmentPanel } from 'shared-state/display-options/hooks';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { useCurrentRoomId } from 'shared-state/location/hook';
import { useUsersInRoom } from 'shared-state/presence/hooks';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCallState = (roomName: string | undefined, room: IResource | undefined, jitsiAPI: any, callEventListeners: any) => {
    const [addOnIsVisible, setAddOnIsVisible] = useState(false);
    const [activeAddOn, setActiveAddOn] = useState<string | undefined>();
    const [statsAreVisible, setStatsAreVisible] = useState(false);

    const [cameraIsMuted, setCameraIsMuted] = useState(false);

    const [micIsMuted, setMicIsMuted] = useState(false);

    const [sharingIsOn, setSharingIsOn] = useState(false);

    const [isConnected, setIsConnected] = useState(false);

    const [tileViewIsEnabled, setTileViewIsEnabled] = useState(false);

    const [encryptionIsEnabled, setEncryptionIsEnabled] = useState(false);

    const [showDeviceSettings, setShowDeviceSettings] = useState(false);

    const [stats, setStats] = useState<ISpeakerStatsSpeaker[]>([]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isAttachmentPanelVisible, setIsAttachmentPanelVisible] = useShowAttachmentPanel();

    const localUser = useLocalProfile();

    const [roomId] = useCurrentRoomId();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const usersInRoom = useUsersInRoom(roomId || '');

    useEffect(() => {
        setIsAttachmentPanelVisible(false);
        setActiveAddOn(undefined);
    }, [roomName]);

    const toggleEncryption = (enabled: boolean) => {
        jitsiAPI._transport.sendEvent({
            data: [enabled],
            name: 'toggle-e2ee'
        });
        setEncryptionIsEnabled(enabled);
    };

    const executeCommand = (command: string, data?: any) => {
        if (command == 'toggleE2ee') {
            const nextEncryptionState = !encryptionIsEnabled;
            //sendData({ type: 'encryptionInfo', enabled: nextEncryptionState });
            toggleEncryption(nextEncryptionState);
            return;
        }
        if (command == 'toggleAddOn-excalidraw') {
            setAddOnIsVisible((prev) => !prev);
            setActiveAddOn('excalidraw');
            return;
        }
        if (command == 'toggleAddOn-etherpad') {
            setAddOnIsVisible((prev) => !prev);
            setActiveAddOn('etherpad');
            return;
        }
        if (command == 'toggleAddOn-chess') {
            setAddOnIsVisible((prev) => !prev);
            setActiveAddOn('chess');
            return;
        }
        if (command == 'toggleAddOn-tictactoe') {
            setAddOnIsVisible((prev) => !prev);
            setActiveAddOn('tictactoe');
            return;
        }
        if (command == 'toggleAttachmentPanel') {
            setIsAttachmentPanelVisible((panelWasVisible) => !panelWasVisible);
            return;
        }
        if (command == 'toggleStats') {
            jitsiAPI.startCollectSpeakerStats(1200);

            setStatsAreVisible((prevVal) => !prevVal);
            return;
        }
        if (command == 'toggleDeviceSettings') return setShowDeviceSettings((prev) => !prev);
        if (command == 'setRoomBackground') {
            jitsiAPI.setBackgroundImage(data, '');
            return;
        }

        jitsiAPI.executeCommand(command, data);
    };

    useEffect(() => {
        jitsiAPI && room && room.attributes.backgroundImageURL && executeCommand('setRoomBackground', room.attributes.backgroundImageURL);
    }, [room, jitsiAPI]);

    const defaultEventListeners = [
        {
            eventName: 'screenSharingStatusChanged',
            handler: (e: any) => {
                setSharingIsOn(e.on);
            }
        },
        {
            eventName: 'audioMuteStatusChanged',
            handler: (e: any) => {
                setMicIsMuted(e.muted);
            }
        },
        {
            eventName: 'tileViewChanged',
            handler: (e: any) => {
                setTileViewIsEnabled(e.enabled);
            }
        },
        {
            eventName: 'videoMuteStatusChanged',
            handler: (e: any) => {
                setCameraIsMuted(e.muted);
            }
        },
        {
            eventName: 'videoConferenceJoined',
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            handler: (e: any) => {
                executeCommand('toggleTileView');
                room && room.attributes.backgroundImageURL && executeCommand('setRoomBackground', room.attributes.backgroundImageURL);
                setIsConnected(true);
                const numberOfParticipants = jitsiAPI?.getNumberOfParticipants();
                localUser?.profileImage && executeCommand('avatarUrl', localUser.profileImage);

                if (room && !room.id.includes('personal') && !room.id.includes('visitor')) {
                    setIsAttachmentPanelVisible(numberOfParticipants < 2);
                }
            }
        },
        {
            eventName: 'participantJoined',
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            handler: (e: any) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const numberOfParticipants = jitsiAPI?.getNumberOfParticipants();
            }
        },
        {
            eventName: 'videoConferenceLeft',
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            handler: (e: any) => {
                setIsConnected(false);
            }
        },
        {
            eventName: 'speakerStatsUpdated',
            handler: (e: any) => onStats(e.speakerData)
        }
    ];

    const onStats = (speakerData: any) => {
        const time = Date.now();

        const calculateActiveDominantSpeakerSpeakingTime = (userInfo: { _dominantSpeakerStart: number }) => {
            if (userInfo._dominantSpeakerStart > 0 && userInfo._dominantSpeakerStart < time) {
                return time - userInfo._dominantSpeakerStart;
            } else return 0;
        };

        const removeMeExpressionFromName = (userInfo: { displayName: string }) => {
            if (userInfo.displayName.endsWith(' (Me)')) {
                return userInfo.displayName.substring(0, userInfo.displayName.indexOf(' (Me)'));
            } else return userInfo.displayName;
        };

        const newSpeakerData = Object.keys(speakerData).map((userIdKey) => {
            return {
                userId: userIdKey,
                userName: removeMeExpressionFromName(speakerData[userIdKey]),
                speakerTime: speakerData[userIdKey].totalDominantSpeakerTime + calculateActiveDominantSpeakerSpeakingTime(speakerData[userIdKey])
            };
        });

        const noDuplicates = removeDuplicates(newSpeakerData);

        setStats(noDuplicates);
    };

    const removeDuplicates = (speakers: any) => {
        const singleNames = [];
        const cleanSpeakerStats = [];

        for (let i = 0; i <= speakers.length; i++) {
            for (let j = 0; j <= speakers.length; j++) {
                if (speakers[i] != undefined && speakers[j] != undefined) {
                    if (speakers[i].userName == speakers[j].userName) {
                        singleNames.indexOf(speakers[i].userName) === -1 ? singleNames.push(speakers[i].userName) : null;
                    }
                }
            }
        }
        for (let i = 0; i <= singleNames.length; i++) {
            if (singleNames[i] != null) {
                cleanSpeakerStats[i] = { userId: '', userName: singleNames[i], speakerTime: 0 };
            }
        }
        for (let i = 0; i <= cleanSpeakerStats.length; i++) {
            for (let j = 0; j <= speakers.length; j++) {
                if (speakers[j] != undefined && cleanSpeakerStats[i] != undefined) {
                    if (cleanSpeakerStats[i].userName == speakers[j].userName) {
                        cleanSpeakerStats[i].speakerTime = cleanSpeakerStats[i].speakerTime + speakers[j].speakerTime;
                    }
                }
            }
        }
        return cleanSpeakerStats;
    };

    const eventListeners = callEventListeners ? [...defaultEventListeners, ...callEventListeners] : defaultEventListeners;

    return {
        executeCommand,
        eventListeners,
        addOnIsVisible,
        activeAddOn,
        cameraIsMuted,
        micIsMuted,
        sharingIsOn,
        isConnected,
        tileViewIsEnabled,
        encryptionIsEnabled,
        stats,
        showDeviceSettings,
        statsAreVisible
    };
};
