import { InvitationDto } from 'client-api-wrapper/lib/identity_provider/responses.dto';
import React, { useState, useEffect } from 'react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { idpService } from 'services/api/identity-provider.service';
import { useCurrentAreaId, useCurrentCampusId } from 'shared-state/location/hook';

export interface ILinkManagerState {
    modalIsOpen: boolean;
    onModalDismiss?: Function;
    setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onModalSubmit?: Function;
    newestLink?: string;
    invitationList: { key: string; url: string; note: string; onDelete: () => Promise<void> }[];
    isCalloutVisible: boolean;
    toggleIsCalloutVisible: () => void;
    buttonId: string;
}

export const useLinkManagerState: () => ILinkManagerState = () => {
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const buttonId = useId('callout-button');
    const [areaId] = useCurrentAreaId();
    const [campusId] = useCurrentCampusId();

    const [invitations, setInvitations] = useState<InvitationDto[]>([]);

    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

    const [newestLink, setNewestLink] = useState<string | undefined>();

    const refetchInvitations = async () => {
        const nextInvitations: any = await idpService.showUserInvitationsList();

        if (!nextInvitations) return;

        setInvitations(nextInvitations.invitations);
    };

    const onModalSubmit = async (desc: string) => {
        if (!areaId || !campusId) return;

        const nextInvitation = await idpService.createInvitation(desc, areaId, campusId);

        if (!nextInvitation) throw new Error('Error occured');

        setNewestLink(window.location.origin + '/i/' + nextInvitation.invitationId);
        refetchInvitations();
    };

    const onModalDismiss = () => {
        setModalIsOpen(false);
        setNewestLink(undefined);
    };

    const invitationList = invitations
        ? invitations
              .filter((invitation) => invitation.areaId == areaId)
              .map((invitation) => {
                  return {
                      key: invitation.invitationId,
                      url: window.location.origin + '/i/' + invitation.invitationId,
                      note: invitation.note,
                      onDelete: async () => {
                          const isSure = confirm('Are you sure you want to delete this invitation link?');
                          if (!isSure) return;
                          await idpService.deleteInvitation(invitation.invitationId);
                          refetchInvitations();
                      }
                  };
              })
        : [];

    useEffect(() => {
        refetchInvitations();
    }, []);

    return {
        modalIsOpen,
        setModalIsOpen,
        newestLink,
        onModalSubmit,
        onModalDismiss,
        invitationList,
        isCalloutVisible,
        toggleIsCalloutVisible,
        buttonId
    };
};
