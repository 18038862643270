/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { aAreaList, aCampus, aOrgBranding, aRoomList, aRoomListStyles, aSelectedVisitor } from 'shared-state/directory/atoms';
import { sArea, sPersonalRoomList, sRoom, sStyledRoomList } from 'shared-state/directory/selectors';

export const useSelectedVisitor = () => {
    return useRecoilState(aSelectedVisitor);
};

export const useRoomList = () => {
    return useRecoilState(aRoomList);
};
export const useRoomListStyles = () => {
    return useRecoilState(aRoomListStyles);
};
export const useStyledRoomList = () => {
    return useRecoilValue(sStyledRoomList);
};
export const usePersonalRoomList = () => {
    return useRecoilValue(sPersonalRoomList);
};

export const useRoom = (roomId: string) => {
    return useRecoilState(sRoom(roomId));
};

export const useCampus = () => useRecoilState(aCampus);
export const useAreaList = () => useRecoilState(aAreaList);
export const useArea = (areaId?: string) => {
    return useRecoilState(sArea(areaId));
};
export const useCurrentOrgBranding = () => {
    return useRecoilState(aOrgBranding);
};
