import IFrame from 'components/iFrameView';
import { sendEvent } from 'kits/eventKit';
import { getIdpUrl } from 'kits/urlKit';
import { Modal } from 'office-ui-fabric-react';
import React from 'react';
const ProfileEditingModal: React.FC<{ isOpen?: boolean; onDismiss?: Function }> = ({ isOpen, onDismiss }) => {
    return (
        <>
            {isOpen && (
                <Modal isOpen>
                    <div style={{ minHeight: 250, height: 500 }}>
                        <IFrame
                            shareToken="IKnowWhatIamDoing"
                            url={getIdpUrl('/edit-profile')}
                            onMessage={(msg) => {
                                if (msg.eventName == 'saga:end' && msg.eventData?.sagaId == 'profileImage') {
                                    onDismiss && onDismiss();
                                    sendEvent('refetchUserDetails', {});
                                }
                            }}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};

export default ProfileEditingModal;
