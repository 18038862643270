import React from 'react';
import IFrame from 'components/iFrameView';
import OutsideWrapper from 'features/Visitors/Entrance/OutsideWrapper/OutsideWrapper';
import { handleMessage } from 'kits/messageBusKit';
import { getIdpUrl } from 'kits/urlKit';
import { useHistory } from 'react-router';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const InstallWizard = () => {
    const routeHistory = useHistory();
    return (
        <OutsideWrapper>
            <IFrame
                isHidden={false}
                onMessage={(res) => {
                    handleMessage(res, routeHistory);
                }}
                url={getIdpUrl('/register')}
            />
        </OutsideWrapper>
    );
};

export default InstallWizard;
