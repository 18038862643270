/* eslint-disable @typescript-eslint/no-unused-vars */
import { DefaultEffects, getTheme, mergeStyles, Stack, Text } from '@fluentui/react';
import 'intro.js/introjs.css';
import IvicosColors from 'kits/colorKit';
import React from 'react';
import 'styles/helpers.css';

const FirefoxAlert: React.FC = () => {
    const actionBarStyles = mergeStyles({
        backgroundColor: IvicosColors.Campusorange,
        transition: 'opacity 0.25s ease-in-out',
        width: 'auto',
        boxShadow: DefaultEffects.elevation16,
        padding: '8px 16px'
    });

    return (
        <Stack className={actionBarStyles} horizontal horizontalAlign="center">
            <Text>Firefox Screen Sharing: Please click anywhere on the video frame below before attempting to share your screen</Text>
        </Stack>
    );
};

export default FirefoxAlert;
