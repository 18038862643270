import React from 'react';
import { useAttachmentsPanelState } from './AttachmentPanel.state';
import AttachmentsPanelView from './AttachmentPanel.view';

const AttachmentsPanel: React.FC = () => {
    const state = useAttachmentsPanelState();

    return (
        <AttachmentsPanelView
            {...{
                ...state
            }}
        ></AttachmentsPanelView>
    );
};
export default AttachmentsPanel;
