import * as React from 'react';

function SvgQuestionCircle(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 16.5a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm0-11.75c-1.963 0-3.75 1.725-3.75 3.75a.75.75 0 001.5 0c0-1.184 1.104-2.25 2.25-2.25s2.25 1.066 2.25 2.25c0 .879-.241 1.385-.932 2.076l-.155.151-.57.528c-.945.903-1.343 1.596-1.343 2.745a.75.75 0 001.5 0c0-.648.213-1.028.889-1.67l.164-.153.204-.187.197-.184c1.08-1.032 1.546-1.875 1.546-3.306 0-2.025-1.787-3.75-3.75-3.75z"
                fill="#212121"
            />
        </svg>
    );
}

export default SvgQuestionCircle;
