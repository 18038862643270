import React from 'react';
import { Stack, Text, mergeStyles } from '@fluentui/react';
import { Announce, Back, ChevronLeft, Close, Menu } from 'kits/IconKit2';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { sendEvent } from 'kits/eventKit';
import { getClassNames } from 'styles/responsiveness';
import { ISideBarState } from 'features/SideBar/SideBar.state';
import { SideBarIconButton } from 'features/SideBar/children/SideBarIconButton';
import { ISideBarProps } from 'features/SideBar/SideBar';
import { useTheme } from '@fluentui/react-theme-provider';

const SideBarView: React.FC<ISideBarState & ISideBarProps> = (props) => {
    const theme = useTheme();

    const {
        children,
        onRenderTabIcons,
        mobileMenuOpen,
        isMaximized,
        toggleSidebar,
        isConnected,
        openAnnouncementDialog,
        goBackToDashboard,
        sidebarTitle
    } = props;

    const { smallDevice } = useWindowWidth();

    const rootStyles = mergeStyles([
        {
            height: '100%',
            userSelect: 'none',
            padding: '0px 0px 0px 16px',
            backgroundColor: 'transparent'
        },
        isMaximized && {
            width: 360
        }
    ]);

    const { sideBarView } = getClassNames();

    return (
        <Stack key="sidebar-x" horizontalAlign="stretch" className={smallDevice ? sideBarView : rootStyles} style={mobileMenuOpen ? {} : { display: 'none' }}>
            <Stack horizontal tokens={{ childrenGap: 8 }} style={{ marginBottom: 8, marginTop: 16 }} verticalAlign="center">
                {isMaximized && (
                    <>
                        <SideBarIconButton title="Back to dashboard" onClick={() => goBackToDashboard()}>
                            <Back />
                            {/*  <ReturnToAreas />*/}
                        </SideBarIconButton>
                        <Stack grow>
                            <Text variant="xLargePlus" style={{ color: theme.palette.black }}>
                                {sidebarTitle}
                            </Text>
                        </Stack>
                        <SideBarIconButton title="Announce something..." onClick={() => openAnnouncementDialog()}>
                            <Announce />
                        </SideBarIconButton>
                    </>
                )}
                {!smallDevice ? (
                    <SideBarIconButton title="Toggle Panel" onClick={() => toggleSidebar()}>
                        {isMaximized ? <ChevronLeft /> : <Menu />}
                    </SideBarIconButton>
                ) : (
                    <SideBarIconButton title="Toggle Panel" onClick={() => sendEvent('closeMobileRoomsPanel', {})}>
                        <Close />
                    </SideBarIconButton>
                )}
            </Stack>
            <Stack
                key="sb-content"
                grow
                style={{
                    opacity: isConnected ? 1 : 0.5,
                    display: isMaximized ? 'flex' : 'none',
                    overflowY: 'hidden',
                    paddingRight: 10
                }}
            >
                {children}
            </Stack>
            {!isMaximized && <Stack grow />}
            <Stack
                key="sb-footer"
                tokens={{ childrenGap: 16 }}
                horizontal={isMaximized}
                disableShrink={true}
                horizontalAlign="space-around"
                style={{
                    overflow: 'hidden',
                    padding: '16px 0px',
                    minHeight: 64
                }}
            >
                {onRenderTabIcons && onRenderTabIcons()}
            </Stack>
        </Stack>
    );
};
export default SideBarView;
