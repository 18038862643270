import { useLocalProfile } from 'shared-state/identity/hooks';
import { IProfile } from 'shared-state/identity/types';
import { useCurrentAreaId } from 'shared-state/location/hook';
import { useVisitorList } from 'shared-state/presence/hooks';

export interface IVisitorsTabControllerState {
    localProfile?: IProfile;
    visitors: IProfile[];
}

export const useVisitorsTabControllerState: () => IVisitorsTabControllerState = () => {
    const [areaId] = useCurrentAreaId();

    const localProfile = useLocalProfile();

    const [visitors] = useVisitorList(areaId || '');

    return {
        localProfile,
        visitors
    };
};
