/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const idpBase = process.env.REACT_APP_IDP_URL;
export const getIdpUrl = (path: string) => {
    return idpBase + path;
};

const apiGatewayBase = process.env.REACT_APP_GATEWAY_URL;
export const getApiGatewayUrl = (path: string, backendBranch: string) => {
    if (apiGatewayBase?.endsWith('a')) {
        return `${apiGatewayBase}/${path}/${backendBranch}`;
    } else {
        return `${apiGatewayBase}/${path}`;
    }
};
