import { useVisitorPresenceChannelHandlers } from 'adapters/realtime-adapter/handlers';
import { useRealtimeChannel } from 'adapters/realtime-adapter/hooks';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const VisitorsChannel: React.FC<{ areaId: string }> = ({ areaId }) => {
    const visitorPresenceChannelHandlers = useVisitorPresenceChannelHandlers();
    useRealtimeChannel('visitor', visitorPresenceChannelHandlers);
    return <></>;
};
export default VisitorsChannel;
