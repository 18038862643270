/* eslint-disable no-empty-pattern */
import React from 'react';
import { Stack, TooltipHost } from '@fluentui/react';
import { Star, StarFilled } from 'kits/IconKit2';
import AreaCard from 'features/Areas/AreaCard/AreaCard';
import { IC } from 'components/SVGIcon';
import IvicosStrings from 'kits/stringKit';
import { IResource } from 'kits/apiKit3/legacy';

export interface IAreaCardControllerViewProps {
    vertical?: boolean;
    area: IResource & { isStared: boolean };
    fluid?: boolean;
    onSelect(e: any): void;
    onStar(a: IResource, isStared: boolean): void;
    //ask friedrich
    msTeam: any;
    setMsTeam: any;
    msToken: any;
    dispatchUnauthorizedEvent: any;
    panelHeader: string;
    setPanelHeader: React.Dispatch<React.SetStateAction<string>>;
    isPanelOpen: boolean;
    openPanel: () => void;
    dismissPanel: () => void;
    campusId: string;
    areaId: string;
    deleteAreaHandler: (areaId: string) => void;
    areaName: string;
    setAreaName: React.Dispatch<React.SetStateAction<string>>;
    saveDataForAreaDeletion: any;
    modalIsOpen: boolean;
    setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setAreaToEdit: any;
}

const AreaCardControllerView: React.FC<IAreaCardControllerViewProps> = ({
    vertical,
    area,
    fluid,
    onSelect,
    onStar,
    msTeam,
    panelHeader,
    setPanelHeader,
    isPanelOpen,
    openPanel,
    dismissPanel,
    campusId,
    areaId,
    deleteAreaHandler,
    areaName,
    setAreaName,
    saveDataForAreaDeletion,
    modalIsOpen,
    setModalIsOpen,
    setAreaToEdit
}) => {
    const renderAreaActions = () => {
        return (
            <TooltipHost content={area.isStared ? IvicosStrings.removeAreaFromTabBarActionTitle : IvicosStrings.addAreaToTabBarActionTitle}>
                <Stack
                    className="pressable--light"
                    onClick={() => {
                        onStar(area, !area.isStared);
                    }}
                >
                    <IC variant="light" size={24}>
                        {area.isStared ? <StarFilled /> : <Star />}
                    </IC>
                </Stack>
            </TooltipHost>
        );
    };
    return (
        <AreaCard
            title={area.attributes.displayName}
            description={area.attributes.desc}
            isPublic={area.attributes.variant == 'DEFAULT'}
            team={msTeam}
            fluid={fluid}
            onClick={(e) => {
                onSelect(e);
            }}
            setAreaToEdit={(areaName: string, areaId: string) => {
                setAreaToEdit(areaName, areaId);
            }}
            onRenderActions={renderAreaActions}
            panelHeader={panelHeader}
            setPanelHeader={setPanelHeader}
            isPanelOpen={isPanelOpen}
            openPanel={openPanel}
            dismissPanel={dismissPanel}
            areaId={areaId}
            deleteAreaHandler={deleteAreaHandler}
            campusId={campusId}
            areaName={areaName}
            setAreaName={setAreaName}
            saveDataForAreaDeletion={saveDataForAreaDeletion}
            modalIsOpen={modalIsOpen}
            setModalIsOpen={setModalIsOpen}
            vertical={vertical}
        />
    );
};

export default AreaCardControllerView;
