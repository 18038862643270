import { useIdentityPanelState } from 'features/IdentityPanel/IdentityPanel.state';
import React from 'react';
import IdentityPanelView from './IdentityPanel.view';

const IdentityPanel: React.FC<{}> = () => {
    const state = useIdentityPanelState();

    return <IdentityPanelView {...state} />;
};

export default IdentityPanel;
