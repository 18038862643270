import { InvitationDto } from 'client-api-wrapper/lib/identity_provider/responses.dto';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { idpService } from 'services/api/identity-provider.service';
import { useCurrentOrgId } from 'shared-state/location/hook';

export interface IVisitorsEntryState {
    visitorsEntryURL: '';
    invitationId: string;
    routeHistory: any;
    orgId?: string;
    invitation?: InvitationDto;
}

interface IVisitorsEntryRouteParams {
    invitationid: string;
}

export const useVisitorsEntryState: () => IVisitorsEntryState = () => {
    const visitorsEntryURL = '';

    const params = useParams<IVisitorsEntryRouteParams>();
    const routeHistory = useHistory();

    const invitationId = params.invitationid;

    const [invitation, setInvitation] = useState<InvitationDto | undefined>();

    const [orgId, setOrgId] = useCurrentOrgId();

    const fetchInvitation = async () => {
        try {
            const nextInvitation = await idpService.showInvitation(invitationId);

            setInvitation(nextInvitation);

            setOrgId(nextInvitation.orgId);
        } catch (err) {
            routeHistory.push('/error/404');
        }
    };
    useEffect(() => {
        fetchInvitation();
    }, [invitationId]);

    return {
        visitorsEntryURL,
        invitationId,
        routeHistory,
        orgId,
        invitation
    };
};
