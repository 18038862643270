import * as React from 'react';

function SvgChess(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M2 2v24h15v-2h-3v-5H9v5H4v-5h5v-5H4V9h5V4h5v5h5V4h5v5h-5v2h7V2H2zm12 7H9v5h5V9zm0 5v5h2v-5h-2zm4-1v8h2.611l-1.486 4.459L18 26.586V30h12v-3.414l-1.125-1.125L27.387 21H30v-8H18zm2 2h1v2h2v-2h2v2h2v-2h1v4h-8v-4zm2.72 6h2.56l1.845 5.541.168.166.707.707V28h-8v-.586l.875-.873L22.721 21z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgChess;
