import React from 'react';

import { Stack, getTheme } from '@fluentui/react';
import { IResource } from 'kits/apiKit3/legacy';

import TopBar from 'features/Header/children/TopBar/TopBar';

export interface IOutsideWrapperProps {
    headerActions?: Array<any>;
    campus?: IResource;
}

const theme = getTheme();

// change?

const OutsideWrapper: React.FC<IOutsideWrapperProps> = ({ headerActions, children }) => {
    const rootStyle: React.CSSProperties = {
        backgroundColor: theme.palette.neutralLight,
        height: '100vh',
        width: '100vw'
    };

    return (
        <Stack style={rootStyle}>
            <TopBar key="topbar" links={headerActions} />

            <Stack
                key="content"
                className="js-o-w"
                grow
                horizontal
                horizontalAlign="center"
                verticalAlign="stretch"
                style={{ height: 'calc( 100vh - 64px )', minHeight: 'calc( 100vh - 64px )' }}
            >
                {children}
            </Stack>
        </Stack>
    );
};

export default OutsideWrapper;
