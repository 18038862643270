import { ITopBarLink, useTopBarState } from 'features/Header/children/TopBar/TopBar.state';
import TopBarView from 'features/Header/children/TopBar/TopBar.view';

import React from 'react';

export interface ITopBarStyles {
    textColor?: string;
    backgroundColor?: string;
    barLogo?: string;
}

const TopBar: React.FC<{ title?: string; links?: ITopBarLink[] }> = (props) => {
    const state = useTopBarState();

    return <TopBarView {...{ ...state, ...{ barTitle: props.title || state.barTitle, links: props.links || state.links } }}>{props.children}</TopBarView>;
};

export default TopBar;
