import { Label, List, Stack, Text, TextField } from '@fluentui/react';
import { useTheme } from '@fluentui/react-theme-provider';
import CreateNewRoomButton from 'features/Rooms/RoomList/CreateNewRoomButton/CreateNewRoomButton';
import { DragNDropContext } from 'features/Rooms/RoomList/DragNDropContext/DragNDropContext';
import ListedRoom from 'features/Rooms/RoomList/ListedRoom/ListedRoom';
import RoomSettings from 'features/Rooms/RoomList/RoomSettings/RoomSettings';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import IvicosStrings from 'kits/stringKit';
import React, { ReactElement, useRef } from 'react';
import { ResizableBox } from 'react-resizable';
import 'styles/resize-handle.css';
import { useContainerWidth } from './helpers';

export interface IindexViewProps {
    localProfile: any;
    personalRoomIds: string[];
    showCreateRoomButton?: boolean;
    commonRoomIds: string[];
    stepsEnabled: boolean | undefined;
    setStepsEnabled: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    filterPersonalRoom: (searchInput: any) => void;
    filterPersonalRoomIds: string[];
    searchInput: string;
    steps: any;
    options: {
        doneLabel: string;
        hideNext: boolean;
        showStepNumbers: boolean;
        showBullets: boolean;
        hidePrev: boolean;
    };
}

const IndexView: React.FC<IindexViewProps> = (props) => {
    const theme = useTheme();

    const onRenderCell = (roomId: string | undefined): ReactElement => {
        if (!roomId) return <></>;
        return <ListedRoom roomId={roomId} />;
    };

    const personalRoomContainerRef = useRef<HTMLDivElement>(null);
    const containerWidth = useContainerWidth(personalRoomContainerRef);

    return (
        <Stack style={{ maxHeight: '100%' }}>
            <DragNDropContext>
                <RoomSettings />
                <Steps
                    enabled={props.stepsEnabled}
                    steps={props.steps.steps}
                    initialStep={props.steps.initialStep}
                    options={props.options}
                    onExit={() => props.setStepsEnabled(false)}
                    onStart={() => {
                        {
                            localStorage.setItem('intro-office', 'false');
                        }
                    }}
                />
                <Stack style={{ maxWidth: 500, maxHeight: '100%' }}>
                    <Stack>
                        {props.personalRoomIds.length > 0 && (
                            <div ref={personalRoomContainerRef} style={{ width: '100%' }}>
                                <ResizableBox
                                    width={containerWidth || 320}
                                    height={460}
                                    axis="y"
                                    maxConstraints={[500, window.innerHeight - 240]}
                                    minConstraints={[200, 120]}
                                    resizeHandles={['s']}
                                >
                                    <div style={{ height: '100%', position: 'relative' }}>
                                        <div
                                            style={{
                                                background: `linear-gradient(0deg,${theme.palette.neutralLight},${theme.palette.neutralLight},transparent)`,
                                                height: 32,
                                                minHeight: 16,
                                                zIndex: 20,
                                                position: 'absolute',
                                                bottom: 0,
                                                pointerEvents: 'none',
                                                width: '100%'
                                            }}
                                        ></div>

                                        <Stack className={'personal-room'} style={{ height: 'calc( 100% - 16px )' }}>
                                            <Label>{IvicosStrings.personalRoomsSectionTitle}</Label>
                                            <Stack style={{ marginTop: 10, marginBottom: 10 }}>
                                                <TextField
                                                    iconProps={{ iconName: 'search', styles: { root: { color: theme.semanticColors.bodyText } } }}
                                                    placeholder="Filter users"
                                                    type="text"
                                                    onChange={(e: any) => props.filterPersonalRoom(e.target.value)}
                                                />
                                            </Stack>
                                            <Stack style={{ direction: 'ltr', overflowY: 'auto' }}>
                                                <List
                                                    items={props.searchInput.length > 0 ? props.filterPersonalRoomIds : props.personalRoomIds}
                                                    onRenderCell={onRenderCell}
                                                />
                                            </Stack>
                                        </Stack>
                                    </div>
                                </ResizableBox>
                            </div>
                        )}
                    </Stack>
                    <Label>{IvicosStrings.commonRoomsSectionTitle}</Label>
                    <Stack className={'other-rooms'} style={{ position: 'relative', overflow: 'hidden' }}>
                        <Stack style={{ overflowY: 'auto', height: '100%' }}>
                            {props.commonRoomIds.length <= 0 ? (
                                <Stack className="padding_vertical" horizontalAlign="center">
                                    <Text variant="small">{IvicosStrings.commonRoomsSectionEmptyNoticeTitle}</Text>
                                </Stack>
                            ) : (
                                <Stack style={{ direction: 'ltr', paddingBottom: 22 }}>
                                    <List items={props.commonRoomIds} onRenderCell={onRenderCell} />
                                </Stack>
                            )}
                        </Stack>
                        <div
                            style={{
                                background: `linear-gradient(0deg,${theme.palette.neutralLight},${theme.palette.neutralLight},transparent)`,
                                height: 32,
                                minHeight: 16,
                                pointerEvents: 'none',
                                zIndex: 20,
                                position: 'absolute',
                                bottom: 0,
                                width: '100%'
                            }}
                        ></div>
                    </Stack>
                    <Stack>{props.showCreateRoomButton && <CreateNewRoomButton />}</Stack>
                </Stack>
            </DragNDropContext>
        </Stack>
    );
};

export default IndexView;
