import { ComboBox, IComboBox, IComboBoxOption, IComboBoxStyles, Stack } from '@fluentui/react';
import { ITextFieldStyles, TextField } from '@fluentui/react/lib/TextField';
import React from 'react';
import { IImportedPersonProps } from './ImportedPerson';

const ImportedPersonView: React.FC<IImportedPersonProps> = (props) => {
    const { indexInCSVDATA, email, role, onChangeImportedPersonData, validateEmail, validateRole } = props;

    const validateEmailMessage = (value: string) => {
        return validateEmail(value) ? '' : 'Invalid email address';
    };
    const validateRoleMessage = validateRole(role) ? undefined : role + ' is not valid, please select user or manager';

    const onImportedUserRoleChange = React.useCallback(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (event: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined, value?: string | undefined): void => {
            if (option) onChangeImportedPersonData(indexInCSVDATA, 1)(event, option.text);
        },
        []
    );

    const textFieldStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 300 } };

    const options: IComboBoxOption[] = [
        { key: 'user', text: 'user' },
        { key: 'manager', text: 'manager' }
    ];
    const comboBoxStyles: Partial<IComboBoxStyles> = { root: { maxWidth: 120 } };

    return (
        <Stack horizontal={true}>
            <TextField
                label={'Email'}
                onChange={onChangeImportedPersonData(indexInCSVDATA, 0)}
                onGetErrorMessage={validateEmailMessage}
                placeholder={'Email address'}
                value={email}
                className={'invite'}
                validateOnLoad={true}
                readOnly={false}
                styles={textFieldStyles}
            />
            <ComboBox
                label={'Role'}
                options={options}
                selectedKey={role}
                text={role}
                errorMessage={validateRoleMessage}
                allowFreeform
                onChange={onImportedUserRoleChange}
                styles={comboBoxStyles}
            />
        </Stack>
    );
};

export default ImportedPersonView;
