/* eslint-disable react/display-name */
import React, { useState } from 'react';

export interface IRoomListMoreButtonState {
    setHovering: React.Dispatch<React.SetStateAction<Boolean>>;
}

export const useRoomListMoreButtonState: () => IRoomListMoreButtonState = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isHovering, setHovering] = useState<Boolean>(false);
    return { setHovering };
};
