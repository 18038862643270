import * as React from 'react';

function SvgEncryptedRoom(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M6 3v26h12v3h14V22h-3v-2c0-2.2-1.8-4-4-4s-4 1.8-4 4v2h-3v5H8V5h16v9h2V3H6zm19 15c1.1 0 2 .9 2 2v2h-4v-2c0-1.1.9-2 2-2zm-5 6h10v6H20v-6z"
                fill="#000"
            />
            <circle cx={11.5} cy={15.5} r={1.5} fill="#000" />
        </svg>
    );
}

export default SvgEncryptedRoom;
