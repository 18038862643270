/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useRecoilState } from 'recoil';
import { aCurrentAreaId, aCurrentCampusId, aCurrentOrgId, aCurrentRoomId, aSelectedAttachment } from 'shared-state/location/atoms';

export const useSelectedAttachment = () => {
    return useRecoilState(aSelectedAttachment);
};
export const useCurrentRoomId = () => {
    return useRecoilState(aCurrentRoomId);
};
export const useCurrentAreaId = () => {
    return useRecoilState(aCurrentAreaId);
};
export const useCurrentCampusId = () => {
    return useRecoilState(aCurrentCampusId);
};
export const useCurrentOrgId = () => {
    return useRecoilState(aCurrentOrgId);
};
