/* eslint-disable @typescript-eslint/no-unused-vars */
import { getTheme, Stack } from '@fluentui/react';
import TopBar from 'features/Header/children/TopBar/TopBar';
import IvicosColors from 'kits/colorKit';
import React from 'react';

export const UITest: React.FC<{}> = ({ children }) => {
    const theme = getTheme();

    return (
        <>
            <TopBar title="Experimental features" />
            <Stack grow horizontal verticalAlign="stretch" style={{ maxWidth: '100%', overflow: 'hidden', paddingTop: 16 }}>
                <Stack grow>{/* <ManageAccess /> */}</Stack>
                <Stack.Item shrink={0}>
                    <div style={{ background: IvicosColors.Grau100, width: 1, minHeight: '90%', marginTop: 32, marginBottom: 32 }} />
                </Stack.Item>
            </Stack>
        </>
    );
};
