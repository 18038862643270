import { mergeStyles, Stack } from '@fluentui/react';
import NotificationBanner from 'features/NotificationsLayer/NotificationsBanner/NotificationBanner';
import React from 'react';
import { IReceivedMessage } from 'shared-state/notifications/types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NotificationsLayerView: React.FC<{ displayHint: string; receivedMessages: IReceivedMessage[] }> = ({ displayHint, receivedMessages, children }) => {
    const rootLayerStyles = mergeStyles([
        {
            position: 'fixed',
            width: 360,
            right: 26,
            zIndex: 303030
        },
        displayHint == 'TOP'
            ? {
                  top: 48,
                  bottom: 'auto'
              }
            : {
                  top: 'auto',
                  bottom: 26
              }
    ]);
    return (
        <Stack className={rootLayerStyles} tokens={{ childrenGap: 8 }}>
            {receivedMessages
                .filter((m) => !m.dismissed)
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .map((message, messageIndex) => (
                    <NotificationBanner messageId={message.id} key={message.id} />
                ))}
        </Stack>
    );
};

export default NotificationsLayerView;
