import React from 'react';
import { DefaultEffects, getTheme, mergeStyles, Stack, Text } from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { ICallActionButtonProps } from './CallActionButton';

const CallActionButtonView: React.FC<ICallActionButtonProps> = ({ action, onPressed }) => {
    const theme = getTheme();

    const iconButtonStyles = mergeStyles({
        borderRadius: 4,
        padding: 8,
        position: 'relative'
    });
    const actionLabelStyles = mergeStyles([
        {
            backgroundColor: theme.palette.white,
            borderRadius: 4,
            pointerEvents: 'none',
            position: 'absolute',
            bottom: -16,
            width: 'auto',
            whiteSpace: 'nowrap',
            padding: '4px 8px',
            boxShadow: DefaultEffects.elevation16,
            right: 34,
            color: theme.palette.black
        }
    ]);
    const { smallDevice } = useWindowWidth();

    return (
        <Stack
            className={iconButtonStyles + ' pressable hoverable'}
            key={action.key}
            onClick={() => onPressed(action)}
            verticalAlign="center"
            horizontalAlign="center"
        >
            {!smallDevice && (
                <Stack className={actionLabelStyles + ' display--on-parent-hover'}>
                    <Text>{action.title}</Text>
                </Stack>
            )}
            <IC variant={action.iconVariant || 'dark'} size={24}>
                {action.icon}
            </IC>
        </Stack>
    );
};

export default CallActionButtonView;
