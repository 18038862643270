/* eslint-disable @typescript-eslint/no-unused-vars */
import { DefaultButton, getTheme, Stack, Text } from '@fluentui/react';
import { SharedColors } from '@uifabric/fluent-theme';
import 'App.css';
import DirectedToWelcome from 'routes/helpers/DirectedToWelcome';
import ProtectedRoute from 'routes/helpers/ProtectedRoute';
import TopBar from 'features/Header/children/TopBar/TopBar';
import IconKit from 'kits/IconKit';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import Core from 'routes/Core/Core';
import Dashboard from 'routes/Dashboard/Dashboard';
import Flags from 'routes/Flags/Flags';
import InstallWizard from 'routes/InstallWizard/InstallWizard';
import LoginPage from 'routes/LoginPage/LoginPage';
import Main from 'routes/Main/Main';
import Mobile from 'routes/Mobile/Mobile';
import ResetWizard from 'routes/ResetWizard/ResetWizard';
import { UITest } from 'routes/UITest';
import VisitorsEntry from 'routes/VisitorsEntry/VisitorsEntry';
import WaitingArea from 'routes/WaitingArea/WaitingArea';
import { isSafari } from 'react-device-detect';
import SafariAlert from 'features/SafariAlert/SafariAlert';

const theme = getTheme();

initializeIcons(/* optional base url */);

function inIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

interface IAppProps {}

const App: React.FC<IAppProps> = ({ children }) => {
    return (
        <Router>
            <Switch>
                {/* Login */}
                <Route path="/login/:domainHint/as/:loginHint">
                    <LoginPage intent="login"></LoginPage>
                </Route>
                <Route path="/login/:domainHint">
                    <LoginPage intent="login"></LoginPage>
                </Route>
                <DirectedToWelcome path="/login">
                    <LoginPage intent="login"></LoginPage>
                </DirectedToWelcome>
                <Route path="/reset-password">
                    <LoginPage intent="resetPassword"></LoginPage>
                </Route>
                <ProtectedRoute path="/switch">
                    <LoginPage intent="switchOrgs"></LoginPage>
                </ProtectedRoute>
                <ProtectedRoute path="/welcome">
                    <LoginPage intent="switchOrgs"></LoginPage>
                </ProtectedRoute>
                <ProtectedRoute path="/invitedivcampus">
                    <LoginPage intent="answerInvitation"></LoginPage>
                </ProtectedRoute>

                {/* Visitors */}
                <Route path="/i/:invitationid">
                    <VisitorsEntry />
                </Route>
                <Route path="/w/:invitationid">
                    <WaitingArea />
                </Route>

                {/* In case someone needs to clear their cache */}
                <Route path="/reset">
                    <ResetWizard />
                </Route>

                {/* Flags */}
                <Route path="/experimental">
                    <Flags />
                </Route>

                {/* Mobile Feature (Sketch) */}
                <Route path="/mobile/:room/:jwt">
                    <Mobile />
                </Route>

                {/* UITesting */}
                <ProtectedRoute path="/ui-test">
                    <UITest />
                </ProtectedRoute>

                {/* Install wizard */}
                <Route path="/register">
                    <InstallWizard />
                </Route>

                {/* Errors */}
                <Route path="/error/409">
                    <DisconnectScreen
                        title="You logged into the same area twice"
                        description="You were automatically disconnected in this tab, because you logged into the same area from somewhere else. You can only log into the same area with the same account from one tab."
                    />
                </Route>
                <Route path="/error/403">
                    <DisconnectScreen title="Your session expired" description="Your have been automatically logged out" clearSessionOnReconnect />
                </Route>
                <Route path="/error/404">
                    <DisconnectScreen
                        title="The resource you have requested could not be found"
                        description="Your have been automatically logged out"
                        clearSessionOnReconnect
                    />
                </Route>
                <Route path="/error/500">
                    <DisconnectScreen
                        title="An unexpected error has occured"
                        description="You were automatically disconnected because an unexpected error occured. Please contact your account manager or info@ivicos.eu"
                    />
                </Route>
                <Route path="/error">
                    <DisconnectScreen />
                </Route>

                {/* Portal */}
                <ProtectedRoute path="/rooms">
                    <Redirect to="/" />
                </ProtectedRoute>
                <ProtectedRoute path="/areas/:areaId/rooms/:roomId">
                    <Core>
                        <Main />
                    </Core>
                </ProtectedRoute>
                <ProtectedRoute path="/areas/:areaId">
                    <Core>
                        <Main />
                    </Core>
                </ProtectedRoute>
                <ProtectedRoute path="/areas">
                    <Core>
                        <Dashboard />
                    </Core>
                </ProtectedRoute>
                <ProtectedRoute path="/dashboard/:tabKey/:actionKey">
                    <Core>
                        <Dashboard />
                    </Core>
                </ProtectedRoute>
                <ProtectedRoute path="/dashboard/:tabKey">
                    <Core>
                        <Dashboard />
                    </Core>
                </ProtectedRoute>

                <ProtectedRoute path="/">
                    <Core>
                        <Dashboard />
                    </Core>
                </ProtectedRoute>
            </Switch>
        </Router>
    );
};

export default App;

interface IDisconnectScreenProps {
    title?: string;
    description?: string;
    clearSessionOnReconnect?: boolean;
}

export const DisconnectScreen: React.FC<IDisconnectScreenProps> = ({ title, description, clearSessionOnReconnect }) => {
    const disconnectRootStyle = {
        height: '100vh',
        width: '100vw',
        background: SharedColors.cyan20,
        color: theme.palette.white
    };

    const disconnectRootProps = {
        ...{
            horizontalAlign: 'center',
            verticalAlign: 'center',
            vertical: true,
            style: disconnectRootStyle
        }
    };

    return (
        <Stack style={{ height: '100vh', width: '100vw' }}>
            <TopBar title="Error" />
            <Stack grow horizontalAlign="center" verticalAlign="center">
                <Stack horizontal verticalAlign="center" style={{ marginBottom: 32, maxWidth: 560 }}>
                    <Stack.Item grow>
                        <IconKit.Icon icon={IconKit.IconSet.xLarge.Disconnected} size={128} style={{ marginRight: 32 }} />
                    </Stack.Item>
                    <Stack>
                        <Text variant="xxLargePlus" style={{ marginBottom: 8 }}>
                            {title || 'Connection lost'}
                        </Text>
                        <Text variant="large">
                            {description || (
                                <>
                                    The client lost connection to the server. <br />
                                    This can have multiple reasons.
                                </>
                            )}
                        </Text>
                    </Stack>
                </Stack>
                <Stack style={{ width: '100%', maxWidth: 560 }} horizontalAlign="end">
                    <DefaultButton
                        onClick={() => {
                            clearSessionOnReconnect && sessionStorage.clear();
                            clearSessionOnReconnect && localStorage.clear();
                            window.location.href = '/login';
                        }}
                    >
                        Reconnect
                    </DefaultButton>
                </Stack>
            </Stack>
        </Stack>
    );
};
