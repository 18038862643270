/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import { useVisitorsEntryState } from './VisitorsEntry.state';
import VisitorsEntryView from './VisitorsEntry.view';

const VisitorsEntry = () => {
    const state = useVisitorsEntryState();

    return (
        <VisitorsEntryView
            {...{
                ...state,
                ...{
                    iFrameURL: state.visitorsEntryURL,
                    invitationid: state.invitationId,
                    onSuccess: () => {
                        state.routeHistory.push('/w/' + state.invitationId);
                    }
                }
            }}
        />
    );
};

export default VisitorsEntry;
