import { useState } from 'react';
import IvicosStrings from 'kits/stringKit';

export interface ICopyButtonState {
    status: number;
    tooltipContentByStatus: {
        [index: number]: string;
    };
    copyValueToClipboard: () => void;
}

export const useCopyButtonState: (value: string) => ICopyButtonState = (value) => {
    const [status, setStatus] = useState(1);

    const tooltipContentByStatus: { [index: number]: string } = {
        0: IvicosStrings.copyButtonErrorLabel,
        1: IvicosStrings.copyButtonLabel,
        2: IvicosStrings.copyButtonSuccessLabel
    };

    const copyValueToClipboard = () => {
        navigator.clipboard
            .writeText(value)
            .then(() => {
                setStatus(2);
                setTimeout(() => setStatus(1), 1500);
            })
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .catch((err) => {
                setStatus(0);
                setTimeout(() => setStatus(1), 1500);
            });
    };

    return {
        status,
        tooltipContentByStatus,
        copyValueToClipboard
    };
};
