import { Panel, PanelType } from '@fluentui/react';
import React from 'react';
import AreaList from 'features/Areas/AreaList/AreaList';
import { useTheme } from '@fluentui/react-theme-provider';

const AreasPanelView: React.FC<{ showPanel: any; setShowPanel: any }> = ({ showPanel, setShowPanel }) => {
    const theme = useTheme();
    return (
        <Panel
            styles={{ main: { background: theme.palette.neutralLight } }}
            type={PanelType.customNear}
            customWidth={'420px'}
            isOpen={showPanel}
            isLightDismiss
            onDismiss={() => setShowPanel(false)}
        >
            <AreaList vertical onSelection={() => setShowPanel(false)} />
        </Panel>
    );
};

export default AreasPanelView;
