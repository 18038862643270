import { atom, atomFamily } from 'recoil';
import { useLocalOptionsAtomArrayEffects, useLocalOptionsAtomEffects } from 'shared-state/display-options/effects';
import { getOptionArrayLocally } from 'shared-state/display-options/helpers';
import { INotificationPositionOption } from './types';

export const aSideBarIsMaximized = atom<boolean>({ key: 'aSideBarIsMaximized', default: true });
export const aShowAreaPanel = atom<boolean>({ key: 'aShowAreaPanel', default: false });

export const aHelpModalIsOpen = atom<boolean>({ key: 'aHelpModalIsOpen', default: false });

export const aShowAttachmentPanel = atom<boolean>({ key: 'aShowAttachmentPanel', default: false });
export const aNotificationPosition = atom<INotificationPositionOption>({ key: 'aNotificationPosition', default: 'BOTTOM' });
export const aShowVisitorRoomSelectionDialog = atom<boolean>({ key: 'aShowVisitorRoomSelectionDialog', default: false });

export const aRoomSettingsRoomId = atom<string>({ key: 'aRoomSettingsRoomId', default: '' });
export const aShouldUseLocalAvailability = atom<string | undefined>({
    key: 'aShouldUseLocalAvailability',
    default: 'YES',
    effects_UNSTABLE: useLocalOptionsAtomEffects('shouldUseLocalAvailability')
});

export const aStaredAreas = atomFamily<string[], string>({
    key: 'aStaredAreas',
    default: (tid: string) => getOptionArrayLocally('tid:' + tid + ':' + 'staredAreas'),
    effects_UNSTABLE: useLocalOptionsAtomArrayEffects('staredAreas')
});
