import React, { useEffect, useState } from 'react';

export interface IConnectivityModalState {
    shouldShowError: boolean;
    isOnline: boolean;
}

export const useConnectivityModalState: () => IConnectivityModalState = () => {
    const [isOnline, setIsOnline] = useState(true);
    const [shouldShowError, setShouldShowError] = useState(false);

    const onOnline = () => {
        setIsOnline(true);
    };
    const onOffline = () => {
        setShouldShowError(true);
        setIsOnline(false);
    };
    useEffect(() => {
        window.addEventListener('online', onOnline);
        window.addEventListener('offline', onOffline);

        return () => {
            window.removeEventListener('online', onOnline);
            window.removeEventListener('offline', onOffline);
        };
    }, []);

    return { shouldShowError, isOnline };
};
