import { SideBarIconButton } from 'features/SideBar/children/SideBarIconButton';
import { ISideBarTabIconState } from 'features/SideBar/children/SideBarTabIcon/SidBarTabIcon.state';
import React from 'react';

export const SideBarTabIconView: React.FC<ISideBarTabIconState> = (props) => {
    const { children, isSelected, iconTitle, onIconClick } = props;

    const iconVariant = isSelected ? 'primary' : 'dark';

    return (
        <SideBarIconButton title={iconTitle} variant={iconVariant} onClick={onIconClick}>
            {children}
        </SideBarIconButton>
    );
};
