/* eslint-disable @typescript-eslint/no-unused-vars */
import { ITheme, Label, Stack, Text } from '@fluentui/react';
import LinkManager from 'features/Visitors/UserTab/LinkManager/LinkManager';
import IvicosStrings from 'kits/stringKit';
import React from 'react';
import { SetterOrUpdater } from 'recoil';
import Visitor from '../Visitor/Visitor';

export interface IVisitorsTabProps {
    featuredVisitors: Array<any>;
    visitors: Array<any>;
    routeHistory: any;
    theme: ITheme;
    areaId?: string;
    selectedVisitor?: string;
    setShowRoomSelectionDialog: SetterOrUpdater<boolean>;
}

const VisitorsTabView: React.FC<IVisitorsTabProps> = (props) => {
    const { featuredVisitors, visitors, routeHistory, theme, areaId, selectedVisitor, setShowRoomSelectionDialog } = props;
    const noVisitors = (m: any) => (
        <Stack style={{ padding: '8px 16px', color: theme.palette.black }}>
            <Text variant="small">{m || 'No visitors in this area.'}</Text>
        </Stack>
    );

    const renderVisitors = (vlist: any) =>
        vlist.map((v: any) => (
            <Visitor
                key={v.uid}
                selected={v.uid == selectedVisitor ? true : false}
                displayName={v.displayName}
                label={v.authedAt && 'Arrived at ' + new Date(v.authedAt).getHours() + ':' + new Date(v.authedAt).getMinutes()}
                action={
                    selectedVisitor == v.uid
                        ? {
                              key: 'letthemin',
                              title: 'Let them in',
                              action: (e: any) => {
                                  setShowRoomSelectionDialog(true);
                              }
                          }
                        : {
                              key: 'meet',
                              title: IvicosStrings.meetVisitorActionTitle,
                              action: (e: any) => {
                                  routeHistory.push('/areas/' + areaId + '/rooms/visitor-' + v.uid);
                              }
                          }
                }
            />
        ));

    return (
        <>
            <Text variant="xxLargePlus" style={{ marginTop: 16, marginBottom: 16, color: theme.palette.black }}>
                Visitors
            </Text>
            <Stack>
                <Stack style={{ marginBottom: 16, background: theme.palette.white, color: theme.palette.black, padding: '16px 16px' }}>
                    <LinkManager />
                </Stack>
                <Stack style={{ padding: '8px 16px' }}>
                    <Label style={{ marginBottom: 8 }}>{IvicosStrings.yourVisitorsSectionTitle}</Label>
                </Stack>

                {featuredVisitors && featuredVisitors.length > 0 ? (
                    <>{renderVisitors(featuredVisitors)}</>
                ) : (
                    noVisitors(IvicosStrings.yourVisitorsSectionEmptyNotice)
                )}
                <Stack style={{ marginTop: 16, padding: '8px 16px' }}>
                    <Label style={{ marginBottom: 8 }}>{IvicosStrings.allVisitorsSectionTitle}</Label>
                </Stack>
                {visitors && visitors.length > 0 ? <>{renderVisitors(visitors)}</> : noVisitors(IvicosStrings.allVisitorsSectionEmpyNotice)}
            </Stack>
        </>
    );
};

export default VisitorsTabView;
