/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import {
    aHelpModalIsOpen,
    aNotificationPosition,
    aRoomSettingsRoomId,
    aShouldUseLocalAvailability,
    aShowAreaPanel,
    aShowAttachmentPanel,
    aShowVisitorRoomSelectionDialog,
    aSideBarIsMaximized,
    aStaredAreas
} from 'shared-state/display-options/atoms';

export const useIsSideBarMaximized = () => {
    return useRecoilState(aSideBarIsMaximized);
};

export const useIsHelpModalOpen = () => {
    return useRecoilState(aHelpModalIsOpen);
};

export const useNotificationPosition = () => {
    return useRecoilState(aNotificationPosition);
};

export const useShowVisitorRoomSelectionDialog = () => {
    return useRecoilState(aShowVisitorRoomSelectionDialog);
};

export const useRoomSettingsRoomId = () => {
    return useRecoilState(aRoomSettingsRoomId);
};

export const useShouldUseLocalAvailability = () => {
    return useRecoilState(aShouldUseLocalAvailability);
};

export const useStaredAreas = (tenantId: string) => {
    return useRecoilState(aStaredAreas(tenantId));
};

export const useShowAreaPanel = () => {
    return useRecoilState(aShowAreaPanel);
};

export const useShowAttachmentPanel = () => {
    return useRecoilState(aShowAttachmentPanel);
};

export const useWindowWidth = () => {
    const [width, setWindowWidth] = useState(0);

    useEffect(() => {
        updateDimensions();

        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    const updateDimensions = () => {
        const width = window.innerWidth;
        setWindowWidth(width);
    };

    const responsive = {
        // smallDevice: width < 600,
        // mediumDevice: (width > 600 || width == 600) && width < 768,
        // largeDevice: (width > 768 || width == 768) && width < 992,
        // smallLaptop: (width > 992 || width == 992) && width < 1200,
        // largeLaptop: width > 1200 || width == 1200

        smallDevice: width < 600,
        mediumDevice: (width > 600 || width == 600) && width < 992,
        smallLaptop: (width > 992 || width == 992) && width < 1400,
        largeLaptop: width > 1400 || width == 1400
    };

    // responsive.smallDevice = responsive.smallDevice || responsive.mediumDevice

    return responsive;
};
