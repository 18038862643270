import { useEffect, useState } from 'react';

export type IJitsiDeviceType = 'audioInput' | 'videoInput' | 'audioOutput';

export interface IJitsiDevice {
    deviceId: string;
    label: string;
    groupId: string;
    kind: string;
}

export interface IJitsiDeviceList {
    audioInput: Array<IJitsiDevice>;
    audioOutput: Array<IJitsiDevice>;
    videoInput: Array<IJitsiDevice>;
}
export interface IJitsiDeviceConfig {
    audioInput: IJitsiDevice | undefined;
    audioOutput: IJitsiDevice | undefined;
    videoInput: IJitsiDevice | undefined;
}

export interface IDeviceSettingsControllerStateProps {
    availableDevices?: IJitsiDeviceList;
    currentDevices?: IJitsiDeviceConfig;
    onDeviceChange: (deviceType: IJitsiDeviceType, device: IJitsiDevice) => void;
}

export const useDeviceSettingsControllerState: (
    jitsiAPI: any,
    visible: boolean,
    onChange: (deviceType: string, device: IJitsiDevice) => void
) => IDeviceSettingsControllerStateProps = (jitsiAPI, visible, onChange) => {
    const [availableDevices, setAvailableDevices] = useState<IJitsiDeviceList>();
    const [currentDevices, setCurrentDevices] = useState<IJitsiDeviceConfig>();

    const updateCurrentDevices = () => {
        if (!jitsiAPI) return;

        jitsiAPI.getCurrentDevices().then((devices: IJitsiDeviceConfig) => {
            setCurrentDevices(devices);
        });
    };

    const updateDevices = () => {
        if (!jitsiAPI) return;

        updateCurrentDevices();
        jitsiAPI.getAvailableDevices().then((devices: IJitsiDeviceList) => setAvailableDevices(devices));
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onDeviceAvailabilityChanged = (e: any) => {
        updateDevices && updateDevices();
    };

    const onDeviceChange = (deviceType: IJitsiDeviceType, device: IJitsiDevice) => {
        if (jitsiAPI) {
            if (deviceType == 'audioInput') jitsiAPI.setAudioInputDevice(null, device.deviceId);
            if (deviceType == 'audioOutput') jitsiAPI.setAudioOutputDevice(null, device.deviceId);
            if (deviceType == 'videoInput') jitsiAPI.setVideoInputDevice(null, device.deviceId);
        }
        onChange(deviceType, device);
        updateCurrentDevices();
    };

    useEffect(() => {
        if (jitsiAPI) {
            jitsiAPI.addEventListener('audioAvailabilityChanged', onDeviceAvailabilityChanged);
            jitsiAPI.addEventListener('videoAvailabilityChanged', onDeviceAvailabilityChanged);
        }

        updateDevices();

        return () => {
            if (jitsiAPI) {
                jitsiAPI.removeEventListener('audioAvailabilityChanged', onDeviceAvailabilityChanged);
                jitsiAPI.removeEventListener('videoAvailabilityChanged', onDeviceAvailabilityChanged);
            }
        };
    }, [jitsiAPI]);

    useEffect(() => {
        updateDevices();
    }, [visible]);

    return {
        availableDevices,
        currentDevices,
        onDeviceChange
    };
};
