import { useNotificationPosition } from 'shared-state/display-options/hooks';
import { useReceivedMessages } from 'shared-state/notifications/hooks';
import { IReceivedMessage } from 'shared-state/notifications/types';

interface INotificationsLayerState {
    displayHint: string;
    receivedMessages: IReceivedMessage[];
}

export const useNotificationsLayerState: () => INotificationsLayerState = () => {
    const [displayHint] = useNotificationPosition();

    const [receivedMessages] = useReceivedMessages();

    return { displayHint, receivedMessages };
};
