import React from 'react';
import { useVisitorsTabState } from './VisitorsTab.state';
import VisitorsTabView from './VisitorsTab.view';

export interface IVisitorsTabProps {
    featuredVisitors: Array<any>;
    visitors: Array<any>;
}

const VisitorsTab: React.FC<IVisitorsTabProps> = (props) => {
    const state = useVisitorsTabState();
    return (
        <VisitorsTabView
            {...{
                ...state,
                ...{
                    featuredVisitors: props.featuredVisitors,
                    visitors: props.visitors
                }
            }}
        ></VisitorsTabView>
    );
};

export default VisitorsTab;
