import { IPersonaProps, PersonaPresence } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { OrgBrandingDto } from 'client-api-wrapper/lib/identity_provider/responses.dto';
import { useOrganizationBranding } from 'features/Header/hooks';
import { availabilityToPersonaPresence } from 'kits/availabilityKit';
import IconKit from 'kits/IconKit';
import IvicosStrings from 'kits/stringKit';
import { ReactElement } from 'react';
import { useCampus } from 'shared-state/directory/hooks';
import { useIsHelpModalOpen } from 'shared-state/display-options/hooks';
import { useIdentityClaims, useLocalProfile, useSharedAvailability } from 'shared-state/identity/hooks';
import { IProfile } from 'shared-state/identity/types';
import { useCurrentOrgId } from 'shared-state/location/hook';

export interface ITopBarLink {
    title: string;
    key: string;
    action?: (e: any) => void;
    getSymbol?: () => ReactElement;
    symbol?: string;
}

export interface ITopBarState {
    barTitle: string;
    links: ITopBarLink[];
    branding: OrgBrandingDto | undefined;
    personaProps: IPersonaProps | undefined;
    localProfile: IProfile | undefined;
    variantLabel: string;
    isIdentityPanelOpen: boolean;
    openIdentityPanel: () => void;
    dismissIdentityPanel: () => void;
}

export const useTopBarState: () => ITopBarState = () => {
    const localProfile = useLocalProfile();

    const [campus] = useCampus();

    const [orgId] = useCurrentOrgId();
    const [branding] = useOrganizationBranding(orgId);
    const [, setIsHelpModalOpen] = useIsHelpModalOpen();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [localAvailability, setLocalAvailability] = useSharedAvailability();

    const variantLabel = window.location.origin.includes('canary') ? 'CANARY' : window.location.origin.includes('localhost') ? 'DEBUG' : 'ALPHA';

    const [identity] = useIdentityClaims();

    const [isIdentityPanelOpen, { setTrue: openIdentityPanel, setFalse: dismissIdentityPanel }] = useBoolean(false);

    const links = [
        /*   {
            key: 'experimental',
            title: IvicosStrings.experimentalLinkTitle,
            symbol: IconKit.IconSet.Small.Experimental,
            action: (e: any) => routeHistory.push('/experimental')
        },*/
        {
            key: 'help',
            title: IvicosStrings.helpLinkTitle,
            symbol: IconKit.IconSet.Small.Help,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: (e: any) => setIsHelpModalOpen(true)
        }
    ];

    const personaProps = identity
        ? {
              primaryText: identity?.displayName,
              imageUrl: identity?.profileImageURL,
              presence: (localAvailability && availabilityToPersonaPresence(localAvailability)) || PersonaPresence.online,
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              onClick: (e: any) => openIdentityPanel()
          }
        : undefined;

    const barTitle = campus?.name || branding?.orgName || '';
    return { localProfile, branding, links, barTitle, variantLabel, personaProps, isIdentityPanelOpen, openIdentityPanel, dismissIdentityPanel };
};
