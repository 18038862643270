import { AttachmentDto } from 'client-api-wrapper/lib/campus_api/responses.dto';
import React from 'react';
import { useAttachmentCreationModalState } from './AttachmentCreationModal.state';
import AttachmentCreationModalView from './AttachmentCreationModal.view';

export interface IAttachmentCreationModalProps {
    isOpen?: boolean;
    onDismiss?: Function;
    onAttachmentCreation?: (attachment?: AttachmentDto) => void;
}

const AttachmentCreationModal: React.FC<IAttachmentCreationModalProps> = (props) => {
    const state = useAttachmentCreationModalState(props.onDismiss, props.onAttachmentCreation);

    return (
        <AttachmentCreationModalView
            {...{
                ...state,
                ...{
                    isOpen: props.isOpen,
                    onDismiss: props.onDismiss
                }
            }}
        ></AttachmentCreationModalView>
    );
};
export default AttachmentCreationModal;
