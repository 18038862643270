import React from 'react';
import { DefaultButton, Persona, PersonaSize, Stack, Text } from '@fluentui/react';
import ProfileEditingModal from 'features/Header/children/HelpModal/ProfileEditingModal';
import { availabilityToPersonaPresence } from 'kits/availabilityKit';
import Badge from 'components/Badge';
import { IUserProfileState } from './UserProfile.State';

const UserProfileView: React.FC<IUserProfileState> = (props) => {
    const { localProfile, identityClaims, sharedAvailability, showProfileImageEditingPanel, setShowProfileImageEditingPanel } = props;
    return (
        <>
            <Stack style={{ padding: 16 }} tokens={{ childrenGap: 8 }}>
                {localProfile?.type == 'visitor' ? (
                    <>
                        {' '}
                        <Stack horizontal verticalAlign="center" style={{ padding: '10px' }}>
                            <Badge text={localProfile.displayName} size="81" fontSize="medium" />
                            <Stack verticalAlign="center" style={{ width: '200px', marginLeft: '15px' }}>
                                <Text variant="xLarge">{localProfile.displayName}</Text>
                                <Text>{identityClaims?.upn}</Text>
                            </Stack>
                        </Stack>
                    </>
                ) : (
                    <Persona
                        text={localProfile?.displayName}
                        secondaryText={identityClaims?.upn}
                        imageUrl={localProfile?.profileImage}
                        presence={sharedAvailability && availabilityToPersonaPresence(sharedAvailability)}
                        size={PersonaSize.size72}
                    ></Persona>
                )}

                <DefaultButton
                    key={'edit-profileImage'}
                    iconProps={{
                        iconName: 'EditContact'
                    }}
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    onClick={(e) => {
                        setShowProfileImageEditingPanel(true);
                    }}
                    disabled={localProfile?.type == 'visitor'}
                >
                    Update image
                </DefaultButton>
                <DefaultButton
                    key={'edit-profileEmail'}
                    iconProps={{
                        iconName: 'Edit'
                    }}
                    disabled
                >
                    Change email
                </DefaultButton>
            </Stack>

            <ProfileEditingModal isOpen={showProfileImageEditingPanel} onDismiss={() => setShowProfileImageEditingPanel(false)} />
        </>
    );
};

export default UserProfileView;
