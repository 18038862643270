import { useState } from 'react';
import { useIsHelpModalOpen } from 'shared-state/display-options/hooks';

export interface IHelpModalState {
    openHelpCentrePage: Function;
    openReportErrorPage: Function;
    resetTutorials: Function;
    refreshPage: Function;
    onHelpModalDismiss: Function;
    isHelpModalOpen: boolean;
    isTutorialResetDialogOpen: boolean;
}

export const useHelpModalState: () => IHelpModalState = () => {
    const [isHelpModalOpen, setIsHelpModalOpen] = useIsHelpModalOpen();
    const [isTutorialResetDialogOpen, setIsTutorialResetDialogOpen] = useState<boolean>(false);

    const refreshPage = () => {
        window.location.reload();
    };

    const onHelpModalDismiss = () => {
        setIsHelpModalOpen(false);
    };

    const resetTutorials = () => {
        localStorage.setItem('intro-areas', 'true');
        localStorage.setItem('intro-manage-access', 'true');
        localStorage.setItem('intro-campus-settings', 'true');
        localStorage.setItem('intro-office', 'true');
        alert('yeet');

        setIsHelpModalOpen(false);
        setIsTutorialResetDialogOpen(true);
    };

    const openHelpCentrePage = () => {
        const helpCentrePageUrl = 'https://help.ivicos.eu/en/HELP-ivcampus';
        window.open(helpCentrePageUrl, '_blank');
    };

    const openReportErrorPage = () => {
        const reportErrorUrl =
            'https://forms.office.com/Pages/ResponsePage.aspx?id=q-C0g-PdF0WW1jR6WQe0uu0em-JhFhVOtlzNnhWhiyFUMjNaSlgyNVdBNlkwTTQ4N1U5OEFSWUpCSS4u';
        window.open(reportErrorUrl, '_blank');
    };

    return {
        isHelpModalOpen,
        onHelpModalDismiss,
        resetTutorials,
        openHelpCentrePage,
        openReportErrorPage,
        refreshPage,
        isTutorialResetDialogOpen
    };
};
