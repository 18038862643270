import { useEffect, useMemo, useState } from 'react';
import { usePersonalRoomList, useStyledRoomList } from 'shared-state/directory/hooks';
import { useIdentityClaims, useLocalProfile } from 'shared-state/identity/hooks';
import { IIdentityClaims, IProfile } from 'shared-state/identity/types';
import { sortRoomList } from './helpers';

export interface IRoomListState {
    localProfile: IProfile | undefined;
    personalIds: string[];
    identityClaims: IIdentityClaims | undefined;
    commonIds: string[];
    stepsEnabled: boolean | undefined;
    setStepsEnabled: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    filterPersonalRoom: (searchInput: any) => void;
    filterPersonalRoomIds: string[];
    searchInput: string;
    steps: {
        stepsEnabled: boolean;
        initialStep: number;
        steps: (
            | {
                  element: string;
                  intro: string;
                  position?: undefined;
                  tooltipClass?: undefined;
              }
            | {
                  element: string;
                  intro: string;
                  position: string;
                  tooltipClass?: undefined;
              }
            | {}
        )[];
        hintsEnabled: boolean;
    };
    options: {
        doneLabel: string;
        hideNext: boolean;
        showStepNumbers: boolean;
        showBullets: boolean;
        hidePrev: boolean;
    };
}

export const useRoomListState: () => IRoomListState = () => {
    const commonRooms = useStyledRoomList();
    const personalRooms = usePersonalRoomList();

    const [identityClaims] = useIdentityClaims();
    const localProfile = useLocalProfile();

    const personalIds = useMemo(() => {
        return sortRoomList(personalRooms).map((r) => r.id);
    }, [personalRooms]);
    const commonIds = useMemo(() => {
        return sortRoomList(commonRooms).map((r) => r.id);
    }, [commonRooms]);

    const [stepsEnabled, setStepsEnabled] = useState<boolean>();
    const [searchInput, setSearchInput] = useState('');
    const [filteredPersonalRooms, setFilteredPersonalRooms] = useState([]);

    const filterPersonalRoom = (searchInput: any) => {
        setSearchInput(searchInput);
        const regex = new RegExp(searchInput.toUpperCase());
        const result: any = personalRooms.filter((personalRooms: any) => regex.test(personalRooms.attributes.displayName.toUpperCase()));
        setFilteredPersonalRooms(result);
    };

    const filterPersonalRoomIds = useMemo(() => {
        return sortRoomList(filteredPersonalRooms).map((r) => r.id);
    }, [filteredPersonalRooms]);

    useEffect(() => {
        if (localStorage.getItem('intro-office') === 'false') {
            setStepsEnabled(false);
        } else if (localStorage.getItem('intro-office') === 'true') {
            setStepsEnabled(true);
        }
    }, []);

    const steps = {
        stepsEnabled: true,
        initialStep: 0,
        steps: [
            {
                element: '.profile-picture',
                intro: 'This section contains all the information regarding your account at ivCAMPUS and lets you choose and set a personal profile image.'
            },
            {
                element: '.personal-room',
                intro: 'Every member has their own personal room when they enter an area. Hover over their names to reveal the communication options.',
                position: 'right'
            },
            {
                element: '.other-rooms',
                intro:
                    'Rooms are customisable and are perfect for meetings, workshops, or a space to chat. They have more features than your personal room, and can be deleted or archived as you please.',
                position: ''
            },
            {
                element: '.visitors',
                intro: 'Here you can invite visitors who are not part of your ivCAMPUS.',
                position: ''
            },
            {
                element: '.call-settings',
                intro: 'Use these icons to adjust the video and call settings.',
                position: '',
                tooltipClass: 'introjs-tooltip-settings'
            },
            {
                element: '.call-settings',
                intro: 'These icons allow you to access ivCAMPUS video features, like speaker stats.',
                position: '',
                tooltipClass: 'introjs-tooltip-video'
            },
            {
                element: '.call-settings',
                intro: 'You successfully completed the tutorial. Enjoy your ivCAMPUS!',
                position: '',
                tooltipClass: 'introjs-tooltip-target'
            }
        ],
        hintsEnabled: false
    };

    const options = {
        doneLabel: 'Ok!',
        hideNext: false,
        showStepNumbers: true,
        showBullets: false,
        hidePrev: true
    };

    return {
        localProfile,
        personalIds,
        identityClaims,
        commonIds,
        stepsEnabled,
        setStepsEnabled,
        filterPersonalRoom,
        filterPersonalRoomIds,
        searchInput,
        steps,
        options
    };
};
