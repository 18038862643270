import { Stack, getTheme } from '@fluentui/react';
import TopBar from 'features/Header/children/TopBar/TopBar';
import React from 'react';
import Jitsi from '@ivicos/react-jitsi';
import { ConfigOptions, InterfaceConfigOptions } from '@ivicos/react-jitsi/dist/types';
import { useCurrentRoomId } from 'shared-state/location/hook';

interface IMobileViewProps {
    params: any;
}
const theme = getTheme();

const MobileView: React.FC<IMobileViewProps> = ({ params }) => {
    const [currentRoomId] = useCurrentRoomId();

    const conferenceTimerEnabled = () => {
        if (currentRoomId?.includes('personal')) {
            return true;
        } else {
            return false;
        }
    };

    const jitsiConfig: ConfigOptions = {
        resolution: 480,
        constraints: {
            video: {
                height: {
                    ideal: 480,
                    max: 480,
                    min: 240
                }
            }
        },
        channelLastN: 4,
        enableLayerSuspension: true,
        disableAudioLevels: false,
        audioLevelsInterval: 200,
        hideConferenceTimer: conferenceTimerEnabled(),
        hideConferenceSubject: true
    };

    const interfaceConfig: InterfaceConfigOptions = {
        APP_NAME: 'Campus Alpha',
        SHOW_BRAND_WATERMARK: false,
        SHOW_POWERED_BY: false,
        SHOW_PROMOTIONAL_CLOSE_PAGE: false,
        DISABLE_VIDEO_BACKGROUND: true,
        DISABLE_FOCUS_INDICATOR: true,
        DEFAULT_REMOTE_DISPLAY_NAME: 'Fellow team member',
        DEFAULT_LOCAL_DISPLAY_NAME: 'me',
        SHOW_JITSI_WATERMARK: false,
        DEFAULT_BACKGROUND: theme.palette.neutralDark,
        SHOW_WATERMARK_FOR_GUESTS: false
    };

    const domainConfig = {
        //adress: 'meet.qsynx.com',
        // adress: 'meet.qvam.de'
        // @TODO remove debug thingy
        adress: localStorage.getItem('jitsiDomain') || 'ivicos-meet.app'
    };

    const containerStyle = {
        width: '100%',
        height: 'calc(100vh - 48px)',
        minHeight: 'calc(100vh - 48px)'
    };

    return (
        <Stack style={{ width: '100vw', height: '100vh', backgroundColor: theme.palette.neutralQuaternaryAlt }}>
            <TopBar title="Mobile" />
            <Stack grow horizontal verticalAlign="stretch" style={{ maxWidth: '100%', minHeight: 'calc(100vh - 48px)', overflow: 'hidden' }}>
                <Stack.Item grow>
                    {params.jwt && (
                        <Jitsi
                            containerStyle={containerStyle}
                            interfaceConfig={interfaceConfig}
                            config={jitsiConfig}
                            jwt={params.jwt}
                            roomName={params.room}
                            domain={domainConfig.adress}
                            displayName={'Mobile User'}
                        />
                    )}
                </Stack.Item>
            </Stack>
        </Stack>
    );
};

export default MobileView;
