import * as React from 'react';

function SvgSpeakerStatsBars(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit={2}
            width="1em"
            height="1em"
            {...props}
        >
            <path
                d="M1.953 1.988h28.094v7.34H1.953v-7.34zM4.12 4.155v3.006h23.76V4.155H4.12zM1.96 12.38h11.812v7.318H1.96V12.38zm2.168 2.168v2.983h7.477v-2.983H4.128zM1.962 22.656h8.282V30H1.962v-7.344zm2.167 2.166v3.011h3.948v-3.011H4.129z"
                fill="#212121"
            />
            <g>
                <path
                    d="M23.976 14.336l1.38-.83s2.915 4.112-.308 9.034l-1.38-.746s2.584-3.495.308-7.458zM26.98 12.563l1.396-.84s3.688 5.746-.381 12.402l-1.43-.77s3.678-4.933.415-10.792zM16.917 29.997l.056-3.587c.087-.246.236-1.577 2.419-1.65.345-.011 2.497.007 2.6 1.468l.04 3.765 1.773.02v-3.64s.184-3.426-4.467-3.62c0 0-3.883-.276-4.356 3.343V30l1.935-.003zM19.396 14.777a3.494 3.494 0 11-3.494 3.494 3.496 3.496 0 013.494-3.494zm-.007 2.006a1.494 1.494 0 110 2.989 1.494 1.494 0 010-2.989z"
                    fill="#212121"
                />
            </g>
        </svg>
    );
}

export default SvgSpeakerStatsBars;
