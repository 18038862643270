import { getTheme, ITheme } from '@fluentui/react';
import { AttachmentDto } from 'client-api-wrapper/lib/campus_api/responses.dto';
import { hardcodedAddOns } from 'features/Rooms/RoomView/addons';
import { useEffect, useState } from 'react';
import { SetterOrUpdater } from 'recoil';
import { resourceService } from 'services/api/resource.service';
import { useShowAttachmentPanel } from 'shared-state/display-options/hooks';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { IProfile } from 'shared-state/identity/types';
import { useCurrentAreaId, useCurrentCampusId, useCurrentRoomId, useSelectedAttachment } from 'shared-state/location/hook';
export interface IAttachmentsPanelState {
    campusId?: string;
    areaId?: string;
    roomId?: string;
    isAttachmentPanelVisible: boolean;
    theme: ITheme;
    setIsAttachmentPanelVisible: SetterOrUpdater<boolean>;
    isAttachmentCreationModalOpen: boolean;
    setIsAttachmentCreationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedAttachment: SetterOrUpdater<
        | {
              addOnId: string;
              ref: string;
              description: string;
          }
        | undefined
    >;
    refetchAttachments: () => Promise<void>;
    isAttachmentUpdateModalOpen: boolean;
    attachmentToEdit?: AttachmentDto;
    setIsAttachmentUpdateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setAttachmentToEdit: React.Dispatch<React.SetStateAction<AttachmentDto | undefined>>;
    filter?: string;
    setFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
    sortedAttachments: AttachmentDto[];
    setIsEditable: React.Dispatch<
        React.SetStateAction<{
            [key: string]: boolean;
        }>
    >;
    addOn: (
        addOnName: string
    ) =>
        | {
              id: string;
              type: string;
              attributes: {
                  urlTemplate: string;
                  iconUrl: string;
                  displayName: string;
                  description: string;
              };
              relationships: {};
          }
        | undefined;
    localProfile?: IProfile;
    isEditable: {
        [key: string]: boolean;
    };
    displayDescription: {
        [key: string]: boolean;
    };
    setDisplayDescription: React.Dispatch<
        React.SetStateAction<{
            [key: string]: boolean;
        }>
    >;
}

export const useAttachmentsPanelState: () => IAttachmentsPanelState = () => {
    const [attachments, setAttachments] = useState<AttachmentDto[]>([]);
    const [campusId] = useCurrentCampusId();
    const [areaId] = useCurrentAreaId();
    const [roomId] = useCurrentRoomId();
    const localProfile = useLocalProfile();
    const [isAttachmentPanelVisible, setIsAttachmentPanelVisible] = useShowAttachmentPanel();
    const [filter, setFilter] = useState<string | undefined>();
    const addOns = hardcodedAddOns;
    const [isEditable, setIsEditable] = useState<{ [key: string]: boolean }>({ no_count1: false }); // added {no_count1: false} to skip typescript warning
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selectedAttachment, setSelectedAttachment] = useSelectedAttachment();
    const [displayDescription, setDisplayDescription] = useState<{ [key: string]: boolean }>({ no_count1: false }); // added {no_count1: false} to skip typescript warning
    const [attachmentToEdit, setAttachmentToEdit] = useState<AttachmentDto | undefined>();

    const [isAttachmentCreationModalOpen, setIsAttachmentCreationModalOpen] = useState<boolean>(false);
    const [isAttachmentUpdateModalOpen, setIsAttachmentUpdateModalOpen] = useState<boolean>(false);

    useEffect(() => {
        refetchAttachments();
    }, [campusId, roomId, areaId, isAttachmentPanelVisible]);

    useEffect(() => {
        setSelectedAttachment(undefined);
    }, [campusId, roomId, areaId]);

    const refetchAttachments = async () => {
        if (!campusId || !areaId || !roomId) return;

        const newAttachments = await resourceService.getAllForRoom(campusId, areaId, roomId);
        setAttachments(newAttachments);

        const final: { [key: string]: boolean } = { no_count: false }; // added {no_count: false} to skip typescript warning
        attachments.map((att) => {
            final[att.id] = false;
        });
        setIsEditable(final);
        setDisplayDescription(final);
    };

    const theme = getTheme();
    const addOn = (addOnName: string) => addOns.find((addon) => addon.id == addOnName);

    const filteredAttachments = attachments.filter((item) => {
        if (!filter) return true;

        if (addOn(item.addOnName)?.attributes.displayName.includes(filter)) return true;
        return false;
    });
    const sortedAttachments = [...filteredAttachments].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

    return {
        campusId,
        areaId,
        roomId,
        isAttachmentPanelVisible,
        theme,
        setIsAttachmentPanelVisible,
        isAttachmentCreationModalOpen,
        setIsAttachmentCreationModalOpen,
        setSelectedAttachment,
        refetchAttachments,
        isAttachmentUpdateModalOpen,
        attachmentToEdit,
        setIsAttachmentUpdateModalOpen,
        setAttachmentToEdit,
        filter,
        setFilter,
        sortedAttachments,
        setIsEditable,
        addOn,
        localProfile,
        isEditable,
        displayDescription,
        setDisplayDescription
    };
};
