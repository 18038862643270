import * as React from 'react';

function SvgDoorOpen(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M15.875 4l-.094.031-8 1.875L7 6.094v20.25l.813.125 8 1.5.093.031H18V4h-2.125zM20 6v2h3v16h-3v2h5V6h-5zm-4 .031V26l-7-1.313V7.657l7-1.626zM14.344 15c-.367 0-.688.45-.688 1s.32 1 .688 1c.367 0 .656-.45.656-1s-.29-1-.656-1z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgDoorOpen;
