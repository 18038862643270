import { DefaultEffects, getTheme, ProgressIndicator, Stack } from '@fluentui/react';

import React from 'react';

const LoadingPage: React.FC<{ label: string; description: string }> = ({ label, description }) => {
    const theme = getTheme();

    const rootStyle = {
        width: '100vw',
        height: '100vh',
        background: theme.palette.neutralLight
    };

    const containterStyle = {
        width: 'auto',
        height: 'auto',
        maxWidth: 480,
        maxHeight: 560,
        borderRadius: 4,
        background: theme.palette.white,
        boxShadow: DefaultEffects.elevation8,
        padding: 16
    };

    return (
        <Stack style={rootStyle} horizontalAlign="center" verticalAlign="center">
            <Stack style={containterStyle} horizontalAlign="center" verticalAlign="center">
                <ProgressIndicator label={label || 'Loading...'} description={description} />
            </Stack>
        </Stack>
    );
};
export default LoadingPage;
