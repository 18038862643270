import * as React from 'react';

function SvgUpArrow(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M4.219 21.219L2.78 19.78l12.5-12.5.719-.687.719.687 12.5 12.5-1.438 1.438L16 9.437 4.219 21.22z" fill="#000" />
        </svg>
    );
}

export default SvgUpArrow;
