import { AttachmentDto } from 'client-api-wrapper/lib/campus_api/responses.dto';
import { useState } from 'react';
import { resourceService } from 'services/api/resource.service';
import { useCurrentCampusId, useCurrentAreaId, useCurrentRoomId } from 'shared-state/location/hook';

export interface IAttachmentUpdateModalState {
    updateAttachment: (event: any) => Promise<void>;
    titleHandler: (e: any) => void;
    attachmentTitle?: string;
    count: number;
    descriptionHandler: (e: any) => void;
    attachmentDescription?: string;
    count2: number;
    setCount: React.Dispatch<React.SetStateAction<number>>;
    setCount2: React.Dispatch<React.SetStateAction<number>>;
}

export const useAttachmentUpdateModalState: (
    onAttachmentUpdate?: (attachment?: AttachmentDto) => void,
    attachment?: AttachmentDto
) => IAttachmentUpdateModalState = (onAttachmentUpdate, attachment) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [count, setCount] = useState(attachment?.title ? attachment?.title.length : 0);
    const [count2, setCount2] = useState(attachment?.description ? attachment?.description.length : 0);

    const titleHandler = (e: any) => {
        setTitle(e.target.value);
        setCount(e.target.value.length);
    };

    const descriptionHandler = (e: any) => {
        setDescription(e.target.value);
        setCount2(e.target.value.length);
    };

    const [campusId] = useCurrentCampusId();
    const [areaId] = useCurrentAreaId();
    const [roomId] = useCurrentRoomId();
    const attachmentId = attachment?.id;
    const attachmentTitle = attachment?.title;
    const attachmentDescription = attachment?.description;

    const updateAttachment = async (event: any) => {
        event.preventDefault();
        if (!campusId || !areaId || !roomId || !attachmentId) return;
        const attachment = await resourceService.updateAttachment(campusId, areaId, roomId, attachmentId, {
            title: title ? title : attachmentTitle,
            description: description ? description : attachmentDescription
        });
        onAttachmentUpdate && onAttachmentUpdate(attachment);
        setCount(0);
        setCount2(0);
    };

    return {
        updateAttachment,
        titleHandler,
        attachmentTitle,
        count,
        descriptionHandler,
        attachmentDescription,
        count2,
        setCount,
        setCount2
    };
};
