import { getTheme, ITheme } from '@fluentui/react';
import { useHistory } from 'react-router-dom';
import { SetterOrUpdater } from 'recoil';
import { useSelectedVisitor } from 'shared-state/directory/hooks';
import { useShowVisitorRoomSelectionDialog } from 'shared-state/display-options/hooks';
import { useCurrentAreaId } from 'shared-state/location/hook';

export interface IVisitorsTabState {
    routeHistory: any;
    theme: ITheme;
    areaId?: string;
    selectedVisitor?: string;
    setShowRoomSelectionDialog: SetterOrUpdater<boolean>;
}

export const useVisitorsTabState: () => IVisitorsTabState = () => {
    const routeHistory = useHistory();
    const theme = getTheme();
    const [areaId] = useCurrentAreaId();
    const [selectedVisitor] = useSelectedVisitor();
    const [, setShowRoomSelectionDialog] = useShowVisitorRoomSelectionDialog();

    return { routeHistory, theme, areaId, selectedVisitor, setShowRoomSelectionDialog };
};
