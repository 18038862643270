/* eslint-disable no-empty-pattern */
import React, { useEffect, useState } from 'react';
import { sendEvent } from 'kits/eventKit';
import { useSession } from 'kits/sessionKit';
import { IResource } from 'kits/apiKit3/legacy';
import AreaCardControllerView from './AreaCardController.view';

interface IAreaCardControllerProps {
    vertical?: boolean;
    area: IResource & { isStared: boolean };
    onSelect(e: any): void;
    onStar(a: IResource, isStared: boolean): void;
    fluid?: boolean;
    panelHeader: string;
    setPanelHeader: React.Dispatch<React.SetStateAction<string>>;
    isPanelOpen: boolean;
    openPanel: () => void;
    dismissPanel: () => void;
    campusId: any;
    areaId: string;
    deleteAreaHandler: (areaId: string) => void;
    areaName: string;
    setAreaName: React.Dispatch<React.SetStateAction<string>>;
    saveDataForAreaDeletion: any;
    modalIsOpen: boolean;
    setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setAreaToEdit: any;
}

const AreaCardController: React.FC<IAreaCardControllerProps> = ({
    vertical,
    area,
    fluid,
    onSelect,
    onStar,
    panelHeader,
    setPanelHeader,
    isPanelOpen,
    openPanel,
    dismissPanel,
    campusId,
    areaId,
    deleteAreaHandler,
    areaName,
    setAreaName,
    saveDataForAreaDeletion,
    modalIsOpen,
    setModalIsOpen,
    setAreaToEdit
}) => {
    const [msTeam, setMsTeam] = useState<any>();
    const [msToken] = useSession('@msAccessToken');

    const dispatchUnauthorizedEvent = (data: any) => sendEvent('ms-unauthorized', data);

    useEffect(() => {
        if (area.attributes.variant == 'MSTEAM' && area.relationships.team) {
            const msTeamId = area.relationships.team;
            fetch(`https://graph.microsoft.com/v1.0/teams/${msTeamId}/`, {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + msToken
                })
            })
                .then((response: any) => {
                    if (!response.ok) {
                        response.status == 401 && dispatchUnauthorizedEvent({ response });
                        return false;
                    }

                    return response.json();
                })
                .then((teamData: any) => {
                    setMsTeam(teamData);
                })
                .catch((err) => console.log(err));
        }
    }, [area]);

    return (
        <AreaCardControllerView
            vertical={vertical}
            area={area}
            fluid={fluid}
            onSelect={onSelect}
            onStar={onStar}
            msTeam={msTeam}
            setMsTeam={setMsTeam}
            msToken={msToken}
            setAreaToEdit={setAreaToEdit}
            dispatchUnauthorizedEvent={dispatchUnauthorizedEvent}
            panelHeader={panelHeader}
            setPanelHeader={setPanelHeader}
            isPanelOpen={isPanelOpen}
            openPanel={openPanel}
            dismissPanel={dismissPanel}
            campusId={campusId}
            areaId={areaId}
            deleteAreaHandler={deleteAreaHandler}
            areaName={areaName}
            setAreaName={setAreaName}
            saveDataForAreaDeletion={saveDataForAreaDeletion}
            modalIsOpen={modalIsOpen}
            setModalIsOpen={setModalIsOpen}
        />
    );
};

export default AreaCardController;
