/* eslint-disable no-empty-pattern */
import { DefaultButton, Modal, Panel, PrimaryButton, ScrollablePane, Stack, Text, TextField } from '@fluentui/react';
import IvicosColors from 'kits/colorKit';
import React from 'react';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import { getClassNames } from 'styles/responsiveness';
import AreaCardController from '../AreaCard/AreaCardController/AreaCardController';
import { IResource } from 'kits/apiKit3/legacy';
import { IIdentityClaims } from 'shared-state/identity/types';
import { useTheme } from '@fluentui/react-theme-provider';

export interface IAreaListViewProps {
    areas: {
        isStared: boolean;
        id: string;
        type: string;
        attributes: any;
        relationships: any;
    }[];
    vertical?: boolean;
    panelHeader: string;
    modalIsOpen: boolean;
    onAreaSelected: (areaId: string) => void;
    staredAreas: string[];
    onAreaStared: (a: IResource, s: boolean) => void;
    campusId?: string;
    roomId?: string;
    isPanelOpen: boolean;
    openPanel: () => void;
    dismissPanel: () => void;
    setPanelHeader: React.Dispatch<React.SetStateAction<string>>;
    areaName: string;
    setAreaName: React.Dispatch<React.SetStateAction<string>>;
    setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    saveDataForAreaDeletion: (
        areaName: string,
        areaId: string
    ) => {
        name: string;
        id: string;
    };
    areaToDelete: {
        name: string;
        id: string;
    };
    searchArea: (e: any, newValue: any) => void;
    searchInput: string;
    identityClaims?: IIdentityClaims;
    deleteAreaHandler: (areaId: string) => Promise<void>;
    setAreaToEdit: React.Dispatch<React.SetStateAction<string>>;
    onCreateAreaHandler: () => Promise<void>;
    onAreaChangeHandler: () => Promise<void>;
    onDismiss: () => void;
}

const AreaListView: React.FC<IAreaListViewProps> = ({
    areas,
    onAreaSelected,
    vertical,
    onAreaStared,
    campusId,
    isPanelOpen,
    openPanel,
    dismissPanel,
    panelHeader,
    setPanelHeader,
    areaName,
    setAreaName,
    modalIsOpen,
    setModalIsOpen,
    saveDataForAreaDeletion,
    areaToDelete,
    searchArea,
    searchInput,
    identityClaims,
    deleteAreaHandler,
    setAreaToEdit,
    onCreateAreaHandler,
    onAreaChangeHandler,
    onDismiss
}) => {
    const filterAreas = (searchValue: any) => {
        if (searchValue === '') {
            return areas;
        } else {
            return (areas = areas.filter((area: any) => area.attributes.displayName.toLowerCase().includes(searchValue.toLowerCase())));
        }
    };
    const theme = useTheme();

    // Responsive design
    const { smallDevice } = useWindowWidth();
    const { areasSearchBar, areaCreateBtn, areasSearchBarVertical, areaCreateBtnVertical } = getClassNames();

    // detect device

    function iOS() {
        return (
            ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
            // iPad on iOS 13 detection
            (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
        );
    }

    console.log('function iOS', iOS());

    return (
        <>
            <Stack className="padding--large" grow tokens={{ childrenGap: 26 }}>
                {smallDevice && (
                    <Stack>
                        <Text variant="large">Areas</Text>
                    </Stack>
                )}
                <Stack horizontalAlign="start" tokens={{ childrenGap: 16 }} horizontal>
                    {/* <Stack horizontalAlign="space-between" horizontal={!vertical} tokens={{ childrenGap: 16 }}> */}
                    <TextField
                        placeholder="Search areas"
                        iconProps={{ iconName: 'search', styles: { root: { color: theme.semanticColors.bodyText } } }}
                        // style={{ minWidth: 320 }}
                        type="text"
                        onChange={searchArea}
                        value={searchInput}
                        className={vertical ? areasSearchBarVertical : areasSearchBar}
                    />

                    {(identityClaims?.roles.includes('manager') || identityClaims?.roles.includes('owner')) && (
                        <PrimaryButton
                            onClick={() => {
                                openPanel();
                                setPanelHeader('Create new area');
                            }}
                            text={'Add area'}
                            className={vertical ? areaCreateBtnVertical : areaCreateBtn}
                            iconProps={{ iconName: 'Add' }}
                        />
                    )}
                </Stack>

                <Stack grow style={{ position: 'relative', height: vertical ? 'calc( 100vh - 140px )' : '400px' }}>
                    <ScrollablePane style={{ height: '100%', minHeight: '100%' }}>
                        <Stack horizontal={!vertical} verticalAlign="start" wrap={!vertical}>
                            {filterAreas(searchInput).map((area: any) => {
                                return (
                                    <AreaCardController
                                        key={area.id}
                                        areaId={area.id}
                                        area={area}
                                        campusId={campusId}
                                        deleteAreaHandler={(areaId: string) => {
                                            deleteAreaHandler(areaId);
                                        }}
                                        setAreaToEdit={(areaName: string, areaId: string) => {
                                            setAreaToEdit(areaId);
                                            setAreaName(areaName);
                                        }}
                                        saveDataForAreaDeletion={saveDataForAreaDeletion}
                                        modalIsOpen={modalIsOpen}
                                        setModalIsOpen={setModalIsOpen}
                                        fluid={vertical}
                                        panelHeader={panelHeader}
                                        setPanelHeader={setPanelHeader}
                                        isPanelOpen={isPanelOpen}
                                        areaName={areaName}
                                        setAreaName={setAreaName}
                                        openPanel={openPanel}
                                        dismissPanel={dismissPanel}
                                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                        onSelect={(e) => {
                                            onAreaSelected(area.id);
                                        }}
                                        onStar={(a, s) => onAreaStared(a, s)}
                                        vertical={vertical}
                                    />
                                );
                            })}
                        </Stack>
                    </ScrollablePane>
                </Stack>
            </Stack>

            <Panel isOpen={isPanelOpen} headerText={panelHeader} onDismiss={dismissPanel}>
                <Stack style={{ flexDirection: 'column', alignItems: 'center', height: '500px', justifyContent: 'space-around' }}>
                    <Text variant="xxLarge"></Text>
                    <Stack style={{ width: '100px', height: '70px', backgroundColor: IvicosColors.Mittelblau, justifyContent: 'center', alignItems: 'center' }}>
                        <Text variant="xxLarge" style={{ color: 'white' }}>
                            {areaName
                                .split(' ')
                                .reduce((a, b) => a + b[0], '')
                                .slice(0, 2)}
                        </Text>
                    </Stack>
                    {/* <DynamicForm formFields={formFields} onChange={onSetareaName} /> */}
                    <TextField
                        label="Area name"
                        type="text"
                        value={areaName}
                        onChange={(e, newValue) => typeof newValue == 'string' && setAreaName(newValue)}
                    />
                    <Stack style={{ flexDirection: 'row', padding: '10px', width: '100%', justifyContent: 'space-around' }}>
                        <DefaultButton
                            text="Cancel"
                            onClick={() => {
                                dismissPanel();
                            }}
                        />
                        {panelHeader === 'Create new area' ? (
                            <PrimaryButton
                                text="Confirm"
                                onClick={() => {
                                    onCreateAreaHandler();
                                    dismissPanel();
                                }}
                            />
                        ) : (
                            <PrimaryButton
                                text="Save changes"
                                onClick={() => {
                                    onAreaChangeHandler();

                                    dismissPanel();
                                }}
                            />
                        )}
                    </Stack>
                </Stack>
            </Panel>

            <Modal isOpen={modalIsOpen} onDismiss={onDismiss}>
                <Stack horizontalAlign="center" verticalAlign="center" style={{ height: '176px', padding: 26 }} tokens={{ childrenGap: 16 }}>
                    <Text variant="large">Are you sure you want to delete {areaToDelete.name} ?</Text>
                    <Stack horizontal tokens={{ childrenGap: 16 }}>
                        <DefaultButton text="Cancel" onClick={onDismiss} />
                        <PrimaryButton
                            text="Delete Area"
                            onClick={() => {
                                deleteAreaHandler(areaToDelete.id);
                                setModalIsOpen(false);
                            }}
                        />
                    </Stack>
                </Stack>
            </Modal>
        </>
    );
};

export default AreaListView;
