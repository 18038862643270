/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Pusher from 'pusher-js';
import { constructRealtimeAuthorizer } from 'services/realtime-transport/ivicos-pusher/pusher/authorizer';
import PusherConfig from 'services/realtime-transport/ivicos-pusher/pusher/config';

Pusher.logToConsole = true;

export let pusherInstance: Pusher | undefined = undefined;

export const initPusherInstance = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise<string | undefined>((resolve, reject) => {
        pusherInstance = new Pusher(PusherConfig.clientId, {
            cluster: 'eu',
            authorizer: constructRealtimeAuthorizer
        });

        pusherInstance.connection.bind('connected', () => {
            resolve(pusherInstance?.connection.socket_id);
        });
        pusherInstance.connection.bind('error', (err: any) => {
            console.log('PR', err);
            alert('There was an error with your connection... press ok to reconnect.');
            window.location.reload();
        });
    });
};

export const cleanUpPusherInstance = () => {
    pusherInstance?.connection.bind('disconnected', () => {
        pusherInstance = undefined;
    });
    pusherInstance?.connection.disconnect();
};
