import * as React from 'react';

function SvgEuropeSecure(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 24h4.838c2.049 2.412 5.101 3.751 5.287 3.813L24.5 28l.375-.188c.281-.094 7.125-3.088 7.125-8.328V14h-.938c-1.337 0-2.284-.354-3.062-.725V10h-2v2.297A4.019 4.019 0 0024.5 12c-1.031 0-1.781.374-2.531.842-.095.047-.192.097-.291.148-.883.452-1.972 1.01-3.74 1.01H17v5.484c0 .909.215 1.746.557 2.516H14v2zm10.5-10c.507 0 .902.175 1.654.592l.078.039.282.142c.785.404 1.902.978 3.486 1.165v3.546c0 3.33-4.267 5.69-5.5 6.301-1.233-.61-5.5-2.972-5.5-6.3v-3.542c1.655-.182 2.79-.763 3.586-1.17l.006-.003.27-.137.085-.043.08-.05c.653-.41 1.012-.54 1.473-.54z"
                fill="#000"
            />
            <path d="M0 24V4h28v10h-2V6H2v16h17v2H0z" fill="#000" />
            <path
                d="M13.688 7.656L14 7l.313.656.718.094-.5.5.094.719L14 8.625l-.625.344.094-.719-.5-.5.719-.094zM10.938 7.938l-.313.625-.719.124.5.5-.094.72.626-.345.624.344-.124-.719.53-.5-.718-.124-.313-.626zM16.031 8.688l.719-.126.313-.624.312.625.719.124-.5.5.093.72-.625-.345-.625.344.125-.719-.53-.5zM8.75 9.938l-.313.656-.718.094.531.53-.125.72.625-.344.656.344-.125-.72.5-.53-.719-.094-.312-.656zM18.938 10.594l.312-.656.313.656.718.094-.531.53.125.72-.625-.344-.656.344.125-.72-.5-.53.718-.094zM8.031 13l-.312.656-.719.125.531.5-.125.719.625-.344.625.344-.094-.719.5-.5-.718-.125L8.03 13zM8.75 15.719l-.313.625-.718.125.531.5-.125.718.625-.343.656.343-.125-.718.5-.5-.719-.125-.312-.625zM10.938 17.75l-.313.656-.719.094.5.531-.094.719.626-.344.624.344-.124-.719.53-.531-.718-.094-.313-.656zM14 19.031l-.313.657-.718.093.5.5-.094.719.625-.344.625.344-.094-.719.5-.5-.719-.093L14 19.03z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgEuropeSecure;
