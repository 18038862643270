/* eslint-disable no-control-regex */
import jwtDecode from 'jwt-decode';
import { useState } from 'react';
import { Control, DeepMap, FieldError, useForm } from 'react-hook-form';
import { idpService } from 'services/api/identity-provider.service';
import { useStatePersist } from 'use-state-persist';

export interface IRegistrationFormControllerState {
    isLoading: boolean;
    handleSubmit: any;
    onFormSubmit: (formValue: any) => Promise<void>;
    control: Control<{
        displayName: string | undefined;
        emailAdress: string | undefined;
    }>;
    errors: DeepMap<
        {
            displayName: string | undefined;
            emailAdress: string | undefined;
        },
        FieldError
    >;
}

export const useRegistrationFormControllerState: (onRegistrationSuccess: any, onError: any, invitationId: string) => IRegistrationFormControllerState = (
    onRegistrationSuccess,
    onError,
    invitationId
) => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useStatePersist<{ displayName: string | undefined; emailAdress: string | undefined }>('@visitorRegistrationForm');
    const { control, handleSubmit, errors } = useForm({ defaultValues: formData });

    const onFormSubmit = async (formValue: any) => {
        const emailAdress = 'visitor@ivicos.eu';
        const { displayName } = formValue;
        setFormData(formValue);
        setIsLoading(true);
        try {
            await idpService.authVisitor(emailAdress, displayName, invitationId);
            const accessToken = localStorage.getItem('ivAccessToken');
            if (!accessToken) throw new Error('No Token');

            const tokenPayload = jwtDecode<any>(accessToken);

            onRegistrationSuccess && onRegistrationSuccess(accessToken, tokenPayload);
        } catch (err) {
            onError && onError(err);
        }
    };

    return { isLoading, handleSubmit, onFormSubmit, control, errors };
};
