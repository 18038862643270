import { ISideBarTabIconProps } from 'features/SideBar/children/SideBarTabIcon/SideBarTabIcon';
import { useEffect } from 'react';
import { useTabOption } from 'shared-state/tabs/hooks';
import { ITabOption } from 'shared-state/tabs/types';

export interface ISideBarTabIconState {
    iconTitle: string;
    isSelected: boolean;
    onIconClick: Function;
}

export const useSideBarTabIconState: (props: ISideBarTabIconProps) => ISideBarTabIconState = (props) => {
    const { tabKey, title, selectedByDefault, onSelect } = props;
    const [tabOption, setTabOption] = useTabOption('sidebar', tabKey);

    const updateOption = (selected: boolean) => {
        const nextOption: ITabOption = {
            key: tabKey,
            title,
            isSelected: selected
        };
        setTabOption(nextOption);
    };

    useEffect(() => {
        updateOption(tabOption?.isSelected || selectedByDefault || false);
        return () => {
            setTabOption(undefined);
        };
    }, [tabKey, title]);

    const iconTitle = tabOption?.title || 'Loading...';
    const isSelected = tabOption?.isSelected || false;

    const onIconClick = () => {
        updateOption(true);
        onSelect();
    };

    return {
        onIconClick,
        iconTitle,
        isSelected
    };
};
