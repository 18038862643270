import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { IDroppableAreaProps } from './DroppableArea';

const DroppableAreaView: React.FC<IDroppableAreaProps> = (props) => {
    const { droppableId, horizontal, disabled, classNames, children } = props;
    return (
        <Droppable droppableId={droppableId} direction={horizontal ? 'horizontal' : 'vertical'} isDropDisabled={disabled}>
            {(provided, snapshot) => (
                <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={(snapshot.isDraggingOver && classNames?.draggingOver) + ' ' + classNames?.default}
                >
                    {typeof children == 'function' && children(provided.placeholder)}
                </div>
            )}
        </Droppable>
    );
};

export default DroppableAreaView;
