import React from 'react';
import { IJitsiDevice, useDeviceSettingsControllerState } from './DeviceSettingsController.state';
import DeviceSettingsControllerView from './DeviceSettingsController.view';

export interface IDeviceSettingsControllerProps {
    jitsiAPI: any;
    visible: boolean;
    onChange(deviceType: string, device: IJitsiDevice): void;
    onDismiss(): void;
}

export const DeviceSettingsController: React.FC<IDeviceSettingsControllerProps> = (props) => {
    const state = useDeviceSettingsControllerState(props.jitsiAPI, props.visible, props.onChange);
    return (
        <DeviceSettingsControllerView
            {...{
                ...state,
                ...{
                    jitsiAPI: props.jitsiAPI,
                    children: props.children,
                    visible: props.visible,
                    onDismiss: props.onDismiss,
                    onChange: props.onChange
                }
            }}
        >
            {props.children}
        </DeviceSettingsControllerView>
    );
};
