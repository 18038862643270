import { UserAvailability } from 'kits/availabilityKit';
import { useState } from 'react';
import { useIdentityClaims, useLocalProfile, useSharedAvailability } from 'shared-state/identity/hooks';
import { IIdentityClaims, IProfile } from 'shared-state/identity/types';

export interface IUserProfileState {
    localProfile?: IProfile;
    identityClaims?: IIdentityClaims;
    sharedAvailability?: UserAvailability;
    showProfileImageEditingPanel: boolean;
    setShowProfileImageEditingPanel: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useUserProfileState: () => IUserProfileState = () => {
    const localProfile = useLocalProfile();
    const [identityClaims] = useIdentityClaims();
    const [sharedAvailability] = useSharedAvailability();
    const [showProfileImageEditingPanel, setShowProfileImageEditingPanel] = useState<boolean>(false);

    return {
        localProfile,
        identityClaims,
        sharedAvailability,
        showProfileImageEditingPanel,
        setShowProfileImageEditingPanel
    };
};
