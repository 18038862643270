/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/display-name */
import React from 'react';
import { useOutsideCallControllerState } from './OutsideCallController.state';
import OutsideCallControllerView from './OutsideCallController.view';

export interface IOutsideCallControllerProps {
    displayName: string;
}

const OutsideCallController: React.FC<IOutsideCallControllerProps> = ({ displayName }) => {
    const state = useOutsideCallControllerState();

    return (
        <OutsideCallControllerView
            {...{
                ...state,
                ...{
                    displayName: displayName
                }
            }}
        />
    );
};

export default OutsideCallController;
