import { getTheme, Stack, Text } from '@fluentui/react';
import { Toggle } from '@fluentui/react/lib/Toggle';
import { IC } from 'components/SVGIcon';
import { Close, CloseModal, InfoBubble } from 'kits/IconKit2';
import React from 'react';
import { IProfile } from 'shared-state/identity/types';
import { ISpeakerStatsSpeaker } from './SpeakerStats.state';
import SpeakerStatsUser from './SpeakerStatsUser/SpeakerStatsUser';

export interface ISpeakerStatsViewProps {
    stats: ISpeakerStatsSpeaker[];
    isOpen: boolean;
    onDismiss: () => void;
    speakerTimeReminderIsEnabled: boolean;
    setSpeakerTimeReminderIsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
    modalTalkOpen: boolean;
    setModalTalkOpen: React.Dispatch<React.SetStateAction<boolean>>;
    modalLocalTalk: boolean;
    setModalLocalTalk: Function;
    localProfile: IProfile | undefined;
    pretifyTime: (timeInMs: number) => string;
    onTalkModalDismiss: () => void;
    getTimes: (item: number) => number;
    addTimes: (total: number, num: number) => number;
    times: any;
    localSpeaker?: ISpeakerStatsSpeaker;
    onLocalTalkDismiss: () => void;
    totalTime: number;
}

const SpeakerStatsView: React.FC<ISpeakerStatsViewProps> = (props) => {
    const theme = getTheme();

    const {
        stats,
        isOpen,
        onDismiss,
        speakerTimeReminderIsEnabled,
        setSpeakerTimeReminderIsEnabled,
        modalTalkOpen,
        modalLocalTalk,
        setModalLocalTalk,
        pretifyTime,
        onTalkModalDismiss,
        localSpeaker,
        onLocalTalkDismiss,
        totalTime
    } = props;

    return (
        <>
            <Stack style={{ background: theme.palette.white, color: theme.palette.white }}>
                {modalTalkOpen && (
                    <Stack
                        style={{
                            position: 'absolute',
                            width: '30vh',
                            height: '20vh',
                            backgroundColor: theme.palette.white,
                            boxShadow: '0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13)',
                            justifyContent: 'center',
                            alignContent: 'center',
                            right: 30,
                            top: 20,
                            zIndex: 2
                        }}
                    >
                        <Stack horizontal horizontalAlign="end" style={{ height: 40 }}>
                            <IC size={24} variant="dark">
                                <CloseModal style={{ width: '70%', padding: '0.9vh' }} onClick={() => onTalkModalDismiss()} className="pressable" />
                            </IC>
                        </Stack>
                        <Stack horizontal verticalAlign="start" style={{ padding: '16px' }} tokens={{ childrenGap: 16 }}>
                            <IC variant="dark" size={48}>
                                <InfoBubble />
                            </IC>
                            <Stack tokens={{ childrenGap: 16 }} horizontalAlign="start">
                                <Text variant="large" style={{ color: theme.palette.black }}>
                                    You are talking more than every one else.
                                </Text>
                                <Text style={{ color: theme.palette.black }}>Think about giving someone else a turn to speak.</Text>
                            </Stack>
                        </Stack>
                        <Stack style={{ height: '3vh' }}></Stack>
                    </Stack>
                )}
                {modalLocalTalk && (
                    <Stack
                        style={{
                            position: 'absolute',
                            width: '30vh',
                            height: '20vh',
                            backgroundColor: theme.palette.white,
                            boxShadow: '0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13)',
                            justifyContent: 'center',
                            alignContent: 'center',
                            left: 30,
                            top: 20,
                            zIndex: 2
                        }}
                    >
                        <Stack style={{ height: '3.5vh', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            <IC size={24} variant="dark">
                                <CloseModal style={{ width: '70%', padding: '0.9vh' }} onClick={() => onLocalTalkDismiss()} className="pressable" />
                            </IC>
                        </Stack>
                        <Stack style={{ height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <Text variant="mega" style={{ color: theme.palette.black }}>
                                {localSpeaker?.speakerTime ? pretifyTime(localSpeaker.speakerTime) : '00:00:00'}
                            </Text>
                        </Stack>

                        <Stack style={{ height: '3.5vh' }}></Stack>
                    </Stack>
                )}
                <Stack
                    style={{
                        display: !isOpen ? 'none' : 'inherit',
                        padding: 16,
                        background: theme.palette.white,
                        width: 280
                    }}
                    tokens={{ childrenGap: 8 }}
                >
                    <Stack horizontal style={{ alignItems: 'center', marginBottom: '3.5vh' }}>
                        <Stack grow style={{ color: `${theme.palette.black}` }}>
                            Speaker stats:
                        </Stack>
                        <Stack onClick={() => onDismiss()} className="pressable">
                            <IC size={24} variant="dark">
                                <Close />
                            </IC>
                        </Stack>
                    </Stack>

                    <Stack style={{ paddingLeft: 8, background: theme.palette.white, color: theme.palette.black }}>
                        {stats.length > 0 ? (
                            <>
                                <Stack>
                                    <Stack style={{ marginBottom: 22 }}>
                                        <Text variant="large" style={{ textAlign: 'center', width: '25vh', color: theme.palette.black }}>
                                            Total talk time:
                                        </Text>
                                        <Text variant="superLarge" style={{ textAlign: 'center' }}>
                                            {totalTime ? pretifyTime(totalTime) : '00:00:00'}
                                        </Text>
                                    </Stack>

                                    <Stack style={{ marginBottom: 48 }}>
                                        <SpeakerStatsUser
                                            totalTime={totalTime}
                                            userName={localSpeaker?.userName || 'Me'}
                                            userId={localSpeaker?.userId}
                                            speakerTime={localSpeaker?.speakerTime}
                                            pretifyTime={pretifyTime}
                                        ></SpeakerStatsUser>
                                        <Stack style={{ height: '40vh', overflow: 'scroll' }}>
                                            <Stack>
                                                {stats.slice(1).map((stat: any) => (
                                                    <>
                                                        <SpeakerStatsUser
                                                            key={stat.userId}
                                                            totalTime={totalTime}
                                                            userName={stat.userName !== null ? stat.userName : 'something is wrong'}
                                                            speakerTime={stat.speakerTime}
                                                            userId={stat.userId}
                                                            pretifyTime={pretifyTime}
                                                        ></SpeakerStatsUser>
                                                    </>
                                                ))}
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                    <Stack>
                                        <Stack
                                            horizontal
                                            style={{
                                                padding: '5px',
                                                marginBottom: '1.5vh'
                                            }}
                                        >
                                            <Toggle checked={modalLocalTalk} onChange={(event, checked) => setModalLocalTalk(checked || false)} inlineLabel />
                                            <Text variant="medium" style={{ padding: '0vh 1vh' }}>
                                                Show me my personal talk time.
                                            </Text>
                                        </Stack>
                                        <Stack
                                            horizontal
                                            style={{
                                                alignItems: 'center',
                                                padding: '5px',
                                                marginTop: 16,
                                                marginBottom: '1.5vh'
                                            }}
                                        >
                                            <Toggle
                                                onChange={(event, checked) => {
                                                    setSpeakerTimeReminderIsEnabled(checked || false);
                                                }}
                                                checked={speakerTimeReminderIsEnabled}
                                                inlineLabel
                                                defaultChecked
                                            />
                                            <Stack horizontalAlign="start">
                                                <span
                                                    style={{
                                                        backgroundColor: theme.palette.themeLight,
                                                        borderRadius: 12,
                                                        padding: '3px 6px',
                                                        fontSize: 10,
                                                        margin: 6
                                                    }}
                                                >
                                                    experimental
                                                </span>
                                                <Text variant="medium" style={{ padding: '0vh 1vh' }}>
                                                    Tell me when I’m talking too much.{' '}
                                                </Text>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </>
                        ) : (
                            <Text>No data yet.</Text>
                        )}
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
};
export default SpeakerStatsView;
