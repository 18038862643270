import React from 'react';
import { useAvailabilitySelectorState } from './AvailabilitySelector.State';
import AvailabilitySelectorView from './AvailabilitySelector.View';

const AvailabilitySelector: React.FC = () => {
    const state = useAvailabilitySelectorState();

    return (
        <AvailabilitySelectorView
            {...{
                ...state
            }}
        ></AvailabilitySelectorView>
    );
};
export default AvailabilitySelector;
