/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CampusDto } from 'client-api-wrapper/lib/campus_api/responses.dto';
import jwtDecode from 'jwt-decode';
import IconKit from 'kits/IconKit';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { resourceService } from 'services/api/resource.service';
import { useIdentityClaims } from 'shared-state/identity/hooks';
import { IIdentityClaims } from 'shared-state/identity/types';
import { useCurrentAreaId, useCurrentCampusId, useCurrentRoomId } from 'shared-state/location/hook';

export interface IWaitingAreaRouteParams {
    invitationid: string;
}

export interface IWaitingsAreaState {
    headerActions: {
        title: string;
        symbol: any;
        action: (e: any) => void;
    }[];
    videntityClaims?: IIdentityClaims;
    currentAreaId?: string;
    campus?: CampusDto;
    params: IWaitingAreaRouteParams;
}

export const useWaitingAreaState: () => IWaitingsAreaState = () => {
    const params = useParams<IWaitingAreaRouteParams>();
    const routeHistory = useHistory();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [currentRoomId, setCurrentRoomId] = useCurrentRoomId();
    const [currentAreaId, setCurrentAreaId] = useCurrentAreaId();
    const [currentCampusId, setCurrentCampusId] = useCurrentCampusId();
    const [videntityClaims, setVidentityClaims] = useIdentityClaims();

    const [campus, setCampus] = useState<CampusDto | undefined>(undefined);

    const refetchCampus = async () => {
        if (!currentCampusId) return;
        const nextCampus = await resourceService.getCampusById(currentCampusId);
        setCampus(nextCampus);
    };

    useEffect(() => {
        refetchCampus();
    }, [currentCampusId]);

    const headerActions = [
        ...(videntityClaims?.uid
            ? [
                  {
                      title: 'Sign Out',
                      symbol: IconKit.getIcon('Small', 'Exit'),
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      action: (e: any) => {
                          localStorage.clear();
                          routeHistory.push('/i/' + params.invitationid);
                      }
                  }
              ]
            : []),
        ...[]
    ];

    useEffect(() => {
        const token = localStorage.getItem('ivAccessToken');
        console.log(token);
        if (!token) return;
        const payload = jwtDecode<any>(token);
        console.log(payload);
        setCurrentAreaId(payload.areaId);
        setCurrentCampusId(payload.campusId);

        const identity: IIdentityClaims = {
            uid: payload.sub.split(':')[2],
            displayName: payload.name,
            roles: [payload.role],
            type: 'visitor',
            tid: payload.orgId,
            upn: payload.email,
            areas: ['*'],
            idp: 'ivicos'
        };
        setVidentityClaims(identity);
    }, []);
    /* useEffect(() => {
        invitation && setCurrentAreaId(invitation.areaId);
    }, [invitation]);*/
    useEffect(() => {
        videntityClaims && setCurrentRoomId('visitor-' + videntityClaims.uid);
    }, [videntityClaims]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const visitorIdToken: string | null = localStorage.getItem('ivAccessToken');

    return {
        headerActions,
        videntityClaims,
        currentAreaId,
        campus,
        params
    };
};
