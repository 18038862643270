import { useState } from 'react';
import { useUserMessagingActions } from 'adapters/realtime-adapter/actions/messaging';
import { useMessageComposerUserIds } from 'shared-state/messaging/hooks';
import { useUsersWithIDs } from 'shared-state/presence/hooks';
import { makeMessageId, useReceivedMessageInbox } from 'shared-state/notifications/hooks';
import { getTheme, ITheme } from '@fluentui/react';
import { IProfile } from 'shared-state/identity/types';

export interface IMemoComposerState {
    userIds: string[];
    users: IProfile[];
    theme: ITheme;
    giphyPanelIsOpen: boolean;
    setGiphyPanelIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    memoBody: string;
    setMemoBody: React.Dispatch<React.SetStateAction<string>>;
    mode: 'markdown' | 'preview';
    setMode: React.Dispatch<React.SetStateAction<'markdown' | 'preview'>>;
    cancel: () => void;
    send: () => void;
}

export const useMemoComposerState: () => IMemoComposerState = () => {
    const [userIds, setUserIds] = useMessageComposerUserIds();
    const users = useUsersWithIDs(userIds);
    const theme = getTheme();
    const { sendMemoToUser } = useUserMessagingActions();
    const [giphyPanelIsOpen, setGiphyPanelIsOpen] = useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [messages, addMessage] = useReceivedMessageInbox();
    const [memoBody, setMemoBody] = useState<string>('');
    const [mode, setMode] = useState<'markdown' | 'preview'>('markdown');
    const cancel = () => {
        setMemoBody('');
        setMode('markdown');
        setUserIds([]);
    };

    const send = () => {
        if (memoBody.length > 0) {
            userIds.forEach((userId) => {
                sendMemoToUser(userId, memoBody);
            });
            addMessage({
                id: makeMessageId(),
                type: 'confirmation',
                body: 'Memo sent to ' + users.map((user) => user.displayName).join(', ') + '.',
                timeout: 2500,
                meta: {},
                sender: 'system',
                timestamp: new Date()
            });
            cancel();
        }
    };

    return {
        userIds,
        users,
        theme,
        giphyPanelIsOpen,
        setGiphyPanelIsOpen,
        memoBody,
        setMemoBody,
        mode,
        setMode,
        cancel,
        send
    };
};
