/* eslint-disable @typescript-eslint/no-unused-vars */
import { DefaultEffects, getTheme, Stack } from '@fluentui/react';
import { CampusDto } from 'client-api-wrapper/lib/campus_api/responses.dto';
import LoadingPage from 'components/LoadingPage';
import OutsideCallController from 'features/Visitors/Entrance/OutsideCallController/OutsideCallController';
import OutsideWrapper from 'features/Visitors/Entrance/OutsideWrapper/OutsideWrapper';
import { campusDTOtoResource } from 'kits/apiKit3/legacy';
import React from 'react';
import { Redirect } from 'react-router';
import PusherServiceProvider from 'services/realtime-transport/ivicos-pusher/provider';
import { IIdentityClaims } from 'shared-state/identity/types';
import { IWaitingAreaRouteParams } from './WaitingArea.state';
export interface IWaitingsAreaView {
    headerActions: {
        title: string;
        symbol: any;
        action: (e: any) => void;
    }[];
    videntityClaims?: IIdentityClaims;
    areaId?: string;
    campus?: CampusDto;
    params: IWaitingAreaRouteParams;
}

const WaitingAreaView: React.FC<IWaitingsAreaView> = (props) => {
    const { headerActions, videntityClaims, areaId, campus, params } = props;
    const theme = getTheme();
    const visitorsCallUrl = campus?.visitorsCallUrl || '';
    const backgroundStyle: React.CSSProperties = {
        backgroundColor: theme.palette.white,
        borderRadius: '4px 4px 0px 0px',
        boxShadow: DefaultEffects.elevation8,
        overflow: 'hidden'
    };
    if (!areaId) return <LoadingPage label="Loading..." description="Please wait while we notify your host..." />;
    if (!localStorage.getItem('ivAccessToken')) return <Redirect to={'/i/' + params.invitationid} />;

    return (
        <PusherServiceProvider>
            <OutsideWrapper campus={campus && campusDTOtoResource(campus)} headerActions={headerActions}>
                {visitorsCallUrl && (
                    <Stack style={{ width: '40%', paddingLeft: '16px', marginTop: 16 }} verticalAlign="stretch">
                        <Stack style={backgroundStyle} grow>
                            <iframe src={visitorsCallUrl} style={{ width: '100%', height: '100%' }} frameBorder="0"></iframe>
                        </Stack>
                    </Stack>
                )}
                {videntityClaims && <OutsideCallController displayName={videntityClaims.displayName} />}
            </OutsideWrapper>
        </PusherServiceProvider>
    );
};

export default WaitingAreaView;
