import { getTheme, ITheme } from '@fluentui/react';

export interface ICallLoadingScreenState {
    theme: ITheme;
    _label: string;
    _description: string;
}

export const useCallLoadingScreenState: (label?: string, description?: string) => ICallLoadingScreenState = (label, description) => {
    const theme = getTheme();
    const _label = label || 'Connecting to secure server...';
    const _description = description || 'Please wait, while we establish a connection with one of our secure servers in Europe.';

    return {
        theme,
        _label,
        _description
    };
};
