import { useEffect } from 'react';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { IProfile } from 'shared-state/identity/types';
import { useStatePersist as useState } from 'use-state-persist';

export interface ISpeakerStatsSpeaker {
    userId: string;
    userName?: string;
    speakerTime: number;
    uid?: string;
}
export interface ISpeakerStats {
    speakerData: ISpeakerStatsSpeaker[];
}

export interface ISpeakerStatsStateProps {
    speakerTimeReminderIsEnabled: boolean;
    setSpeakerTimeReminderIsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
    modalTalkOpen: boolean;
    setModalTalkOpen: React.Dispatch<React.SetStateAction<boolean>>;
    modalLocalTalk: boolean;
    setModalLocalTalk: Function;
    localProfile: IProfile | undefined;
    pretifyTime: (timeInMs: number) => string;
    onTalkModalDismiss: () => void;
    getTimes: (item: number) => number;
    addTimes: (total: number, num: number) => number;
    times: any;
    localSpeaker?: ISpeakerStatsSpeaker;
    onLocalTalkDismiss: () => void;
    totalTime: number;
}

export const useSpeakerStatsState: (stats: ISpeakerStatsSpeaker[]) => ISpeakerStatsStateProps = (stats) => {
    const [speakerTimeReminderIsEnabled, setSpeakerTimeReminderIsEnabled] = useState<boolean>('@speakerTimeReminderIsEnabled', false);
    const [modalTalkOpen, setModalTalkOpen] = useState('@modalTalkOpen', false);
    const [modalLocalTalk, setModalLocalTalk] = useState('@modalLocalTalk', false);

    const localProfile = useLocalProfile();

    const pretifyTime = (timeInMs: number) => {
        function pad(n: number, z?: number) {
            z = z || 2;
            return ('00' + n).slice(-z);
        }
        let s = timeInMs;
        const ms = s % 1000;
        s = (s - ms) / 1000;
        const secs = s % 60;
        s = (s - secs) / 60;
        const mins = s % 60;
        const hrs = (s - mins) / 60;
        return pad(hrs) + ':' + pad(mins) + ':' + pad(secs);
    };

    useEffect(() => {
        const statTimes = stats.slice(1).map((speaker) => speaker.speakerTime);

        if (statTimes.length <= 0) return;

        const highest = Math.max(...statTimes);
        const localSpeakerTime = localSpeaker?.speakerTime || 1;

        const ratio = localSpeakerTime / highest;

        localSpeaker &&
            localSpeaker.speakerTime > 300000 &&
            (highest == 0 || ratio > 1.25) &&
            speakerTimeReminderIsEnabled &&
            !modalTalkOpen &&
            setModalTalkOpen(true);
    }, [stats]);

    const onTalkModalDismiss = () => {
        setModalTalkOpen(false);
        setSpeakerTimeReminderIsEnabled(false);
    };

    const onLocalTalkDismiss = () => {
        setModalLocalTalk(false);
    };

    const getTimes = (item: any) => {
        const timeSpoken = item.speakerTime;
        return timeSpoken;
    };

    function addTimes(total: any, num: any) {
        return total + num;
    }

    const times = stats.map(getTimes);

    let totalTime: any;

    if (times.length > 1) {
        totalTime = times.reduce(addTimes);
    }

    const localSpeaker = stats.find((speaker: ISpeakerStatsSpeaker) => speaker.userName == localProfile?.displayName);

    return {
        speakerTimeReminderIsEnabled,
        setSpeakerTimeReminderIsEnabled,
        modalTalkOpen,
        setModalTalkOpen,
        modalLocalTalk,
        setModalLocalTalk,
        localProfile,
        pretifyTime,
        onTalkModalDismiss,
        getTimes,
        addTimes,
        times,
        localSpeaker,
        onLocalTalkDismiss,
        totalTime
    };
};
