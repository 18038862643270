/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useState } from 'react';
import { useRealtimeService } from 'services/realtime-transport';
import { authorizeChannel } from 'services/realtime-transport/ivicos-pusher/pusher/authorizer';
import { useCurrentAreaId, useCurrentCampusId } from 'shared-state/location/hook';

export const useRealtimeChannel = (channelType: string, eventHandlers: { eventName: string; callback: Function }[]) => {
    const REALTIME = useRealtimeService();
    const { isInitialized } = REALTIME;
    const [areaId] = useCurrentAreaId();
    const [campusId] = useCurrentCampusId();
    const [channelName, setChannelName] = useState<string | undefined>();
    const [debug, setDebug] = useState<any>(undefined);
    const authorize = async () => {
        setDebug({ areaId, campusId, channelType, isInitialized });
        if (!areaId || !campusId || !channelType) {
            console.log('[RTA] ' + channelType + ' for ' + areaId + ' inactive...', { areaId, campusId, channelType });
            return false;
        }
        eventHandlers.find((h) => h.eventName == 'custom:connection-attempt')?.callback();

        console.log('[RTA]', 'Trying to connect... ' + JSON.stringify({ areaId, campusId, channelType }));

        const _channelName = await authorizeChannel(campusId, areaId, channelType);
        return _channelName;
    };

    useEffect(() => {
        if (!isInitialized) return;
        authorize().then((_channelName) => {
            if (!_channelName) return;

            console.log('[RTA] Authing ' + _channelName + '...');

            setChannelName(_channelName);

            console.log('[RTA] Subscribing to ' + _channelName + '...');

            _channelName && REALTIME?.subscribe(_channelName);
        });

        return () => {
            console.log('[RTA] Unsubscribing from  ' + channelName + '...');
            channelName && REALTIME?.unsubscribe(channelName);
            eventHandlers.find((h) => h.eventName == 'custom:unsubscribed')?.callback();
        };
    }, [channelType, areaId, campusId, isInitialized]);

    useEffect(() => {
        if (!channelName) return;
        REALTIME?.bind(channelName, REALTIME.defaultEventTypes.error, (data: any) => {
            console.log('[RTA]  PUSHER ERR', data);
        });

        eventHandlers.forEach(({ eventName, callback }) => {
            if (eventName.includes('custom')) return;
            console.log('[RTA] Binding ' + eventName + ' event to ' + channelName + '...');

            REALTIME?.bind(channelName, eventName, callback);
        });
        return () => {
            eventHandlers.forEach(({ eventName, callback }) => {
                REALTIME?.unbind(channelName, eventName, callback);
            });
        };
    }, [eventHandlers, channelName]);

    return debug;
};
