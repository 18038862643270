import { IComboBox } from '@fluentui/react';
import React, { useEffect } from 'react';

export type ICSVData = { body: string[][]; headline: string[]; setBody: (value: React.SetStateAction<string[][]>) => void };

export interface IReviewDataStepStateProps {
    onChangeImportedPersonData: (
        index: number,
        datatype: number
    ) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement | IComboBox>, newValue?: string) => void;
    validateEmail: (value: string) => boolean;
    validateRole: (value: string) => boolean;
}

export const useReviewDataStepState: (data?: ICSVData) => IReviewDataStepStateProps = (data) => {
    const onChangeImportedPersonData = (index: number, datatype: number) => {
        const importedPersonIndex = index;
        const personDataType = datatype;
        return (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement | IComboBox>, newValue?: string) => {
            if (newValue) {
                data?.setBody((prevState: string[][]) => {
                    const newState = [...prevState];
                    newState[importedPersonIndex][personDataType] = newValue;
                    return newState;
                });
            }
        };
    };

    const validateEmail = React.useCallback((value: string) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    }, []);

    const validateRole = React.useCallback((value: string) => {
        return value === 'manager' || value === 'user';
    }, []);

    useEffect(() => {
        const invalidUserInfo = data?.body
            .filter((importedUser) => !validateEmail(importedUser[0]) || !validateRole(importedUser[1]))
            .sort((a: string[], b: string[]) => {
                return a[0].localeCompare(b[0]);
            });
        const validUserInfo = data?.body
            .filter((importedUser) => validateEmail(importedUser[0]) && validateRole(importedUser[1]))
            .sort((a: string[], b: string[]) => {
                return a[0].localeCompare(b[0]);
            });
        let groupedAndSortedUserInfo;
        if (validUserInfo && invalidUserInfo) groupedAndSortedUserInfo = invalidUserInfo?.concat(validUserInfo);
        data && groupedAndSortedUserInfo && data?.setBody(groupedAndSortedUserInfo);
    }, [validateEmail, validateRole]);

    return {
        onChangeImportedPersonData,
        validateEmail,
        validateRole
    };
};
