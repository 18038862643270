import { Stack, Text, TooltipHost } from '@fluentui/react';
import React from 'react';
import 'styles/helpers.css';
import { IC } from 'components/SVGIcon';
import SvgAreaOverview from 'kits/IconKit2/AreaOverview';
import { SetterOrUpdater } from 'recoil';

export interface IAreaSwitchProps {
    selectedArea?: string;
    options: any;
    color?: string;
    setShowAreaPanel: SetterOrUpdater<boolean>;
    onClickOption: (e: any, k: string | null) => void;
}
const AreaSwitchView: React.FC<IAreaSwitchProps> = ({ selectedArea, options, color, setShowAreaPanel, onClickOption }) => {
    const BarDivider = () => {
        return <div style={{ margin: '16px 8px', background: 'rgba(255,255,255,0.3)', width: 1 }} />;
    };

    return (
        <Stack horizontal style={{ height: '100%' }} grow>
            {selectedArea && selectedArea != 'null' && (
                <Stack
                    horizontal
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    onClick={(e) => setShowAreaPanel(true)}
                    className="padding--default pressable"
                    tokens={{ childrenGap: 8 }}
                    style={{ backgroundColor: !selectedArea || selectedArea == 'null' ? 'rgba(0,0,0,0.3)' : undefined }}
                    verticalAlign="center"
                >
                    <TooltipHost content="Available areas">
                        <IC variant={'custom'} iconColor={color} size={24}>
                            <SvgAreaOverview />
                        </IC>
                    </TooltipHost>
                </Stack>
            )}
            {options &&
                options.map((areaTab: any) => {
                    const isSelectedTab = areaTab.key == selectedArea;
                    const tabWidth = options.length > 4 ? Math.floor(90 / options.length) + '%' : '180px';
                    return (
                        <React.Fragment key={areaTab.key}>
                            <BarDivider />
                            <Stack
                                horizontal
                                className="padding--default pressable"
                                style={{ width: tabWidth, backgroundColor: isSelectedTab ? 'rgba(0,0,0,0.3)' : undefined }}
                                verticalAlign="center"
                            >
                                <Stack grow onClick={(e) => !isSelectedTab && onClickOption(e, areaTab.key)} verticalAlign="center" style={{ height: '100%' }}>
                                    <Text variant="medium">{areaTab.title}</Text>
                                </Stack>
                            </Stack>
                        </React.Fragment>
                    );
                })}
            <BarDivider />
        </Stack>
    );
};

export default AreaSwitchView;
