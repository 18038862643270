import { usePresenceChannelHandlers } from 'adapters/realtime-adapter/handlers';
import { useRealtimeChannel } from 'adapters/realtime-adapter/hooks';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PresenceChannel: React.FC<{ areaId: string }> = ({ areaId }) => {
    const presenceChannelHandlers = usePresenceChannelHandlers();

    useRealtimeChannel('presence', presenceChannelHandlers);
    return <></>;
};
export default PresenceChannel;
