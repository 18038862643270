/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useRecoilState } from 'recoil';
import { aReceivedMessages } from 'shared-state/notifications/atoms';
import { sReceivedMessage } from 'shared-state/notifications/selectors';
import { IReceivedMessage } from 'shared-state/notifications/types';
import { v1 as uuidv1 } from 'uuid';

export const makeMessageId = () => {
    return uuidv1();
};

export const useReceivedMessages = () => {
    return useRecoilState(aReceivedMessages);
};

export const useReceivedMessage = (id: string) => {
    return useRecoilState(sReceivedMessage(id));
};

export const useReceivedMessageInbox = (): [IReceivedMessage[], (msg: IReceivedMessage) => void] => {
    const [messages, setMessages] = useRecoilState(aReceivedMessages);

    const addMessage = (newMessage: IReceivedMessage) => {
        console.log('Adding message... SILENT: ', newMessage.announce);
        if (newMessage.announce) {
            console.log('Trying to play sound...');
            const notificationSound = new Audio('https://static-cdn.vercel.app/notification.mp3');
            notificationSound.preload = 'auto';
            notificationSound.play();
        }

        if (newMessage.timeout) {
            setTimeout(() => {
                setMessages((prevMsgs: IReceivedMessage[]) => prevMsgs.filter((msg) => msg.id != newMessage.id));
            }, newMessage.timeout);
        }

        setMessages((prevMsgs: IReceivedMessage[]) => [...prevMsgs, ...[newMessage]]);
    };

    return [messages, addMessage];
};
