import { IdentityAdapter } from 'adapters/identity-adapter/identity-adapter';
import RealtimeAdapter from 'adapters/realtime-adapter';
import { ResourceAdapter } from 'adapters/resource-adapter/resource-adapter';
import AreaSwitch from 'features/Areas/AreaSwitch/AreaSwitch';
import { ConnectivityModal } from 'features/ConnectivityModal/ConnectivityModal';
import React from 'react';
import CoreView from 'routes/Core/Core.view';
import PusherServiceProvider from 'services/realtime-transport/ivicos-pusher/provider';
import { useIdentityClaims } from 'shared-state/identity/hooks';
import { useCurrentAreaId } from 'shared-state/location/hook';

const Core: React.FC<{}> = ({ children }) => {
    const [areaId] = useCurrentAreaId();
    const [identity] = useIdentityClaims();

    const onRenderTopBarDocumentPicker = (textColor?: string) => (identity ? <AreaSwitch color={textColor} /> : <></>);
    return (
        <>
            <IdentityAdapter />
            <ResourceAdapter />
            <ConnectivityModal />
            {areaId ? (
                <PusherServiceProvider>
                    <RealtimeAdapter />
                    <CoreView onRenderTopBarDocumentPicker={onRenderTopBarDocumentPicker}>{children}</CoreView>
                </PusherServiceProvider>
            ) : (
                <CoreView onRenderTopBarDocumentPicker={onRenderTopBarDocumentPicker}>{children}</CoreView>
            )}
        </>
    );
};

export default Core;
