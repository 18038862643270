import { Icon, ITheme, ITooltipHostStyles, Stack, Text, TooltipHost } from '@fluentui/react';
import { RoomListMoreButton } from '../MoreButton/MoreButton';
import { IResource, IRoomResource } from 'kits/apiKit3/legacy';
import IconKit from 'kits/IconKit';
import React, { useEffect } from 'react';
import 'styles/RoomList.css';
import { IRoomAction } from 'features/Rooms/RoomList/helpers/roomTypes';
import { useId } from '@uifabric/react-hooks';
import { IC } from 'components/SVGIcon';
import SvgVisitors from 'kits/IconKit2/Visitors';
import { More, SvgLockNo, SvgLockYes } from 'kits/IconKit2';
import { IListedRoomHeaderState } from './ListedRoomHeader.state';

const calloutProps = { gapSpace: 0 };
const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

export const RoomStatusIcon: React.FC<{ variant: 'dark' | 'green' | 'red'; toolTip?: string }> = ({ children, toolTip, variant }) => {
    const tooltipId = useId('roomIcon');

    if (!toolTip)
        return (
            <Stack style={{ padding: 4 }}>
                <IC size={18} variant={variant}>
                    {children}
                </IC>
            </Stack>
        );

    return (
        <TooltipHost content={toolTip} calloutProps={calloutProps} styles={hostStyles}>
            <Stack style={{ padding: 4 }}>
                <IC size={18} variant={variant}>
                    {children}
                </IC>
            </Stack>
        </TooltipHost>
    );
};

const ListedRoomHeaderView: React.FC<IListedRoomHeaderState & { isSelected?: boolean; actions: IRoomAction[] }> = ({
    isSelected,
    actions,
    symbol,
    displayName,
    isPrivate,
    whitelist,
    openForVisitors,
    onDoubleClick,
    isVisitorInArea,
    theme,
    userId,
    isRoomEnteranceAllowedForUser,
    roomOwner
}) => {
    if (isPrivate && !roomOwner)
        return (
            <Stack
                key="header"
                horizontal
                verticalAlign="center"
                style={{
                    padding: '3px 3px',
                    borderRadius: '4px 4px 0px 0px',
                    backgroundColor: isSelected ? theme.palette.themeLight : theme.palette.white,
                    color: theme.palette.black
                }}
            >
                <Text variant="smallPlus" style={{ margin: '3px 8px' }}>
                    {displayName}
                </Text>
            </Stack>
        );

    const tooltipId = useId('whitelist_lock_tooltip');

    return (
        <Stack
            key={'header'}
            horizontal
            verticalAlign="center"
            styles={{
                root: {
                    opacity: !isRoomEnteranceAllowedForUser() ? '0.4' : '1',
                    width: '100%',
                    cursor: 'pointer',
                    background: isSelected ? theme.palette.themeLight : theme.palette.white,
                    ':hover': {
                        backgroundColor: theme.palette.themeLight
                    },
                    ':active': {
                        backgroundColor: theme.palette.themeTertiary
                    }
                }
            }}
        >
            <Stack
                horizontal
                className="padding--small"
                grow
                verticalAlign="center"
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                onDoubleClick={(e) => isRoomEnteranceAllowedForUser() && onDoubleClick && onDoubleClick()}
                style={{ height: '100%', minHeight: '100%' }}
            >
                <Stack disableShrink={true} key={'symbol'} className="roomlist_room_symbol-wrapper" verticalAlign="center" horizontalAlign="center">
                    <IconKit.Icon icon={IconKit.getIcon('Rooms', symbol)} size={24} />
                </Stack>
                <Stack key={'displayName'} grow style={{ overflow: 'hidden' }}>
                    <Text title={displayName} className="shorten-text" style={{ maxWidth: 160, color: theme.palette.black }}>
                        {displayName} {isRoomEnteranceAllowedForUser()}
                    </Text>
                </Stack>
            </Stack>

            <Stack className="padding--small" horizontal verticalAlign="center">
                {openForVisitors && whitelist.length == 0 && (
                    <RoomStatusIcon toolTip="The room is open to visitors" variant={isVisitorInArea ? 'green' : 'dark'}>
                        <SvgVisitors />
                    </RoomStatusIcon>
                )}

                {!isRoomEnteranceAllowedForUser() && (
                    <Stack style={{ marginRight: 2.2 }}>
                        <RoomStatusIcon toolTip="You do not have access to this room" variant="dark">
                            <SvgLockNo />
                        </RoomStatusIcon>
                    </Stack>
                )}
                {isRoomEnteranceAllowedForUser() && isPrivate && roomOwner && (
                    <RoomStatusIcon toolTip="The access to this room is restricted" variant="green">
                        <SvgLockYes />
                    </RoomStatusIcon>
                )}
                {isRoomEnteranceAllowedForUser() ? (
                    <RoomListMoreButton actions={actions} />
                ) : (
                    <RoomStatusIcon variant="dark">
                        <More />
                    </RoomStatusIcon>
                )}
            </Stack>
        </Stack>
    );
};

export default ListedRoomHeaderView;
