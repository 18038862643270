/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { useRecoilState } from 'recoil';
import { aTabOptionList } from 'shared-state/tabs/atoms';
import { sTabOptionInList } from 'shared-state/tabs/selectors';

export const useTabOptionList = (listId: string) => {
    return useRecoilState(aTabOptionList(listId));
};

export const useTabOption = (listId: string, key: string) => {
    return useRecoilState(sTabOptionInList({ listId, key }));
};
