import React from 'react';
import { useVisitorsTabControllerState } from './VisitorsTabController.state';
import VisitorsTabControllerView from './VisitorsTabController.view';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const VisitorsTabController: React.FC<{}> = ({ children }) => {
    const state = useVisitorsTabControllerState();
    return (
        <VisitorsTabControllerView
            {...{
                ...state
            }}
        ></VisitorsTabControllerView>
    );
};

export default VisitorsTabController;
