/* eslint-disable prettier/prettier */
/* eslint-disable quotes */
import LocalizedStrings, { LocalizedStringsMethods } from 'react-localization';

export interface IStrings extends LocalizedStringsMethods {
    //Topbar
    helpLinkTitle: string;
    experimentalLinkTitle: string;
    userDetailsPanelTitle: string;
    userSettingsActionTitle: string;
    signOutActionTitle: string;

    // General
    someone: string;
    dismissActionTitle: string;
    saveChangesActionTitle: string;
    savingActionLoadingTitle: string;
    savingActionSuccessTitle: string;

    // Auth
    loginPromptTitle: string;
    loginPromptDescription: string;
    loggedInAsLabel: string;

    // Notifications
    meetNotificationActionTitle: string;
    inviteNotificationActionTitle: string;
    acceptNotificationActionTitle: string;
    inXMinutesNotificationTitle: string;
    inXMinutesMessageBody: string;
    answerNotificationTitle: string;
    visitorArrivalNotificationTitle: string;
    visitorArrivalNotificationBody: string;
    doorKnockNotificationTitle: string;
    doorKnockNotificationBody: string;
    doorKnockConfirmationNotificationTitle: string;
    doorKnockConfirmationNotificationBody: string;
    messagePromptLabel: string;
    memoRecievedNotificationTitle: string;
    memoSentNotificationTitle: string;
    memoSentNotificationBody: string;

    //User Actions
    inviteUserToRoomActionTitle: string;
    sendUserMemoActionTitle: string;

    //Sidebar
    roomsTabTitle: string;
    personalRoomsSectionTitle: string;
    personalRoomsSectionEmptyNotice: string;
    personalRoomLabel: string;
    commonRoomsSectionTitle: string;
    commonRoomsSectionEmptyNoticeTitle: string;
    commonRoomsSectionEmptyNotice: string;

    visitorTabTitle: string;
    visitorLinkTitle: string;
    visitorLinkDescription: string;
    copyButtonLabel: string;
    copyButtonSuccessLabel: string;
    copyButtonErrorLabel: string;
    yourVisitorsSectionTitle: string;
    yourVisitorsSectionEmptyNotice: string;
    allVisitorsSectionTitle: string;
    allVisitorsSectionEmpyNotice: string;
    letVisitorInsideActionTitle: string;
    meetVisitorActionTitle: string;

    // Room Actions
    enterRoomActionTitle: string;
    knockOnDoorActionTitle: string;
    roomSettingsActionTitle: string;
    createNewRoomActionTitle: string;
    createNewRoomActionLoadingTitle: string;
    deleteRoomActionTitle: string;

    // Room settings
    roomSettingsPanelTitle: string;
    roomNameInputLabel: string;
    iconPickerLabel: string;
    privacySectionLabel: string;
    openToVisitorsCheckBoxLabel: string;
    openTovisitorsCheckBoxDescription: string;
    confirmDeletePromptLabel: string;
    audioOnlyCheckBoxLabel: string;
    audioOnlyCheckBoxDescription: string;
    whitelistSelectorLabel: string;
    whitelistSelectorDescription: string;
    toggleWhitelistLabel: string;
    toggleWhitelistDescription: string;

    //Call view
    deviceSettingsActionTitle: string;
    toggleVideoActionTitle: string;
    toggleAudioActionTitle: string;
    toggleTileViewActionTitle: string;
    shareScreenActionTitle: string;
    shareScreenActionActiveTitle: string;
    toggleEncryptionActionTitle: string;
    toggleEncryptionActionActiveTitle: string;
    toggleStatsActionTitle: string;
    toggleBackgroundDialogActionTitle: string;
    shareVideoActionTitle: string;

    leaveRoomActionTitle: string;
    audioIsMutedLabel: string;
    videoIsMutedLabel: string;
    encryptionIsEnabledLabel: string;
    connectedToLabel: string;

    //Addons
    showAddonActionLabel: string;
    switchToMobileLabel: string;
    disableMobileLabel: string;

    // Portal
    portalPageTitle: string;
    availableAreasTitle: string;
    manageAccessTitle: string;
    addAreaToTabBarActionTitle: string;
    removeAreaFromTabBarActionTitle: string;
    changeLogTitle: string;
    areaOpenToEveryoneLabel: string;

    //Alerts
    safariDetection: string;
    mobileDetection: string;
}

const IvicosStrings: IStrings = new LocalizedStrings({
    en: {
        //Topbar
        helpLinkTitle: 'Help',
        experimentalLinkTitle: 'Lab',
        userDetailsPanelTitle: 'User details',
        userSettingsActionTitle: 'User settings',
        signOutActionTitle: 'Sign out',

        // General
        someone: 'Someone',
        dismissActionTitle: 'Dismiss',
        saveChangesActionTitle: 'Save changes',
        savingActionLoadingTitle: 'Saving...',
        savingActionSuccessTitle: 'Saved!',

        // Auth
        loginPromptTitle: 'Identification, please!',
        loginPromptDescription: 'Sign in with your company-issued Microsoft account...',
        loggedInAsLabel: 'Logged in as {0}',

        // Notifications
        meetNotificationActionTitle: 'Meet',
        inviteNotificationActionTitle: 'Invite',
        acceptNotificationActionTitle: 'Accept',
        inXMinutesNotificationTitle: 'In {0} min...',
        inXMinutesMessageBody: "I'll be with you in {0} min...",
        answerNotificationTitle: 'Answer',
        visitorArrivalNotificationTitle: '{0} is waiting for you',
        visitorArrivalNotificationBody: 'Your guest is waiting for you at the visitors portal',
        doorKnockNotificationTitle: '{0}  is at the door',
        doorKnockNotificationBody: 'Do you want to invite them in?',
        doorKnockConfirmationNotificationTitle: 'Knocked on {0}',
        doorKnockConfirmationNotificationBody: 'Everyone inside has been notified',
        messagePromptLabel: 'What do you want to tell them?',
        memoRecievedNotificationTitle: '{0} says:',
        memoSentNotificationTitle: 'Memo sent.',
        memoSentNotificationBody: 'The user has been notified',

        //User Actions
        inviteUserToRoomActionTitle: 'Invite to your room',
        sendUserMemoActionTitle: 'Send memo',

        // Sidebar
        roomsTabTitle: 'Rooms',
        personalRoomsSectionTitle: 'Personal rooms',
        personalRoomsSectionEmptyNotice: 'There seems to be no one here',
        personalRoomLabel: "{0}'s personal room",
        commonRoomsSectionTitle: 'Rooms',
        commonRoomsSectionEmptyNoticeTitle: 'No rooms on this floor',
        commonRoomsSectionEmptyNotice: 'You can add rooms below.',

        visitorTabTitle: 'Visitors',
        visitorLinkTitle: 'Your invitation link',
        visitorLinkDescription: 'Share this link to invite people to this floor. You will get notified when you have a visitor and they will show up below.',
        copyButtonLabel: 'Copy to clipboard',
        copyButtonSuccessLabel: 'Copied.',
        copyButtonErrorLabel: 'Error :(',
        yourVisitorsSectionTitle: 'Your visitors',
        yourVisitorsSectionEmptyNotice: 'You have no visitors',
        allVisitorsSectionTitle: 'Other visitors',
        allVisitorsSectionEmpyNotice: 'Nobody else has visitors',
        letVisitorInsideActionTitle: 'Let them inside',
        meetVisitorActionTitle: 'Meet',

        // Room Actions
        enterRoomActionTitle: 'Enter Room',
        knockOnDoorActionTitle: 'Knock on Door',
        roomSettingsActionTitle: 'Room settings',
        createNewRoomActionTitle: 'Create new room',
        createNewRoomActionLoadingTitle: 'Creating new room...',
        deleteRoomActionTitle: 'Archive room',

        // Room settings
        roomSettingsPanelTitle: 'Room settings',
        roomNameInputLabel: 'Room name',
        iconPickerLabel: 'Icon',
        privacySectionLabel: 'Privacy',
        openToVisitorsCheckBoxLabel: 'Open to external visitors',
        openTovisitorsCheckBoxDescription:
            'Visitors invited by area members are able to enter this room. This room will be marked with a coloured tag when visitors are around',
        confirmDeletePromptLabel: 'Are you sure you want to delete this room?',
        audioOnlyCheckBoxLabel: 'Disable video in this room',
        audioOnlyCheckBoxDescription: 'Conversations in this room are audio only if this option is set',
        whitelistSelectorLabel: 'Whitelist',
        whitelistSelectorDescription: '',
        toggleWhitelistLabel: 'Allow access for only selected users',
        toggleWhitelistDescription: '',

        //Call view
        deviceSettingsActionTitle: 'Device settings',
        toggleVideoActionTitle: 'Toggle camera',
        toggleAudioActionTitle: 'Toggle microphone',
        toggleTileViewActionTitle: 'Toggle tile view',
        shareScreenActionTitle: 'Share screen',
        shareScreenActionActiveTitle: 'Stop sharing screen',
        toggleEncryptionActionTitle: 'Toggle encryption (BETA)',
        toggleEncryptionActionActiveTitle: 'Encryption is enabled (BETA)',
        toggleBackgroundDialogActionTitle: 'Set video background',
        leaveRoomActionTitle: 'Back to personal room',
        toggleStatsActionTitle: 'Toggle Speaker stats',
        shareVideoActionTitle: 'Play a video',
        audioIsMutedLabel: 'Your Mic is disabled',
        videoIsMutedLabel: 'Your Camera is disabled',
        encryptionIsEnabledLabel: 'End-to-end encrypted',
        connectedToLabel: 'Securely connected to',

        //Addons
        showAddonActionLabel: 'Show {0}',
        switchToMobileLabel: 'Switch to Mobile',
        disableMobileLabel: 'Go back to normal',

        // Portal
        portalPageTitle: 'Campus dashboard',
        availableAreasTitle: 'Areas',
        manageAccessTitle: 'Manage access',
        addAreaToTabBarActionTitle: 'Add to tab bar',
        removeAreaFromTabBarActionTitle: 'Remove from tab bar',
        changeLogTitle: 'Changelog',
        areaOpenToEveryoneLabel: 'Open to all members',

        //Alerts
        safariDetection: 'Sadly iv:CAMPUS does not support the Safari browser at the moment :/ We recommend using Edge or Chrome',
        mobileDetection: 'Sadly iv:CAMPUS does not support mobile view at the moment :/ We recommend using a computer'
    }
});

export default IvicosStrings;
