import { Callout, DefaultEffects, ITheme, mergeStyles, Stack, Text } from '@fluentui/react';
import { AreaDto } from 'client-api-wrapper/lib/campus_api/responses.dto';
import { IC } from 'components/SVGIcon';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import { getTextColorForBackground } from 'kits/colorKit';
import { Group, MsTeams, ThreeDots } from 'kits/IconKit2';
import { TeamImage } from 'kits/profileImageKit';
import IvicosStrings from 'kits/stringKit';
import React, { ReactElement } from 'react';
import { useArea } from 'shared-state/directory/hooks';
import { useIdentityClaims } from 'shared-state/identity/hooks';
// import 'styles/introjs-custom.css';
import 'styles/introjs-custom.css';
// import { useWindowWidth } from 'shared-state/display-options/hooks';
import { getClassNames } from 'styles/responsiveness';
import { useTheme } from '@fluentui/react-theme-provider';
import { IIdentityClaims } from 'shared-state/identity/types';

export interface IAreaCardViewProps {
    fluid?: boolean;
    panelHeader: string;
    isPanelOpen: boolean;
    dismissPanel: () => void;
    campusId: any;
    deleteAreaHandler: (areaId: string) => void;
    areaName: string;
    setAreaName: React.Dispatch<React.SetStateAction<string>>;
    vertical?: boolean;
    title: string;
    description?: string;
    team?: any;
    isPublic?: boolean;
    onClick?: (e: any) => void;
    onRenderActions?: () => ReactElement;
    setPanelHeader: React.Dispatch<React.SetStateAction<string>>;
    openPanel: () => void;
    areaId: string;
    saveDataForAreaDeletion: any;
    modalIsOpen: boolean;
    setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setAreaToEdit: any;
    theme: ITheme;
    getPrimaryCardColor: () => string;
    stepsEnabled?: boolean;
    setStepsEnabled: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    steps: {
        initialStep: number;
        steps: (
            | {
                  element: string;
                  intro: string;
                  position: string;
                  tooltipClass: string;
                  showProgress?: undefined;
                  showBullets?: undefined;
              }
            | {
                  element: string;
                  intro: string;
                  position: string;
                  showProgress: boolean;
                  showBullets: boolean;
                  tooltipClass?: undefined;
              }
        )[];
    };
    options: {
        doneLabel: string;
        hideNext: boolean;
        showStepNumbers: boolean;
        showBullets: boolean;
        hidePrev: boolean;
    };
    areaCardWidth: string;
    areaCardWidthVertical: string;
    area?: AreaDto;
    descriptionId: string;
    labelId: string;
    buttonId: string;
    identityClaims?: IIdentityClaims;
    isCalloutVisible: boolean;
    toggleIsCalloutVisible: () => void;
}

const AreaCardView: React.FC<IAreaCardViewProps> = (props) => {
    const {
        vertical,
        title,
        description,
        isPublic,
        team,
        onClick,
        onRenderActions,
        setPanelHeader,
        openPanel,
        areaId,
        saveDataForAreaDeletion,
        modalIsOpen,
        setAreaToEdit,
        setModalIsOpen,
        theme,
        getPrimaryCardColor,
        stepsEnabled,
        setStepsEnabled,
        steps,
        options,
        areaCardWidth,
        areaCardWidthVertical,
        descriptionId,
        labelId,
        buttonId,
        identityClaims,
        isCalloutVisible,
        toggleIsCalloutVisible
    } = props;

    const areaCardImageStyles = mergeStyles({
        borderRadius: 2,
        height: 48,
        width: 48,
        minHeight: 48,
        maxHeight: 48,
        border: '2px ' + theme.palette.white + ' solid',
        boxShadow: DefaultEffects.elevation8,
        color: theme.palette.neutralLighter,
        background: getPrimaryCardColor(),
        marginTop: -48,
        marginBottom: 16
    });

    const [area] = useArea(areaId);

    return (
        <>
            <Steps
                enabled={stepsEnabled}
                onStart={() => {
                    localStorage.setItem('intro-areas', 'false');
                }}
                options={options}
                steps={steps.steps}
                initialStep={steps.initialStep}
                onExit={() => {
                    setStepsEnabled(false);
                }}
            />
            <Stack
                style={{
                    boxShadow: DefaultEffects.elevation8
                }}
                className={`${vertical ? areaCardWidthVertical : areaCardWidth} one-card`}
            >
                <Stack
                    key="area-color"
                    className="padding--small"
                    horizontalAlign="end"
                    verticalAlign="center"
                    style={{ height: 32, background: getPrimaryCardColor() }}
                >
                    {onRenderActions && onRenderActions()}
                </Stack>
                <Stack
                    className="padding--default padding_vertical--large"
                    styles={{
                        root: {
                            background: theme.palette.white,
                            cursor: 'pointer',
                            ':hover': {
                                background: theme.palette.themeLighter
                            },
                            ':active': {
                                background: theme.palette.themeLight
                            }
                        }
                    }}
                    onClick={(e) => onClick && onClick(e)}
                >
                    <Stack key="area-image" verticalAlign="center" horizontalAlign="center" className={areaCardImageStyles}>
                        {team ? (
                            <TeamImage teamId={team.id} imageProps={{ style: { width: '100%', height: '100%' } }} />
                        ) : (
                            <Text variant="large" style={{ color: getTextColorForBackground(getPrimaryCardColor()) }}>
                                {title
                                    .split(' ')
                                    .reduce((a, b) => a + b[0], '')
                                    .slice(0, 2)}
                            </Text>
                        )}
                    </Stack>
                    <Text variant="large" style={{ fontWeight: 600, color: theme.palette.black }}>
                        {title}
                    </Text>
                    <Stack horizontal tokens={{ childrenGap: 8 }} style={{}}>
                        <IC size={20} variant="dark">
                            <Group />
                        </IC>
                        <Text variant="medium" style={{ color: theme.palette.black }}>
                            {IvicosStrings.areaOpenToEveryoneLabel}
                        </Text>
                    </Stack>

                    {description && (
                        <Stack style={{ borderTop: '1px #e6e6e6 solid', marginTop: 16, paddingTop: 8, color: theme.palette.black }}>
                            <Text>{description}</Text>
                        </Stack>
                    )}
                </Stack>
                <Stack style={{ alignItems: 'flex-end', backgroundColor: theme.palette.white }}>
                    {(identityClaims?.roles.includes('manager') || identityClaims?.roles.includes('owner')) && (
                        <IC size={17} variant="custom" style={{ width: '10%', padding: '5px' }}>
                            <ThreeDots id={buttonId} onClick={toggleIsCalloutVisible} style={{ cursor: 'pointer' }} />
                        </IC>
                    )}
                </Stack>
                {isCalloutVisible && (
                    <Callout
                        ariaLabelledBy={labelId}
                        ariaDescribedBy={descriptionId}
                        role="alertdialog"
                        gapSpace={0}
                        target={`#${buttonId}`}
                        onDismiss={toggleIsCalloutVisible}
                        setInitialFocus
                    >
                        <Stack style={{ margin: '10px', height: '50px', justifyContent: 'center' }}>
                            <Text
                                variant="mediumPlus"
                                style={{ cursor: 'pointer', marginBottom: '7px' }}
                                onClick={() => {
                                    openPanel();
                                    setAreaToEdit(area && area.name, areaId);
                                    setPanelHeader('Edit Area');
                                }}
                            >
                                Edit Area
                            </Text>
                            <Text
                                variant="mediumPlus"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setModalIsOpen(!modalIsOpen);
                                    saveDataForAreaDeletion(area && area.name, areaId);
                                }}
                            >
                                Delete Area
                            </Text>
                        </Stack>
                    </Callout>
                )}
            </Stack>
        </>
    );
};

export default AreaCardView;
