import React, { useEffect, useState } from 'react';
import { RealtimeContext } from 'services/realtime-transport';
import { DefaultEventTypes } from 'services/realtime-transport/ivicos-pusher/pusher/event-types';
import { cleanUpPusherInstance, initPusherInstance } from 'services/realtime-transport/ivicos-pusher/pusher/instance';
import { IRealtimeService } from 'services/realtime-transport/types';
import { bind, getChannelNameForOptions, getChannelSubscribers, getSocketId, send, subscribe, unbind, unsubscribe } from './actions';

const PusherServiceProvider: React.FC<{}> = ({ children }) => {
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    useEffect(() => {
        initPusherInstance().then(() => setIsInitialized(true));

        return () => {
            cleanUpPusherInstance();
            setIsInitialized(false);
        };
    }, []);

    const value: IRealtimeService = {
        id: 'PUSHER',
        isInitialized,
        defaultEventTypes: DefaultEventTypes,
        getSocketId,
        send,
        bind,
        unbind,
        subscribe,
        unsubscribe,
        getChannelSubscribers,
        getChannelNameForOptions
    };

    return <RealtimeContext.Provider value={value}>{children}</RealtimeContext.Provider>;
};

export default PusherServiceProvider;
