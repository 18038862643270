import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { idpService } from 'services/api/identity-provider.service';

export interface IPowerUserImporterStateProps {
    routeHistory: any;
    actionKey?: string;
    isOpen: boolean;
    setIsOpen: Function;
    step: number;
    setStep: Function;
    invitationStatus: number[];
    setInvitationStatus: Function;
    setHeadline: Function;
    headline: string[];
    body: string[][];
    setBody: React.Dispatch<React.SetStateAction<string[][]>>;
    onFileUpload: (e: any) => void;
    setStatus: Function;
    onAcceptData: Function;
    onFinish: Function;
}

export const PowerUserImporterState: () => IPowerUserImporterStateProps = () => {
    const routeHistory = useHistory();
    const { actionKey } = useParams<{ actionKey?: string }>();
    const isOpen = actionKey == 'powerImporter';
    const setIsOpen = (a: boolean) => {
        routeHistory.push('/dashboard/members' + (a ? '/powerImporter' : ''));
    };
    const [step, setStep] = useState(0);
    const [invitationStatus, setInvitationStatus] = useState<number[]>([]);

    const [headline, setHeadline] = useState<string[]>([]);
    const [body, setBody] = useState<Array<string[]>>([]);
    const onFileUpload = (file: File) => {
        const reader = new FileReader();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        reader.onload = (ev) => {
            const text = reader.result;
            if (typeof text != 'string') return;

            const parsedCSV = text
                ?.split('\r')
                .join('')
                .split('\n')
                .map((line) => line.split(';'));
            const nextHeadline = parsedCSV[0];
            const nextBody = parsedCSV.splice(1);
            setHeadline(nextHeadline);
            setBody(
                nextBody
                    .map((importedPerson) => {
                        return [
                            importedPerson[0],
                            ['user', 'manager'].includes(importedPerson[1].toLocaleLowerCase()) ? importedPerson[1].toLocaleLowerCase() : importedPerson[1]
                        ];
                    })
                    .sort((a: string[], b: string[]) => {
                        return a[0].localeCompare(b[0]);
                    })
            );
            setStep(1);
        };
        reader.readAsText(file);
    };

    const setStatus = (index: number, value: number) => {
        setInvitationStatus((prevStatus: number[]) => {
            const imStatus = [...prevStatus];

            imStatus[index] = value;

            return imStatus;
        });
    };

    const onAcceptData = () => {
        setStep(2);
        body.forEach((line, index) => {
            const [email, role] = line;

            if (email && ['user', 'manager'].includes(role))
                idpService
                    .newInvited(email, role == 'manager' ? role : 'user')
                    .then(() => {
                        setStatus(index, 1);
                    })
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .catch((err) => {
                        setStatus(index, 2);
                    });
        });
    };

    const onFinish = () => {
        setIsOpen(false);
        setBody([]);
        setHeadline([]);
        setInvitationStatus([]);
        setStep(0);
    };

    return {
        routeHistory,
        actionKey,
        isOpen,
        setIsOpen,
        step,
        setStep,
        invitationStatus,
        setInvitationStatus,
        headline,
        setHeadline,
        body,
        setBody,
        onFileUpload,
        setStatus,
        onAcceptData,
        onFinish
    };
};
