import React from 'react';
import 'styles/helpers.css';
import { useRoomViewState } from './RoomView.state';
import RoomViewView from './RoomView.view';

const RoomView: React.FC<{ mobileMenuOpen: boolean }> = (props) => {
    const state = useRoomViewState();

    return (
        <RoomViewView
            {...{
                ...state,
                ...{
                    mobileMenuOpen: props.mobileMenuOpen
                }
            }}
        >
            {props.children}
        </RoomViewView>
    );
};
export default RoomView;
