import React, { ReactElement } from 'react';
import { useAreaCardState } from './AreaCard.state';
import AreaCardView from './AreaCard.view';

export interface IAreaCardProps {
    fluid?: boolean;
    panelHeader: string;
    isPanelOpen: boolean;
    dismissPanel: () => void;
    campusId: string;
    deleteAreaHandler: (areaId: string) => void;
    areaName: string;
    setAreaName: React.Dispatch<React.SetStateAction<string>>;
    vertical?: boolean;
    title: string;
    description?: string;
    //ask friedrich
    team?: any;
    isPublic?: boolean;
    onClick?: (e: any) => void;
    onRenderActions?: () => ReactElement;
    setPanelHeader: React.Dispatch<React.SetStateAction<string>>;
    openPanel: () => void;
    areaId: string;
    //ask friedrich
    saveDataForAreaDeletion: any;
    modalIsOpen: boolean;
    setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    //ask friedrich
    setAreaToEdit: any;
}

const AreaCard: React.FC<IAreaCardProps> = (props) => {
    const state = useAreaCardState(props.isPublic);

    return (
        <AreaCardView
            {...{
                ...state,
                ...{
                    vertical: props.vertical,
                    title: props.title,
                    description: props.description,
                    team: props.team,
                    onClick: props.onClick,
                    onRenderActions: props.onRenderActions,
                    setPanelHeader: props.setPanelHeader,
                    openPanel: props.openPanel,
                    areaId: props.areaId,
                    saveDataForAreaDeletion: props.saveDataForAreaDeletion,
                    modalIsOpen: props.modalIsOpen,
                    setModalIsOpen: props.setModalIsOpen,
                    setAreaToEdit: props.setAreaToEdit,
                    panelHeader: props.panelHeader,
                    isPanelOpen: props.isPanelOpen,
                    dismissPanel: props.dismissPanel,
                    campusId: props.campusId,
                    deleteAreaHandler: props.deleteAreaHandler,
                    areaName: props.areaName,
                    setAreaName: props.setAreaName
                }
            }}
        ></AreaCardView>
    );
};
export default AreaCard;
