/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useHistory } from 'react-router';
import { idpService } from 'services/api/identity-provider.service';
import { useRealtimeService } from 'services/realtime-transport';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { IProfile } from 'shared-state/identity/types';
import { useCurrentAreaId, useCurrentRoomId } from 'shared-state/location/hook';
import { makeMessageId, useReceivedMessageInbox } from 'shared-state/notifications/hooks';
import { useVisitorList } from 'shared-state/presence/hooks';
import { useIvicosAccesToken } from 'shared-state/tokens/hooks';

const useVisitorAddedHandler = () => {
    const [currentAreaId] = useCurrentAreaId();
    const localProfile = useLocalProfile();

    const [, setVisitorList] = useVisitorList(currentAreaId || '');
    const [, addMessage] = useReceivedMessageInbox();

    const handleVisitor = (user: any) => {
        console.log('Handling...', user);
        if (!user) return console.warn('[RTA] User undefined');
        if (user.info.type != 'unconfirmed_visitor') return;
        const visitorUID = user.info.sub.split(':')[2];
        const newProfile: IProfile = {
            displayName: user.info.name,
            uid: visitorUID,
            type: 'visitor',
            host: user.info.inviterSub
        };

        if (localProfile?.uid && newProfile.host && newProfile.host.includes(localProfile?.uid))
            addMessage({
                type: 'guest',
                body: user.info.name + ' is waiting for you...',
                timestamp: new Date(),
                id: makeMessageId(),
                sender: 'system',
                meta: { attachedRoom: 'visitor-' + user.info.sub.split(':')[2] }
            });

        setVisitorList((prevList: IProfile[]) => {
            return [...prevList, ...[newProfile]];
        });
    };
    return handleVisitor;
};

const useConnectionHandler = () => {
    const handleVisitor = useVisitorAddedHandler();
    const handleConnection = (data: any) => {
        console.log('[RTA] Connected to Visitors channel', data);
        Object.keys(data.members).forEach((memberKey: any) => handleVisitor({ info: data.members[memberKey] }));
    };
    return handleConnection;
};

const useTokenHandler = () => {
    const routeHistory = useHistory();
    const localProfile = useLocalProfile();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [, setAccessToken] = useIvicosAccesToken('default');
    const [currentRoomId, setCurrentRoomId] = useCurrentRoomId();
    const [currentAreaId] = useCurrentAreaId();

    const handleToken = (data: any, metaData: any) => {
        console.log('[RTA] Received a token', data);
        // Do something with the message
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const senderId = metaData.user_id.split(':')[3];

        if (data.meta?.forUser != localProfile?.uid && data.meta?.forUser != localProfile?.uid) return;
        if (!currentRoomId?.includes('visitor')) return;
        idpService.useConfirmationToken(data.token);
        setCurrentRoomId(data.meta.attachedRoom);
        data.meta?.attachedRoom && routeHistory.push('/areas/' + currentAreaId + '/rooms/' + data.meta.attachedRoom);
    };
    return handleToken;
};

const useVisitorRemovedHandler = () => {
    const [currentAreaId] = useCurrentAreaId();

    const [, setVisitorList] = useVisitorList(currentAreaId || '');
    const handleVisitorRemoved = (user: any) => {
        console.log('[RTA] VISITOR LEFT', user);

        setVisitorList((prevList: IProfile[]) => {
            console.log('[RTA] Vlist', prevList);
            const nextList = prevList.filter((u) => !user.info.sub.includes(u.uid));
            console.log('[RTA] Vlist II ', nextList);

            return nextList;
        });
    };
    return handleVisitorRemoved;
};

export const useVisitorPresenceChannelHandlers = () => {
    const REALTIME = useRealtimeService();
    const DefaultEventTypes = REALTIME.defaultEventTypes;

    const handleVisitorAdded = useVisitorAddedHandler();
    const handleConnection = useConnectionHandler();
    const handleToken = useTokenHandler();
    const handleVisitorRemoved = useVisitorRemovedHandler();
    return [
        {
            eventName: DefaultEventTypes.connected,
            callback: handleConnection
        },
        {
            eventName: DefaultEventTypes.memberAdded,
            callback: handleVisitorAdded
        },
        {
            eventName: 'client-token',
            callback: handleToken
        },
        {
            eventName: DefaultEventTypes.memberRemoved,
            callback: handleVisitorRemoved
        }
    ];
};
