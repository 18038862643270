import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    IDialogContentProps,
    Layer,
    List,
    Persona,
    PersonaSize,
    PrimaryButton,
    Stack,
    Text
} from '@fluentui/react';
import { useTokenSender } from 'adapters/realtime-adapter/actions/messaging';
import { IRoomResource } from 'kits/apiKit3/legacy';
import IconKit from 'kits/IconKit';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { idpService } from 'services/api/identity-provider.service';
import { useSelectedVisitor, useStyledRoomList } from 'shared-state/directory/hooks';
import { useShowVisitorRoomSelectionDialog } from 'shared-state/display-options/hooks';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { useCurrentAreaId, useCurrentCampusId } from 'shared-state/location/hook';
import { useUsersInRoom } from 'shared-state/presence/hooks';
import { useTabOption } from 'shared-state/tabs/hooks';
import { ITabOption } from 'shared-state/tabs/types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const VisitorRoomSelector: React.FC<{}> = ({ children }) => {
    const [isOpen, setIsOpen] = useShowVisitorRoomSelectionDialog();
    const [selectedVisitor] = useSelectedVisitor();
    const [areaId] = useCurrentAreaId();
    const [campusId] = useCurrentCampusId();
    const sendToken = useTokenSender();
    const routeHistory = useHistory();
    const rooms = useStyledRoomList();
    const localProfile = useLocalProfile();

    const [, setTabOption] = useTabOption('sidebar', 'rooms');

    const selectRooomsTab = () => {
        setTabOption((prevOption: ITabOption | undefined) => {
            if (!prevOption) return prevOption;
            const nextOption: ITabOption = { ...prevOption, ...{ isSelected: true } };
            return nextOption;
        });
    };

    const [selectedRoom, setSelectedRoom] = useState<string | undefined>();

    const [buttonLabel, setButtonLabel] = useState<string>('Let them in');

    const letVisitorInside = async (visitorId: string, roomId: string) => {
        if (!areaId || !campusId) return;
        setButtonLabel('Loading...');

        const token = await idpService.confirmVisitor(areaId, campusId, visitorId);
        if (!token) {
            setButtonLabel('Error :(');
            return false;
        }

        sendToken(token, visitorId, roomId);
        setButtonLabel('Success!');

        routeHistory.push('/areas/' + areaId + '/rooms/' + selectedRoom);
        cancel();
        selectRooomsTab();

        return true;
    };

    const dialogContentProps: IDialogContentProps = {
        type: DialogType.normal,
        styles: {
            title: {
                fontSize: 16
            }
        },
        title: 'Take your visitor inside...',
        subText: 'Select a room to take them into',
        closeButtonAriaLabel: 'Close'
    };

    const renderListCell = (room: (IRoomResource & { selected?: boolean }) | undefined) => {
        if (!room) return <></>;
        return <SelectorListCell room={room} onClick={() => setSelectedRoom(room.id)} />;
    };

    const cancel = () => {
        setIsOpen(false);
    };

    const send = () => {
        selectedVisitor && selectedRoom && letVisitorInside(selectedVisitor, selectedRoom);
    };
    if (!isOpen || !selectedVisitor) return <></>;

    const openRooms = rooms
        .filter((room) => room.attributes.openForVisitors && (room.attributes.whitelist || []).length == 0)
        .map((r) => ({ ...r, ...{ selected: r.id == selectedRoom } }));

    // Create a fake room resource (this could be done by a factory in the future)
    const virtualRoomId = 'personal-' + localProfile?.uid + '_' + areaId;
    const virtualRoom: IRoomResource & { selected: boolean } = {
        selected: virtualRoomId == selectedRoom,
        id: virtualRoomId,
        type: 'iv_campus_room',
        attributes: { variant: 'DEFAULT', symbol: 'Room', private: true, displayName: localProfile?.displayName + '´s personal Room', openForVisitors: true },
        relationships: { area: areaId || '', owner: localProfile?.uid || '*' }
    };

    // Append it to the list of available rooms
    const roomList = [...[virtualRoom], ...openRooms];

    return (
        <Layer>
            <Dialog hidden={false} onDismiss={cancel} dialogContentProps={dialogContentProps}>
                <Stack tokens={{ childrenGap: 16 }}>
                    <List items={roomList} onRenderCell={renderListCell} />
                </Stack>
                <DialogFooter>
                    <DefaultButton onClick={cancel} text="Cancel" />

                    <PrimaryButton disabled={!selectedRoom} onClick={send} text={buttonLabel} />
                </DialogFooter>
            </Dialog>
        </Layer>
    );
};

export default VisitorRoomSelector;

export const SelectorListCell: React.FC<{ room: IRoomResource & { selected?: boolean }; onClick: Function }> = ({ room, onClick }) => {
    const usrs = useUsersInRoom(room.id);

    return (
        <Stack
            className="pressable padding--small"
            onClick={() => onClick()}
            style={{ background: room.selected ? 'rgba(0,0,0,0.1)' : 'transparent', marginBottom: 8 }}
            tokens={{ childrenGap: 8 }}
        >
            <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center">
                <Stack verticalAlign="center" horizontalAlign="center" style={{ width: 24, height: 24 }}>
                    <IconKit.Icon icon={IconKit.getIcon('Rooms', room.attributes.symbol)}></IconKit.Icon>
                </Stack>
                <Text>{room.attributes.displayName}</Text>
            </Stack>
            {usrs.length > 0 && (
                <Stack className="padding--small" style={{ paddingLeft: 8, marginLeft: 8, borderLeft: '2px #ccc solid' }} horizontal wrap>
                    {usrs.map((userProfile) => {
                        return (
                            <Persona
                                key={userProfile.uid}
                                size={PersonaSize.size8}
                                text={userProfile.displayName + (userProfile.type == 'visitor' ? '(V)' : '')}
                            />
                        );
                    })}
                </Stack>
            )}
        </Stack>
    );
};
