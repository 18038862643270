import { useNotificationAssets } from 'features/NotificationsLayer/hooks/useNotificationAssets';
import { useEffect, useState } from 'react';
import { makeMessageId, useReceivedMessage } from 'shared-state/notifications/hooks';
import { IReceivedMessage } from 'shared-state/notifications/types';
import { INotificationBannerAction } from './NotificationsBanner.view';

export interface INotificationsBannerState {
    getNotificationIcon: Function;
    timestamp: string;
    title: string;
    description?: string;
    onDismiss: () => void;
    actions: INotificationBannerAction[];
}

export const useNotificationBannerState: (messageId: string) => INotificationsBannerState = (messageId) => {
    const [message, setMessage] = useReceivedMessage(messageId);
    const [webNotification, setWebNotification] = useState<Notification | undefined>();

    const onDismiss = () => {
        setMessage((prevMessage) => {
            if (!prevMessage) return prevMessage;
            return { ...prevMessage, ...{ dismissed: true } };
        });
        webNotification?.close();
    };

    const getMessageFallback = () => {
        const defaultMessage: IReceivedMessage = {
            id: makeMessageId(),
            sender: 'server',
            type: 'info',
            meta: {},
            timestamp: new Date(),
            body: 'Something went wrong',
            announce: false
        };

        return defaultMessage;
    };

    const { title, description, timestamp, actions, getNotificationIcon } = useNotificationAssets(message || getMessageFallback(), onDismiss);

    const webNotifcationTitle = message?.type == 'memo' ? title + ' ' + description : title;

    useEffect(() => {
        if (!document.hasFocus()) setWebNotification(new Notification(webNotifcationTitle, {}));
        return () => {
            webNotification?.close();
        };
    }, [title]);

    return { getNotificationIcon, timestamp, title, description, onDismiss, actions };
};
