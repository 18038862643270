import { useSideBarTabIconState } from 'features/SideBar/children/SideBarTabIcon/SidBarTabIcon.state';
import { SideBarTabIconView } from 'features/SideBar/children/SideBarTabIcon/SideBarTabIcon.view';
import React from 'react';

export interface ISideBarTabIconProps {
    tabKey: string;
    title: string;
    selectedByDefault?: boolean;
    onSelect: Function;
}
export const SideBarTabIcon: React.FC<ISideBarTabIconProps> = (props) => {
    const { children } = props;

    const state = useSideBarTabIconState(props);

    return <SideBarTabIconView {...state}>{children}</SideBarTabIconView>;
};
