import { useMemo } from 'react';
import { useShowAreaPanel, useStaredAreas } from 'shared-state/display-options/hooks';
import { useCurrentAreaId, useCurrentOrgId } from 'shared-state/location/hook';
import { useAreaList } from 'shared-state/directory/hooks';
import { useHistory } from 'react-router';
import { SetterOrUpdater } from 'recoil';
export interface IAreaSwitchState {
    currentAreaId?: string;
    areaOptions: {
        key: string;
        title: string;
    }[];
    setShowAreaPanel: SetterOrUpdater<boolean>;
    onClickOption: (e: any, k: string | null) => void;
}

export const useAreaSwitchState: (color?: string) => IAreaSwitchState = (color) => {
    const routeHistory = useHistory();

    const [, setShowAreaPanel] = useShowAreaPanel();
    const [orgId] = useCurrentOrgId();
    const [currentAreaId] = useCurrentAreaId();
    const [staredAreaIds] = useStaredAreas(orgId || 'default');
    const [areas] = useAreaList();
    const areaOptions = useMemo(() => areas.filter((area) => staredAreaIds.includes(area.id)).map((area) => ({ key: area.id, title: area.name })), [
        areas,
        staredAreaIds
    ]);

    const onClickOption = (e: any, k: string | null) => {
        if (currentAreaId && currentAreaId != 'null' && !confirm('Are you sure you want to leave this area?')) return;
        k && routeHistory.push('/areas/' + k);
    };

    return {
        currentAreaId,
        areaOptions,
        color,
        setShowAreaPanel,
        onClickOption
    };
};
