import { DefaultButton, IconButton, ITheme, List, PrimaryButton, Stack, Text, TextField } from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import { prettyDate } from 'features/ChangeLog/helpers';
import { parseUrlTemplate } from 'features/Rooms/RoomView/addons';
import AttachmentCreationModal from 'features/Rooms/RoomView/comps/Attachments/AttachmentCreationModal/AttachmentCreationModal';
import React from 'react';
import { resourceService } from 'services/api/resource.service';
import 'styles/helpers.css';
import AttachmentUpdateModal from '../AttachmentUpdateModel/AttachmentUpdateModal';
import { DownArrow, UpArrow } from 'kits/IconKit2';
import { IProfile } from 'shared-state/identity/types';
import { SetterOrUpdater } from 'recoil';
import { AttachmentDto } from 'client-api-wrapper/lib/campus_api/responses.dto';

export interface IAttachmentsPanelViewProps {
    campusId?: string;
    areaId?: string;
    roomId?: string;
    isAttachmentPanelVisible: boolean;
    theme: ITheme;
    setIsAttachmentPanelVisible: SetterOrUpdater<boolean>;
    isAttachmentCreationModalOpen: boolean;
    setIsAttachmentCreationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedAttachment: SetterOrUpdater<
        | {
              addOnId: string;
              ref: string;
              description: string;
          }
        | undefined
    >;
    refetchAttachments: () => Promise<void>;
    isAttachmentUpdateModalOpen: boolean;
    attachmentToEdit?: AttachmentDto;
    setIsAttachmentUpdateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setAttachmentToEdit: React.Dispatch<React.SetStateAction<AttachmentDto | undefined>>;
    filter?: string;
    setFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
    sortedAttachments: AttachmentDto[];
    setIsEditable: React.Dispatch<
        React.SetStateAction<{
            [key: string]: boolean;
        }>
    >;
    addOn: (
        addOnName: string
    ) =>
        | {
              id: string;
              type: string;
              attributes: {
                  urlTemplate: string;
                  iconUrl: string;
                  displayName: string;
                  description: string;
              };
              relationships: {};
          }
        | undefined;
    localProfile?: IProfile;
    isEditable: {
        [key: string]: boolean;
    };
    displayDescription: {
        [key: string]: boolean;
    };
    setDisplayDescription: React.Dispatch<
        React.SetStateAction<{
            [key: string]: boolean;
        }>
    >;
}

const AttachmentsPanelView: React.FC<IAttachmentsPanelViewProps> = (props) => {
    const {
        campusId,
        areaId,
        roomId,
        isAttachmentPanelVisible,
        theme,
        setIsAttachmentPanelVisible,
        isAttachmentCreationModalOpen,
        setIsAttachmentCreationModalOpen,
        setSelectedAttachment,
        refetchAttachments,
        isAttachmentUpdateModalOpen,
        attachmentToEdit,
        setIsAttachmentUpdateModalOpen,
        setAttachmentToEdit,
        filter,
        setFilter,
        sortedAttachments,
        setIsEditable,
        addOn,
        localProfile,
        isEditable,
        displayDescription,
        setDisplayDescription
    } = props;

    if (!campusId || !areaId || !roomId) return <></>;

    return (
        <>
            {isAttachmentPanelVisible && (
                <Stack style={{ background: theme.palette.white, color: theme.semanticColors.bodyText, minWidth: 320, width: 320, padding: '8px 16px' }}>
                    <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                        <Text variant="mediumPlus">Attachments</Text>
                        <IconButton
                            onClick={() => {
                                setIsAttachmentPanelVisible(false);
                            }}
                            iconProps={{ iconName: 'clear' }}
                        />
                    </Stack>
                    {isAttachmentCreationModalOpen ? (
                        <AttachmentCreationModal
                            isOpen={isAttachmentCreationModalOpen}
                            onAttachmentCreation={(attachment?) => {
                                setIsAttachmentCreationModalOpen(false);
                                setSelectedAttachment(undefined);
                                setTimeout(() => {
                                    attachment &&
                                        setSelectedAttachment({ addOnId: attachment.addOnName, description: attachment.description, ref: attachment.ref });
                                }, 400);
                                setIsAttachmentPanelVisible(false);
                                refetchAttachments();
                            }}
                            onDismiss={() => {
                                setIsAttachmentCreationModalOpen(false);
                                refetchAttachments();
                            }}
                        />
                    ) : null}
                    {isAttachmentUpdateModalOpen ? (
                        <AttachmentUpdateModal
                            attachment={attachmentToEdit}
                            isOpen={isAttachmentUpdateModalOpen}
                            onAttachmentUpdate={() => {
                                setIsAttachmentUpdateModalOpen(false);
                                refetchAttachments();
                                setAttachmentToEdit(undefined);
                            }}
                            onDismiss={() => {
                                setIsAttachmentUpdateModalOpen(false);
                                refetchAttachments();
                                setAttachmentToEdit(undefined);
                            }}
                        />
                    ) : null}
                    <Stack tokens={{ childrenGap: 16 }} style={{ paddingTop: 16 }}>
                        <PrimaryButton
                            onClick={() => {
                                setIsAttachmentCreationModalOpen(true);
                            }}
                            iconProps={{ iconName: 'Add' }}
                            text="Create new attachment"
                        />

                        <Stack horizontal tokens={{ childrenGap: 6 }}>
                            <Stack.Item grow>
                                <TextField
                                    value={filter}
                                    iconProps={{ iconName: 'search', styles: { root: { color: theme.semanticColors.bodyText } } }}
                                    placeholder="Filter attachments..."
                                    onChange={(e, newValue) => setFilter(newValue)}
                                />
                            </Stack.Item>
                            {filter != undefined && filter.length > 0 && <DefaultButton text="Clear filter" onClick={() => setFilter(undefined)} />}
                        </Stack>
                        <Stack style={{ height: '67vh', overflow: 'scroll' }}>
                            {sortedAttachments.length <= 0 && (
                                <Stack verticalAlign="center" horizontalAlign="center" style={{ padding: 32, opacity: 0.8 }} tokens={{ childrenGap: 8 }}>
                                    <div style={{ height: 24, minHeight: 24, width: 24, border: '2px ' + theme.palette.black + ' dotted' }} />
                                    <Text variant="small">No attachments on this room yet</Text>
                                </Stack>
                            )}

                            <List
                                items={sortedAttachments}
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                onRenderCell={(item, index) => {
                                    if (!item) return <></>;

                                    return (
                                        <Stack style={{ marginBottom: 8 }}>
                                            <Stack
                                                className="pressable"
                                                horizontal
                                                style={{ marginBottom: 2, padding: 4 }}
                                                verticalAlign="center"
                                                horizontalAlign="space-between"
                                                tokens={{ childrenGap: 8 }}
                                                onMouseEnter={() => setIsEditable({ [item.id]: true })}
                                                onMouseLeave={() => setIsEditable({ [item.id]: false })}
                                            >
                                                <Stack
                                                    horizontal
                                                    tokens={{ childrenGap: 8 }}
                                                    verticalAlign="center"
                                                    onClick={() => {
                                                        if (item.addOnName == 'linkmanager') {
                                                            const url = parseUrlTemplate(
                                                                addOn(item.addOnName),
                                                                roomId || '',
                                                                item.ref,
                                                                localProfile?.displayName || ''
                                                            );
                                                            window.open(url, '_blank');
                                                            return;
                                                        }
                                                        item &&
                                                            setSelectedAttachment({ addOnId: item?.addOnName, ref: item?.ref, description: item?.description });
                                                        setIsAttachmentPanelVisible(false);
                                                        refetchAttachments();
                                                    }}
                                                >
                                                    <img
                                                        height={48}
                                                        src={addOn(item.addOnName)?.attributes.iconUrl || 'https://static-cdn.vercel.app/addon.svg'}
                                                    />
                                                    <Stack style={{ width: 110 }}>
                                                        {/* <Text variant="large" className="shorten-text" style={{ width: 100 }}>
                                                            {item.addOnName == 'linkmanager'
                                                                ? Buffer.from(item.ref, 'base64')
                                                                      .toString('utf-8')
                                                                      .replace('https://', '')
                                                                      .replace('http://', '')
                                                                      .replace('www.', '')
                                                                : addOn(item.addOnName)?.attributes.displayName}
                                                        </Text> */}
                                                        <Text
                                                            variant="mediumPlus"
                                                            style={{
                                                                wordWrap: 'break-word',
                                                                overflow: 'hidden',
                                                                display: '-webkit-box',
                                                                WebkitLineClamp: 2,
                                                                WebkitBoxOrient: 'vertical'
                                                            }}
                                                        >
                                                            {item.title ? item.title : item.addOnName}
                                                        </Text>
                                                    </Stack>
                                                </Stack>
                                                <Stack style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: 'transparent' }}>
                                                    {isEditable[item.id] ? (
                                                        <Stack style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: 'transparent' }}>
                                                            <IconButton
                                                                iconProps={{ iconName: 'edit' }}
                                                                title="Edit"
                                                                onClick={() => {
                                                                    setIsAttachmentUpdateModalOpen(true);
                                                                    setAttachmentToEdit(item);
                                                                }}
                                                            />
                                                            <IconButton
                                                                iconProps={{ iconName: 'delete' }}
                                                                title="Delete"
                                                                onClick={async () => {
                                                                    if (confirm('Are you sure you want to delete this attachment?')) {
                                                                        await resourceService.deleteAttachment(campusId, areaId, roomId, item.id);
                                                                        refetchAttachments();
                                                                    }
                                                                }}
                                                            />
                                                        </Stack>
                                                    ) : null}
                                                    {displayDescription[item.id] ? (
                                                        <Stack
                                                            style={{ padding: 10, paddingLeft: 6 }}
                                                            onClick={() => {
                                                                setDisplayDescription({ [item.id]: false });
                                                            }}
                                                        >
                                                            <IC size={18} variant="dark">
                                                                <UpArrow />
                                                            </IC>
                                                        </Stack>
                                                    ) : (
                                                        <Stack
                                                            style={{ padding: 10, paddingLeft: 6 }}
                                                            onClick={() => {
                                                                setDisplayDescription({ [item.id]: true });
                                                            }}
                                                        >
                                                            <IC size={18} variant="dark">
                                                                <DownArrow />
                                                            </IC>
                                                        </Stack>
                                                    )}
                                                </Stack>
                                            </Stack>
                                            {displayDescription[item.id] ? (
                                                <Stack
                                                    style={{
                                                        marginTop: 4,
                                                        marginBottom: 4,
                                                        paddingRight: 20,
                                                        paddingLeft: 15,
                                                        gap: 10,
                                                        wordBreak: 'break-word'
                                                    }}
                                                >
                                                    <Text variant="medium" style={{ fontStyle: 'italic' }}>
                                                        {item.title}
                                                    </Text>
                                                    <Text variant="medium" style={{ marginTop: -8 }}>
                                                        {item.description ? item.description : 'No description provided'}
                                                    </Text>
                                                    <Text variant="smallPlus" style={{ fontStyle: 'italic' }}>
                                                        {'Created ' + prettyDate(new Date(item.createdAt))}
                                                    </Text>
                                                </Stack>
                                            ) : null}
                                        </Stack>
                                    );
                                }}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </>
    );
};

export default AttachmentsPanelView;
