/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useContext } from 'react';
import { IRealtimeService } from 'services/realtime-transport/types';

export const RealtimeContextDefaultValue: IRealtimeService = {
    id: 'EMPTY',
    defaultEventTypes: {
        connected: '',
        error: '',
        memberAdded: '',
        memberRemoved: ''
    },
    getSocketId: () => '',
    getChannelSubscribers: (channelName: string) => [],
    send: (channelName: string, eventName: string, eventData: any) => {
        console.log('RT Service not available');
    },
    subscribe: (channelName: string) => {
        console.log('RT Service not available');
    },
    unsubscribe: (channelName: string) => {
        console.log('RT Service not available');
    },
    bind: (channelName: string, eventName: string, handler: Function) => {
        console.log('RT Service not available');
    },
    unbind: (channelName: string, eventName: string, handler: Function) => {
        console.log('RT Service not available');
    },
    getChannelNameForOptions: (options): any => {
        console.log('RT Service not available');
        return '';
    }
};

export const RealtimeContext = createContext<IRealtimeService>(RealtimeContextDefaultValue);

export const useRealtimeService = () => useContext(RealtimeContext);
