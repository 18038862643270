import { DefaultButton, Dialog, IButtonStyles, Pivot, PivotItem, PrimaryButton, Stack, Text } from '@fluentui/react';
import { IOnboardingVideoPopupState } from 'features/OnboardingVideoPopup/OnboardingVideoPopup.state';
import React from 'react';

const OnboardingVideoPopupView: React.FC<IOnboardingVideoPopupState> = (props) => {
    const {
        currentStep,
        isDialogOpen,
        onDialogDismiss,
        dialogTitle,
        onSkipIntroVideo,
        availableLanguages,
        introVideoSrc,
        onLanguageSelected,
        onStartTutorial,
        onSkipTutorial,
        playVideo,
        pauseVideo,
        videoRef,
        isVideoPlaying
    } = props;

    const orangeButtonStyles: IButtonStyles = {
        root: {
            backgroundColor: '#FF8900',
            color: '#fff',
            border: 0
        },
        rootHovered: {
            backgroundColor: '#e17900',
            color: '#fff',
            boder: 0
        },
        rootPressed: {
            backgroundColor: '#cd6e00',
            color: '#fff',
            border: 0
        }
    };

    return (
        <Dialog
            isOpen={isDialogOpen}
            dialogContentProps={{ title: dialogTitle }}
            styles={{ main: { width: '640px !important', maxWidth: '90% !important' } }}
            modalProps={{ isBlocking: true }}
            onDismiss={() => onDialogDismiss()}
        >
            {currentStep == 'introVideo' && (
                <>
                    <video autoPlay={true} ref={videoRef} src={introVideoSrc} controls style={{ width: '100%', height: '100%', marginBottom: 16 }} />
                    <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                        <Pivot onLinkClick={onLanguageSelected}>
                            {availableLanguages.map((lang) => {
                                <PivotItem headerText={lang.displayName} itemKey={lang.languageCode} key={lang.languageCode} />;
                            })}
                        </Pivot>
                        <Stack horizontal horizontalAlign="space-between" style={{ width: '31%' }}>
                            <PrimaryButton onClick={() => onSkipIntroVideo()} text="Skip" />

                            {!isVideoPlaying ? (
                                <DefaultButton styles={orangeButtonStyles} text="Play" onClick={() => playVideo()} />
                            ) : (
                                <DefaultButton styles={orangeButtonStyles} text="Pause" onClick={() => pauseVideo()} />
                            )}
                        </Stack>
                    </Stack>
                </>
            )}
            {currentStep == 'tutorialConfirmation' && (
                <>
                    <Text variant="large">This short tour explains the most important features. </Text>
                    <Stack horizontal style={{ height: '60px', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                        <DefaultButton onClick={() => onSkipTutorial()} style={{ marginRight: '20px' }}>
                            SKIP
                        </DefaultButton>
                        <DefaultButton onClick={() => onStartTutorial()} styles={orangeButtonStyles}>
                            START
                        </DefaultButton>
                    </Stack>
                </>
            )}
        </Dialog>
    );
};

export default OnboardingVideoPopupView;
