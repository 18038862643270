import { loadTheme, Stack } from '@fluentui/react';

import Header from 'features/Header/Header';
import React, { useEffect } from 'react';
import { ReactElement } from 'react-markdown';
import { getFlag } from 'routes/Flags/Flags';
import { DarkTheme } from 'themes/dark';
import { DefaultTheme } from 'themes/default';
import { createTheme } from 'office-ui-fabric-react/lib/Styling';
import { useTheme } from '@fluentui/react-theme-provider';

interface ICoreViewProps {
    onRenderTopBarDocumentPicker: (textColor?: string) => ReactElement;
}
const savedTheme = localStorage.getItem('experimental:customTheme');
const parsedTheme = savedTheme ? JSON.parse(savedTheme) : undefined;
const CustomTheme = parsedTheme ? createTheme({ palette: parsedTheme }) : DefaultTheme;
console.log(CustomTheme);

const CoreView: React.FC<ICoreViewProps> = ({ onRenderTopBarDocumentPicker, children }) => {
    const theme = useTheme();
    useEffect(() => {
        const experimental_isDarkmodeEnabled = getFlag('useDarkMode') == 'YES';

        let themeToLoad = DefaultTheme;
        if (experimental_isDarkmodeEnabled) themeToLoad = DarkTheme;

        const experimental_isCustomThemeEnabled = getFlag('useCustomTheme') == 'YES';
        if (experimental_isCustomThemeEnabled) themeToLoad = CustomTheme;

        const a = loadTheme(themeToLoad);
        console.log(a);
    }, []);

    return (
        <Stack style={{ width: '100vw', height: '100vh', backgroundColor: theme.palette.neutralLight }} tokens={{ childrenGap: 0 }}>
            <Header onRenderDocumentPicker={onRenderTopBarDocumentPicker} />

            {children}
        </Stack>
    );
};

export default CoreView;
