import React from 'react';
import { useCampusSettingsState } from './CampusSettings.state';
import CampusSettingsView from './CampusSettings.view';

const CampusSettings: React.FC = () => {
    const state = useCampusSettingsState();

    return (
        <CampusSettingsView
            {...{
                ...state,
                ...{
                    microsoftProps: {
                        microsoftTenantIsInvited: state.microsoftTenantIsInvited,
                        isMicrosoftUser: state.isMicrosoftUser,
                        setMicrosoftTenantIsInvited: state.setMicrosoftTenantIsInvited
                    }
                }
            }}
        />
    );
};

export default CampusSettings;
