import * as React from 'react';

function SvgTictactoe(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fill="#000" d="M28 19v2H4v-2zM28 11v2H4v-2z" />
            <path fill="#000" d="M19 4h2v24h-2zM11 4h2v24h-2z" />
        </svg>
    );
}

export default SvgTictactoe;
