import { AttachmentDto } from 'client-api-wrapper/lib/campus_api/responses.dto';
import { resourceService } from 'services/api/resource.service';
import { useCurrentCampusId, useCurrentAreaId, useCurrentRoomId } from 'shared-state/location/hook';
import { hardcodedAddOns } from '../../../addons';

export interface IAttachmentCreationModalState {
    createAttachment: (addOnName: string, optionalRef?: string | undefined) => Promise<void>;
    addOns: {
        id: string;
        type: string;
        attributes: {
            urlTemplate: string;
            iconUrl: string;
            displayName: string;
            description: string;
        };
        relationships: {};
    }[];
}

export const useAttachmentCreationModalState: (
    onDismiss?: Function,
    onAttachmentCreation?: (attachment?: AttachmentDto) => void
) => IAttachmentCreationModalState = (onDismiss, onAttachmentCreation) => {
    const [campusId] = useCurrentCampusId();
    const [areaId] = useCurrentAreaId();
    const [roomId] = useCurrentRoomId();

    const randomString = (length = 20) => {
        // Declare all characters
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        // Pick characers randomly
        let str = '';
        for (let i = 0; i < length; i++) {
            str += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return str;
    };

    const createAttachment = async (addOnName: string, optionalRef?: string) => {
        if (!campusId || !areaId || !roomId) return;
        const attachment = await resourceService.createAttachment(campusId, areaId, roomId, addOnName, optionalRef || randomString(), false);
        if (addOnName == 'linkmanager') {
            onDismiss && onDismiss();
            return;
        }

        onAttachmentCreation && onAttachmentCreation(attachment);
    };

    const addOns = hardcodedAddOns;
    return {
        createAttachment,
        addOns
    };
};
