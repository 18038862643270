import IvicosColors, { getTextColorForBackground } from 'kits/colorKit';
import IconKit from 'kits/IconKit';
import { Stack } from '@fluentui/react';
import DroppableArea from 'components/DroppableArea/DroppableArea';
import React, { ReactElement } from 'react';
import 'styles/RoomList.css';
import UserFacepile from '../UserFacepile/UserFacepile';
import { ICButton } from 'components/SVGIcon';
import ListedRoomHeader from '../ListedRoomHeader/ListedRoomHeader';
import { IProfile } from 'shared-state/identity/types';
import { IRoomAction } from '../helpers/roomTypes';
import { IRoomResource } from 'kits/apiKit3/legacy';
import { useTheme } from '@fluentui/react-theme-provider';

export interface IListedRoomsViewProps {
    roomId: string;
    isSelected: boolean;
    room: IRoomResource | undefined;
    users: IProfile[];
    actions: IRoomAction[];
    isDropDisabled: boolean;
    rootStyle: string;
}

const ListedRoomView: React.FC<IListedRoomsViewProps> = ({ roomId, isSelected, room, users, actions, isDropDisabled, rootStyle }) => {
    const theme = useTheme();

    if (!room) return <></>;
    return (
        <DroppableArea droppableId={roomId} horizontal disabled={isDropDisabled}>
            {(placeholder: ReactElement, isDraggingOver: boolean) => (
                <Stack className={rootStyle}>
                    <ListedRoomHeader room={room} actions={actions} isDraggingOver={isDraggingOver} isSelected={isSelected} />
                    <Stack
                        horizontal
                        styles={{
                            root: {
                                background: isSelected ? theme.palette.themeLighter : theme.palette.neutralLighter,
                                color: isSelected ? getTextColorForBackground(theme.palette.themeLight) : theme.palette.black,
                                '& .ms-Persona-primaryText': {
                                    color: isSelected ? getTextColorForBackground(theme.palette.themeLight) : theme.palette.black
                                },
                                padding: 8,
                                minHeight: 32,
                                display: users.length > 0 || isDraggingOver ? 'inherit' : 'none'
                            }
                        }}
                        horizontalAlign="space-between"
                        tokens={{ childrenGap: 8 }}
                    >
                        <UserFacepile draggable users={users} panelTitle={'Users in ' + room.attributes.displayName}>
                            {placeholder}
                        </UserFacepile>
                        <Stack horizontal tokens={{ childrenGap: 8 }}>
                            {actions
                                .filter((a: any) => a.hasPriority && !a.isDisabled)
                                .map((a: any) => {
                                    if (!a) return <></>;
                                    return (
                                        <ICButton key={a.key} title={a.title} size={42} onClick={() => a.action()}>
                                            {a.getSymbol && a.getSymbol()}
                                        </ICButton>
                                    );
                                })}
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </DroppableArea>
    );
};

export default ListedRoomView;
