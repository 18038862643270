/* eslint-disable @typescript-eslint/no-unused-vars */
import { DefaultEffects, Dialog, DialogType, getTheme, ITheme, mergeStyles, Stack, Text } from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import { ICallAction } from 'features/Rooms/RoomView/CallActions';
import 'intro.js/introjs.css';
import { IRoomResource } from 'kits/apiKit3/legacy';
import { ThreeDots } from 'kits/IconKit2';
import React from 'react';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import 'styles/helpers.css';
import { ICallActionGroup } from './CallActionBar';
import CallActionButton from './Children/CallActionButton/CallActionButton';

export interface ICallActionBarViewProps {
    actions: Array<ICallActionGroup>;
    onActionPressed: (a: ICallAction) => void;
    visible?: boolean;
    theme: ITheme;
    currentRoomId?: string;
    currentRoom?: IRoomResource;
    hideDialog: boolean;
    toggleHideDialog: () => void;
    dialogContentProps: {
        type: DialogType;
        title: string;
        closeButtonAriaLabel: string;
    };
}

const CallActionBarView: React.FC<ICallActionBarViewProps> = (props) => {
    const { theme, currentRoomId, currentRoom, hideDialog, toggleHideDialog, dialogContentProps, actions, onActionPressed, visible } = props;

    const actionBarStyles = mergeStyles({
        backgroundColor: theme.palette.neutralLighterAlt,
        transition: 'opacity 0.25s ease-in-out',
        width: 'auto',
        boxShadow: DefaultEffects.elevation16,
        padding: '8px 16px'
    });

    const { smallDevice, mediumDevice } = useWindowWidth();

    return (
        <React.Fragment>
            {hideDialog && (smallDevice || mediumDevice) && (
                <Dialog hidden={false} onDismiss={toggleHideDialog} dialogContentProps={dialogContentProps}>
                    {actions.map((actionGroup, actionGroupIndex) => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const isLastActionGroup = actionGroupIndex === actions.length - 1;
                        if (actionGroup.actions.length == 0) return <React.Fragment key={actionGroup.key}></React.Fragment>;
                        return (
                            <React.Fragment key={actionGroup.key}>
                                <Stack horizontal={false} horizontalAlign={'start'}>
                                    {actionGroup.actions.map((action) => (
                                        <>
                                            <Stack
                                                horizontal
                                                verticalAlign={'center'}
                                                key={action.key}
                                                onClick={() => {
                                                    onActionPressed(action);
                                                    toggleHideDialog();
                                                }}
                                            >
                                                <CallActionButton action={action} onPressed={(a: ICallAction) => null} />
                                                <Text>{action.title}</Text>
                                            </Stack>
                                        </>
                                    ))}
                                </Stack>
                            </React.Fragment>
                        );
                    })}
                </Dialog>
            )}

            <Stack>
                <Stack className={actionBarStyles + (!visible ? ' reveal--on-hover' : '')} horizontal tokens={{ childrenGap: 8 }}>
                    <Stack grow horizontal verticalAlign="center" horizontalAlign={smallDevice || mediumDevice ? 'center' : 'start'}>
                        <Text variant="medium" style={{ color: theme.palette.black }}>
                            {currentRoom?.attributes.displayName || (currentRoomId?.includes('visitor') ? 'Waiting Area' : 'Video Call')}
                        </Text>
                    </Stack>
                    {(smallDevice || mediumDevice) && (
                        <IC variant={'custom'} size={22}>
                            <ThreeDots onClick={() => toggleHideDialog()} />
                        </IC>
                    )}
                    {!(smallDevice || mediumDevice) &&
                        actions.map((actionGroup, actionGroupIndex) => {
                            const isLastActionGroup = actionGroupIndex === actions.length - 1;
                            if (actionGroup.actions.length == 0) return <React.Fragment key={actionGroup.key}></React.Fragment>;
                            return (
                                <React.Fragment key={actionGroup.key}>
                                    <Stack horizontal verticalAlign="center" horizontalAlign="center" tokens={{ childrenGap: 8 }}>
                                        {actionGroup.actions.map((action) => (
                                            <CallActionButton key={action.key} action={action} onPressed={(a: ICallAction) => onActionPressed(a)} />
                                        ))}
                                    </Stack>
                                    {!isLastActionGroup && <CallActionBarDivider vertical={false} />}
                                </React.Fragment>
                            );
                        })}
                </Stack>
            </Stack>
        </React.Fragment>
    );
};

export default CallActionBarView;

export interface ICallActionBarDividerProps {
    vertical?: boolean;
}
export const CallActionBarDivider: React.FC<ICallActionBarDividerProps> = ({ vertical }) => {
    const theme = getTheme();

    const dividerStyles = mergeStyles([
        {
            backgroundColor: theme.palette.neutralQuaternary
        },
        vertical
            ? {
                  height: 1,
                  minHeight: 1,
                  width: '100%'
              }
            : {
                  height: '100%',
                  minHeight: '100%',
                  width: 1
              }
    ]);

    return <div className={dividerStyles} />;
};
