import { IPersonaProps, PersonaPresence, Stack } from '@fluentui/react';
import { IUpdateEvent, useUpdateEvents } from 'adapters/realtime-adapter/actions/messaging';
import { IDynamicFormElementProps } from 'components/DynamicForm/DynamicForm';
import StatefulButton from 'components/StatefulButton/StatefulButton';
import { IC } from 'components/SVGIcon';
import { IResource, IRoomResource, roomDTOtoResource } from 'kits/apiKit3/legacy';
import { availabilityToPersonaPresence } from 'kits/availabilityKit';
import { sendEvent } from 'kits/eventKit';
import { Save } from 'kits/IconKit2';
import SvgArchive from 'kits/IconKit2/Archive';
import IvicosStrings from 'kits/stringKit';
import React, { useCallback, useEffect, useState } from 'react';
import { SetterOrUpdater } from 'recoil';
import { idpService } from 'services/api/identity-provider.service';
import { resourceService } from 'services/api/resource.service';
import { useRoom } from 'shared-state/directory/hooks';
import { useRoomSettingsRoomId } from 'shared-state/display-options/hooks';
import { useIdentityClaims, useLocalProfile, useSharedAvailability } from 'shared-state/identity/hooks';
import { IIdentityClaims } from 'shared-state/identity/types';
import { useCurrentAreaId, useCurrentCampusId, useCurrentRoomId } from 'shared-state/location/hook';
import { useUsersInRoom } from 'shared-state/presence/hooks';

export interface IRoomSettingsState {
    roomForm: IDynamicFormElementProps[];
    onRoomFormChange: (key: string, newValue: any) => void;
    isOpen: boolean;
    confirmationTarget: 'dismiss' | 'delete' | 'none';
    setConfirmationTarget: React.Dispatch<React.SetStateAction<'dismiss' | 'delete' | 'none'>>;
    getConfirmationTitle: () => 'Are you sure?' | 'Discard unsaved changes?';
    getConfirmationDesc: () =>
        | 'Archived rooms will become unavailable to all users...'
        | 'You have made changes to this room that have not been saved yet... do you want to discard them?';
    onConfirm: (target: string) => void;
    onPanelDismiss: () => void;
    onChange: (change: { [index: string]: string | boolean }) => void;
    room: IRoomResource | undefined;
    idClaims: IIdentityClaims | undefined;
    currentRoomId: string | undefined;
    roomSettingsRoomId: string;
    changes: any;
    setChanges: React.Dispatch<any>;
    onUpdateRoom: (roomId: string, changes: any) => Promise<any | undefined>;
    sendUpdateEvent: (updateEvent: IUpdateEvent) => void;
    setRoom: SetterOrUpdater<IRoomResource | undefined>;
    onDismiss: (changed: boolean) => void;
    hasChanges: () => any;

    onRenderPanelFooterContent: () => any;
    onOpenForVisitorsChange: (event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined) => void;
    whitelistSelectorIsVisible: boolean;
    activeTab: string;
    setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

export const useRoomSettingsState: () => IRoomSettingsState = () => {
    const [changes, setChanges] = useState<any>({});

    const [campusId] = useCurrentCampusId();
    const [areaId] = useCurrentAreaId();
    const [currentRoomId] = useCurrentRoomId();
    const [roomSettingsRoomId, setRoomSettingsRoomId] = useRoomSettingsRoomId();
    const [idClaims] = useIdentityClaims();
    const isOpen = roomSettingsRoomId.length > 0;

    const [confirmationTarget, setConfirmationTarget] = useState<'dismiss' | 'delete' | 'none'>('none');

    const [room, setRoom] = useRoom(roomSettingsRoomId);

    const sendUpdateEvent = useUpdateEvents();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onDismiss = (changed: boolean) => {
        //if (changed) return;
        setRoomSettingsRoomId('');
    };

    const getConfirmationTitle = () => (confirmationTarget == 'delete' ? 'Are you sure?' : 'Discard unsaved changes?');
    const getConfirmationDesc = () =>
        confirmationTarget == 'delete'
            ? 'Archived rooms will become unavailable to all users...'
            : 'You have made changes to this room that have not been saved yet... do you want to discard them?';

    const onConfirm = (target: string) => {
        if (target == 'dismiss') {
            setConfirmationTarget('none');
            onDismiss(false);
        }
        if (target == 'delete') {
            const _roomId = room?.id;
            _roomId &&
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                onRemoveRoom(_roomId).then((success) => {
                    sendUpdateEvent({ type: 'deletion', roomId: _roomId });
                    setConfirmationTarget('none');
                });
            setRoom(undefined);
            onDismiss(true);
        }
    };

    const hasChanges = () => changes && Object.keys(changes).length > 0;

    const onPanelDismiss = () => {
        console.log('XXX', changes);
        if (hasChanges()) return setConfirmationTarget('dismiss');
        onDismiss && onDismiss(hasChanges());
    };

    const onChange = (change: { [index: string]: string | boolean }) => {
        setChanges((prevState: any) => {
            const newState = { ...prevState, ...change };
            return newState;
        });
    };

    const onRemoveRoom = (roomId: string): Promise<boolean> => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return new Promise((resolve, reject) => {
            if (!areaId || !campusId || !roomId) return resolve(false);
            resourceService.archiveRoom(campusId, areaId, roomId).then(() => {
                resolve(true);

                // removing roomId from localStorage on archiving the room
                if (localStorage.getItem(`noVideo-${roomId}`)) {
                    localStorage.removeItem(`noVideo-${roomId}`);
                }
            });
        });
    };

    const onUpdateRoom = (roomId: string, changes: any): Promise<any | undefined> => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return new Promise((resolve, reject) => {
            if (!areaId || !campusId || !roomId) return resolve(undefined);

            if (whitelistSelectorIsVisible) {
                delete changes.isOpenForVisitors;
            } else changes.whitelist = [];

            resourceService.updateRoom(campusId, areaId, roomId, changes).then((room) => {
                resolve({ data: roomDTOtoResource(room) });
            });
        });
    };

    const [localAvailability] = useSharedAvailability();
    const [members, setMembers] = useState<IPersonaProps[] | undefined>([]);

    const [whitelistSelectorIsVisible, setWhitelistSelectorIsVisible] = useState<boolean>(
        room && room?.attributes.whitelist ? room?.attributes.whitelist.length > 0 : false
    );

    const usersInRoom = useUsersInRoom(roomSettingsRoomId);

    const extractRoomWhitelistedPersona = useCallback((whitelist: Set<string>, members: IPersonaProps[]) => {
        const whitelistedPersona = members.filter((member: IPersonaProps) => member.id !== undefined && whitelist.has(member.id));
        return whitelistedPersona;
    }, []);

    const localProfile = useLocalProfile();

    const getSelectedItems = useCallback(() => {
        const roomHasUsers = usersInRoom && usersInRoom.length > 0;
        if (room?.attributes.whitelist && room?.attributes.whitelist.length > 0 && members) {
            const personaInWhitelist = extractRoomWhitelistedPersona(new Set(room?.attributes.whitelist), members);
            return personaInWhitelist;
        } else if (room?.attributes.whitelist && room?.attributes.whitelist.length === 0 && members && roomHasUsers) {
            const userIdsInRoom = usersInRoom.map((user) => user.uid);
            localProfile && userIdsInRoom.push(localProfile.uid);
            const personaInRoom = extractRoomWhitelistedPersona(new Set(userIdsInRoom), members);
            // onRoomFormChange('whitelist', userIdsInRoom);
            return personaInRoom;
        } else return localProfile && members ? extractRoomWhitelistedPersona(new Set([localProfile.uid]), members) : [];
    }, [members, room, usersInRoom]);

    const roomFormForRoom: (room: IResource | undefined) => IDynamicFormElementProps[] = React.useCallback(
        (room) => {
            return [
                { key: 'name', label: 'Room name', value: room?.attributes.displayName, type: 'text' },
                { key: 'iconKey', label: 'Room icon', value: room?.attributes.symbol, type: 'icon' },
                { key: 'backgroundImageURL', label: 'Background Image', type: 'image', value: room?.attributes.backgroundImageURL },
                {
                    key: 'isAudioOnly',
                    label: IvicosStrings.audioOnlyCheckBoxLabel,
                    description: IvicosStrings.audioOnlyCheckBoxDescription,
                    value: room?.attributes.audioOnly,
                    type: 'checkbox'
                },
                {
                    key: 'isOpenForVisitors',
                    label: IvicosStrings.openToVisitorsCheckBoxLabel,
                    description: IvicosStrings.openTovisitorsCheckBoxDescription,
                    value: room?.attributes.openForVisitors,
                    type: 'checkbox',
                    disabled: whitelistSelectorIsVisible
                },
                {
                    key: 'whitelist',
                    label: IvicosStrings.whitelistSelectorLabel,
                    description: IvicosStrings.whitelistSelectorDescription,
                    value: {
                        // selectedItems: members ? members.filter((member: IPersonaProps) => room?.attributes.whitelist.includes(member.id)) : [],
                        selectedItems: getSelectedItems(),
                        membersList: members,
                        isVisible: whitelistSelectorIsVisible
                    },
                    type: 'personaSelector'
                }
            ];
        },
        [members, room, whitelistSelectorIsVisible]
    );
    const [roomForm, setRoomForm] = useState<IDynamicFormElementProps[]>(roomFormForRoom(room));

    const convertMemberToPersona = (member: any): IPersonaProps => {
        const { userId, profile_pic, name } = member;

        const personaDetails = {
            id: userId,
            primaryText: name,
            imageUrl: profile_pic,
            presence: (localAvailability && availabilityToPersonaPresence(localAvailability)) || PersonaPresence.online
        };
        return personaDetails;
    };

    const fetchMembersList = React.useCallback(async (): Promise<void> => {
        const fetchedMembers = await idpService.showMembers();
        const memberPersona: IPersonaProps[] = [];
        if (fetchedMembers) {
            fetchedMembers.forEach((member) => memberPersona.push(convertMemberToPersona(member)));
            setMembers(memberPersona);
        }
    }, []);

    useEffect(() => {
        const roomHasUsers = usersInRoom && usersInRoom.length > 0;
        const roomHasNonEmptyWhitelist = room && room?.attributes.whitelist ? room.attributes.private : false;
        if (((room?.attributes.whitelist && room?.attributes.whitelist.length === 0) || !room?.attributes.whitelist) && members && roomHasUsers) {
            const userIdsInRoom = usersInRoom.map((user) => user.uid);
            onRoomFormChange('whitelist', userIdsInRoom);
        }
        setWhitelistSelectorIsVisible(roomHasNonEmptyWhitelist);
    }, [room]);

    useEffect(() => {
        fetchMembersList();
    }, []);

    useEffect(() => {
        setRoomForm(roomFormForRoom(room));
    }, [room, members, whitelistSelectorIsVisible]);

    const onOpenForVisitorsChange: (event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean | undefined) => void = React.useCallback(() => {
        setWhitelistSelectorIsVisible((prev) => !prev);
    }, [whitelistSelectorIsVisible, room, usersInRoom]);

    const onRoomFormChange = (key: string, newValue: any) => {
        onChange({ [key]: newValue });
        setRoomForm((prevArray) => {
            return prevArray.map((el: any) => {
                if (el.key != key) return el;
                return { ...el, ...{ value: newValue } };
            });
        });
    };

    const [activeTab, setActiveTab] = useState<string>('General');

    const onRenderPanelFooterContent = () => {
        return (
            <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                {(idClaims?.roles.includes('manager') || idClaims?.roles.includes('owner')) && (
                    <StatefulButton
                        disabled={currentRoomId == roomSettingsRoomId}
                        onRenderIcon={() => (
                            <IC size={24} variant="red">
                                <SvgArchive />
                            </IC>
                        )}
                        onClick={(e, setButtonState) => {
                            if (!room) return;
                            setConfirmationTarget('delete');
                        }}
                        text={IvicosStrings.deleteRoomActionTitle}
                        loadingText={'Archiving...'}
                        successText={'Room archived.'}
                    ></StatefulButton>
                )}
                <StatefulButton
                    variant="primary"
                    text={IvicosStrings.saveChangesActionTitle}
                    loadingText={IvicosStrings.savingActionLoadingTitle}
                    successText={IvicosStrings.savingActionSuccessTitle}
                    onRenderIcon={() => <Save />}
                    onClick={(e, setButtonState) => {
                        if (!room) return;

                        if (changes && Object.keys(changes).length > 0) {
                            setButtonState(1);
                            onUpdateRoom &&
                                onUpdateRoom(room.id, changes).then((r) => {
                                    if (r.data) sendUpdateEvent({ type: 'update', room: r.data });
                                    // storing room id in localStorage for audio only rooms
                                    const idValue = r.data.id;
                                    if (r.data.id && r.data.attributes.audioOnly) {
                                        sendEvent('disable-camera', {});
                                        const noVideoRoomId: any = room?.id;
                                        localStorage.setItem(`noVideo-${idValue}`, noVideoRoomId);
                                    }
                                    if (!r.data.attributes.audioOnly) {
                                        localStorage.removeItem(`noVideo-${idValue}`);
                                    }
                                    setRoom((prevRoom: IRoomResource | undefined) => {
                                        if (!prevRoom) return prevRoom;

                                        const newRoom = { ...prevRoom, ...{ attributes: r.data.attributes } };

                                        return newRoom;
                                    });

                                    setChanges({});
                                    setButtonState(2);

                                    onDismiss && onDismiss(hasChanges());
                                    return r;
                                });
                            return;
                        }
                        onDismiss && onDismiss(hasChanges());
                    }}
                />
            </Stack>
        );
    };

    return {
        roomForm,
        onRoomFormChange,
        isOpen,
        confirmationTarget,
        setConfirmationTarget,
        getConfirmationTitle,
        getConfirmationDesc,
        onConfirm,
        onPanelDismiss,
        onChange,
        room,
        idClaims,
        currentRoomId,
        roomSettingsRoomId,
        changes,
        setChanges,
        onUpdateRoom,
        sendUpdateEvent,
        setRoom,
        onDismiss,
        hasChanges,
        onRenderPanelFooterContent,
        onOpenForVisitorsChange,
        whitelistSelectorIsVisible,
        activeTab,
        setActiveTab
    };
};
