import * as React from 'react';

function SvgClipboardError(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M16 2c-1.257 0-2.14.893-2.584 2H5v25h22V4h-8.416C18.141 2.893 17.257 2 16 2zm0 2c.565 0 1 .435 1 1v1h3v2h-8V6h3V5c0-.565.435-1 1-1zM7 6h3v4h12V6h3v21H7V6zm5.707 7.293l-1.414 1.414L14.586 18l-3.293 3.293 1.414 1.414L16 19.414l3.293 3.293 1.414-1.414L17.414 18l3.293-3.293-1.414-1.414L16 16.586l-3.293-3.293z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgClipboardError;
