import React from 'react';
import { ISpeakerStatsSpeaker, useSpeakerStatsState } from './SpeakerStats.state';
import SpeakerStatsView from './SpeakerStats.view';

export interface ISpeakerStatsProps {
    stats: ISpeakerStatsSpeaker[];
    isOpen: boolean;
    onDismiss: () => void;
}

const SpeakerStats: React.FC<ISpeakerStatsProps> = (props) => {
    const state = useSpeakerStatsState(props.stats);
    return (
        <SpeakerStatsView
            {...{
                ...state,
                ...{
                    stats: props.stats,
                    onDismiss: props.onDismiss,
                    isOpen: props.isOpen
                }
            }}
        />
    );
};
export default SpeakerStats;
