import React from 'react';
import { Icon, Stack, Text } from '@fluentui/react';
import { localAvailabilities } from 'kits/availabilityKit';
import { IAvailabilitySelectorState } from './AvailabilitySelector.State';

const AvailabilitySelectorView: React.FC<IAvailabilitySelectorState> = (props) => {
    const { theme, sharedAvailability, setSharedAvailability } = props;

    return (
        <Stack style={{ paddingBottom: 16, paddingTop: 16 }} tokens={{ childrenGap: 8 }}>
            <Stack>
                {localAvailabilities(theme).map((a) => (
                    <Stack
                        className={'pressable padding--default'}
                        horizontal
                        key={a.key}
                        style={{ backgroundColor: sharedAvailability == a.key ? theme.palette.neutralQuaternaryAlt : undefined }}
                        tokens={{ childrenGap: 8 }}
                        verticalAlign="center"
                        onClick={() => setSharedAvailability(a.key)}
                    >
                        <Icon iconName={a.iconName} style={{ color: a.iconColor }} />
                        <Text variant="mediumPlus">{a.description}</Text>
                    </Stack>
                ))}
            </Stack>
        </Stack>
    );
};

export default AvailabilitySelectorView;
