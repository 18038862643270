import { getTheme, Stack, Text } from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import { usePersonalPreferencesState } from 'features/IdentityPanel/children/PersonalPreferences/PersonalPreferences.State';
import { IRoomResource } from 'kits/apiKit3/legacy';
import { sendEvent } from 'kits/eventKit';
import { Menu, SecurityCheck } from 'kits/IconKit2';
import React from 'react';
import { IIdentityClaims } from 'shared-state/identity/types';
import 'styles/helpers.css';
import { ICallActionKey } from './CallActions';
import CallLoadingScreen from './comps/RoomCall/CallLoadingScreen/CallLoadingScreen';
import CallSurface from './comps/RoomCall/CallSurface/CallSurface';
import RoomCallController from './RoomCallController/RoomCallController';

export interface IRoomViewViewProps {
    jwt: string | undefined;
    room: string | undefined;
    domain: string | undefined;
    smallDevice: boolean;
    innerBurgerMenu: string;
    campusNameSmallDevice: string;
    currentRoom: IRoomResource | undefined;
    title: string;
    callActions: ICallActionKey[][];
    pimg: string | undefined;
    showSideBySide: boolean;
    selectedTool: string;
    identity: IIdentityClaims | undefined;
    noVideoRoom: any;
    mobileMenuOpen: boolean;
}

const RoomViewView: React.FC<IRoomViewViewProps> = (props) => {
    const {
        jwt,
        room,
        domain,
        smallDevice,
        innerBurgerMenu,
        campusNameSmallDevice,
        currentRoom,
        title,
        callActions,
        pimg,
        showSideBySide,
        selectedTool,
        identity,
        noVideoRoom,
        mobileMenuOpen
    } = props;

    const personalPreferencesState = usePersonalPreferencesState();
    const deviceSettings = personalPreferencesState.deviceSettings;

    const theme = getTheme();
    return jwt && room && domain ? (
        <>
            {/* If there is Jitsi Auth render Addons + Call */}
            {smallDevice ? (
                <Stack style={mobileMenuOpen ? { display: 'none' } : { height: '100%' }} onClick={(e: any) => sendEvent('closeMobileCallActionBar', e)}>
                    {/* {smallDevice || mediumDevice ? ( */}
                    <Stack horizontal={true} style={{ marginTop: 16, marginBottom: 8, display: 'flex', flexDirection: 'row', padding: '0px 26px' }}>
                        <IC size={32} variant="custom">
                            <Menu
                                className={innerBurgerMenu}
                                onClick={() => {
                                    sendEvent('openMobileRoomsPanel', {});
                                    sendEvent('maximizeSideBar', {});
                                }}
                            />
                        </IC>

                        <Text className={campusNameSmallDevice} variant={title.length < 32 ? 'xxLarge' : 'large'}>
                            {title}
                        </Text>
                    </Stack>
                    {/* ) : null} */}

                    <RoomCallController
                        room={currentRoom}
                        jwt={jwt}
                        roomName={room}
                        domain={domain}
                        actions={callActions}
                        profileImageURL={pimg}
                        isHidden={!showSideBySide && selectedTool != 'jitsi'}
                        displayName={identity?.displayName || ''}
                        muteOnJoin={
                            !window.location.pathname.includes('rooms')
                                ? 'both'
                                : window.location.pathname.includes(noVideoRoom) && deviceSettings?.microphoneDisabled
                                ? 'both'
                                : window.location.pathname.includes(noVideoRoom)
                                ? 'video'
                                : window.location.pathname.includes('rooms') && deviceSettings?.cameraDisabled && deviceSettings?.microphoneDisabled
                                ? 'both'
                                : window.location.pathname.includes('rooms') && deviceSettings?.cameraDisabled
                                ? 'video'
                                : window.location.pathname.includes('rooms') && deviceSettings?.microphoneDisabled
                                ? 'audio'
                                : undefined
                        }
                    />
                </Stack>
            ) : (
                <RoomCallController
                    room={currentRoom}
                    jwt={jwt}
                    roomName={room}
                    domain={domain}
                    actions={callActions}
                    profileImageURL={pimg}
                    isHidden={!showSideBySide && selectedTool != 'jitsi'}
                    displayName={identity?.displayName || ''}
                    muteOnJoin={
                        !window.location.pathname.includes('rooms')
                            ? 'both'
                            : window.location.pathname.includes(noVideoRoom) && deviceSettings?.microphoneDisabled
                            ? 'both'
                            : window.location.pathname.includes(noVideoRoom)
                            ? 'video'
                            : window.location.pathname.includes('rooms') && deviceSettings?.cameraDisabled && deviceSettings?.microphoneDisabled
                            ? 'both'
                            : window.location.pathname.includes('rooms') && deviceSettings?.cameraDisabled
                            ? 'video'
                            : window.location.pathname.includes('rooms') && deviceSettings?.microphoneDisabled
                            ? 'audio'
                            : undefined
                    }
                />
            )}
        </>
    ) : (
        <CallSurface>
            <Stack horizontal style={{ minHeight: 56, backgroundColor: theme.palette.neutralLighterAlt }} />
            <CallLoadingScreen
                onRenderIcon={() => (
                    <Stack verticalAlign="center" horizontalAlign="center" className="brand-icon_wrap" style={{ marginBottom: 32 }}>
                        <IC variant="light" size={64}>
                            <SecurityCheck />
                        </IC>
                    </Stack>
                )}
                label={'Authenticating...'}
            />
        </CallSurface>
    );
};

export default RoomViewView;
