import { Bell, Check, DoorOpen, InfoBubble, Memo, Visitors } from 'kits/IconKit2';
import React, { ReactElement, useMemo } from 'react';
import { IReceivedMessage } from 'shared-state/notifications/types';
import { useUser } from 'shared-state/presence/hooks';
import { INotificationBannerAction } from 'features/NotificationsLayer/NotificationsBanner/NotificationsBanner.view';
import { useUserMessagingActions } from 'adapters/realtime-adapter/actions/messaging';
import { useCurrentAreaId, useCurrentRoomId } from 'shared-state/location/hook';
import { useMessageComposerUserIds } from 'shared-state/messaging/hooks';
import { useRoom } from 'shared-state/directory/hooks';
import { useHistory } from 'react-router';
import { useTabOption } from 'shared-state/tabs/hooks';
import { DefaultValue } from 'recoil';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useNotificationAssets = (message: IReceivedMessage, onDismiss: Function) => {
    const _sender = useUser(message.sender);
    const sender = useMemo(() => _sender, [message]);
    const [room] = useRoom(message.meta.attachedRoom || '');

    const routeHistory = useHistory();

    const { inviteUserToRoom } = useUserMessagingActions();

    const [currentRoomId] = useCurrentRoomId();
    const [currentAreaId] = useCurrentAreaId();

    const [, setMessageComposerUserIds] = useMessageComposerUserIds();

    const timestamp = message.timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [tabOption, setTabOption] = useTabOption('sidebar', 'visitors');

    const getNotificationIcon = (): ReactElement => {
        if (message.type == 'knock') return <Bell />;
        if (message.type == 'invite') return <DoorOpen />;
        if (message.type == 'memo') return <Memo />;
        if (message.type == 'confirmation') return <Check />;
        if (message.type == 'guest') return <Visitors />;
        return <InfoBubble />;
    };

    const getNotificationTitle = () => {
        if (message.type == 'knock') return `${sender?.displayName || 'Someone'} is at the door`;
        if (message.type == 'memo') return `${sender?.displayName || 'Someone'} says:`;
        if (message.type == 'invite') return `${sender?.displayName || 'Someone'} invited you to ${room?.attributes.displayName}`;
        if (message.type == 'info') return message.body || '';
        if (message.type == 'confirmation') return message.body || 'Success!';
        if (message.type == 'guest') return message.body || 'You have a guest!';
        return 'New notification';
    };

    const getNotificationDescription = () => {
        if (message.type == 'knock') return 'Do you want to invite them in?';
        if (message.type == 'memo') return message.body;
        if (message.type == 'invite') return 'Do you want to join them?';
        if (message.type == 'guest') return 'Do you want to meet them?';

        return '';
    };

    const getNotificationActions = (): INotificationBannerAction[] => {
        if (message.type == 'memo')
            return [
                {
                    key: 'reply',
                    title: 'Reply',
                    action: () => {
                        setMessageComposerUserIds([message.sender]);
                        onDismiss();
                    }
                }
            ];
        if (message.type == 'knock')
            return [
                {
                    key: 'memo',
                    title: 'Send memo',
                    action: () => {
                        setMessageComposerUserIds([message.sender]);
                        onDismiss();
                    }
                },
                {
                    key: 'invite',
                    title: 'Invite them in',
                    action: () => {
                        inviteUserToRoom(message.sender, currentRoomId || '');
                        onDismiss();
                    }
                }
            ];
        if (message.type == 'invite')
            return [
                {
                    key: 'memo',
                    title: 'Send memo',
                    action: () => {
                        setMessageComposerUserIds([message.sender]);
                        onDismiss();
                    }
                },
                {
                    key: 'Accept',
                    title: 'Accept invitation',
                    action: () => {
                        routeHistory.push('/areas/' + currentAreaId + '/rooms/' + message.meta.attachedRoom);
                        localStorage.setItem('jitsiSessionId', message.body || '');
                        onDismiss();
                    }
                }
            ];
        if (message.type == 'guest')
            return [
                {
                    key: 'meet',
                    title: 'Meet them',
                    action: () => {
                        setTabOption((prevOption) => {
                            if (!prevOption) return;
                            if (prevOption instanceof DefaultValue) return prevOption;

                            return { ...prevOption, ...{ isSelected: true } };
                        });
                        routeHistory.push('/areas/' + currentAreaId + '/rooms/' + message.meta.attachedRoom);
                        onDismiss();
                    }
                }
            ];
        return [];
    };

    const title = getNotificationTitle();
    const description = getNotificationDescription();
    const actions = getNotificationActions();

    return { getNotificationIcon, timestamp, title, description, actions };
};
