import * as React from 'react';

function SvgAreaOverview(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M26 17c-2.75 0-5 2.25-5 5 0 1.944 1.112 3.965 2.148 5.648a32.987 32.987 0 002.07 2.977l.782.977.781-.977s1.035-1.293 2.07-2.977C29.889 25.965 31 23.944 31 22c0-2.75-2.25-5-5-5zm0 2c1.669 0 3 1.331 3 3 0 1.056-.888 3.035-1.852 4.602-.573.932-.777 1.15-1.148 1.65-.37-.5-.575-.718-1.148-1.65C23.888 25.035 23 23.056 23 22c0-1.669 1.331-3 3-3zm0 2a1 1 0 100 2 1 1 0 000-2z"
                fill="#000"
            />
            <path d="M4 29V3h24v13h-2V5H6v22h7v-4h6v4h2l1 2h-5v-4h-2v4H4z" fill="#000" />
            <path fill="#000" d="M8 7h7v3H8zM8 12h7v3H8zM8 17h7v3H8zM17 7h7v3h-7zM17 12h7v3h-7zM17 17h3v3h-3z" />
        </svg>
    );
}

export default SvgAreaOverview;
