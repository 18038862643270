import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { makeMessageId } from 'shared-state/notifications/hooks';
import { IDragNDropContextState } from './DragNDropContext.state';

export const DragNDropContextView: React.FC<IDragNDropContextState> = ({ children, localProfile, routeHistory, areaId, inviteUserToRoom, addMessage }) => {
    return (
        <DragDropContext
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onDragEnd={(result, provided) => {
                const userId = result.draggableId.replace('user-', '');
                const destinationRoomId = result.destination?.droppableId;

                //@TODO add checking for visitor

                if (userId == localProfile?.uid) {
                    if (destinationRoomId?.includes('personal-')) return; //@TODO add automatic knocking
                    destinationRoomId && routeHistory.push('/areas/' + areaId + '/rooms/' + destinationRoomId);
                    return;
                }

                if (destinationRoomId?.includes('personal-') && localProfile?.uid && !destinationRoomId?.includes(localProfile?.uid)) return;

                destinationRoomId && inviteUserToRoom(userId, destinationRoomId);
                destinationRoomId &&
                    addMessage({
                        id: makeMessageId(),
                        type: 'confirmation',
                        body: 'Invitation sent!',
                        timeout: 2000,
                        sender: '',
                        meta: {},
                        timestamp: new Date()
                    });
            }}
        >
            {children}
        </DragDropContext>
    );
};
