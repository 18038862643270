import React from 'react';
import DashboardView from 'routes/Dashboard/Dashboard.view';
import { useDashboardState } from './Dashboard.state';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Dashboard: React.FC<{}> = () => {
    const state = useDashboardState();

    return (
        <DashboardView
            {...{
                ...state,
                ...{
                    selectedKey: state.tabKey || 'areas'
                }
            }}
        ></DashboardView>
    );
};

export default Dashboard;
