/* eslint-disable no-control-regex */
import { PrimaryButton, ProgressIndicator, Stack, Text, TextField } from '@fluentui/react';
import React from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';

export interface IRegistrationFormControllerViewProps {
    isLoading: boolean;
    handleSubmit: any;
    onFormSubmit: (formValue: any) => Promise<void>;
    control: Control<{
        displayName: string | undefined;
        emailAdress: string | undefined;
    }>;
    errors: DeepMap<
        {
            displayName: string | undefined;
            emailAdress: string | undefined;
        },
        FieldError
    >;
}
export const RegistrationFormControllerView: React.FC<IRegistrationFormControllerViewProps> = (props) => {
    const { isLoading, handleSubmit, onFormSubmit, control, errors } = props;

    return (
        <>
            {isLoading ? (
                <ProgressIndicator label="Authenticating..." description="Please stand by." styles={{ root: { width: '100%' } }} />
            ) : (
                <Stack grow horizontalAlign="center" style={{ width: '100%', marginTop: 48 }} verticalAlign="center">
                    <Stack grow>
                        <Text variant="xLargePlus">Guest registration</Text>
                        <Text style={{ marginBottom: 32 }}>{'Your host will be informed of your arrival after you press "enter".'}</Text>
                        <form onSubmit={handleSubmit(onFormSubmit)} style={{ width: '100%' }}>
                            <Controller
                                name="displayName"
                                rules={{ required: true, pattern: /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/ }}
                                control={control}
                                render={({ onChange, value }) => (
                                    <TextField
                                        label="Name"
                                        placeholder="Your name here"
                                        value={value}
                                        errorMessage={errors.displayName && 'Please enter your Name.'}
                                        onChange={onChange}
                                    />
                                )}
                            />

                            <Stack horizontal horizontalAlign="end" style={{ marginTop: 16 }}>
                                <PrimaryButton type="submit">Enter</PrimaryButton>
                            </Stack>
                        </form>
                    </Stack>
                </Stack>
            )}
        </>
    );
};

export default RegistrationFormControllerView;
