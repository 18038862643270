/* eslint-disable no-empty-pattern */
import React from 'react';
import AreaListView from './AreaList.view';

import { useAreaListState } from './AreaList.state';

interface IAreaListProps {
    vertical?: boolean;
    onSelection?: Function;
}

export const AreaList: React.FC<IAreaListProps> = (props) => {
    const state = useAreaListState(props.vertical, props.onSelection);

    return (
        <AreaListView
            {...{
                ...state,
                ...{
                    vertical: props.vertical,
                    areas: state._areas
                }
            }}
        ></AreaListView>
    );
};

export default AreaList;
