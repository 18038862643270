import * as React from 'react';

function SvgDownArrow(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M4.219 10.781L2.78 12.22l12.5 12.5.719.687.719-.687 12.5-12.5-1.438-1.438L16 22.562 4.219 10.783z" fill="#000" />
        </svg>
    );
}

export default SvgDownArrow;
