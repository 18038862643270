import { IRoomAction } from 'features/Rooms/RoomList/helpers/roomTypes';
import { IRoomResource } from 'kits/apiKit3/legacy';
import React from 'react';
import 'styles/RoomList.css';
import { useListedRoomHeaderState } from './ListedRoomHeader.state';
import ListedRoomHeaderView from './ListedRoomHeader.view';

export interface IListedRoomHeaderProps {
    room: IRoomResource;
    isDraggingOver: boolean;
    isSelected: boolean;
    actions: IRoomAction[];
}

const ListedRoomHeader: React.FC<IListedRoomHeaderProps> = (props) => {
    const state = useListedRoomHeaderState(props.room);

    return (
        <ListedRoomHeaderView
            {...{
                ...state,
                ...{
                    actions: props.actions,
                    isSelected: props.isSelected
                }
            }}
        />
    );
};

export default ListedRoomHeader;
