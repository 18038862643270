/* eslint-disable react/display-name */

import React from 'react';
import { IRoomAction } from 'features/Rooms/RoomList/helpers/roomTypes';
import { DoorOpen, HandKnock, Memo, Room, Settings } from 'kits/IconKit2';
import { useHistory } from 'react-router';
import { useRoomMessagingActions } from 'adapters/realtime-adapter/actions/messaging';
import { useIdentityClaims, useLocalProfile } from 'shared-state/identity/hooks';
import { useCurrentAreaId, useCurrentRoomId } from 'shared-state/location/hook';
import { useMessageComposerUserIds } from 'shared-state/messaging/hooks';
import { useUsersInRoom } from 'shared-state/presence/hooks';
import { useRoomSettingsRoomId } from 'shared-state/display-options/hooks';
import { makeMessageId, useReceivedMessageInbox } from 'shared-state/notifications/hooks';
import { useRoom } from 'shared-state/directory/hooks';
export const useRoomActions = (roomId: string): IRoomAction[] => {
    const { knock } = useRoomMessagingActions(roomId);

    const routeHistory = useHistory();
    const [, setRoomSettingsRoomId] = useRoomSettingsRoomId();
    const [identityClaims] = useIdentityClaims();
    const localProfile = useLocalProfile();

    const usersInRoom = useUsersInRoom(roomId);
    const [, setMemoComposerUsersIds] = useMessageComposerUserIds();
    const [currentRoomId] = useCurrentRoomId();
    const [currentAreaId] = useCurrentAreaId();
    const [, addMessage] = useReceivedMessageInbox();

    const [room] = useRoom(roomId);

    const isRoomAccessRestricted = room?.attributes.whitelist && room?.attributes.whitelist.length > 0;
    const isLocalUserOnWhitelist = !isRoomAccessRestricted || room?.attributes.whitelist?.includes(localProfile?.uid || '@@@');
    const isLocalUserVisitor = localProfile?.type == 'visitor';
    const doesLocalUserHaveAccess = isLocalUserOnWhitelist && (!isLocalUserVisitor || room?.attributes.openForVisitors);

    const userIdsInRoom = usersInRoom.filter((u) => u.uid != localProfile?.uid).map((u) => u.uid);
    const knockOnRoomAction: IRoomAction = {
        key: 'knock',
        title: 'Knock on door',
        hasPriority: localProfile?.room != roomId && usersInRoom.length > 0,
        getSymbol: () => <HandKnock />,
        action: () => {
            knock();
            addMessage({
                id: makeMessageId(),
                type: 'confirmation',
                body: 'Everyone in the room has been notified!',
                timeout: 2500,
                meta: {},
                sender: 'system',
                timestamp: new Date()
            });
        }
    };

    const sendMemoToRoom: IRoomAction = {
        key: 'send-memo',
        title: 'Send memo into room',
        hasPriority: currentRoomId == roomId || !doesLocalUserHaveAccess,
        isDisabled: userIdsInRoom.length <= 0,
        getSymbol: () => <Memo />,
        action: () => {
            setMemoComposerUsersIds(userIdsInRoom);
        }
    };

    const enterRoomAction: IRoomAction = {
        key: 'enter',
        title: 'Enter room',
        getSymbol: () => <DoorOpen />,
        action: () => {
            routeHistory.push('/areas/' + currentAreaId + '/rooms/' + roomId);
        }
    };

    const editRoomAction: IRoomAction = {
        key: 'edit',
        title: 'Edit room',
        isDisabled: identityClaims?.roles.includes('visitor'),
        getSymbol: () => <Settings />,
        action: () => {
            setRoomSettingsRoomId(roomId);
        }
    };

    if (currentRoomId == roomId) {
        return roomId.includes('personal') ? [sendMemoToRoom] : [sendMemoToRoom, editRoomAction];
    }

    if (doesLocalUserHaveAccess) return [knockOnRoomAction, sendMemoToRoom, enterRoomAction, editRoomAction];

    return [sendMemoToRoom, editRoomAction];
};
