/* eslint-disable quotes */
import IFrame from 'components/iFrameView';
import OutsideWrapper from 'features/Visitors/Entrance/OutsideWrapper/OutsideWrapper';
import { handleMessage } from 'kits/messageBusKit';
import { getIdpUrl } from 'kits/urlKit';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import 'styles/helpers.css';

const LoginPage: React.FC<{ intent: 'login' | 'switchOrgs' | 'answerInvitation' | 'resetPassword' }> = ({ intent }) => {
    const routeHistory = useHistory();
    const location = useLocation();
    useEffect(() => {
        console.log(location);
    }, []);

    const _handleMessage = (res: any) => {
        handleMessage(res, routeHistory);
    };

    const urlsByIntent = {
        login: getIdpUrl('/login') + location.search,
        switchOrgs: getIdpUrl('/welcome') + location.search,
        resetPassword: getIdpUrl('/reset-password') + location.search,
        answerInvitation: getIdpUrl('/findivcampus') + location.search
    };

    return (
        <OutsideWrapper>
            <IFrame isHidden={false} onMessage={_handleMessage} url={urlsByIntent[intent]} />
        </OutsideWrapper>
    );
};

export default LoginPage;
