/* eslint-disable @typescript-eslint/no-unused-vars */
import 'index.css';
import React, { ReactElement, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot, useRecoilSnapshot } from 'recoil';
import App from './App';
import * as serviceWorker from './serviceWorker';
function DebugObserver(): ReactElement {
    const snapshot = useRecoilSnapshot();
    useEffect(() => {
        console.debug('The following atoms were modified:');
        for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
            console.log('[OBS]: ', node.key, snapshot.getInfo_UNSTABLE(node));
        }
    }, [snapshot]);

    return <></>;
}
ReactDOM.render(
    <React.StrictMode>
        <RecoilRoot>
            <App />
        </RecoilRoot>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
