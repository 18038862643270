import { Dropdown, Panel, PrimaryButton, Separator, Stack } from '@fluentui/react';
import React from 'react';
import { IJitsiDevice, IJitsiDeviceConfig, IJitsiDeviceList, IJitsiDeviceType } from './DeviceSettingsController.state';
export interface IDeviceSettingsControllerProps {
    visible: boolean;
    onDismiss(): void;
    availableDevices?: IJitsiDeviceList;
    currentDevices?: IJitsiDeviceConfig;
    onDeviceChange: (deviceType: IJitsiDeviceType, device: IJitsiDevice) => void;
}

const DeviceSettingsControllerView: React.FC<IDeviceSettingsControllerProps> = (props) => {
    const { children, visible, onDismiss, availableDevices, currentDevices, onDeviceChange } = props;

    return (
        <Panel
            isLightDismiss
            onDismiss={onDismiss}
            isOpen={visible}
            isFooterAtBottom={true}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onRenderFooterContent={(props) => (
                <Stack horizontalAlign="end">
                    <PrimaryButton onClick={() => onDismiss()}>Save</PrimaryButton>
                </Stack>
            )}
            headerText={'Device settings'}
            hasCloseButton={true}
        >
            <Stack style={{ marginTop: '26px' }}>
                <Separator style={{ marginBottom: '13px' }}>Audio settings</Separator>
                <Dropdown
                    label="Audio input device"
                    selectedKey={currentDevices?.audioInput && currentDevices.audioInput.deviceId}
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={(e, i) => onDeviceChange('audioInput', i?.data)}
                    placeholder="Select device"
                    options={
                        availableDevices?.audioInput.map((device: any) => ({
                            key: device.deviceId,
                            text: device.label,
                            data: device
                        })) || []
                    }
                    styles={{}}
                />
                <Dropdown
                    label="Audio output device"
                    selectedKey={currentDevices?.audioOutput && currentDevices.audioOutput.deviceId}
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={(e, i) => onDeviceChange('audioOutput', i?.data || {})}
                    placeholder="Select device"
                    options={
                        availableDevices?.audioOutput.map((device: any) => ({
                            key: device.deviceId,
                            text: device.label,
                            data: device
                        })) || []
                    }
                    styles={{}}
                />

                <span style={{ display: 'block', minHeight: 16 }}></span>
                <Separator style={{ marginBottom: '13px' }}>Video settings</Separator>
                <Dropdown
                    label="Video device"
                    selectedKey={currentDevices?.videoInput && currentDevices.videoInput.deviceId}
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={(e, i) => onDeviceChange('videoInput', i?.data)}
                    placeholder="Select device"
                    options={
                        availableDevices?.videoInput.map((device: any) => ({
                            key: device.deviceId,
                            text: device.label,
                            data: device
                        })) || []
                    }
                    styles={{}}
                />
            </Stack>
            {children}
        </Panel>
    );
};

export default DeviceSettingsControllerView;
