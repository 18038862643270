import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import React from 'react';
import { IConfirmationDialogProps } from './ConfirmationDialog';

const ConfirmationDialogView: React.FC<IConfirmationDialogProps> = (props) => {
    const { title, desc, onConfirm, onDismiss, target, isOpen, confirmText, cancelText } = props;

    const dialogContentProps = {
        type: DialogType.normal,
        title: title,
        closeButtonAriaLabel: 'Close',
        subText: desc
    };

    return (
        <>
            {isOpen && (
                <Dialog hidden={false} onDismiss={() => onDismiss(target)} dialogContentProps={dialogContentProps}>
                    <DialogFooter>
                        <DefaultButton onClick={() => onDismiss(target)} text={cancelText || 'Cancel'} />
                        <PrimaryButton onClick={() => onConfirm(target)} text={confirmText || 'Confirm'} />
                    </DialogFooter>
                </Dialog>
            )}
        </>
    );
};

export default ConfirmationDialogView;
