import React from 'react';
import FlagsView from './Flags.view';

export type IFeatureFlag = 'useJitsiStagingEnv' | 'useDarkMode' | 'useCustomTheme';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setFlag = (flag: IFeatureFlag, value: string) => {
    localStorage.setItem('flag:' + flag, value);
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getFlag = (flag: IFeatureFlag) => {
    return localStorage.getItem('flag:' + flag);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Flags = () => {
    return <FlagsView setFlag={setFlag} getFlag={getFlag} />;
};

export default Flags;
