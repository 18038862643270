import * as React from 'react';

function SvgResendmail(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 26.1" width="1em" height="1em" {...props}>
            <defs>
                <style>{'.resendmail_svg__cls-2{fill:#1d1d1b}'}</style>
            </defs>
            <path
                d="M2 2v19.7h10.5v-2.2H4.2V5.1L9.9 9l4 2.8 1.5 1 .6.3.6-.3 1.1-.8 3.6-2.5 6.5-4.4V15l2.2-1.3V2zm17 6.5l-3 2-3.5-2.4-5.8-3.9h18.7z"
                transform="translate(-2 -2)"
                fill="#010101"
                id="resendmail_svg__mail"
            />
            <g id="resendmail_svg__draw">
                <path
                    className="resendmail_svg__cls-2"
                    d="M14.4 17.2l1.6 1.1s5.2-7.7 10.3-.4L24.7 19l5.3 2.7-.4-6.1-1.7 1.1s-5.9-9.3-13.5.5zM27.9 22.1l1.5 1.2s-6.2 10-13.4.9l-1.8 1.1v-6.1l5.1 2.9-1.6 1.1s4.5 7 10.2-1.1z"
                    transform="translate(-2 -2)"
                />
            </g>
        </svg>
    );
}

export default SvgResendmail;
