import * as React from 'react';

function SvgSecurityCheck(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M15 3v4h2V3h-2zM7 5v22h9.594c1.195 2.918 4.066 5 7.406 5 4.406 0 8-3.594 8-8 0-4.066-3.066-7.438-7-7.938V5h-7v2h5v9.063a7.994 7.994 0 00-3.531 1.375 4.968 4.968 0 00-.688-.594C19.523 16.117 20 15.114 20 14c0-2.2-1.8-4-4-4s-4 1.8-4 4c0 1.113.477 2.117 1.219 2.844A5.036 5.036 0 0011 21h2c0-1.668 1.332-3 3-3 .762 0 1.441.285 1.969.75A7.995 7.995 0 0016.062 25H9V7h5V5H7zm9 7c1.117 0 2 .883 2 2s-.883 2-2 2-2-.883-2-2 .883-2 2-2zm8 6c3.324 0 6 2.676 6 6s-2.676 6-6 6-6-2.676-6-6 2.676-6 6-6zm3.281 3.281L24 24.563l-2.281-2.282-1.438 1.438 3 3 .719.687.719-.687 4-4-1.438-1.438z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgSecurityCheck;
