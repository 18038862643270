import * as React from 'react';

function SvgAntenna(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M16 3.006a10.965 10.965 0 00-7.777 3.217c-4.288 4.287-4.288 11.267 0 15.554l1.414-1.414a8.984 8.984 0 010-12.726 8.984 8.984 0 0112.726 0 8.984 8.984 0 010 12.726l1.414 1.414c4.287-4.287 4.287-11.267 0-15.554A10.965 10.965 0 0016 3.006zm0 4.002c-1.793 0-3.587.68-4.95 2.043-2.724 2.725-2.724 7.173 0 9.898l1.415-1.414a4.984 4.984 0 010-7.07 4.984 4.984 0 017.07 0 4.984 4.984 0 010 7.07l1.414 1.414c2.725-2.725 2.725-7.173 0-9.898A6.976 6.976 0 0016 7.008zM16 11c-.917 0-1.731.38-2.248.96A3.05 3.05 0 0013 14c0 .722.235 1.458.752 2.04.314.352.758.59 1.248.755V23h-3.53L9 26.633V29h14v-2.303L20.535 23H17v-6.205c.49-.164.934-.403 1.248-.756A3.05 3.05 0 0019 14a3.05 3.05 0 00-.752-2.04C17.731 11.38 16.917 11 16 11zm0 2c.417 0 .602.12.752.29.15.168.248.432.248.71 0 .278-.098.542-.248.71-.15.17-.335.29-.752.29s-.602-.12-.752-.29A1.095 1.095 0 0115 14c0-.278.098-.542.248-.71.15-.17.335-.29.752-.29zm-3.47 12h6.935l1.334 2h-9.631l1.361-2z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgAntenna;
