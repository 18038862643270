/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { sendEvent } from 'kits/eventKit';
import { getSession, setSession } from 'kits/sessionKit';

export const handleMessage = (message: any, routeHistory: any) => {
    const [rememberedPath] = getSession('@rememberedPath');
    if (message.eventName == 'redirect') {
        const routes: any = {
            login: '/login',
            register: '/register'
        };
        routeHistory.push(routes[message.eventData.to] || '/');
    }
    if (message.eventName == 'tokens') {
        const { accessToken, msToken } = message.eventData;
        localStorage.setItem('ivAccessToken', accessToken);
        msToken && sessionStorage.setItem('msToken', msToken);
        msToken && setSession('@msAccessToken', msToken);
        sendEvent('refetchUserDetails', {});
        if (!window.location.pathname.includes('login')) {
            routeHistory.push('/');
            return;
        }
        routeHistory.push(typeof rememberedPath == 'string' && rememberedPath !== '/login' ? rememberedPath : '/');
    }
};
