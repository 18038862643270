import { mergeStyles } from '@fluentui/react';
import { useRoomActions } from 'features/Rooms/RoomList/helpers/roomHooks';
import { IRoomResource } from 'kits/apiKit3/legacy';
import { useRoom } from 'shared-state/directory/hooks';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { IProfile } from 'shared-state/identity/types';
import { useCurrentRoomId } from 'shared-state/location/hook';
import { useUsersInRoom } from 'shared-state/presence/hooks';
import 'styles/RoomList.css';
import { IRoomAction } from '../helpers/roomTypes';

export interface IListedRoomState {
    isSelected: boolean;
    users: IProfile[];
    actions: IRoomAction[];
    isDropDisabled: boolean;
    rootStyle: string;
    room: IRoomResource | undefined;
}

export const useListedRoomState: (roomId: string) => IListedRoomState = (roomId) => {
    const [currentRoom] = useCurrentRoomId();
    const isSelected = currentRoom == roomId;

    const localProfile = useLocalProfile();
    const [room] = useRoom(roomId);

    const users = useUsersInRoom(roomId);
    const actions = useRoomActions(roomId);

    const isDropDisabled = localProfile?.type == 'visitor' && !room?.attributes.openForVisitors;

    const rootStyle = mergeStyles({ marginBottom: 8, overflow: 'hidden', borderRadius: 4 });

    return { isSelected, users, actions, isDropDisabled, rootStyle, currentRoom, localProfile, room };
};
