import React from 'react';
import { useSpeakerStatsUserState } from './SpeakerStatsUser.state';
import SpeakerStatsUserView from './SpeakerStatsUser.view';

export interface ISpeakerStatsUserProps {
    speakerTime?: number;
    userId?: string;
    totalTime: number;
    userName: string;
    pretifyTime: (timeInMs: number) => string;
}

const SpeakerStatsUser: React.FC<ISpeakerStatsUserProps> = (props) => {
    const state = useSpeakerStatsUserState(props.userName);
    return (
        <SpeakerStatsUserView
            {...{
                ...state,
                ...{
                    speakerTime: props.speakerTime,
                    totalTime: props.totalTime,
                    userName: props.userName,
                    pretifyTime: props.pretifyTime,
                    userId: props.userId
                }
            }}
        />
    );
};
export default SpeakerStatsUser;
