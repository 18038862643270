/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/display-name */
import { DefaultEffects, getTheme, PrimaryButton, Stack, Text } from '@fluentui/react';
import { NeutralColors } from '@uifabric/fluent-theme';
import RealtimeServiceAdapter from 'adapters/realtime-adapter';
import RoomCallController from 'features/Rooms/RoomView/RoomCallController/RoomCallController';
import React from 'react';
const theme = getTheme();

export interface IOutsideCallControllerViewProps {
    displayName: string;
    room: string | undefined;
    jwt: string | undefined;
    jitsiRoom: string | undefined;
    domain: string | undefined;
    numberOfParticipants: number;
    setNumberOfParticipants: React.Dispatch<React.SetStateAction<number>>;
}

const OutsideCallControllerView: React.FC<IOutsideCallControllerViewProps> = (props) => {
    const { displayName, room, jwt, jitsiRoom, domain, numberOfParticipants, setNumberOfParticipants } = props;

    return (
        <Stack style={{ width: '80%', position: 'relative' }} verticalAlign="stretch">
            <RealtimeServiceAdapter isOutside />
            {numberOfParticipants <= 1 && (
                <Stack
                    verticalAlign="end"
                    horizontalAlign="end"
                    style={{
                        position: 'absolute',
                        top: 16 + 56,
                        left: 16,
                        right: 16,
                        padding: 32,
                        height: 'calc( 100% - 16px - 56px )',
                        width: 'calc( 100% - 32px )',
                        zIndex: 100
                        // background: 'rgba(0,0,0,0.3)'
                    }}
                >
                    <Stack
                        horizontalAlign="start"
                        verticalAlign="center"
                        style={{ minHeight: 176, padding: 32, background: theme.palette.white, boxShadow: DefaultEffects.elevation64, borderRadius: 2 }}
                    >
                        <Text variant="large">Waiting for host to join...</Text>
                        <Text variant="medium" style={{ maxWidth: 256 }}>
                            Your host has been informed about your arrival and should be with you any minute.
                        </Text>
                    </Stack>
                </Stack>
            )}
            {jwt && room && jitsiRoom && domain ? (
                <RoomCallController
                    callEventListeners={[
                        {
                            eventName: 'participantJoined',
                            handler: () => {
                                setNumberOfParticipants((n) => n + 1);
                            }
                        },
                        {
                            eventName: 'participantLeft',
                            handler: () => {
                                setNumberOfParticipants((n) => n - 1);
                            }
                        }
                    ]}
                    jwt={jwt}
                    roomName={jitsiRoom}
                    domain={domain}
                    actions={[[], ['device-settings', 'toggle-video', 'toggle-audio'], ['toggle-view']]}
                    displayName={displayName || 'Visitor'}
                />
            ) : (
                <Stack
                    grow
                    verticalAlign="stretch"
                    style={{
                        borderRadius: 0,
                        overflow: 'hidden',
                        height: '100%',
                        marginTop: 16,
                        boxShadow: DefaultEffects.elevation8,
                        background: NeutralColors.gray200,
                        position: 'relative',
                        marginLeft: 16,
                        marginRight: 16
                    }}
                >
                    <Stack
                        verticalAlign="center"
                        horizontalAlign="center"
                        style={{ width: '100%', minHeight: '100%', height: '100%', color: theme.palette.neutralLighter }}
                    >
                        <Text variant="xLargePlus">Please stand by.</Text>
                        <Text variant="large">We are announcing your arrival...</Text>
                        <Stack horizontal tokens={{ childrenGap: 16 }} verticalAlign="center" style={{ marginTop: 32 }}>
                            <Text>If this takes longer than expected retry</Text>
                            <PrimaryButton onClick={() => window.location.reload()}>Retry</PrimaryButton>
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
};

export default OutsideCallControllerView;
