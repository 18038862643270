/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { ConfigOptions, InterfaceConfigOptions } from '@ivicos/react-jitsi/dist/types';
import { usePersonalPreferencesState } from 'features/IdentityPanel/children/PersonalPreferences/PersonalPreferences.State';
import { getJitsiConfig, getJitsiInterfaceConfig } from 'features/Rooms/RoomView/config/jitsi';
import { ISpeakerStatsSpeaker } from 'features/Rooms/SpeakerStats/SpeakerStats.state';
import { IResource } from 'kits/apiKit3/legacy';
import { useEvent } from 'kits/eventKit';
import React, { useEffect, useState } from 'react';
import { SetterOrUpdater } from 'recoil';
import { useSelectedAttachment } from 'shared-state/location/hook';
import { useStatePersist } from 'use-state-persist';
import { hardcodedAddOns } from '../addons';
import { ICallAction, ICallActionKey, useCallActions } from '../CallActions';
import { IJitsiDevice, IJitsiDeviceConfig, IJitsiDeviceType } from '../DeviceSettingsController/DeviceSettingsController.state';
import { useCallState } from './callstate';

export interface IRoomCallControllerStateProps {
    jitsiAPI: any;
    jitsiConfig: ConfigOptions;
    interfaceConfig: InterfaceConfigOptions;
    devices: IJitsiDeviceConfig;
    deviceConfig: {
        audioInput: string | undefined;
        audioOutput: string | undefined;
        videoInput: string | undefined;
    };
    onDeviceSettingsChange: (t: IJitsiDeviceType, d: IJitsiDevice) => void;
    selectedAttachment:
        | {
              addOnId: string;
              ref: string;
              description: string;
          }
        | undefined;
    setSelectedAttachment: SetterOrUpdater<
        | {
              addOnId: string;
              ref: string;
              description: string;
          }
        | undefined
    >;
    addOnIsVisible: boolean;
    eventListeners: any[];
    activeAddOn: string | undefined;
    cameraIsMuted: boolean;
    micIsMuted: boolean;
    sharingIsOn: boolean;
    isConnected: boolean;
    tileViewIsEnabled: boolean;
    encryptionIsEnabled: boolean;
    stats: ISpeakerStatsSpeaker[];
    statsAreVisible: boolean;
    executeCommand: (command: string, data?: any) => void;
    showDeviceSettings: boolean;
    actionBarActions: {
        key: string;
        actions: ICallAction[];
    }[];
    handleAPILoad: (api: any) => void;
    isVideoHidden: boolean;
    setIsVideoHidden: React.Dispatch<React.SetStateAction<boolean>>;
    isDragging: boolean;
    setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
    movementDirection: {
        x: number;
        y: number;
    };
    setMovementDirection: React.Dispatch<
        React.SetStateAction<{
            x: number;
            y: number;
        }>
    >;
    dragStartPosition: {
        x: number;
        y: number;
    };
    setDragStartPosition: React.Dispatch<
        React.SetStateAction<{
            x: number;
            y: number;
        }>
    >;
    translateToHideOrShow: () => {
        x: number;
        y: number;
    };
    jitsiShowHideInnerContainerOpacity: number;
    activeAddOnData:
        | {
              id: string;
              type: string;
              attributes: {
                  urlTemplate: string;
                  iconUrl: string;
                  displayName: string;
                  description: string;
              };
              relationships: {};
          }
        | undefined;
    extractPositionFromEvent: (
        event: React.MouseEvent
    ) => {
        x: number;
        y: number;
    };
}

export interface IRoomCallEventListener {
    eventName: string;
    handler: (e: any) => void;
}

const useRoomCallController: (
    actions: Array<Array<ICallActionKey>>,
    profileImageURL: string | undefined,
    callEventListeners: Array<IRoomCallEventListener> | undefined,
    muteOnJoin: 'audio' | 'both' | 'video' | undefined,
    room: IResource | undefined,
    onAPI: ((api: any) => void) | undefined,
    roomName: string,
    onAddOnDataRecieved?: ((addonId: string, data: any) => void) | undefined
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
) => IRoomCallControllerStateProps = (actions, profileImageURL, callEventListeners, muteOnJoin, room, onAPI, roomName, onAddOnDataRecieved) => {
    const [jitsiAPI, setJitsiAPI] = useState<any>();
    const jitsiConfig: ConfigOptions = getJitsiConfig(muteOnJoin);

    const interfaceConfig: InterfaceConfigOptions = getJitsiInterfaceConfig();

    // Settings management
    const [devices, setDefaultDevices] = useStatePersist<IJitsiDeviceConfig>('@defaultDevices', {
        audioInput: undefined,
        videoInput: undefined,
        audioOutput: undefined
    });

    const [overlayURI, setOverlayURI] = useStatePersist<string | undefined>('@exp:overlay');
    const [overlayIsTransparent, setOverlayIsTransparent] = useStatePersist<boolean>('@exp:overlay:istransparent');
    const [overlayIsBackground, setOverlayIsBackground] = useStatePersist<boolean>('@exp:overlay:isbackground');

    const updateOverlay = () => {
        typeof overlayURI != 'undefined' &&
            typeof overlayIsTransparent != 'undefined' &&
            setOverlay(overlayURI || '', overlayIsTransparent, overlayIsBackground);
    };

    const setOverlay = (url: string, isTransparent: boolean, isBackground: boolean) => {
        jitsiAPI.setForegroundOverlay(url || '', '', isTransparent ? 'fusion' : 'circle');
    };
    const overlayListener = {
        eventName: 'videoMuteStatusChanged',
        handler: (e: any) => {
            if (!e.muted) updateOverlay();
        }
    };

    const getDeviceLabel = (type: IJitsiDeviceType) => devices && devices[type] && devices[type]?.label;
    const deviceConfig = {
        audioInput: getDeviceLabel('audioInput'),
        audioOutput: getDeviceLabel('audioOutput'),
        videoInput: getDeviceLabel('videoInput')
    };

    const onDeviceSettingsChange = (t: IJitsiDeviceType, d: IJitsiDevice) => {
        setDefaultDevices((pd: IJitsiDeviceConfig) => {
            pd[t] = d;
            return pd;
        });
    };

    const [selectedAttachment, setSelectedAttachment] = useSelectedAttachment();
    const addOnIsVisible = selectedAttachment ? true : false;
    // CallState
    const {
        eventListeners,
        activeAddOn,
        cameraIsMuted,
        micIsMuted,
        sharingIsOn,
        isConnected,
        tileViewIsEnabled,
        encryptionIsEnabled,
        stats,
        statsAreVisible,
        executeCommand,
        showDeviceSettings
    } = useCallState(roomName, room, jitsiAPI, [...(callEventListeners || []), ...[overlayListener]]);

    // Actions
    const callActions = useCallActions({
        tileViewIsEnabled,
        cameraIsMuted,
        sharingIsOn,
        micIsMuted,
        encryptionIsEnabled,
        statsAreVisible,
        addOnIsVisible,
        activeAddOn
    });

    const actionBarActions = actions.map((actionGroup, actionGroupIndex) => {
        const groupActions: Array<ICallAction> = actionGroup.map((action) => {
            return callActions[action];
        });

        return {
            key: 'action-group-' + actionGroupIndex,
            actions: groupActions.filter((a) => a != undefined)
        };
    });

    useEffect(() => {
        const api = jitsiAPI;

        if (!api) return () => {};

        onAPI && onAPI(api);

        updateOverlay();

        eventListeners.forEach((listener) => {
            api.addEventListener(listener.eventName, listener.handler);
        });

        return () => {
            eventListeners.forEach((listener) => {
                api.removeEventListener(listener.eventName, listener.handler);
            });
        };
    }, [jitsiAPI, overlayURI, overlayIsTransparent]);

    const handleAPILoad = (api: any) => {
        api.executeCommand('subject', ' ');
        setJitsiAPI(api);
    };

    const [isVideoHidden, setIsVideoHidden] = useState(false);

    const [isDragging, setIsDragging] = useState(false);
    const [movementDirection, setMovementDirection] = useState({ x: 0, y: 0 });
    const [dragStartPosition, setDragStartPosition] = useState({ x: 0, y: 0 });

    const translateToHideOrShow = React.useCallback(() => {
        return isVideoHidden ? { x: -409, y: 0 } : movementDirection;
    }, [isVideoHidden, movementDirection]);

    const [jitsiShowHideInnerContainerOpacity, setJitsiShowHideInnerContainerOpacity] = useState(0);

    const activeAddOnData = hardcodedAddOns.find((a) => a.id == selectedAttachment?.addOnId);

    const personalPreferencesState = usePersonalPreferencesState();
    const deviceSettings = personalPreferencesState.deviceSettings;

    useEvent('disable-camera', () => {
        cameraIsMuted == false ? executeCommand('toggleVideo') : null;
    });

    useEvent('disable-mic', () => {
        if (micIsMuted == false && deviceSettings?.microphoneDisabled) {
            return null;
        }
        micIsMuted == false ? executeCommand('toggleAudio') : null;
    });

    const extractPositionFromEvent = React.useCallback((event: React.MouseEvent) => {
        const positionX = event.screenX;
        const positionY = event.screenY;
        return { x: positionX, y: positionY };
    }, []);

    useEffect(() => {
        let timer;
        if (!selectedAttachment) setJitsiShowHideInnerContainerOpacity(0);
        else timer = setTimeout(() => setJitsiShowHideInnerContainerOpacity(1), 500);
    }, [selectedAttachment]);

    return {
        jitsiAPI,
        jitsiConfig,
        interfaceConfig,
        devices,
        deviceConfig,
        onDeviceSettingsChange,
        selectedAttachment,
        setSelectedAttachment,
        addOnIsVisible,
        eventListeners,
        activeAddOn,
        cameraIsMuted,
        micIsMuted,
        sharingIsOn,
        isConnected,
        tileViewIsEnabled,
        encryptionIsEnabled,
        stats,
        statsAreVisible,
        executeCommand,
        showDeviceSettings,
        actionBarActions,
        handleAPILoad,
        isVideoHidden,
        setIsVideoHidden,
        isDragging,
        setIsDragging,
        movementDirection,
        setMovementDirection,
        dragStartPosition,
        setDragStartPosition,
        translateToHideOrShow,
        jitsiShowHideInnerContainerOpacity,
        activeAddOnData,
        extractPositionFromEvent
    };
};

export default useRoomCallController;
