/* eslint-disable quotes */
import { CommandBarButton, DefaultButton, getTheme, IconButton, Label, mergeStyles, Modal, PrimaryButton, Stack, Text, TooltipHost } from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import { Trash } from 'kits/IconKit2';
import React, { useState } from 'react';
import Files from 'react-files';
import { IImagePickerImage } from '../ImagePicker.state';

interface IImagePickerViewProps {
    onDismiss: () => void;
    modalIsOpen: boolean;
    selectedImage: IImagePickerImage;
    imageOptions: IImagePickerImage[];
    onImageSelect: (i: IImagePickerImage) => void;
    onFile: (f: any) => void;
    onModalOpen: () => void;
    onDiscardImage: (f: string) => void;
    label?: string;
}

const ImagePickerView: React.FC<IImagePickerViewProps> = ({
    onModalOpen,
    onDismiss,
    imageOptions,
    modalIsOpen,
    selectedImage,
    onImageSelect,
    onFile,
    label,
    onDiscardImage
}) => {
    const theme = getTheme();

    const onCloseToUploadImage = () => onImageSelect({ id: '', url: '' });

    const [isHoverVisible, setIsHoverVisible] = useState('');

    const dropZoneStyles = mergeStyles({
        background: theme.palette.neutralLight,
        width: '30vh',
        height: '22vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        cursor: 'pointer',
        alignItems: 'center',
        paddingTop: '0.5vh',
        ':hover': {
            background: theme.palette.themeLight
        },
        ':hover > .note': {
            opacity: 1
        }
    });

    const onImageHoverHandler = (id: string) => {
        imageOptions.map((img) => (img.id === id ? setIsHoverVisible(id) : null));
    };

    return (
        <Stack>
            {label && <Label>{label}</Label>}
            <Stack
                style={{
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxWidth: '320px',
                    padding: '16px 26px',
                    background: theme.palette.neutralLight
                }}
                tokens={{ childrenGap: 16 }}
            >
                <Stack
                    grow
                    style={{
                        width: '100%',
                        height: 128,
                        backgroundColor: theme.palette.themePrimary,
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundImage: "url('" + selectedImage.url + "')"
                    }}
                >
                    {selectedImage.url == '' && <Text>No image selected :(</Text>}
                </Stack>
                <Stack style={{ flexDirection: 'column', justifyContent: 'space-around' }}>
                    <DefaultButton onClick={onModalOpen}>Change Image</DefaultButton>
                </Stack>
            </Stack>

            <Modal isOpen={modalIsOpen} onDismiss={onDismiss}>
                <Stack style={{ maxWidth: 580, flexDirection: 'column', alignItems: 'center' }}>
                    <Stack horizontal horizontalAlign="space-between" style={{ width: '100%', padding: '16px 16px' }}>
                        <Text variant="large">Upload a new image</Text>
                        <IconButton iconProps={{ iconName: 'Cancel' }} onClick={onDismiss} />
                    </Stack>
                    <Stack grow horizontalAlign="center" tokens={{ childrenGap: 16 }}>
                        {selectedImage.url != '' ? (
                            <Stack horizontalAlign="center" verticalAlign="center" style={{ width: '100%' }} tokens={{ childrenGap: 16 }}>
                                <Stack horizontal horizontalAlign="start" style={{ width: '100%', padding: '0px 16px' }} tokens={{ childrenGap: 16 }}>
                                    <CommandBarButton onClick={onCloseToUploadImage} text="Clear" iconProps={{ iconName: 'Clear' }} style={{ height: 42 }} />
                                    <Files onChange={onFile} accepts={['image/jpg', 'image/jpeg', 'image/png']} maxFileSize={700000} minFileSize={0} clickable>
                                        <CommandBarButton text="Upload new Image" iconProps={{ iconName: 'Upload' }} style={{ height: 42 }} />
                                    </Files>
                                </Stack>
                                <img
                                    src={selectedImage.url}
                                    alt="selected image"
                                    style={{ width: '30vh', justifyContent: 'center', marginBottom: 8, alignContent: 'center' }}
                                />
                            </Stack>
                        ) : (
                            <Stack>
                                <Files
                                    className={dropZoneStyles}
                                    onChange={onFile}
                                    accepts={['image/jpg', 'image/jpeg', 'image/png']}
                                    minFileSize={0}
                                    clickable
                                >
                                    <Text className={mergeStyles(['note', { opacity: 1 }])}> Click to upload image </Text>
                                </Files>
                            </Stack>
                        )}
                        <Stack
                            style={{
                                width: '100%',
                                padding: '0px 16px',
                                backgroundColor: theme.palette.neutralLighter,
                                borderBottom: '1px ' + theme.palette.neutralQuaternary + ' solid'
                            }}
                        >
                            <Stack style={{ width: '100%', margin: '2vh 0vh' }}>
                                <Label>Recently uploaded Images</Label>
                            </Stack>
                            <Stack
                                horizontal
                                horizontalAlign="space-between"
                                wrap
                                styles={{ inner: { overflowY: 'scroll', overflowX: 'hidden', width: '100%', height: '250px', marginBottom: '20px' } }}
                            >
                                {imageOptions.map((img: IImagePickerImage) => (
                                    <Stack
                                        key={img.id}
                                        styles={{
                                            root: {
                                                padding: '0.5vh',
                                                cursor: 'pointer',
                                                width: '160px',
                                                height: '160px',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                border: '2px transparent solid',
                                                ':hover': {
                                                    border: '2px ' + theme.palette.themePrimary + ' solid'
                                                }
                                            }
                                        }}
                                        onMouseEnter={() => onImageHoverHandler(img.id)}
                                        onMouseLeave={() => setIsHoverVisible('')}

                                        // style={{ padding: '10px', cursor: 'pointer', width: '160px', height: '160px', border: '2px transparent solid' }}
                                    >
                                        <Stack style={{ width: '140px', position: 'relative' }}>
                                            <img
                                                src={img.url}
                                                alt="img"
                                                key={img.id}
                                                onClick={() => {
                                                    onImageSelect(img);
                                                }}
                                            />
                                            {isHoverVisible === img.id ? (
                                                <Stack
                                                    horizontal
                                                    styles={{
                                                        root: {
                                                            position: 'absolute',
                                                            right: 0,
                                                            padding: '8px',
                                                            top: 0,
                                                            color: theme.palette.red,
                                                            backgroundColor: theme.palette.white,
                                                            '&:hover': {
                                                                backgroundColor: theme.palette.neutralLighter
                                                            },
                                                            '&:active': {
                                                                backgroundColor: theme.palette.neutralLight
                                                            }
                                                        }
                                                    }}
                                                    onClick={() => onDiscardImage(img.id)}
                                                >
                                                    <TooltipHost content="Delete">
                                                        <IC variant="custom" iconColor={theme.palette.red} size={15}>
                                                            <Trash />
                                                        </IC>
                                                    </TooltipHost>
                                                </Stack>
                                            ) : null}
                                        </Stack>
                                    </Stack>
                                ))}
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack horizontal horizontalAlign="end" style={{ width: '100%', padding: '16px' }}>
                        <PrimaryButton onClick={onDismiss} text="Close" />
                    </Stack>
                </Stack>
            </Modal>
        </Stack>
    );
};

export default ImagePickerView;
