import React from 'react';
import VisitorsTab from './children/VisitorsTab/VisitorsTab';
import { IVisitorsTabControllerState } from './VisitorsTabController.state';

const VisitorsTabControllerView: React.FC<IVisitorsTabControllerState> = (props) => {
    const { localProfile, visitors } = props;
    return (
        <VisitorsTab
            visitors={visitors.filter((v: any) => v.host != localProfile?.uid)}
            featuredVisitors={visitors.filter((v: any) => v.host == localProfile?.uid)}
        />
    );
};

export default VisitorsTabControllerView;
