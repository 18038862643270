import { ActionButton, Dialog, DialogFooter, DialogType, IconButton, IIconProps, Modal, PrimaryButton, Stack, Text } from '@fluentui/react';
import IconKit from 'kits/IconKit';
import React from 'react';
import { IHelpModalState } from 'features/Header/children/HelpModal/HelpModal.state';

const HelpModalView: React.FC<IHelpModalState> = (props) => {
    const { isHelpModalOpen, onHelpModalDismiss, resetTutorials, openHelpCentrePage, openReportErrorPage, refreshPage, isTutorialResetDialogOpen } = props;

    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Tutorials Reset',
        closeButtonAriaLabel: 'Close',
        subText: 'Please refresh the page to see the tutorials!'
    };

    const refreshIcon: IIconProps = { iconName: 'Refresh' };
    const helpCentreIcon: IIconProps = { iconName: 'Help' };
    const reportBugIcon: IIconProps = { iconName: 'Error' };

    return (
        <>
            <Modal isOpen={isHelpModalOpen} onDismiss={() => onHelpModalDismiss()} isBlocking={false}>
                <Stack horizontal horizontalAlign="end">
                    <IconButton iconProps={{ iconName: 'cancel' }} ariaLabel="Close popup modal" onClick={() => onHelpModalDismiss()} />
                </Stack>
                <Stack verticalAlign={'center'} horizontalAlign={'start'} tokens={{ childrenGap: 8 }} style={{ paddingBottom: '20px', marginLeft: '25%' }}>
                    <Stack horizontal horizontalAlign={'center'} style={{ marginBottom: '10px' }} tokens={{ childrenGap: 16 }}>
                        <IconKit.Icon icon={IconKit.IconSet.Small.Help} />
                        <Text variant={'xLarge'}>Help</Text>
                    </Stack>
                    <ActionButton iconProps={refreshIcon} text={'Reset Tutorials'} onClick={() => resetTutorials()}></ActionButton>
                    <ActionButton iconProps={helpCentreIcon} text={'Help Centre'} onClick={() => openHelpCentrePage()}></ActionButton>
                    <ActionButton iconProps={reportBugIcon} text={'Report Error'} onClick={() => openReportErrorPage()}></ActionButton>
                </Stack>
            </Modal>

            <Dialog hidden={!isTutorialResetDialogOpen} onDismiss={() => refreshPage()} dialogContentProps={dialogContentProps}>
                <DialogFooter>
                    <PrimaryButton onClick={() => refreshPage()} text={'Refresh page'} />
                </DialogFooter>
            </Dialog>
        </>
    );
};
export default HelpModalView;
