import { Callout, CommandButton, DirectionalHint, Separator, Stack, Text, TextField } from '@fluentui/react';
import CopyButton from 'components/CopyButton/CopyButton';
import IconButton from 'components/IconButton';
import { IC } from 'components/SVGIcon';
import { Trash } from 'kits/IconKit2';
import SvgHistory from 'kits/IconKit2/History';
import React from 'react';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import CreateNewLinkModal from './CreateNewLinkModal/CreateNewLinkModal';

interface ILinkManagerViewProps {
    createLinkModalIsOpen?: boolean;
    onModalDismiss?: Function;
    onModalOpened?: Function;
    onModalSubmit?: Function;
    modalLink?: string;
    invitationList: { key: string; url: string; note: string; onDelete: () => Promise<void> }[];
    isCalloutVisible: boolean;
    toggleIsCalloutVisible: () => void;
    buttonId: string;
}

const LinkManagerView: React.FC<ILinkManagerViewProps> = ({
    createLinkModalIsOpen,
    onModalOpened,
    modalLink,
    onModalSubmit,
    onModalDismiss,
    invitationList,
    isCalloutVisible,
    toggleIsCalloutVisible,
    buttonId
}) => {
    const { smallDevice } = useWindowWidth();

    return (
        <>
            <CreateNewLinkModal
                isOpen={createLinkModalIsOpen}
                onDismiss={onModalDismiss}
                onSubmit={(desc) => onModalSubmit && onModalSubmit(desc)}
                linkToCopy={modalLink}
            />
            <Stack tokens={{ childrenGap: 8 }}>
                <Stack horizontal horizontalAlign="space-between">
                    <Text variant="large">Invite visitors...</Text>
                    {invitationList.length > 0 && (
                        <Stack id={buttonId} className="pressable" style={{ padding: 3 }} onClick={toggleIsCalloutVisible}>
                            <IC variant="dark" size={24}>
                                <SvgHistory />
                            </IC>
                        </Stack>
                    )}
                </Stack>

                <Stack horizontalAlign="center">
                    <CommandButton
                        iconProps={{ iconName: 'Add' }}
                        text="Create new Invitation"
                        onClick={() => {
                            onModalOpened && onModalOpened();
                        }}
                    />
                </Stack>
            </Stack>

            {invitationList.length > 0 && isCalloutVisible && (
                <Callout
                    role="alertdialog"
                    directionalHint={DirectionalHint.bottomRightEdge}
                    gapSpace={0}
                    target={`#${buttonId}`}
                    onDismiss={toggleIsCalloutVisible}
                    setInitialFocus
                >
                    <Stack tokens={{ childrenGap: 8 }} style={{ padding: 16 }}>
                        {invitationList.map((invitation, index, arr) => {
                            return (
                                <>
                                    <Stack horizontal key={invitation.key} verticalAlign="center" tokens={{ childrenGap: 4 }}>
                                        <Text
                                            style={{
                                                width: smallDevice ? 100 : 120,
                                                minWidth: smallDevice ? 100 : 120,
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}
                                        >
                                            {invitation.note || 'No note'}
                                        </Text>

                                        <TextField style={{ minWidth: smallDevice ? 100 : 180 }} readOnly value={invitation.url} />
                                        <CopyButton value={invitation.url} />
                                        <IconButton tooltipContent="Delete link" onRenderIcon={() => <Trash />} onPress={invitation.onDelete} />
                                    </Stack>
                                    {index + 1 != arr.length && <Separator styles={{ root: { height: 3 } }} />}
                                </>
                            );
                        })}
                    </Stack>
                </Callout>
            )}
        </>
    );
};

export default LinkManagerView;
