import { getTheme, Stack, TooltipHost } from '@fluentui/react';
import React from 'react';
import { IC, ISVGIconVariant } from 'components/SVGIcon';
import { ReactElement } from 'react-markdown';

const IconButton: React.FC<{
    tooltipContent?: string;
    iconVariant?: ISVGIconVariant;
    onRenderIcon: () => ReactElement;
    onPress: () => void;
    isSelected?: boolean;
    isDisabled?: boolean;
}> = ({ isSelected, tooltipContent, onRenderIcon, isDisabled, iconVariant, onPress }) => {
    const theme = getTheme();
    const onRenderContent = () => (
        <Stack
            styles={{
                root: {
                    padding: 4,
                    opacity: isDisabled ? 0.7 : 1,
                    background: isSelected ? theme.palette.neutralLighter : theme.palette.white,

                    '&:hover': {
                        background: !isDisabled && theme.palette.neutralLighter
                    },
                    '&:active': {
                        background: !isDisabled && theme.palette.neutralLight
                    }
                }
            }}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onClick={(e: any) => !isDisabled && onPress()}
        >
            <IC variant={iconVariant || isSelected ? 'primary' : 'dark'} size={24}>
                {onRenderIcon()}
            </IC>
        </Stack>
    );

    const wrap = (content: ReactElement) => {
        if (tooltipContent) return <TooltipHost content={tooltipContent}>{content}</TooltipHost>;

        return <>{content}</>;
    };

    return wrap(onRenderContent());
};

export default IconButton;
