/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';
import { IProfile } from './types';
import { sProfile } from 'shared-state/identity/selectors';
import { aAvailability, aIdentityClaims, aProfileImage } from './atoms';

export const useLocalProfile = (): IProfile | undefined => {
    return useRecoilValue(sProfile);
};

export const useSharedAvailability = () => {
    return useRecoilState(aAvailability);
};

export const useIdentityClaims = () => {
    return useRecoilState(aIdentityClaims);
};

export const useLocalProfileImage = () => {
    return useRecoilState(aProfileImage);
};
