import React from 'react';
import { useNotificationsLayerState } from './NotificationsLayer.state';
import NotificationsLayerView from './NotificationsLayer.view';

const NotificationsLayer: React.FC<{}> = ({ children }) => {
    const state = useNotificationsLayerState();
    return (
        <NotificationsLayerView
            {...{
                ...state
            }}
        >
            {children}
        </NotificationsLayerView>
    );
};

export default NotificationsLayer;
