/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { IRoomResource } from 'kits/apiKit3/legacy';
import { useCallback } from 'react';
import { useRealtimeService } from 'services/realtime-transport';
import { getChannelNameForOptions } from 'services/realtime-transport/ivicos-pusher/actions';
import { IRealtimeService } from 'services/realtime-transport/types';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { IProfile } from 'shared-state/identity/types';
import { useCurrentAreaId, useCurrentCampusId, useCurrentRoomId } from 'shared-state/location/hook';
import { useUsersInRoom } from 'shared-state/presence/hooks';
import { v1 as uuidv1 } from 'uuid';

export interface IMessagingEvent {
    type: 'knock' | 'invite' | 'memo' | 'request-jitsisessionid' | 'response-jitsisessionid';
    meta: {
        forRoom?: string;
        forUser?: string;
        attachedRoom?: string;
    };
    body?: string;
    announce?: boolean;
    timeout?: number;
}

export interface IUpdateEvent {
    type: 'update' | 'deletion' | 'creation';
    room?: IRoomResource;
    roomId?: string;
}

export const useTokenSender = () => {
    const REALTIME = useRealtimeService();
    const [areaId] = useCurrentAreaId();
    const [campusId] = useCurrentCampusId();
    const channelType = 'visitor';

    const channelName = getChannelNameForOptions({ areaId, campusId, channelType });

    return (token: string, user: string, destinationRoom?: string) =>
        channelName &&
        REALTIME?.send(channelName, 'client-token', {
            type: 'visitor-access',
            meta: {
                forUser: user,
                attachedRoom: destinationRoom
            },
            token: token
        });
};

export const sendHeartbeatEvent = (service: IRealtimeService, campusId: string, areaId: string, profile: IProfile) => {
    const channelName = getChannelNameForOptions({ areaId, campusId, channelType: 'presence' });
    service.send(channelName, 'client-heartbeat', profile);
};

export const useHeartbeatEvent = () => {
    const [areaId] = useCurrentAreaId();
    const [campusId] = useCurrentCampusId();
    const channelType = 'presence';
    const channelName = getChannelNameForOptions({ areaId, campusId, channelType });

    const REALTIME = useRealtimeService();
    const profile = useLocalProfile();

    const sendEvent = useCallback(() => {
        channelName && REALTIME.send(channelName, 'client-heartbeat', profile);
    }, [profile]);

    return sendEvent;
};

export const useMessagingEvents = () => {
    const [campusId] = useCurrentCampusId();
    const [areaId] = useCurrentAreaId();

    const REALTIME = useRealtimeService();

    const sendMessagingEvent = (messagingEvent: IMessagingEvent) => {
        areaId && REALTIME?.send(getChannelNameForOptions({ channelType: 'presence', campusId, areaId }), 'client-message', messagingEvent);
    };
    return sendMessagingEvent;
};

export const useUpdateEvents = () => {
    const REALTIME = useRealtimeService();
    const [campusId] = useCurrentCampusId();
    const [areaId] = useCurrentAreaId();

    const sendUpdateEvent = (updateEvent: IUpdateEvent) => {
        areaId && REALTIME?.send(getChannelNameForOptions({ channelType: 'presence', campusId, areaId }), 'client-update', updateEvent);
    };
    return sendUpdateEvent;
};

export const useUserMessagingActions = (): {
    inviteUserToRoom: (userId: string, roomId: string) => void;
    sendMemoToUser: (userId: string, memoBody: string) => void;
    sendAccessRequest: (userId: string, roomId: string) => void;
    sendAccessResponse: (userId: string, jitsiSessionId: string) => void;
} => {
    const sendMessagingEvent = useMessagingEvents();
    const [roomId] = useCurrentRoomId();
    const usersInRoom = useUsersInRoom(roomId || '');
    const inviteUserToRoom = (userId: string, roomId: string) => {
        let id;
        if (usersInRoom.length > 0) {
            id = localStorage.getItem('jitsiSessionId');
        } else {
            id = uuidv1();
            localStorage.setItem('jitsiSessionId', id);
        }

        sendMessagingEvent({
            type: 'invite',
            meta: { forUser: userId, attachedRoom: roomId },
            body: id || '',
            announce: true
        });
    };

    const sendMemoToUser = (userId: string, memoBody: string) => {
        sendMessagingEvent({
            type: 'memo',
            meta: { forUser: userId },
            body: memoBody,
            announce: true
        });
    };

    const sendAccessRequest = (userId: string, roomId: string) => {
        sendMessagingEvent({
            type: 'request-jitsisessionid',
            meta: { forUser: userId },
            body: roomId,
            announce: true
        });
    };

    const sendAccessResponse = (userId: string, jitsiSessionId: string) => {
        sendMessagingEvent({
            type: 'response-jitsisessionid',
            meta: { forUser: userId },
            body: jitsiSessionId,
            announce: true
        });
    };

    return { inviteUserToRoom, sendMemoToUser, sendAccessRequest, sendAccessResponse };
};

export const useRoomMessagingActions = (roomId?: string) => {
    const sendMessagingEvent = useMessagingEvents();

    const knock = (room?: string) => {
        sendMessagingEvent({
            type: 'knock',
            meta: { forRoom: room || roomId },
            announce: true
        });
    };

    return { knock };
};
