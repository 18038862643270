import * as React from 'react';

function SvgLink(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M18 5v2h5.563L11.28 19.281l1.438 1.438L25 8.437V14h2V5h-9zM5 9v18h18V14l-2 2v9H7V11h9l2-2H5z" fill="#000" />
        </svg>
    );
}

export default SvgLink;
