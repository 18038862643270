import React from 'react';
import { Draggable, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';
import { IDraggableItemProps } from './DraggableItem';

const DraggableItemView: React.FC<IDraggableItemProps> = (props) => {
    const { draggableId, index, children } = props;

    const getItemStyle = (isDragging: boolean, providedStyle: DraggingStyle | NotDraggingStyle | undefined) => providedStyle;

    return (
        <Draggable draggableId={draggableId} index={index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    {...provided.draggableProps}
                >
                    {children}
                </div>
            )}
        </Draggable>
    );
};

export default DraggableItemView;
