import { IRoomAction } from 'features/Rooms/RoomList/helpers/roomTypes';
import React from 'react';
import { useVisitorState } from './Visitor.state';
import VisitorView from './Visitor.view';
export interface IVisitorProps {
    displayName: string;
    label: string;
    selected: boolean;
    action: IRoomAction;
}
const Visitor: React.FC<IVisitorProps> = (props) => {
    const state = useVisitorState();
    return (
        <VisitorView
            {...{
                ...state,
                ...{
                    displayName: props.displayName,
                    label: props.label,
                    selected: props.selected,
                    action: props.action
                }
            }}
        ></VisitorView>
    );
};

export default Visitor;
