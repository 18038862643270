import * as React from 'react';

function SvgEtherpad(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M5.033 5v22h22V5h-22zm2 2h18v18h-18V7zm3 4v2h12v-2h-12zm-.017 4v2h8v-2h-8zm.017 4v2h12v-2h-12z" fill="#000" />
        </svg>
    );
}

export default SvgEtherpad;
