import React from 'react';
import { useRoomListState } from './RoomList.state';
import RoomListView from './RoomList.view';

const RoomList: React.FC<{}> = () => {
    const state = useRoomListState();

    return (
        <RoomListView
            {...{
                ...state,
                ...{
                    personalRoomIds: state.personalIds,
                    showCreateRoomButton: !state.identityClaims?.roles.includes('visitor') && !state.identityClaims?.roles.includes('user'),
                    commonRoomIds: state.commonIds
                }
            }}
        />
    );
};
export default RoomList;
