/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Channel } from 'pusher-js';
import { AuthorizerOptions } from 'pusher-js/types/src/core/auth/options';
import { thirdPartyAuthService } from 'services/api/third-party-auth.service';
import { getChannelNameForOptions } from 'services/realtime-transport/ivicos-pusher/actions';
import { pusherInstance } from './instance';

export const authLib: any = {};

export async function authorizeChannel(campusId: string, areaId: string, channelType: string): Promise<string> {
    const socketId = pusherInstance?.connection.socket_id;
    const auth = await thirdPartyAuthService.getPusherAuth(campusId, areaId, socketId, channelType);

    const channelName = getChannelNameForOptions({ channelType, campusId, areaId });
    console.log('DEBUG', channelName);
    authLib[channelName] = auth;

    return channelName;
}

export function constructRealtimeAuthorizer(channel: Channel, options: AuthorizerOptions) {
    console.log('[RT] Constructing auth for ', channel.name);
    return {
        authorize: async (socketId: string, callback: Function) => {
            console.log('[RTA] authorizing ', channel.name);

            const auth = authLib[channel.name];
            console.log('[RTA] got  ', auth);

            if (!auth) return callback(new Error('You have to preauth to subscribe!'), false);
            callback(false, auth);
        }
    };
}
