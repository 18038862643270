import { IUserFaceActionStyles } from 'features/Rooms/RoomList/UserFace/UserFace';
import React from 'react';
import { IProfile } from 'shared-state/identity/types';
import UserFacepileView from './UserFacepile.view';

export interface IUserFacepileProps {
    users: IProfile[];
    draggable?: boolean;
    actionStyles?: IUserFaceActionStyles;
    panelTitle?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UserFacepile: React.FC<IUserFacepileProps> = (props) => {
    return (
        <UserFacepileView
            {...{
                ...{
                    users: props.users,
                    draggable: props.draggable,
                    actionStyles: props.actionStyles,
                    panelTitle: props.panelTitle
                }
            }}
        />
    );
};

export default UserFacepile;
