import { getTheme, ITheme } from '@fluentui/react';
import { SharedColors } from '@uifabric/fluent-theme';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { AreaDto } from 'client-api-wrapper/lib/campus_api/responses.dto';
import { useOrganizationBranding } from 'features/Header/hooks';
import 'intro.js/introjs.css';
import IvicosColors from 'kits/colorKit';
import { useEffect, useState } from 'react';
import { useCampus } from 'shared-state/directory/hooks';
import { useIdentityClaims } from 'shared-state/identity/hooks';
import { IIdentityClaims } from 'shared-state/identity/types';
import { useCurrentOrgId } from 'shared-state/location/hook';
import { getClassNames } from 'styles/responsiveness';

export interface IAreaCardStateProps {
    theme: ITheme;
    getPrimaryCardColor: () => string;
    stepsEnabled?: boolean;
    setStepsEnabled: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    steps: {
        initialStep: number;
        steps: (
            | {
                  element: string;
                  intro: string;
                  position: string;
                  tooltipClass: string;
                  showProgress?: undefined;
                  showBullets?: undefined;
              }
            | {
                  element: string;
                  intro: string;
                  position: string;
                  showProgress: boolean;
                  showBullets: boolean;
                  tooltipClass?: undefined;
              }
        )[];
    };
    options: {
        doneLabel: string;
        hideNext: boolean;
        showStepNumbers: boolean;
        showBullets: boolean;
        hidePrev: boolean;
    };
    areaCardWidth: string;
    areaCardWidthVertical: string;
    area?: AreaDto;
    descriptionId: string;
    labelId: string;
    buttonId: string;
    identityClaims?: IIdentityClaims;
    isCalloutVisible: boolean;
    toggleIsCalloutVisible: () => void;
}

export const useAreaCardState: (isPublic?: boolean) => IAreaCardStateProps = (isPublic) => {
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [identityClaims, setIdentityClaims] = useIdentityClaims();
    const buttonId = useId('callout-button');

    const labelId = useId('callout-label');

    const descriptionId = useId('callout-description');
    const [orgId] = useCurrentOrgId();
    const [orgBranding] = useOrganizationBranding(orgId);

    const getPrimaryCardColor = () => (isPublic ? orgBranding?.orgColors[0] || IvicosColors.Mittelblau : SharedColors.blueMagenta10);

    const theme = getTheme();

    const [stepsEnabled, setStepsEnabled] = useState<boolean>();

    useEffect(() => {
        // localStorage.getItem('intro-areas') === 'false' ? setStepsEnabled(false) : setStepsEnabled(true);
        // localStorage.getItem('intro-areas-final') === 'false' ? setStepsEnabledFinal(false) : setStepsEnabledFinal(true);
        if (localStorage.getItem('intro-areas') === 'false') {
            setStepsEnabled(false);
        } else if (localStorage.getItem('intro-areas') === 'true') {
            setStepsEnabled(true);
        }
    }, []);

    const steps = {
        initialStep: 0,
        steps: [
            {
                element: '.start',
                intro: 'Welcome to this guided tutorial to your ivCAMPUS! Simply click next to start exploring. ',
                position: 'middle',
                tooltipClass: 'introjs-tooltip-start'
            },
            {
                element: '.menu',
                intro:
                    'Here you can find the Areas tab and the Change Log tab. For ivCAMPUS managers, along with these you will find the Manage Access and Campus Settings tabs.',
                position: 'right',
                showProgress: false,
                showBullets: true
            },
            {
                element: '.one-card',
                intro: 'This is an Area. This is where you can meet and interact with your team.',
                position: 'top',
                tooltipClass: 'introjs-tooltip-areas'
            }
        ]
    };

    const options = {
        doneLabel: 'Ok!',
        hideNext: false,
        showStepNumbers: true,
        showBullets: false,
        hidePrev: true
    };

    const { areaCardWidth, areaCardWidthVertical } = getClassNames();

    return {
        theme,
        getPrimaryCardColor,
        stepsEnabled,
        setStepsEnabled,
        steps,
        options,
        areaCardWidth,
        areaCardWidthVertical,
        descriptionId,
        labelId,
        buttonId,
        identityClaims,
        isCalloutVisible,
        toggleIsCalloutVisible
    };
};
