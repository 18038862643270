/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getSessionKeyForTokenType = (tokenType: string, tennantId: string) => {
    return 'token:' + tokenType + ':' + tennantId;
};

export const getTokenFromSession = (tokenType: string, tennantId: string) => {
    const tokenKey = getSessionKeyForTokenType(tokenType, tennantId);
    const token = sessionStorage.getItem(tokenKey);
    return token;
};

export const setTokenInSession = (tokenType: string, tennantId: string, newValue: string) => {
    const tokenKey = getSessionKeyForTokenType(tokenType, tennantId);
    const token = sessionStorage.setItem(tokenKey, newValue);
    return token;
};
