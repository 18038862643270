import { getTheme, ITheme } from '@fluentui/react';
import { useUserMessagingActions } from 'adapters/realtime-adapter/actions/messaging';
import { useJitsiSessionRouter } from 'adapters/realtime-adapter/handlers';
import { useOrganizationBranding } from 'features/Header/hooks';
import { IRoomResource } from 'kits/apiKit3/legacy';
import IvicosColors from 'kits/colorKit';
import { sendEvent } from 'kits/eventKit';
import { useHistory } from 'react-router';
import { useLocalProfile } from 'shared-state/identity/hooks';
import { useCurrentAreaId, useCurrentOrgId } from 'shared-state/location/hook';
import { useUsers, useVisitorList } from 'shared-state/presence/hooks';
import 'styles/RoomList.css';

export interface IListedRoomHeaderState {
    symbol: string;
    displayName: string;
    openForVisitors?: boolean;
    onDoubleClick: () => void;
    isRoomEnteranceAllowedForUser: () => boolean;
    theme: ITheme;
    userId: string | undefined;
    isPrivate: boolean;
    whitelist: string[];
    isVisitorInArea?: boolean;
    roomOwner?: string;
}

export const useListedRoomHeaderState: (room: IRoomResource) => IListedRoomHeaderState = (room) => {
    // const owner = useUser(room?.relationships.owner);
    const theme = getTheme();
    const [areaId] = useCurrentAreaId();
    const localProfile = useLocalProfile();
    const visitors = useVisitorList(areaId || '');
    const users = useUsers();
    const routeHistory = useHistory();
    const { sendAccessRequest } = useUserMessagingActions();

    const { conditionRouting } = useJitsiSessionRouter(room.id);

    const { symbol, displayName, openForVisitors } = room.attributes;

    const userId = localProfile?.uid;
    const isRoomEnteranceAllowedForUser: () => boolean = () => {
        if (!userId) return false;

        if (localProfile?.type == 'visitor' && !openForVisitors) return false;
        else if (room.attributes.whitelist)
            return (
                (room.attributes.whitelist.length === 0 && !isPrivate) || room.attributes.whitelist.includes(userId) || userId === room?.attributes.creatorId
            );
        else return true;
    };
    const onDoubleClick = () => {
        if (isRoomEnteranceAllowedForUser()) {
            conditionRouting();
            routeHistory.push('/areas/' + areaId + '/rooms/' + room.id);
            sendEvent('closeMobileRoomsPanel', {});
        } else return;
    };

    const whitelist: string[] = room.attributes.whitelist || [];

    const roomOwner = room.attributes.creatorId;

    const isPrivate = room.attributes.private;

    const visitorHosts = visitors[0].map((visitor: any) => visitor.host).join();

    const visitorRooms = users.map((user: any) => user.type).join();

    const isVisitorInArea = visitorHosts.includes(localProfile?.uid || '@@@@@@@@2') || visitorRooms.includes('visitor');

    return {
        symbol,
        whitelist,
        displayName,
        openForVisitors,
        onDoubleClick,
        isVisitorInArea,
        isRoomEnteranceAllowedForUser,
        theme,
        userId,
        isPrivate,
        roomOwner
    };
};
