/* eslint-disable react/display-name */

import { getTheme, IconButton, IContextualMenuItem, TooltipHost } from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import { More } from 'kits/IconKit2';
import React, { useMemo } from 'react';
import { IRoomAction } from '../helpers/roomTypes';

export interface IMoreButtonViewProps {
    setHovering: React.Dispatch<React.SetStateAction<Boolean>>;
    actions?: Array<IRoomAction>;
}

const MoreButtonView: React.FC<IMoreButtonViewProps> = ({ setHovering, actions }) => {
    const theme = getTheme();
    if (!actions) return <></>;
    const actionList: Array<IContextualMenuItem> | undefined = useMemo(
        () =>
            actions &&
            actions
                .filter((a) => a.title)
                .map((action) => ({
                    key: action.key,
                    text: action.title,
                    disabled: action.isDisabled,
                    onClick: (e: any) => action.action && action.action(e),
                    iconProps: { iconName: 'Room' },
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    onRenderIcon: (props: any) => (
                        <IC size={16} variant={action.isDisabled ? 'dim' : 'dark'}>
                            {action.getSymbol && action.getSymbol()}
                        </IC>
                    )
                })),
        [actions]
    );
    return (
        <TooltipHost content={'Room options'}>
            <IconButton
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
                key="MoreButton"
                // change?
                styles={{ rootHovered: { backgroundColor: theme.palette.neutralQuaternary }, rootPressed: { backgroundColor: theme.palette.neutralTertiary } }}
                onRenderIcon={() => (
                    <IC variant="dark" size={20}>
                        <More />
                    </IC>
                )}
                onRenderMenuIcon={() => <></>}
                iconProps={{ iconName: 'MoreVertical' }}
                menuProps={{ items: actionList || [] }}
            />
        </TooltipHost>
    );
};

export default MoreButtonView;
