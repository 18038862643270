import React from 'react';
import { useCopyButtonState } from './CopyButton.state';
import CopyButtonView from './CopyButtonView';

export interface ICopyButtonProps {
    value: string;
}

const CopyButton: React.FC<ICopyButtonProps> = (props) => {
    const state = useCopyButtonState(props.value);

    return (
        <CopyButtonView
            {...{
                ...state
            }}
        ></CopyButtonView>
    );
};
export default CopyButton;
