import * as React from 'react';

function SvgHandKnock(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#hand--knock_svg__clip0)" fill="#000">
                <path d="M11.96 15.886a2.701 2.701 0 00-.426 2.574c-.421.169-.81.432-1.109.81a2.717 2.717 0 00.437 3.797l2.581 2.047.724 2.983c.451 1.827 2.173 3.094 4.053 3l3.227-.17a6.29 6.29 0 004.616-2.376l2.958-3.727c2.16-2.721 1.701-6.702-1.02-8.86l-6.352-5.041a2.717 2.717 0 00-3.798.437c-.3.378-.467.815-.535 1.264a2.701 2.701 0 00-2.41 1c-.3.377-.468.814-.536 1.263a2.701 2.701 0 00-2.41 1zm1.411 1.12a.885.885 0 011.266-.146l2.117 1.68 1.12-1.41-1.411-1.121a.885.885 0 01-.146-1.266.885.885 0 011.266-.146l1.411 1.12 1.12-1.411-.705-.56a.885.885 0 01-.146-1.266.885.885 0 011.266-.146l6.352 5.04a4.491 4.491 0 01.729 6.33l-2.958 3.727a4.479 4.479 0 01-3.291 1.702l-3.25.153a2.106 2.106 0 01-2.169-1.614l-.183-.72.75.595 1.12-1.412-5.647-4.48a.885.885 0 01-.145-1.266.885.885 0 011.266-.145l1.411 1.12 1.12-1.412-2.117-1.68a.885.885 0 01-.146-1.266zM5.808 18.145l.233-1.787 3.683.483-.233 1.787-3.683-.483zM15.406 7.227l-1.743.461.953 3.59 1.743-.46-.953-3.591zM9.287 9.944l-1.255 1.293 2.742 2.663 1.255-1.292-2.742-2.664zM2 1h2v26H2V1zM19 1h2v6h-2V1z" />
                <path d="M4 3V1h17v2H4zM4 27v-2h5v2H4z" />
            </g>
            <defs>
                <clipPath id="hand--knock_svg__clip0">
                    <path fill="#fff" d="M0 0h32v32H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default SvgHandKnock;
