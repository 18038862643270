import React from 'react';
import { useParams } from 'react-router';
import MobileView from './Mobile.view';

export interface IMobileParams {
    jwt: string;
    room: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Mobile = () => {
    const params = useParams<IMobileParams>();
    return <MobileView params={params} />;
};

export default Mobile;
