/* eslint-disable react/display-name */

import React from 'react';
import { IProfile } from 'shared-state/identity/types';
import { useUserFaceState } from './UserFace.state';
import UserFaceView from './UserFace.view';

export interface IUserFaceActionStyles {
    hidden?: boolean;
    expanded?: boolean;
}

export interface IUserFaceProps {
    user: IProfile;
    index: number;
    showDetails?: boolean;
    actionStyles?: IUserFaceActionStyles;
    expanded?: boolean;
}

const UserFace: React.FC<IUserFaceProps> = (props) => {
    const state = useUserFaceState(props.user, props.showDetails, props.actionStyles);

    return (
        <UserFaceView
            {...{
                ...state,
                ...{
                    user: props.user,
                    index: props.index,
                    showDetails: props.showDetails,
                    actionStyles: props.actionStyles
                }
            }}
        />
    );
};
export default UserFace;
