/* eslint-disable react/display-name */

import React from 'react';
import { Checkbox, Dropdown, IPersonaProps, Text, TextField, Toggle } from '@fluentui/react';
import ColorSelector from '../ColorSelector/ColorSelector';
import IconPicker from '../IconPicker/IconPicker';
import ImagePicker from 'components/ImagePicker/ImagePicker';
import { PeoplePicker } from 'features/RoomList/PeoplePicker/PeoplePicker';

export type IDynamicFormType = 'text' | 'dropdown' | 'image' | 'color' | 'multiline-text' | 'icon' | 'toggle' | 'checkbox' | 'section-heading';

export interface IDynamicFormElementState {
    key: string;
    type: IDynamicFormType;
    label?: string;
    value?: any;
    description?: string;
    callout?: string;
    width?: string;
    height?: string;
    view?: string;
    isVisible?: boolean;
    disabled?: boolean;
}

export interface IDynamicFormStateProps {
    ElemDictionary: any;
}

export const useDynamicFormState: (onChange: any) => IDynamicFormStateProps = (onChange) => {
    const ElemDictionary = {
        text: (elem: IDynamicFormElementState) => (
            <TextField
                value={elem.value}
                key={elem.key}
                onChange={(event, newValue) => typeof newValue == 'string' && onChange(elem.key, newValue)}
                label={elem.label}
            ></TextField>
        ),
        dropdown: (elem: IDynamicFormElementState) => (
            <Dropdown
                key={elem.key}
                label={elem.label}
                options={elem.value.options}
                selectedKey={elem.value.selectedKey}
                onChange={(event, option) => onChange(elem.key, { options: elem.value.options, selectedKey: option?.key })}
            />
        ),
        image: (elem: IDynamicFormElementState) => (
            <ImagePicker
                selectedImageURL={elem.value}
                onImageSelect={(p: string) => onChange(elem.key, p)}
                key={elem.key}
                label={elem.label}
                view={elem.view || ''}
            />
        ),
        color: (elem: IDynamicFormElementState) => (
            <ColorSelector
                key={elem.key}
                value={elem.value}
                label={elem.label}
                onChange={(c: string) => onChange(elem.key, c)}
                callout={elem.callout}
                width={elem.width || '400px'}
                height={elem.height || '60px'}
            />
        ),
        'multiline-text': (elem: IDynamicFormElementState) => (
            <TextField
                multiline
                resizable={false}
                placeholder={elem.value}
                key={elem.key}
                onChange={(event, newValue) => typeof newValue == 'string' && onChange(elem.key, newValue)}
                label={elem.label}
            ></TextField>
        ),
        icon: (elem: IDynamicFormElementState) => (
            <IconPicker onChange={(i: string) => onChange(elem.key, i)} variant={'Rooms'} value={elem.value} label={elem.label} key={elem.key}></IconPicker>
        ),
        toggle: (elem: IDynamicFormElementState) => (
            <Toggle
                key={elem.key}
                inlineLabel
                label={elem.label}
                checked={elem.value}
                role="checkbox"
                onChange={(event, checked) => onChange(elem.key, checked)}
            ></Toggle>
        ),
        checkbox: (elem: IDynamicFormElementState) => (
            <Checkbox
                key={elem.key}
                label={elem.label}
                checked={elem.value}
                onChange={(event, checked) => onChange(elem.key, checked)}
                disabled={elem.disabled}
            ></Checkbox>
        ),
        'section-heading': (elem: IDynamicFormElementState) => (
            <Text variant="xLarge" key={elem.key}>
                {elem.label}
            </Text>
        ),
        personaSelector: (elem: IDynamicFormElementState) => (
            <PeoplePicker
                key={elem.key}
                label={elem.label}
                membersList={elem.value.membersList ? elem.value.membersList : []}
                selectedItems={elem.value.selectedItems}
                isVisible={elem.value.isVisible}
                onChangeSelections={(items?: IPersonaProps[] | undefined): void => {
                    const whitelistedIds = items?.map((item) => item.id);
                    onChange(elem.key, whitelistedIds);
                }}
            ></PeoplePicker>
        )
    };

    return { ElemDictionary };
};
