import * as React from 'react';

function SvgExit(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M5 3v26h18v-8l-1.094 1.125L21 23v4H7V5h14v4l.906.875L23 11V3H5zm11.688 8.281l1.437 1.438L15.844 15H28.03v2H15.844l2.281 2.281-1.438 1.438-4-4L12 16l.688-.719 4-4z"
                fill="#000"
            />
        </svg>
    );
}

export default SvgExit;
