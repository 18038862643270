import { isVisitor } from 'kits/authKit';
import { selector } from 'recoil';
import { aAvailability, aIdentityClaims } from 'shared-state/identity/atoms';
import { IProfile } from 'shared-state/identity/types';
import { aCurrentRoomId } from 'shared-state/location/atoms';

export const sProfile = selector<IProfile | undefined>({
    key: 'sProfile',
    get: ({ get }) => {
        const idClaims = get(aIdentityClaims);
        //const profileImage = get(aProfileImage);
        const room = get(aCurrentRoomId);
        const availability = get(aAvailability);
        if (!idClaims) return undefined;

        const nextProfile: IProfile = {
            uid: idClaims.uid,
            displayName: idClaims.displayName,
            profileImage: idClaims.profileImageURL,
            room: room,
            availability: availability,
            type: isVisitor(idClaims) ? 'visitor' : 'user'
        };

        return nextProfile;
    }
});
