import { getTheme, Persona, PersonaSize, Stack, Text } from '@fluentui/react';
import Badge from 'components/Badge';
import React from 'react';

export interface ISpeakerStatsUserViewProps {
    speakerTime: number | undefined;
    userId?: string;
    totalTime: number;
    userName: string;
    pretifyTime: (timeInMs: number) => string;
    profileImage: string | undefined;
    userType: 'user' | 'visitor' | 'unconfirmed_visitor' | 'manager' | 'owner' | undefined;
    colorReturner: (userName: string) => number;
}

const SpeakerStatsUserView: React.FC<ISpeakerStatsUserViewProps> = (props) => {
    const { speakerTime, totalTime, userName, pretifyTime, profileImage, userType, colorReturner } = props;

    const COLORS = ['#0B84A5', '#F6C85F', '#6F4E7C', '#9DD866', '#CA472F', '#FFA056', '#8DDDD0', '#0ba59d', '#5f9ef6', '#8c21cf', '#b01b00'];

    const theme = getTheme();

    return (
        <>
            <Stack style={{ margin: '1vh 0vh', position: 'relative', justifyContent: 'center', color: theme.palette.white }}>
                <Stack style={{ paddingLeft: 46 }}>
                    <Stack
                        style={{
                            height: 32,
                            borderRadius: 2,
                            width: `${speakerTime && (speakerTime / totalTime) * 100}%`,
                            backgroundColor: COLORS[colorReturner(userName)],
                            opacity: 0.4
                        }}
                    ></Stack>
                </Stack>
                <Stack
                    horizontal
                    horizontalAlign="space-between"
                    verticalAlign="center"
                    style={{
                        height: 32,
                        width: '100%',
                        paddingRight: 16,
                        position: 'absolute',
                        padding: 8,
                        textAlign: 'center',
                        fontWeight: 'bolder'
                    }}
                >
                    <Stack grow>
                        {userType == 'visitor' ? (
                            <Stack horizontal verticalAlign="center">
                                <Stack horizontalAlign="center" verticalAlign="start" style={{ width: '40px', paddingRight: 10 }}>
                                    <Badge text={userName} size="30" fontSize="small" />
                                </Stack>
                                <Text variant="medium" style={{ color: theme.palette.black }}>
                                    {userName.split(' ').length > 1
                                        ? `${userName.split(' ')[0]} ${userName.split(' ')[1].slice(0, 1).toUpperCase()}.`
                                        : `${userName.split(' ')[0]}`}
                                </Text>
                            </Stack>
                        ) : (
                            <Persona
                                size={PersonaSize.size32}
                                text={userName.split(' ')[1] ? `${userName.split(' ')[0]} ${userName.split(' ')[1].slice(0, 1)}.` : `${userName.split(' ')[0]}`}
                                imageUrl={profileImage}
                            ></Persona>
                        )}
                    </Stack>
                    <Text style={{ fontSize: '20px', color: theme.palette.black }}>{speakerTime && pretifyTime(speakerTime)}</Text>
                </Stack>
            </Stack>

            <Stack style={{ width: '100%', height: '1px', backgroundColor: 'lightgray' }}></Stack>
        </>
    );
};
export default SpeakerStatsUserView;
