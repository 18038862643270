import { InterfaceConfigOptions } from '@ivicos/react-jitsi/dist/types';
import { useCurrentRoomId } from 'shared-state/location/hook';

export const getJitsiConfig = (muteOnJoin?: 'audio' | 'both' | 'video'): any => {
    const [currentRoomId] = useCurrentRoomId();

    return {
        resolution: 480,
        constraints: {
            video: {
                height: {
                    ideal: 480,
                    max: 480,
                    min: 240
                }
            }
        },
        startWithAudioMuted: muteOnJoin && ['audio', 'both'].includes(muteOnJoin),
        startWithVideoMuted: muteOnJoin && ['video', 'both'].includes(muteOnJoin),
        channelLastN: 22,
        disableDeepLinking: true,
        enableLayerSuspension: true,
        disableAudioLevels: false,
        audioLevelsInterval: 200,
        hideConferenceTimer: currentRoomId?.includes('personal'),
        hideConferenceSubject: true,
        disableSelfViewSettings: true,
        disableTileEnlargement: true,
        disableScreensharingVirtualBackground: true
    };
};

export const getJitsiInterfaceConfig = (): InterfaceConfigOptions => {
    return {
        APP_NAME: 'Campus Alpha',
        SHOW_BRAND_WATERMARK: false,
        SHOW_POWERED_BY: false,
        SHOW_PROMOTIONAL_CLOSE_PAGE: false,
        DISABLE_VIDEO_BACKGROUND: true,
        TOOLBAR_BUTTONS: [],
        DISABLE_FOCUS_INDICATOR: true,
        DEFAULT_REMOTE_DISPLAY_NAME: 'Fellow team member',
        DEFAULT_LOCAL_DISPLAY_NAME: 'me',
        SHOW_JITSI_WATERMARK: false,
        SHOW_WATERMARK_FOR_GUESTS: false,
        VIDEO_LAYOUT_FIT: 'height',
        VIDEO_QUALITY_LABEL_DISABLED: true
    };
};
