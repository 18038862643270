import { useJitsiSessionRouter } from 'adapters/realtime-adapter/handlers';
import {
    Attachments,
    Chess,
    Exit,
    GalleryView,
    Marker,
    Mic,
    MicOff,
    Settings,
    ShareScreen,
    ShareScreenOff,
    SpeakerStatsBars,
    SpeakerView,
    Tictactoe,
    Video,
    VideoOff
} from 'kits/IconKit2';
import SvgEtherpad from 'kits/IconKit2/Etherpad';
import IvicosStrings from 'kits/stringKit';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router';
import { useWindowWidth } from 'shared-state/display-options/hooks';
import SvgVideoBackground from 'kits/IconKit2/VideoBackground';
import { isSafari } from 'react-device-detect';
import { useCurrentAreaId } from 'shared-state/location/hook';
import { useLocalProfile } from 'shared-state/identity/hooks';

export type ICallActionKey =
    | 'device-settings'
    | 'toggle-view'
    | 'toggle-audio'
    | 'toggle-video'
    | 'leave-room'
    | 'share-screen'
    | 'encrypt-room'
    | 'toggle-stats'
    | 'toggle-excalidraw'
    | 'toggle-etherpad'
    | 'toggle-chess'
    | 'toggle-tictactoe'
    | 'toggle-attachments'
    | 'toggle-background-dialog';

export interface ICallAction {
    key: ICallActionKey;
    title: string;
    icon: ReactElement;
    command?: string;
    iconVariant?: 'dark' | 'light' | 'red' | 'primary' | 'green';
    data?: any;
    onCommandSent?: () => void;
}

export interface ICallState {
    micIsMuted: boolean;
    cameraIsMuted: boolean;
    sharingIsOn: boolean;
    encryptionIsEnabled: boolean;
    addOnIsVisible: boolean;
    activeAddOn: string | undefined;
    statsAreVisible: boolean;
    tileViewIsEnabled: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useCallActions = (callState: ICallState) => {
    const { smallDevice, mediumDevice } = useWindowWidth();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { micIsMuted, cameraIsMuted, tileViewIsEnabled, sharingIsOn, encryptionIsEnabled, statsAreVisible, addOnIsVisible, activeAddOn } = callState;

    const routeHistory = useHistory();
    const [currentAreaId] = useCurrentAreaId();
    const localProfile = useLocalProfile();

    const { conditionRouting } = useJitsiSessionRouter('personal-' + localProfile?.uid + '_' + currentAreaId);

    let callActions: { [index: string]: ICallAction } = {};

    if (!(smallDevice || mediumDevice)) {
        callActions = {
            'device-settings': {
                key: 'device-settings',
                title: IvicosStrings.deviceSettingsActionTitle,
                command: 'toggleDeviceSettings',
                icon: <Settings />
            },
            'toggle-audio': {
                key: 'toggle-audio',
                title: IvicosStrings.toggleAudioActionTitle,
                command: 'toggleAudio',
                icon: micIsMuted ? <MicOff /> : <Mic />
            },
            'toggle-video': {
                key: 'toggle-video',
                title: IvicosStrings.toggleVideoActionTitle,
                command: 'toggleVideo',
                icon: cameraIsMuted ? <VideoOff /> : <Video />
            },
            'toggle-view': {
                key: 'toggle-view',
                title: IvicosStrings.toggleTileViewActionTitle,
                command: 'toggleTileView',
                icon: tileViewIsEnabled ? <GalleryView /> : <SpeakerView />
            },
            'toggle-stats': {
                key: 'toggle-stats',
                title: IvicosStrings.toggleStatsActionTitle,
                command: 'toggleStats',
                iconVariant: statsAreVisible ? 'primary' : 'dark',
                icon: <SpeakerStatsBars />
            },
            'toggle-background-dialog': {
                key: 'toggle-background-dialog',
                title: isSafari ? 'This feature is currently not supported in safari' : IvicosStrings.toggleBackgroundDialogActionTitle,
                command: isSafari ? undefined : 'toggleVirtualBackgroundDialog',
                icon: <SvgVideoBackground />
            },
            'share-screen': {
                key: 'share-screen',
                title: sharingIsOn ? IvicosStrings.shareScreenActionActiveTitle : IvicosStrings.shareScreenActionTitle,
                command: 'toggleShareScreen',
                data: { enable: !sharingIsOn },
                iconVariant: sharingIsOn ? 'red' : 'green',
                icon: sharingIsOn ? <ShareScreenOff /> : <ShareScreen />
            },

            // 'encrypt-room': {
            //     key: 'encrypt-room',
            //     title: encryptionIsEnabled ? IvicosStrings.toggleEncryptionActionActiveTitle : IvicosStrings.toggleEncryptionActionTitle,
            //     command: 'toggleE2ee',
            //     iconVariant: 'dark',
            //     icon: <EncryptedRoom />
            // },

            'leave-room': {
                key: 'leave-room',
                title: IvicosStrings.leaveRoomActionTitle,
                onCommandSent: () => {
                    routeHistory.push('/areas/' + currentAreaId);
                    conditionRouting();
                },
                iconVariant: 'red',
                icon: <Exit />
            },
            'toggle-excalidraw': {
                key: 'toggle-excalidraw',
                title: 'Toggle Excalidraw',
                command: 'toggleAddOn-excalidraw',
                iconVariant: addOnIsVisible && activeAddOn == 'excalidraw' ? 'red' : 'dark',
                icon: <Marker />
            },
            'toggle-etherpad': {
                key: 'toggle-etherpad',
                title: 'Toggle Etherpad',
                command: 'toggleAddOn-etherpad',
                iconVariant: addOnIsVisible && activeAddOn == 'etherpad' ? 'red' : 'dark',
                icon: <SvgEtherpad />
            },
            'toggle-attachments': {
                key: 'toggle-attachments',
                title: 'Toggle Attachments',
                command: 'toggleAttachmentPanel',
                iconVariant: 'dark',
                icon: <Attachments />
            },
            'toggle-chess': {
                key: 'toggle-chess',
                title: 'Toggle Chess',
                command: 'toggleAddOn-chess',
                iconVariant: addOnIsVisible ? 'red' : 'dark',
                icon: <Chess />
            },
            'toggle-tictactoe': {
                key: 'toggle-tictactoe',
                title: 'Toggle TicTacToe',
                command: 'toggleAddOn-tictactoe',
                iconVariant: addOnIsVisible ? 'red' : 'dark',
                icon: <Tictactoe />
            }
        };
    } else {
        callActions = {
            'device-settings': {
                key: 'device-settings',
                title: IvicosStrings.deviceSettingsActionTitle,
                command: 'toggleDeviceSettings',
                icon: <Settings />
            },
            'toggle-audio': {
                key: 'toggle-audio',
                title: IvicosStrings.toggleAudioActionTitle,
                command: 'toggleAudio',
                icon: micIsMuted ? <MicOff /> : <Mic />
            },
            'toggle-video': {
                key: 'toggle-video',
                title: IvicosStrings.toggleVideoActionTitle,
                command: 'toggleVideo',
                icon: cameraIsMuted ? <VideoOff /> : <Video />
            },
            'toggle-view': {
                key: 'toggle-view',
                title: IvicosStrings.toggleTileViewActionTitle,
                command: 'toggleTileView',
                icon: tileViewIsEnabled ? <GalleryView /> : <SpeakerView />
            },
            'leave-room': {
                key: 'leave-room',
                title: IvicosStrings.leaveRoomActionTitle,
                onCommandSent: () => {
                    routeHistory.push('/areas/' + currentAreaId);
                    conditionRouting();
                },
                iconVariant: 'red',
                icon: <Exit />
            }
        };
    }

    // random comment
    return callActions;
};
